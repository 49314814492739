import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useQueryParams } from 'hooks'

import { VerifyAccount, VERIFY_ACCOUNT, VerifyAccountVariables } from '../api'

export const useVerifyAccount = () => {
  const query = useQueryParams()
  const token = query.get('token')
  const emailToValidate = query.get('email')
  const { t } = useTranslation('login')

  const [verifyAccount, { loading, data }] = useMutation<VerifyAccount, VerifyAccountVariables>(
    VERIFY_ACCOUNT,
    {
      errorPolicy: 'all',
    },
  )

  useEffect(() => {
    if (token) {
      verifyAccount({ variables: { token } })
    }
  }, [token, verifyAccount])

  const isAccountVerified = !!data?.verifyAccount.success

  const accountVerificationText = isAccountVerified
    ? t('verifyEmailPage.validatedEmail')
    : t('verifyEmailPage.validatedEmailError')

  return {
    loading,
    isAccountVerified,
    accountVerificationText,
    isVerification: !!token,
    emailToValidate,
  }
}
