export const COLUMNS = {
  COMPANY: 'company-name',
  FIELD_NAME: 'field-name',
  LOT_NAME: 'lot-name',
  SEASON_NAME: 'season-name',
  EMERGENCE_DATE: 'emergence',
  HARVEST_DATE: 'harvest',
  FIRST_IRRIGATION_START_DATE: 'irrigation-start',
  FIRST_IRRIGATION_END_DATE: 'irrigation-end',
  DIFFERENTIATION_DATE: 'differentiation',
  FLOWERING_DATE: 'flowering',
  PHYSIOLOGICAL_MATURITY_DATE: 'physiological-maturity',
  YIELD: 'yield',
  PREPARATION_DATE: 'preparation',
  BURNING_DATE: 'burning',
  EAR_APPEARANCE_DATE: 'ear-appearance',
  PLANTING_DATE: 'planting',
  VARIETY: 'variety',
  PREVIOUS_CROP: 'prev-crop',
  CROP: 'crop',
  PRODUCTION_TYPE: 'production-type',
  ABANDONED_SIZE: 'abandoned-size',
  DELIVERY_CONDITION: 'delivery-condition',
  DELIVERY_MILL: 'delivery-mill',
  ESTIMATED_YIELD: 'estimated-yield',
}
