import { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { DrawMode, Features } from 'types'

import { Dam } from './types'

export const useFeatures = ({
  handleDams,
  handleSelectedDamToDelete,
  handleShowDeleteModal,
}: {
  handleDams: Dispatch<SetStateAction<Dam[]>>
  handleSelectedDamToDelete: (damToDelete: Dam) => void
  handleShowDeleteModal: (value: boolean) => void
}) => {
  const [mode, setMode] = useState<
    | {
        mode: Extract<DrawMode, 'direct_select'>
        options: { featureId: string }
      }
    | { mode: Exclude<DrawMode, 'direct_select'> }
  >({
    mode: 'simple_select',
  })
  const [selectedDamIndex, setSelectedDamIndex] = useState<number>()

  const onCreateFeature = useCallback(
    ({ features }: { features: Features }) => {
      if (!features) return

      const createdFeature = features[0]
      const createdFeatureId = createdFeature.id?.toString()
      if (!createdFeatureId) return

      handleDams(prevDams => {
        const newDams = [...prevDams]
        const createdDamIndex = prevDams.findIndex(dam => !dam.feature)
        // Workaround for show again dam on the map when user delete last dam and cancels deletion
        if (createdDamIndex === -1) return prevDams
        newDams[createdDamIndex] = {
          ...newDams[createdDamIndex],
          dam: { ...newDams[createdDamIndex].dam, id: createdFeatureId },
          feature: features[0],
        }
        return newDams
      })
      setMode({
        mode: 'direct_select',
        options: { featureId: createdFeatureId.toString() },
      })
    },
    [handleDams],
  )

  const onUpdateFeature = useCallback(
    ({ features }: { features: Features }) => {
      if (!features) return
      const updatedFeature = features[0]

      handleDams(prevDams => {
        const newDams = [...prevDams]

        const updatedDamIndex = newDams.findIndex(({ dam }) => dam.id === updatedFeature.id)

        newDams[updatedDamIndex] = {
          ...newDams[updatedDamIndex],
          feature: updatedFeature,
        }

        return newDams
      })
    },
    [handleDams],
  )

  const onDelete = useCallback(
    (e: { features: Features }) => {
      handleDams(prevDams => {
        const index = prevDams.findIndex(({ dam }) => dam.id === e.features?.[0].id)
        const damToDelete = prevDams[index]
        handleSelectedDamToDelete(damToDelete)
        handleShowDeleteModal(true)
        return prevDams.filter(dam => dam.dam.id !== damToDelete.dam.id)
      })
    },
    [handleDams, handleSelectedDamToDelete, handleShowDeleteModal],
  )

  const onSelectionChange = useCallback(
    (e: { features: Features }) => {
      if (!e.features?.length || !e.features?.[0].id) {
        setMode({ mode: 'simple_select' })
        return setSelectedDamIndex(undefined)
      }

      setMode({
        mode: 'direct_select',
        options: { featureId: e.features[0].id.toString() },
      })
      return handleDams(prevDams => {
        const index = prevDams.findIndex(({ dam }) => dam.id === e.features?.[0].id)
        setSelectedDamIndex(index)
        return prevDams
      })
    },
    [handleDams],
  )

  return {
    onCreateFeature,
    onUpdateFeature,
    onDelete,
    onSelectionChange,
    mode,
    setMode,
    selectedDamIndex,
    setSelectedDamIndex,
  }
}
