/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  CompanyWithUsers,
  CompanyWithUsersVariables,
  CompanyWithUsers_company_usersRoles as UserRole,
  CompanyWithUsers_company_invitations as Invitation,
  CompanyWithUsers_company_subregion as Subregion,
  CompanyWithUsers_company_usersRoles_user_organizationRole as CompanyUserOrganizationRole,
} from './types/CompanyWithUsers'
export type { LeaveCompany, LeaveCompanyVariables } from './types/LeaveCompany'
export type { ChangeUserRole, ChangeUserRoleVariables } from './types/ChangeUserRole'
export type { InviteUser, InviteUserVariables } from './types/InviteUser'
export type {
  ResendCompanyInvitation,
  ResendCompanyInvitationVariables,
} from './types/ResendCompanyInvitation'

export const COMPANY_WITH_USERS = gql`
  query CompanyWithUsers($id: Int!) {
    company(id: $id) {
      id
      name
      logo
      governmentId
      irrigationWorkersAccessMode
      phoneNumber
      subregion {
        id
        region {
          id
          name
        }
      }
      organization {
        id
      }
      country {
        id
      }
      usersRoles {
        role
        user {
          id
          email
          name
          lastName
          isVerified
          profilePicture
          organizationRole {
            role
          }
        }
      }
      invitations {
        id
        email
        role
      }
    }
  }
`

export const LEAVE_COMPANY = gql`
  mutation LeaveCompany($companyId: Int!, $userId: Int!) {
    leaveCompany(companyId: $companyId, userId: $userId) {
      user {
        id
        email
        companiesRoles {
          role
          company {
            id
            name
            country {
              id
              lng
              code
            }
          }
        }
      }
    }
  }
`

export const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($userId: Int!, $companyId: Int!, $role: UserCompanyRole!) {
    changeUserRole(userId: $userId, companyId: $companyId, role: $role) {
      company {
        id
        name
        usersRoles {
          role
          user {
            id
            email
          }
        }
      }
    }
  }
`

export const INVITE_USER = gql`
  mutation InviteUser($inviteUserDTO: InviteUserInput!) {
    inviteUser(inviteUserDTO: $inviteUserDTO) {
      company {
        id
        name
        usersRoles {
          role
          user {
            id
            email
          }
        }
        invitations {
          id
          email
          role
        }
      }
    }
  }
`

export const RESEND_COMPANY_INVITATION = gql`
  mutation ResendCompanyInvitation($id: Int!) {
    resendInvitation(id: $id) {
      success
    }
  }
`
