import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { MapImage } from 'features/MainRouter/features/DasboardRouter/features/components'
import { LANGUAGE } from 'consts'
import { LocaleLanguage } from 'types'
import { EllipsisText } from 'components'

import { StyledTable } from './components'
import { ReuseLot } from '../../hooks/useReuseLots/api'

const ELLIPSIS_TEXT_STYLE = { width: '100%', fontWeight: 400 }

interface Props {
  reusableLots?: ReuseLot[]
  isLoading: boolean
  selectedRowKeys: React.Key[]
  onSelectChange: (newSelectedRowKeys: React.Key[]) => void
}

export const Table: React.FC<Props> = ({
  reusableLots,
  selectedRowKeys,
  onSelectChange,
  isLoading,
}) => {
  const { t } = useTranslation('common')
  const language = LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]

  interface DataType {
    id: React.Key
    name: string
    area: string
    crop: string
    variety: string
    lotCoordinates?: number[][]
  }

  const columns: ColumnsType<DataType> = [
    {
      render: ({ lotCoordinates }: DataType) => {
        return (
          <MapImage width="110px" height="80px" lotCoordinates={lotCoordinates} isOnClickDisabled />
        )
      },
    },
    {
      render: ({ name }: DataType) => (
        <EllipsisText text={name} overlayInnerStyle={ELLIPSIS_TEXT_STYLE} />
      ),
    },
    {
      dataIndex: 'area',
      width: '75px',
    },
    {
      render: ({ crop }: DataType) => (
        <EllipsisText text={crop} overlayInnerStyle={ELLIPSIS_TEXT_STYLE} />
      ),
    },
    {
      render: ({ variety }: DataType) => (
        <EllipsisText text={variety} overlayInnerStyle={ELLIPSIS_TEXT_STYLE} />
      ),
    },
  ]

  const dataSource =
    reusableLots?.map(reusableLot => {
      return {
        id: reusableLot.id,
        name: reusableLot.name,
        area: t('systemsFormat.area', { val: { area: reusableLot.size } }),
        crop: reusableLot.crop.locales[language],
        variety: reusableLot.variety?.locales[language] ?? '-',
        lotCoordinates: reusableLot.area?.coordinates[0],
      }
    }) ?? []

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const onClickRow = (id: React.Key) => {
    const newSelectedRowKeys = selectedRowKeys.includes(id)
      ? selectedRowKeys.filter(rowKey => rowKey !== id)
      : [...selectedRowKeys, id]
    onSelectChange(newSelectedRowKeys)
  }

  return (
    <StyledTable
      loading={isLoading}
      rowSelection={rowSelection}
      columns={columns}
      showHeader={false}
      dataSource={dataSource}
      scroll={{ y: 350 }}
      pagination={false}
      rowKey={({ id }) => id}
      onRow={({ id }) => {
        return {
          onClick: () => onClickRow(id),
          style: { cursor: 'pointer' },
        }
      }}
    />
  )
}
