/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  RtkLots as RtkLotsQuery,
  RtkLotsVariables,
  RtkLots_lotsV2_results as RtkLots,
} from './types/RtkLots'

export const RTK_LOTS = gql`
  query RtkLots($seasonId: Int!, $fieldId: Int!) {
    lotsV2(seasonId: $seasonId, fieldId: $fieldId) {
      results {
        id
        rtk {
          coordinates
        }
      }
    }
  }
`
