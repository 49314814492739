import { useMutation } from '@apollo/client'

import { ActiveCompanyContext } from 'contexts'

import { DELETE_COMPANY, DeleteCompany, DeleteCompanyVariables } from './api'

interface Props {
  onCompleted: () => void
}

export const useDeleteCompany = ({ onCompleted }: Props) => {
  const { setActiveCompany } = ActiveCompanyContext.useActiveCompanyContext()

  const [deleteCompany, { loading }] = useMutation<DeleteCompany, DeleteCompanyVariables>(
    DELETE_COMPANY,
    {
      onCompleted: ({ deleteCompany: deleteCompanyResponse }) => {
        onCompleted()
        const newActiveCompanyId = deleteCompanyResponse.user.companiesRoles?.length
          ? deleteCompanyResponse.user.companiesRoles[0].company.id
          : undefined
        const newActiveCountryId = deleteCompanyResponse.user.companiesRoles?.length
          ? deleteCompanyResponse.user.companiesRoles[0].company.country.id
          : undefined
        const newActiveCompanyName = deleteCompanyResponse.user.companiesRoles?.length
          ? deleteCompanyResponse.user.companiesRoles[0].company.name
          : undefined

        setActiveCompany({
          id: newActiveCompanyId,
          countryId: newActiveCountryId,
          name: newActiveCompanyName,
        })
      },
    },
  )

  return { deleteCompany, loading }
}
