import { Table as AntTable, Input } from 'antd'
import styled from 'styled-components'

import {
  VarietiesSelect as BaseVarietiesSelect,
  CropsSelect as BaseCropsSelect,
  DatePicker as BaseDatePicker,
} from 'components'
import { DATE_FORMATS } from 'consts'

export { DeliveryMillSelect } from './DeliveryMillSelect'
export { InputRender } from './InputRender'

export const StyledInput = styled(Input)`
  width: 100%;

  > .ant-input {
    border-radius: 0;
  }
`

export const DatePicker = styled(BaseDatePicker).attrs({
  format: DATE_FORMATS.FULL_DATE,
})`
  width: 100%;
  padding: 7px;
`
export const StyledTable = styled(AntTable)`
  & .ant-table-cell {
    padding: 5px !important;
  }

  & .ant-table-tbody > tr > td {
    border-bottom: unset;
  }

  & th {
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.grey3} !important`};
    background: ${({ theme }) => theme.colors.transparent} !important;
    border-radius: 0px !important;
    text-align: center !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  & td:nth-child(even) {
    background: ${({ theme }) => theme.colors.grey2} !important;
  }
  .ant-table-tbody {
    & tr:last-child {
      & td:nth-child(even) {
        border-bottom-left-radius: ${({ theme }) => theme.foundation.borderRadius};
        border-bottom-right-radius: ${({ theme }) => theme.foundation.borderRadius};
      }
    }
  }
  & .ant-table-cell-scrollbar {
    box-shadow: none;
  }

  .ant-table-cell-fix-left {
    background: ${({ theme }) => theme.colors.grey1} !important;
  }

  .ant-table-row .ant-table-cell {
    > span > span {
      color: ${({ theme }) => theme.colors.black};
    }
  }
` as typeof AntTable

export const VarietiesSelect = styled(BaseVarietiesSelect)`
  width: 100%;
`
export const CropsSelect = styled(BaseCropsSelect)`
  width: 100%;
`
