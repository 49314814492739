import { memo, useState } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts'
import { useTheme } from 'styled-components'

const RenderActiveShape = (props: {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  payload: { name: string; color: string }
  percent: number
}) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, percent } = props
  const { colors } = useTheme()

  return (
    <g>
      <text x={cx} y={cy} dy={5} textAnchor="middle" fill={colors.olive}>
        {`${(percent * 100).toFixed()}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 5}
        outerRadius={outerRadius + 10}
        fill={payload.color}
      />
    </g>
  )
}

interface Props {
  data: { name: string; value?: number; color: string }[]
}

export const Chart: React.FC<Props> = memo(({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { colors } = useTheme()
  const onPieEnter = (_: unknown, index: number) => {
    setActiveIndex(index)
  }

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={props => <RenderActiveShape {...props} />}
          data={data}
          innerRadius={35}
          outerRadius={45}
          fill={colors.olive4}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {data.map(entry => (
            <Cell key={entry.name} fill={entry.color} stroke={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
})
