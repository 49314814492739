import { Button, Space as AntSpace } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CenteredText } from '../../../components'

export const LoginButton = styled(Button)`
  width: 100%;
`

export const MainText = styled(CenteredText)`
  margin-bottom: 8px;
`

export const Space = styled(AntSpace)`
  & .ant-form-item {
    margin-bottom: 0 !important;
  }
`

export const LoginErrorText = styled(CenteredText)`
  margin-top: 10px;
  font-size: 14px;
`

export const StyledLink = styled(Link)`
  font-size: 20px;
  font-weight: 700;
` as typeof Link
