import { Col, Row } from 'antd'

import { HistoricalIrrigation } from '../../api'
import { LeftCardsContainer, ChartCardsContainer } from './components'

type Values = {
  daysSinceEmergence: number
  chlorophyll: number
}
interface Props {
  daysToHarvestFromEmergence?: number
  densitySurfaceYield?: number
  hasEmergenceDate: boolean
  historicalIrrigationValues: HistoricalIrrigation['values']
  emergenceDate?: string
  yieldName?: string
  targetYieldCurveValues?: Values[]
  realYieldCurveValues?: Values[]
  lotCoordinates?: number[][]
  fieldId?: number
  lotId?: number
}

export const ReportTab: React.FC<Props> = ({
  daysToHarvestFromEmergence,
  densitySurfaceYield,
  historicalIrrigationValues,
  hasEmergenceDate,
  emergenceDate,
  yieldName,
  targetYieldCurveValues,
  realYieldCurveValues,
  fieldId,
  lotId,
  lotCoordinates,
}) => {
  return (
    <Row wrap={false} gutter={24}>
      <Col flex="230px">
        <LeftCardsContainer
          daysToHarvestFromEmergence={daysToHarvestFromEmergence}
          densitySurfaceYield={densitySurfaceYield}
          fieldId={fieldId}
          lotId={lotId}
          lotCoordinates={lotCoordinates}
        />
      </Col>
      <Col flex="auto">
        <ChartCardsContainer
          hasEmergenceDate={hasEmergenceDate}
          historicalIrrigationValues={historicalIrrigationValues}
          emergenceDate={emergenceDate}
          yieldName={yieldName}
          targetYieldCurveValues={targetYieldCurveValues}
          realYieldCurveValues={realYieldCurveValues}
        />
      </Col>
    </Row>
  )
}
