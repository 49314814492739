/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { DeleteField, DeleteFieldVariables } from './types/DeleteField'
export type { DeleteSeason, DeleteSeasonVariables } from './types/DeleteSeason'
export type {
  CreateSeason,
  CreateSeasonVariables,
  CreateSeason_createSeason_season as Season,
} from './types/CreateSeason'

export const DELETE_FIELD = gql`
  mutation DeleteField($id: Int!) {
    deleteField(id: $id) {
      success
    }
  }
`

export const DELETE_SEASON = gql`
  mutation DeleteSeason($id: Int!) {
    deleteSeason(id: $id) {
      success
    }
  }
`

export const CREATE_SEASON = gql`
  mutation CreateSeason($seasonDTO: CreateSeasonInput!) {
    createSeason(createSeasonDTO: $seasonDTO) {
      season {
        id
        name
        startDate
        endDate
        description
        isArchived
      }
    }
  }
`
