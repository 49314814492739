import { Form, Row, Typography } from 'antd'
import styled from 'styled-components'

export { DamsList } from './DamsList'
export { DropdownMenu } from './DropdownMenu'

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  font-size: 16px;
  font-style: normal;
`
export const StyledRow = styled(Row)`
  font-size: 13px;
  background-color: ${({ theme }) => theme.colors.grey2};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 14px;
  width: 100%;
`

export const StyledItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`
