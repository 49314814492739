import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { DatePicker as BaseDatePicker } from 'antd'
import dayjs from 'dayjs'

import { CalendarPanel } from 'components'
import { DATE_FORMATS } from 'consts'
import { CalendarIcon, CloudyIcon } from 'assets/icons'
import { DateState, CalendarDateIndex } from 'types'
import { LotsUtils } from 'features/MainRouter/features/Map/utils'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
`

const DatePicker = styled(BaseDatePicker)`
  width: 100% !important;
  height: 48px !important;
  .ant-picker-input > input::placeholder {
    color: ${({ theme }) => theme.colors.white};
  }
  input {
    text-align: center;
    font-size: 11px !important;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white} !important;
    height: 100%;
  }
  &.ant-picker {
    border: none;
    box-shadow: none;
    width: 160px;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    padding: 10px 16px;
    border-radius: ${({ theme }) => theme.foundation.smallRadius};
  }
  .ant-picker-input {
    flex-direction: row-reverse;
  }
` as unknown as typeof BaseDatePicker

interface Props {
  calendarDates: CalendarDateIndex[]
  onChange: (date?: string) => void
  defaultValue?: string
  setDates: Dispatch<SetStateAction<DateState>>
  isShowDamsModeSelected: boolean
}

export const Calendar: React.FC<Props> = ({
  calendarDates,
  onChange,
  defaultValue,
  setDates,
  isShowDamsModeSelected,
}) => {
  const { colors } = useTheme()

  const findDateInCalendar = useCallback(
    (date: dayjs.Dayjs) =>
      calendarDates.find(
        calendarDate =>
          dayjs(calendarDate.date).format(DATE_FORMATS.DATE) === date.format(DATE_FORMATS.DATE),
      ),
    [calendarDates],
  )

  const disabledDate = (current: dayjs.Dayjs) => {
    const isDateInCalendar = findDateInCalendar(current)

    if (!isDateInCalendar || !isDateInCalendar.mapIndexInfo.isAvailable) return true

    return false
  }

  const getDateStyleAndInfo = (date: dayjs.Dayjs) => {
    const isDateInCalendar = findDateInCalendar(date)

    if (!isDateInCalendar || !isDateInCalendar?.mapIndexInfo.isAvailable)
      return { style: { border: '0', borderRadius: '0' }, isDateInCalendar }

    return {
      style: {
        border: `1px solid ${colors.white}`,
        borderRadius: '100%',
        backgroundColor: 'transparent',
      },
      isDateInCalendar,
    }
  }

  const calendarValue = useMemo(
    () => (defaultValue ? dayjs(defaultValue) : undefined),
    [defaultValue],
  )

  return (
    <Container>
      <DatePicker
        inputReadOnly
        disabledDate={disabledDate}
        format={DATE_FORMATS.STRING_MONTH}
        value={calendarValue}
        allowClear={false}
        showToday={false}
        suffixIcon={<CalendarIcon />}
        panelRender={originalPanel => {
          return <CalendarPanel>{originalPanel}</CalendarPanel>
        }}
        onChange={date => {
          if (!date) return
          const calendarDate = findDateInCalendar(date)

          const dateHasUrl = LotsUtils.dateHasUrls(calendarDate)

          if (!dateHasUrl && !isShowDamsModeSelected) {
            const fromYearMonth = new Date(date.toDate())
            const toYearMonth = new Date(date.toDate())
            setDates({ fromYearMonth, toYearMonth, newDates: true })
          }
          onChange(dayjs(date).format(DATE_FORMATS.DATE_WITH_SEPARATOR))
        }}
        cellRender={(current, info) => {
          if (info.type !== 'date') return info.originNode
          const style = getDateStyleAndInfo(current)
          return (
            <div className="ant-picker-cell-inner" style={style.style}>
              {style.isDateInCalendar && !style.isDateInCalendar.mapIndexInfo.isAvailable ? (
                <CloudyIcon />
              ) : (
                current.date()
              )}
            </div>
          )
        }}
      />
    </Container>
  )
}
