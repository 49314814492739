import { gql } from '@apollo/client'

export type { LoginWithToken } from './types/LoginWithToken'

export const LOGIN_WITH_TOKEN = gql`
  mutation LoginWithToken {
    loginWithToken {
      success
      token
      user {
        id
        name
        lastName
        email
        organizationRole {
          organization {
            id
            name
          }
        }
      }
    }
  }
`
