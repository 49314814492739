import { Input } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { UpdateLotByIdVariables } from 'hooks/useUpdateLot'
import { LANGUAGE_CODES } from 'consts'
import { ConversionUtils } from 'utils'

const StyledInput = styled(Input)`
  width: 100%;
  > .ant-input {
    border-radius: 0;
  }
`

interface Props {
  lotId?: number
  abandonedSize?: number
  onChange: ({ variables }: { variables: UpdateLotByIdVariables }) => void
}

export const AbandonedSizeInput: React.FC<Props> = ({ lotId, abandonedSize, onChange }) => {
  const { t: commonT } = useTranslation('common')

  const isUsLanguage = i18next.resolvedLanguage === LANGUAGE_CODES.US

  return (
    <StyledInput
      defaultValue={isUsLanguage ? ConversionUtils.hectareToAcres(abandonedSize) : abandonedSize}
      suffix={commonT('systems.areaAbbreviation')}
      onBlur={(event: React.FormEvent<HTMLInputElement>) => {
        if (!lotId) return

        const abandonedSizeNumber = Number(event.currentTarget.value)
        const newAbandonedSize = isUsLanguage
          ? ConversionUtils.acresTohectare(abandonedSizeNumber)
          : abandonedSizeNumber
        onChange({
          variables: { updateLotDto: { id: lotId, abandonedSize: newAbandonedSize ?? null } },
        })
      }}
    />
  )
}
