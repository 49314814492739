import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Ball = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.new.secondary[600]};
  border-radius: 50%;
`
const AutomaticBall = styled(Ball)`
  background-color: ${({ theme }) => theme.colors.new.timelineViolet};
`

const SatelliteBall = styled(Ball)`
  background-color: ${({ theme }) => theme.colors.new.timelineLightBlue};
`

const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.grey1};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`

const Title = styled(Text)`
  font-weight: 700;
`

export const Content = ({ title }: { title?: string }) => {
  const { t } = useTranslation('lot-dashboard')

  const information = [
    { text: t('timeline.information.manual'), ball: <Ball /> },
    { text: t('timeline.information.satellite'), ball: <SatelliteBall /> },
    {
      text: t('timeline.information.estimated'),
      ball: <AutomaticBall />,
    },
  ]

  return (
    <Row gutter={[0, 10]}>
      {title && <Title>{title}</Title>}
      {information.map(data => {
        return (
          <Row gutter={20} align="middle">
            <Col>{data.ball}</Col>
            <Col>
              <Text>{data.text}</Text>
            </Col>
          </Row>
        )
      })}
    </Row>
  )
}
