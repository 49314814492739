import { UpdateRiceLotVariables } from 'hooks/useUpdateRiceLot/api'
import { usePermissions } from 'hooks'

import { Body } from './components'
import { EstimatedYield } from '../../../../api'

interface Props {
  estimatedYield?: EstimatedYield
  size: number
  yieldValue?: number
  varietyName?: string
  emergenceDate?: string
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
  riceLotId?: number
}

export const Yield: React.FC<Props> = ({
  estimatedYield,
  size,
  yieldValue,
  riceLotId,
  updateRiceLotMutation,
}) => {
  const { permissions } = usePermissions()

  return (
    <Body
      estimatedBySubregion={{
        inTn: estimatedYield?.value ? estimatedYield?.value / 1000 : undefined,
        kgPerHa: estimatedYield?.value ? estimatedYield.value / size : undefined,
      }}
      estimatedByLot={{
        inTn: estimatedYield?.lotValue ? estimatedYield?.lotValue / 1000 : undefined,
        kgPerHa: estimatedYield?.lotValue ? estimatedYield.lotValue / size : undefined,
      }}
      real={{
        inTn: yieldValue ? yieldValue / 1000 : undefined,
        kgPerHa: yieldValue ? yieldValue / size : undefined,
      }}
      riceLotId={riceLotId}
      updateRiceLotMutation={updateRiceLotMutation}
      isFeatureSetGrupoDiana={permissions.isFeatureSetGrupoDiana}
      isAnOrganizationUser={permissions.isAnOrganizationUser}
      size={size}
    />
  )
}
