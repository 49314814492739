import { Col } from 'antd'
import styled from 'styled-components'

import { Layout as BaseLayout } from 'components'

export { InformationCard } from './InformationCard'
export { LotCard } from './FieldsAndLotsBody/components/LotCard'
export { SortSelect } from './SortSelect'
export { EmptyResultsCard } from './EmptyResultsCard'
export { FieldsAndLotsBody } from './FieldsAndLotsBody'

export const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: column;
`

export const ScrollColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ContainerRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 90%;
  grid-gap: 24px;
  overflow-y: auto;
  overflow-x: auto;
`
