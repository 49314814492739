import styled from 'styled-components'

import { FieldsSelect as BaseFieldsSelect } from 'components'

import { MapSidebar as BaseMapSidebar } from '../../../components'

export { FieldCard } from './FieldCard'
export { GeocoderControl } from './GeocoderControl'
export { CreationFeedbackCard } from './CreationFeedbackCard'
export { Control } from '../../../components/Control'

export const FieldsSelect = styled(BaseFieldsSelect)`
  margin-bottom: 30px;
  @media (max-height: 735px) {
    margin-bottom: 15px;
  }
`
export const Container = styled.div`
  height: 100%;
  .mapboxgl-ctrl-top-right {
    display: none;
  }
`

export const LeftSidebar = styled.div`
  width: 300px;
  position: absolute;
  top: 25px;
  left: 50px;
  z-index: 10;
`

export const MapSidebar = styled(BaseMapSidebar)`
  top: 25px;
`
