import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { HistoricalIrrigation } from '../../../../api'
import { TargetYield } from '../../../TargetYield'
import { PendingEmergenceDateTooltip } from '../../../PendingEmergenceDateTooltip'
import { HistoricIrrigationCard } from './components'

type Values = {
  daysSinceEmergence: number
  chlorophyll: number
}
interface Props {
  hasEmergenceDate: boolean
  historicalIrrigationValues: HistoricalIrrigation['values']
  emergenceDate?: string
  yieldName?: string
  targetYieldCurveValues?: Values[]
  realYieldCurveValues?: Values[]
}

export const ChartCardsContainer: React.FC<Props> = ({
  historicalIrrigationValues,
  hasEmergenceDate,
  emergenceDate,
  yieldName,
  targetYieldCurveValues,
  realYieldCurveValues,
}) => {
  const { t } = useTranslation('lot-dashboard')

  return (
    <Row gutter={[0, 24]}>
      <PendingEmergenceDateTooltip hasEmergenceDate={hasEmergenceDate}>
        <Col span={24}>
          <HistoricIrrigationCard
            title={t('irrigationCard.title')}
            subTitle={`${t('irrigationCard.subtitle')} ${t('irrigationCard.hydricStatus')}`}
            historicalIrrigationValues={historicalIrrigationValues}
          />
        </Col>
      </PendingEmergenceDateTooltip>
      <Col span={24}>
        <TargetYield
          emergenceDate={emergenceDate}
          yieldName={yieldName}
          targetYieldCurveValues={targetYieldCurveValues}
          realYieldCurveValues={realYieldCurveValues}
          height="300px"
        />
      </Col>
    </Row>
  )
}
