import { Row, Dropdown } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { useState } from 'react'

import { SelectField } from 'components/fields-selects/types'
import { NAVIGATION } from 'consts'

import { Lot } from '../../types'
import {
  LotsList,
  StyledCard,
  AddLotButton,
  ConfirmLotsButton,
  Title,
  Text,
  CancelButton,
  DropdownMenu,
  ReuseLotsModal,
} from './components'
import { ReuseLot } from './components/ReuseLotsModal/hooks/useReuseLots/api'

interface Props {
  field?: SelectField
  lots: Lot[]
  selectedLotIndex?: number
  onSelectLot: (lotIndex: number) => void
  onAddLot: () => void
  onChangeLot: (lot: Lot['lot'], lotIndex: number) => void
  onDeleteLot: (lotIndex: number) => void
  onConfirmLots: () => void
  loading?: boolean
  handleKml: (file: UploadChangeParam<UploadFile>) => void
  onDrawMode: boolean
  seasonId?: number
  handleReuseLots: (lots: ReuseLot[]) => void
  onAddLotWithoutPerimeter: () => void
}

export const LotsCard: React.FC<Props> = ({
  field,
  lots,
  selectedLotIndex,
  onSelectLot,
  onAddLot,
  onChangeLot,
  onDeleteLot,
  onConfirmLots,
  loading,
  handleKml,
  onDrawMode,
  seasonId,
  handleReuseLots,
  onAddLotWithoutPerimeter,
}) => {
  const [showReuseLotsModal, setShowReuseModal] = useState(false)
  const [showAddLotDropdown, setShowAddLotDropdown] = useState(false)

  const { t } = useTranslation('lot-settings')
  const navigate = useNavigate()

  if (!field) return null

  const onConfirmReuseLots = (reusableLots: ReuseLot[]) => {
    setShowReuseModal(false)
    handleReuseLots(reusableLots)
  }

  return (
    <>
      <StyledCard>
        <Title level={3}>{field.name}</Title>
        <Text>{t('description')}</Text>
        <Row justify="space-between">
          <Dropdown
            onOpenChange={() => setShowAddLotDropdown(prevState => !prevState)}
            dropdownRender={() => (
              <DropdownMenu
                handleKml={file => {
                  setShowAddLotDropdown(false)
                  handleKml(file)
                }}
                onAddLot={() => {
                  setShowAddLotDropdown(false)
                  onAddLot()
                }}
                onReuseButtonClick={() => {
                  setShowAddLotDropdown(false)
                  setShowReuseModal(true)
                }}
                onAddLotWithoutPerimeter={() => {
                  setShowAddLotDropdown(false)
                  onAddLotWithoutPerimeter()
                }}
              />
            )}
            trigger={['click']}
            open={showAddLotDropdown}
          >
            <AddLotButton>
              {t('buttons.addLot')}
              <DownOutlined />
            </AddLotButton>
          </Dropdown>
        </Row>
        <LotsList
          lots={lots}
          selectedLotIndex={selectedLotIndex}
          onSelect={onSelectLot}
          onChange={onChangeLot}
          onDelete={onDeleteLot}
          loading={loading}
          disableDelete={onDrawMode}
        />
        <Row justify="end">
          <CancelButton onClick={() => navigate(NAVIGATION.GO_BACK)}>
            {t('buttons.cancel')}
          </CancelButton>
          <ConfirmLotsButton
            disabled={!lots.length || lots.some(({ lot }) => !lot.name || !lot.cropId || !lot.size)}
            onClick={onConfirmLots}
          >
            {t('buttons.confirmLotsText')}
          </ConfirmLotsButton>
        </Row>
      </StyledCard>
      {showReuseLotsModal && (
        <ReuseLotsModal
          fieldName={field.name}
          onCancel={() => setShowReuseModal(false)}
          seasonId={seasonId}
          fieldId={field.id}
          onSuccess={onConfirmReuseLots}
        />
      )}
    </>
  )
}
