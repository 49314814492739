import { Row, Col, Typography, Space, Progress as AntProgress } from 'antd'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { IndicatorStatus, Indicators } from 'types'

import { Indicator } from '../../../../../../../../components'

const { Text: AntText } = Typography

const Container = styled(Space)`
  background-color: ${({ theme }) => theme.colors.grey1};
  width: 290px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 22px;
`

interface ProgressProps {
  $showText?: boolean
}

const Progress = styled(AntProgress)<ProgressProps>`
  & .ant-progress-text {
    font-size: 14px;
    font-weight: 600;
    display: ${({ $showText = true }) => ($showText ? 'inline' : 'none')};
    color: ${({ theme }) => theme.colors.olive5} !important;
  }
`

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive5};
  font-size: 16px;
  font-weight: 600;
`

const ProgressText = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive5};
  font-size: 14px;
  font-weight: 400;
`
const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const STROKE_COLOR = '#72B2ED'

interface Props {
  sufficientWaterPercentage: number
  irrigationIndicator?: IndicatorStatus
  hasEmergenceDate: boolean
  isHarvested: boolean
}

export const IrrigationMonitorCard: React.FC<Props> = ({
  sufficientWaterPercentage,
  irrigationIndicator,
  hasEmergenceDate,
  isHarvested,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation('lot-dashboard')

  return (
    <Container size={22} direction="vertical">
      <Row justify="space-between">
        <Col>
          <Text>{t('irrigationCard.title')}</Text>
        </Col>
        <Col>
          <Indicator type={Indicators.IRRIGATION} indicatorStatus={irrigationIndicator} />
        </Col>
      </Row>
      <Row gutter={10} wrap={false}>
        <Col>
          <Progress
            type="circle"
            percent={sufficientWaterPercentage}
            size={55}
            strokeLinecap="square"
            strokeColor={STROKE_COLOR}
            trailColor={colors.grey2}
            $showText={hasEmergenceDate}
            format={percent => (isHarvested ? '-' : `${percent} %`)}
          />
        </Col>
        <StyledCol>
          {hasEmergenceDate ? (
            <>
              <ProgressText>{t('irrigationCard.hydricStatus')}</ProgressText>
            </>
          ) : (
            <>
              <ProgressText>{t('irrigationCard.emergenceDatePending')}</ProgressText>
            </>
          )}
        </StyledCol>
      </Row>
    </Container>
  )
}
