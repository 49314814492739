import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'

import { useCompanyMilestones, usePermissions } from 'hooks'
import { RiceLotStatus } from 'types'

import { COLUMNS } from '../../../consts'

export const useLotsManagementColumns = (isAnAdminUser: boolean) => {
  const { t } = useTranslation('lots-management')

  const { activeCompanyMilestones } = useCompanyMilestones()
  const { permissions } = usePermissions()

  const allColumns = [
    {
      label: t('table.columns.fieldName'),
      value: COLUMNS.FIELD_NAME,
      order: 1,
      enabled: !!permissions.fieldEntity,
    },
    {
      label: t('table.columns.seasonName'),
      value: COLUMNS.SEASON_NAME,
      order: 2,
      enabled: true,
    },
    {
      label: t('table.columns.previousCrop'),
      value: COLUMNS.PREVIOUS_CROP,
      order: 3,
      enabled: true,
    },
    {
      label: t('table.columns.preparation'),
      value: COLUMNS.PREPARATION_DATE,
      order: 4,
      enabled: activeCompanyMilestones?.includes(RiceLotStatus.PREPARATION),
    },
    {
      label: t('table.columns.burning'),
      value: COLUMNS.BURNING_DATE,
      order: 5,
      enabled: activeCompanyMilestones?.includes(RiceLotStatus.BURNING),
    },
    {
      label: t('table.columns.planting'),
      value: COLUMNS.PLANTING_DATE,
      order: 6,
      enabled: activeCompanyMilestones?.includes(RiceLotStatus.PLANTING),
    },
    {
      label: t('table.columns.emergence'),
      value: COLUMNS.EMERGENCE_DATE,
      order: 7,
      enabled: isAnAdminUser && activeCompanyMilestones?.includes(RiceLotStatus.EMERGENCE),
    },
    {
      label: t('table.columns.initialIrrigation'),
      value: COLUMNS.FIRST_IRRIGATION_START_DATE,
      order: 8,
      enabled: activeCompanyMilestones.includes(RiceLotStatus.START_IRRIGATION),
    },
    {
      label: t('table.columns.irrigationCompleted'),
      value: COLUMNS.FIRST_IRRIGATION_END_DATE,
      order: 9,
      enabled: activeCompanyMilestones.includes(RiceLotStatus.END_IRRIGATION),
    },
    {
      label: t('table.columns.panicleInitiation'),
      value: COLUMNS.DIFFERENTIATION_DATE,
      order: 10,
      enabled: activeCompanyMilestones.includes(RiceLotStatus.DIFFERENTIATION),
    },
    {
      label: t('table.columns.flowering'),
      value: COLUMNS.FLOWERING_DATE,
      order: 11,
      enabled: activeCompanyMilestones.includes(RiceLotStatus.FLOWERING),
    },
    {
      label: t('table.columns.earAppearance'),
      value: COLUMNS.EAR_APPEARANCE_DATE,
      order: 12,
      enabled: activeCompanyMilestones.includes(RiceLotStatus.EAR_APPEARANCE),
    },
    {
      label: t('table.columns.maturation'),
      value: COLUMNS.PHYSIOLOGICAL_MATURITY_DATE,
      order: 13,
      enabled: activeCompanyMilestones.includes(RiceLotStatus.PHYSIOLOGICAL_MATURITY),
    },
    {
      label: t('table.columns.harvest'),
      value: COLUMNS.HARVEST_DATE,
      order: 14,
      enabled: true,
    },
    {
      label: t('table.columns.crop'),
      value: COLUMNS.CROP,
      order: 15,
      enabled: true,
    },
    { label: t('table.columns.variety'), value: COLUMNS.VARIETY, order: 16, enabled: true },
    {
      label: t('table.columns.estimatedYield'),
      value: COLUMNS.ESTIMATED_YIELD,
      order: 17,
      enabled: true,
    },
    { label: t('table.columns.yield'), value: COLUMNS.YIELD, order: 18, enabled: true },
    {
      label: t('table.columns.productionType'),
      value: COLUMNS.PRODUCTION_TYPE,
      order: 19,
      enabled: true,
    },
    {
      label: t('table.columns.abandonedSize'),
      value: COLUMNS.ABANDONED_SIZE,
      order: 20,
      enabled: true,
    },
    {
      label: t('table.columns.deliveryCondition'),
      value: COLUMNS.DELIVERY_CONDITION,
      order: 21,
      enabled: permissions.isAnOrganizationUser,
    },
    {
      label: t('table.columns.deliveryMill'),
      value: COLUMNS.DELIVERY_MILL,
      order: 22,
      enabled: permissions.isAnOrganizationUser,
    },
  ]

  const filteredColumns = allColumns.filter(column => column.enabled)

  const columns = orderBy(filteredColumns, 'order')

  return { columns }
}
