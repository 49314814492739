import { Col, Row, Typography } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { LastUpdateIcon } from 'assets/icons'
import { DATE_FORMATS } from 'consts'

const { Title: AntTitle, Text: AntText } = Typography

const Callout = styled(Row).attrs({
  gutter: 23,
})`
  width: 100%;
  height: 105px;
  padding: 22px;
  background-color: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

const Title = styled(AntTitle)`
  margin-bottom: 10px !important;
`

const Text = styled(AntText)`
  font-size: 13px;
`

interface Props {
  date: Date
}

export const LastModificationCallout: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation('season-settings')

  return (
    <Callout>
      <Col>
        <LastUpdateIcon />
      </Col>
      <Col flex={1}>
        <Row>
          <Title level={5}>{t('seasonCard.lasUpdateText')}</Title>
        </Row>
        <Row justify="space-between">
          <Text>{dayjs(date).format(DATE_FORMATS.STRING_MONTH)}</Text>
          <Text>{`${dayjs(date).format(DATE_FORMATS.HOURS_AND_SECS)} hs`}</Text>
        </Row>
      </Col>
    </Callout>
  )
}
