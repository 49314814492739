import ReactMapGL, { Layer, Source, useMap as useReactMapGl } from 'react-map-gl'
import { multiLineString, polygon } from '@turf/helpers'
import React, { useEffect } from 'react'
import bbox from '@turf/bbox'
import { useTheme } from 'styled-components'

import { MAP } from 'consts'
import { config } from 'config'
import { MapContext } from 'features/MainRouter/contexts'
import { Lot } from 'types'
import { GeoUtils } from 'utils'
import { useImageUrl } from 'hooks'

import { LotsUtils } from '../../utils'
import { AreaSource } from '../AreaSource'

interface Props {
  lots: Lot[]
  initialViewPort: {
    latitude: number
    longitude: number
    zoom: number
  }
  onMount: () => void
  showRtk: boolean
  rtkLots?: { id: number; rtk?: number[][][] }[]
  onClick: () => void
}

export const CompareImagesMap: React.FC<Props> = ({
  lots,
  initialViewPort,
  onMount,
  showRtk,
  rtkLots,
  onClick,
}) => {
  const { colors } = useTheme()
  const { selectedCompareMapIndex, selectedCompareCalendarDate, selectedCompareSatellite } =
    MapContext.useMapContext()
  const { compareMap } = useReactMapGl()

  const { getUrlWithToken } = useImageUrl()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, [compareMap])

  return (
    <ReactMapGL
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
      }}
      mapStyle={MAP.STYLES.SATELLITE_STREET}
      id="compareMap"
      initialViewState={{ ...initialViewPort }}
      onClick={onClick}
    >
      {selectedCompareCalendarDate &&
        lots.map(lot => {
          const baseUrl = LotsUtils.getImageUrl(
            lot,
            selectedCompareMapIndex,
            selectedCompareCalendarDate,
            selectedCompareSatellite,
          )

          const lotImageUrl = baseUrl ? getUrlWithToken(baseUrl) : undefined

          const rtk = rtkLots?.find(rtkLot => rtkLot.id === lot.id)?.rtk

          const lotRtk = rtk ? multiLineString(rtk) : undefined

          return (
            <React.Fragment key={lot.id}>
              <>
                {compareMap && (
                  <AreaSource
                    lotImageUrl={lotImageUrl}
                    lotId={lot.id.toString()}
                    area={polygon(lot.area.coordinates)}
                    mapRef={compareMap}
                  />
                )}
                {config.isSentinelImageryOn && lotImageUrl && (
                  <Source
                    type="image"
                    id={`image-${lot.id.toString()}`}
                    url={lotImageUrl}
                    coordinates={GeoUtils.bboxCoords(bbox(lot.area))}
                  >
                    <Layer
                      beforeId={lot.id.toString()}
                      type="raster"
                      id={`image-${lot.id.toString()}`}
                      paint={{}}
                    />
                  </Source>
                )}
                {showRtk && lotRtk && (
                  <Source
                    key={`compare-rtk-${lot.id.toString()}`}
                    type="geojson"
                    id={`rtk-${lot.id.toString()}`}
                    data={lotRtk}
                  >
                    <Layer
                      beforeId={lot.id.toString()}
                      id={`compare-lot-rtk-${lot.id}`}
                      type="line"
                      paint={{
                        'line-color': colors.white,
                        'line-width': 1.5,
                      }}
                    />
                  </Source>
                )}
              </>
            </React.Fragment>
          )
        })}
    </ReactMapGL>
  )
}
