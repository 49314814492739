import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { UpdateClientForm, StyledRow, DeleteCompanyButton } from './components'
import { Props } from './types'

interface FarmerInformationProps extends Props {
  onDeleteCompanyButtonClick: () => void
  isReadOnly: boolean
}

export const FarmerInformation: React.FC<FarmerInformationProps> = ({
  data,
  companyId,
  onDeleteCompanyButtonClick,
  isReadOnly,
}) => {
  const { t } = useTranslation('company-settings')

  return (
    <>
      <UpdateClientForm companyId={companyId} data={data} />
      <StyledRow>
        <Col span={6}>
          <DeleteCompanyButton onClick={onDeleteCompanyButtonClick} disabled={isReadOnly}>
            {t('buttons.deleteCompany')}
          </DeleteCompanyButton>
        </Col>
      </StyledRow>
    </>
  )
}
