import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Lot } from '../useLots/api'
import { RTK_LOTS, RtkLotsQuery, RtkLotsVariables } from './api'

interface Props {
  seasonId?: number
  fieldId?: number
  skip: boolean
  selectedLots: Lot[]
}

export const useRtkLots = ({ seasonId, fieldId, selectedLots, skip }: Props) => {
  const { t } = useTranslation('common')
  const [rtkLots, setRtkLots] = useState<{ id: number; rtk?: number[][][] }[]>()

  const { data, loading } = useQuery<RtkLotsQuery, RtkLotsVariables>(RTK_LOTS, {
    variables: {
      seasonId: seasonId!,
      fieldId: fieldId!,
    },
    onError: error => {
      Sentry.captureException(error)
      notification.error({
        duration: 0,
        message: t('messages.genericErrorRefreshPage'),
      })
    },
    skip: skip || !fieldId || !seasonId,
  })

  useEffect(() => {
    if (loading) return
    if (data?.lotsV2.results.length) {
      const rtkData = data.lotsV2.results.filter(rtkLot => rtkLot.rtk)
      setRtkLots(
        rtkData.map(rtkLot => ({
          id: rtkLot.id,
          rtk: rtkLot.rtk?.coordinates,
        })),
      )
    }
  }, [loading, data])

  const selectedLotsRtkList = useMemo(
    () =>
      selectedLots.map(lot => {
        return {
          id: lot.id,
          name: lot.name,
          hasRtk: !!rtkLots?.find(rtkLot => rtkLot.id === lot.id)?.rtk,
        }
      }),
    [rtkLots, selectedLots],
  )

  return { rtkLots, loading, selectedLotsRtkList }
}
