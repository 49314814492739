import {
  Col,
  Row,
  Typography,
  Table as AntTable,
  Checkbox as AntCheckbox,
  Input as AntInput,
} from 'antd'
import styled from 'styled-components'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteButton } from 'components'
import { ConversionUtils } from 'utils'

import { Dam } from '../../../../hooks/useDams/types'

const { Text } = Typography

const Header = styled(Row)`
  background-color: ${({ theme }) => theme.colors.grey2};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 45px;
  padding: 0 17px;
  margin-bottom: 12px;
  align-items: center;
`

const Table = styled(AntTable)<Dam>`
  & .ant-table {
    background-color: ${({ theme }) => theme.colors.grey1};
  }
  & .ant-table-cell {
    padding: 12px 2px !important;
    border-bottom: unset;
  }
` as unknown as typeof AntTable

const Input = styled(AntInput)`
  padding: 6px 9px;
`

const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    border-radius: 100%;
  }
`

interface Props {
  dams: Dam[]
  selectedDamIndex?: number
  onSelect: (damIndex: number) => void
  onChange: (dam: Dam['dam'], damIndex: number) => void
  onDelete: (damIndex: number) => void
  loading?: boolean
  disableDelete: boolean
}

export const DamsList: React.FC<Props> = ({
  dams,
  selectedDamIndex,
  onSelect,
  onChange,
  onDelete,
  loading,
  disableDelete,
}) => {
  const { t } = useTranslation('field-settings')
  const { t: commonT } = useTranslation('common')
  const isDataSourceBiggerThanMaxTableDams = !!dams.length

  const selectCheckBox = (index: number) => {
    if (index !== selectedDamIndex) onSelect(index)
  }

  type DataObject = {
    [key: number]: { current: HTMLDivElement | null }
  }

  const refs = useMemo(
    () =>
      dams.reduce((accumulator: DataObject, _, index) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[index] = React.createRef<HTMLDivElement>()
        return accumulator
      }, {}),
    [dams],
  )

  const handleListScroll = useCallback(
    (index: number) => {
      if (!refs[index]) return
      const ref = refs[index].current

      if (ref === null) return

      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },
    [refs],
  )

  useEffect(() => {
    if (selectedDamIndex === undefined || selectedDamIndex === -1 || selectedDamIndex === null)
      return
    handleListScroll(selectedDamIndex)
  }, [handleListScroll, selectedDamIndex])

  const columns = [
    {
      render: (_: Dam, __: Dam, index: number) => (
        <div ref={refs[index]}>
          <Checkbox checked={index === selectedDamIndex} onClick={() => onSelect(index)} />
        </div>
      ),
      width: '22px',
    },

    {
      render: ({ dam }: Dam, __: Dam, index: number) => (
        <div ref={refs[index]}>
          <Input
            onKeyDown={e => {
              if (e.key === 'Backspace') {
                e.stopPropagation()
              }
            }}
            placeholder={commonT('inputs.name')}
            value={dam.name}
            onClick={() => selectCheckBox(index)}
            onChange={event =>
              onChange({ ...dam, name: ConversionUtils.autoCapitalize(event.target.value) }, index)
            }
            autoFocus={index === selectedDamIndex}
          />
        </div>
      ),
    },
    {
      render: (_: Dam, __: Dam, index: number) => (
        <DeleteButton
          disabled={disableDelete && index !== dams.length - 1}
          onClick={() => onDelete(index)}
        />
      ),
      width: '42px',
    },
  ]

  return (
    <Row>
      <Col flex={1}>
        <Header>
          <Text strong>{t('tableTitle')}</Text>
        </Header>
        {!!dams.length && (
          <Table
            size="small"
            rowKey={({ dam }) => dam.id}
            dataSource={dams}
            pagination={false}
            showHeader={false}
            columns={columns}
            scroll={isDataSourceBiggerThanMaxTableDams ? { y: 85 } : undefined}
            loading={loading}
          />
        )}
      </Col>
    </Row>
  )
}
