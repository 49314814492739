import { Col, Row, Space, Typography, Button as AntButton } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { UserAvatar } from 'components'
import { useAuth } from 'hooks'

const { Title: AntTitle } = Typography

const FullWidthRow = styled(Row)`
  flex: 1;
  padding-bottom: 30px;
`
const UserName = styled(AntTitle)`
  margin-bottom: 0 !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white} !important;
`
const Button = styled(AntButton)`
  padding: 0;
  height: 20px !important;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
  & span {
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.new.secondary[400]} !important;
  }
`
const ButtonText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 5px;
`

interface Props {
  name: string
  lastName: string
  onConfigureAccount: () => void
}

export const UserSettings: React.FC<Props> = ({ name, lastName, onConfigureAccount }) => {
  const { logout } = useAuth()
  const { t } = useTranslation('navbar')

  return (
    <FullWidthRow wrap={false}>
      <Col flex={1}>
        <Space size="middle">
          <UserAvatar />
          <Row>
            <Col span={24}>
              <UserName level={5} ellipsis={{ rows: 2, tooltip: true }}>
                {name} {lastName}
              </UserName>
            </Col>
            <Col>
              <Button type="link" onClick={onConfigureAccount}>
                {t('user.menu.user.manageButtonText.part1')}
                <ButtonText>{t('user.menu.user.manageButtonText.part2')}</ButtonText>
              </Button>
            </Col>
            <Button type="link" onClick={logout}>
              {t('user.menu.user.logoutButtonText')}
            </Button>
          </Row>
        </Space>
      </Col>
    </FullWidthRow>
  )
}
