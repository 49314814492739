import { Tooltip } from 'antd'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  hasEmergenceDate: boolean
}

export const PendingEmergenceDateTooltip: React.FC<PropsWithChildren<Props>> = ({
  hasEmergenceDate,
  children,
}) => {
  const { t } = useTranslation('lot-dashboard')

  if (hasEmergenceDate) return <>{children}</>
  return <Tooltip title={t('emergenceDatePendingTooltip')}>{children}</Tooltip>
}
