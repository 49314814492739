import { BBox, Feature, Position, polygon } from '@turf/helpers'
import area from '@turf/area'
import centroid from '@turf/centroid'

const SQUARE_METERS_HECTARES_CONVERSION_RATE = 1 / 10000

/**
 * Converts a bbox to coordinates
 * TopLeft, TopRight, BottomRight, BottomLeft
 * @param {turf.BBox} bbox
 */
const bboxCoords = (bbox: BBox) => {
  const minX = Number(bbox[0])
  const minY = Number(bbox[1])
  const maxX = Number(bbox[2])
  const maxY = Number(bbox[3])

  return [
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ]
}

/**
 * Returns the size of a geometry in hectares
 */
const size = (geometry: Feature) => {
  return area(geometry) * SQUARE_METERS_HECTARES_CONVERSION_RATE
}

/**
 * Returns the center and area of a polygon
 */
const getPolygonCenterAndArea = (coordinates: Position[][]) => {
  const polygonArea = polygon(coordinates)
  const polygonCenter = centroid(polygonArea)
  const lotArea = Math.round(size(polygonArea))

  return { polygonCenter, area: lotArea }
}

export const GeoUtils = {
  bboxCoords,
  size,
  getPolygonCenterAndArea,
}
