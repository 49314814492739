import styled from 'styled-components'
import { Col, Menu, Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'

import { useFeatures } from 'hooks'

const MenuContainer = styled(Menu)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: none;
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-selected {
    background-color: transparent !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey1} !important;
      span {
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
  .ant-dropdown-menu-item {
    display: flex;
    padding: 0 15px;
    align-items: center;
    margin: 0 !important;
    height: 52px !important;
  }
`

const UploaderTextContainer = styled.div``

interface Props {
  onAddLot: () => void
  handleKml: (file: UploadChangeParam<UploadFile>) => void
  onReuseButtonClick: () => void
  onAddLotWithoutPerimeter: () => void
}

export const DropdownMenu: React.FC<Props> = ({
  onAddLot,
  handleKml,
  onReuseButtonClick,
  onAddLotWithoutPerimeter,
}) => {
  const { t } = useTranslation('lot-settings')
  const { organizationFeatures } = useFeatures()

  const dataSource = [
    {
      key: 'draw',
      label: <Col>{t('buttons.drawLotText')}</Col>,
      onClick: onAddLot,
    },
    {
      key: 'withoutPerimeter',
      label: <Col>{t('buttons.withoutPerimeter')}</Col>,
      onClick: onAddLotWithoutPerimeter,
      disabled: !organizationFeatures.crm?.enabled,
    },
    {
      key: 'kml',
      label: (
        <Upload
          beforeUpload={() => false}
          onChange={handleKml}
          showUploadList={false}
          accept=".kml"
        >
          <UploaderTextContainer>{t('buttons.uploadKMLText')}</UploaderTextContainer>
        </Upload>
      ),
    },
    {
      key: 'reuse',
      label: <Col>{t('buttons.reuseLots')}</Col>,
      onClick: onReuseButtonClick,
    },
  ]

  const filteredData = dataSource.filter(option => !option.disabled)

  return <MenuContainer items={filteredData} />
}
