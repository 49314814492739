import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { InputTitle, UpdateTypeInformation } from 'components'
import { UpdateRiceLotVariables } from 'hooks/useUpdateRiceLot/api'
import { MilestoneUpdateType } from 'types'

import { Title, Space, FlexCol, Ball, MarginRow, Divider } from './components'
import { useBody } from './hooks'

interface Props {
  lotId: number
  previousCropId?: number
  riceLotId: number
  emergenceDate?: string
  plantingDate?: string
  firstIrrigationStartDate?: string
  firstIrrigationEndDate?: string
  floweringDate?: string
  harvestDate?: string
  physiologicalMaturityDate?: string
  differentiationDate?: string
  preparationDate?: string
  burningDate?: string
  earAppearanceDate?: string
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
  emergenceUpdateType?: MilestoneUpdateType
  plantingUpdateType?: MilestoneUpdateType
  floweringUpdateType?: MilestoneUpdateType
  harvestUpdateType?: MilestoneUpdateType
  preparationUpdateType?: MilestoneUpdateType
  burningUpdateType?: MilestoneUpdateType
  earAppearanceUpdateType?: MilestoneUpdateType
  differentiationUpdateType?: MilestoneUpdateType
}

export const Timeline: React.FC<Props> = ({
  lotId,
  previousCropId,
  riceLotId,
  plantingDate,
  emergenceDate,
  firstIrrigationStartDate,
  firstIrrigationEndDate,
  floweringDate,
  harvestDate,
  differentiationDate,
  physiologicalMaturityDate,
  preparationDate,
  burningDate,
  earAppearanceDate,
  updateRiceLotMutation,
  emergenceUpdateType,
  plantingUpdateType,
  floweringUpdateType,
  harvestUpdateType,
  preparationUpdateType,
  burningUpdateType,
  earAppearanceUpdateType,
  differentiationUpdateType,
}) => {
  const { t } = useTranslation('lot-dashboard')

  const { bodyData } = useBody({
    lotId,
    previousCropId,
    riceLotId,
    plantingDate,
    emergenceDate,
    firstIrrigationStartDate,
    firstIrrigationEndDate,
    floweringDate,
    harvestDate,
    differentiationDate,
    physiologicalMaturityDate,
    preparationDate,
    burningDate,
    earAppearanceDate,
    updateRiceLotMutation,
    emergenceUpdateType,
    plantingUpdateType,
    floweringUpdateType,
    harvestUpdateType,
    preparationUpdateType,
    burningUpdateType,
    earAppearanceUpdateType,
    differentiationUpdateType,
  })

  return (
    <Space direction="vertical" size={24}>
      <Row justify="space-between">
        <Col>
          <Title level={5}>{t('timeline.title')}</Title>
        </Col>
        <FlexCol>
          <UpdateTypeInformation maxWidth={185} />
        </FlexCol>
      </Row>
      <Row>
        {bodyData.map(timelineObject => {
          return (
            <Col span={24} key={timelineObject.title}>
              <Row gutter={[24, 0]}>
                <FlexCol>
                  <Ball
                    $completedDate={timelineObject.completedDate}
                    $backgroundColor={timelineObject.ballBackground}
                  />
                  <Divider />
                </FlexCol>
                <MarginRow>
                  <Col>
                    <InputTitle title={timelineObject.title} />
                  </Col>
                  <Col flex={1}>{timelineObject.component}</Col>
                </MarginRow>
              </Row>
            </Col>
          )
        })}
      </Row>
    </Space>
  )
}
