import React, { PropsWithChildren } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const ViewerUserTooltip: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common')

  return (
    <Tooltip title={t('messages.readOnlyAccess')} placement="top">
      {children}
    </Tooltip>
  )
}

export { ViewerUserTooltip }
