import { parseInt } from 'lodash'
import { ThemeConfig } from 'antd'

import { Colors } from './colors'
import { Foundation } from './foundation'
import { Modal } from './modal'

const themeToken = {
  fontFamily: 'Inter',
  colorPrimary: Colors.primary,
  contentBg: Colors.primary,
  borderRadius: parseInt(Foundation.borderRadius),
  colorBgContainer: Colors.grey1,
  colorBgContainerDisabled: Colors.grey2,
  colorLink: Colors.primary,
}

export const antdTheme: ThemeConfig = {
  token: themeToken,
  components: {
    Select: {
      colorBgContainer: Colors.white,
      controlHeight: parseInt(Foundation.height),
      colorTextPlaceholder: Colors.grey3,
    },
    Input: {
      colorBgContainer: Colors.white,
      controlHeight: parseInt(Foundation.height),
      colorBorder: Colors.grey2,
      colorTextPlaceholder: Colors.grey3,
    },
    Button: {
      controlHeight: parseInt(Foundation.height),
      colorLink: Colors.primary,
      colorLinkHover: Colors.primary,
    },
    DatePicker: {
      controlHeight: parseInt(Foundation.height),
      colorBgContainer: Colors.white,
      colorTextPlaceholder: Colors.grey3,
    },
    Modal: {
      contentBg: Colors.grey1,
      headerBg: Colors.grey1,
      titleFontSize: Modal.header.titleFontSize,
    },
  },
}
