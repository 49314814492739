import dayjs from 'dayjs'

import { UserUserCompanyRole } from 'api/user'
import { HistoricalSeason } from 'types'

const filterCompanySeasonsByHistoricalSeasonRange = (
  historicalSeason: HistoricalSeason,
  companiesRoles?: UserUserCompanyRole[],
) => {
  return companiesRoles?.filter(companyRole => {
    return companyRole.company.seasons?.some(season => {
      const seasonStartDate = dayjs(season.startDate)
      const selectedHistoricalSeasonStartRangeDate = dayjs(historicalSeason.startRange)
      const selectedHistoricalSeasonEndRangeDate = dayjs(historicalSeason.endRange)

      const isSeasonStartDateSameOrAfter =
        seasonStartDate.isSame(selectedHistoricalSeasonStartRangeDate) ||
        seasonStartDate.isAfter(selectedHistoricalSeasonStartRangeDate)

      const isSeasonStartDateSameOrBefore =
        seasonStartDate.isSame(selectedHistoricalSeasonEndRangeDate) ||
        seasonStartDate.isBefore(selectedHistoricalSeasonEndRangeDate)

      return isSeasonStartDateSameOrAfter && isSeasonStartDateSameOrBefore
    })
  })
}

export const CompanyUtils = { filterCompanySeasonsByHistoricalSeasonRange }
