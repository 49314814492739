import { gql } from '@apollo/client'

export type { DeliveryMills } from './types/DeliveryMills'

export const DELIVERY_MILLS = gql`
  query DeliveryMills {
    organization {
      id
      mills {
        name
        id
      }
    }
  }
`
