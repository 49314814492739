import { Typography, Col, Row } from 'antd'
import styled from 'styled-components'

export { YieldValues } from './YieldValues'

const { Text: AntText } = Typography

export const RightAlignCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`

export const TextContainer = styled(Row)`
  margin-top: 10px;
`
