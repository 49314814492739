import { useQuery } from '@apollo/client'

import { ActiveCompanyContext } from 'contexts'

import { CompanyFields, CompanyFieldsVariables, COMPANY_FIELDS } from './api'
import { useSeason } from '../useSeason'
import { usePermissions } from '../usePermissions'

export const useHasCompanyOrFieldsOrLots = () => {
  const { activeCompanyId, activeSeasonId, loadingCompanySeasons, hasCompanies } =
    ActiveCompanyContext.useActiveCompanyContext()
  const { permissions } = usePermissions()
  const { data: season, loading: loadingSeason } = useSeason(activeSeasonId)

  const { data: companyFields, loading: loadingCompanyFields } = useQuery<
    CompanyFields,
    CompanyFieldsVariables
  >(COMPANY_FIELDS, {
    variables: { id: activeCompanyId! },
    skip: !activeCompanyId,
  })

  const noCompany = !hasCompanies
  const noFields = !companyFields?.company.fields?.length
  const noLots =
    !noCompany &&
    !noFields &&
    !season?.season.seasonFields.some(seasonField => !!seasonField.lots?.length)
  const noCompanyOrFieldsOrLots = noCompany || noFields || noLots

  const loading =
    loadingCompanySeasons ||
    loadingSeason ||
    loadingCompanyFields ||
    (!!activeCompanyId && (!companyFields || (!season && !permissions.isIrrigationWorkerUser)))

  return { noCompanyOrFieldsOrLots, noCompany, noFields, noLots, loading }
}
