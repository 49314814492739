import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { WeatherDay } from 'api'
import { RainDropIcon, RainIcon, SunIcon, CloudyIcon, RainWeatherIcon } from 'assets/icons'
import { EXTERNAL_LINKS } from 'consts'

import {
  Title,
  FlexCol,
  StyledCol,
  WeatherContainer,
  Divider,
  DayText,
  TempMaxText,
  TempMinText,
  RainProbText,
  SponsorText,
} from './components'

interface Props {
  days?: WeatherDay[]
}

const getWeatherIcon = (precipProb: number | null, cloudCover: number | null) => {
  if (precipProb && precipProb > 40) return <RainWeatherIcon />
  if (cloudCover && cloudCover > 50) return <CloudyIcon />
  return <SunIcon />
}

export const Weather: React.FC<Props> = ({ days }) => {
  const { t } = useTranslation('dashboard')
  const { t: commonT } = useTranslation('common')
  return (
    <>
      <Col flex={1}>
        <Row gutter={10}>
          <FlexCol>
            <RainIcon />
          </FlexCol>
          <Col>
            <Title level={5}>{t('weatherCard.title')}</Title>
          </Col>
        </Row>
      </Col>
      <StyledCol>
        <Row wrap={false} gutter={12}>
          {days?.map((day, index) => {
            return (
              <Col key={day.dateTime}>
                <WeatherContainer $isToday={index === 0}>
                  {getWeatherIcon(day.precipProb, day.cloudCover)}
                  <DayText>
                    {index === 0
                      ? t('weatherCard.today')
                      : commonT('dates.shortDay', {
                          val: dayjs(day.dateTime),
                        })}
                  </DayText>
                  <Divider type="horizontal" />
                  <Row>
                    <Col span={24}>
                      <TempMaxText>
                        {commonT('systemsFormat.degree', {
                          val: day.tempMax,
                        })}
                      </TempMaxText>
                    </Col>
                    <Col span={24}>
                      <TempMinText>
                        {commonT('systemsFormat.degree', {
                          val: day.tempMin,
                        })}
                      </TempMinText>
                    </Col>
                  </Row>
                  <Divider type="horizontal" />
                  <Row wrap={false} align="middle">
                    <RainProbText> {day.precipProb?.toFixed()}%</RainProbText>
                    <RainDropIcon />
                  </Row>
                </WeatherContainer>
              </Col>
            )
          })}
        </Row>
        <Row justify="end" align="bottom">
          <SponsorText target="_blank" href={EXTERNAL_LINKS.WEATHER_SPONSOR}>
            {t('weatherCard.linkText')}
          </SponsorText>
        </Row>
      </StyledCol>
    </>
  )
}
