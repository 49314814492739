import { useContext, useState, createContext } from 'react'
import * as Sentry from '@sentry/react'
import { setUserId as setAmplitudeUserId, track } from '@amplitude/analytics-browser'

import { AmplitudeEventsName } from 'types'
import { BROADCAST_CHANNEL } from 'consts'

interface Login {
  id: number
  name: string
  lastName: string
  email: string
  organizationId?: number
  organizationName?: string
}
interface AuthContextType {
  userId?: number
  login: (auth: Login) => void
  logout: () => void
  authChannel?: BroadcastChannel
}

const Context = createContext<AuthContextType>({
  userId: undefined,
  login: () => {
    throw new Error(
      'Attempted to use default login function for AuthContext, make sure to pass a custom function implementation',
    )
  },
  logout: () => {
    throw new Error(
      'Attempted to use default logout function for AuthContext, make sure to pass a custom function implementation',
    )
  },
  authChannel: undefined,
})

const useValue = (): AuthContextType => {
  const [userId, setUserId] = useState<AuthContextType['userId']>()

  const authChannel = new BroadcastChannel(BROADCAST_CHANNEL.AUTH.NAME)

  const login = ({ id, name, lastName, email, organizationId, organizationName }: Login) => {
    setUserId(id)
    Sentry.setUser({ id: id.toString() })
    setAmplitudeUserId(id.toString())

    const eventProperties = organizationId
      ? {
          email,
          fullName: `${name} ${lastName}`,
          organizationName,
          organizationId,
        }
      : {
          email,
          fullName: `${name} ${lastName}`,
        }

    track(AmplitudeEventsName.LOGIN, eventProperties)
  }

  const logout: AuthContextType['logout'] = () => {
    setUserId(undefined)
    Sentry.configureScope(scope => scope.setUser(null))
  }

  return { userId, login, logout, authChannel }
}

const useAuthContext = () => {
  return useContext(Context)
}

export const AuthContext = {
  Provider: Context.Provider,
  useValue,
  useAuthContext,
}
