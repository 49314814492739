import { Marker } from 'react-map-gl'

import { PopupInfo } from 'features/MainRouter/features/Map/types'

import { PopupCard, LotCard, DamCard } from './components'

interface Props {
  data: PopupInfo
  coordinates?: { latitude?: number; longitude?: number }
  isShowDamsModeSelected: boolean
}

export const InformationPopup: React.FC<Props> = ({
  data,
  coordinates,
  isShowDamsModeSelected,
}) => {
  if (!coordinates?.latitude || !coordinates.longitude) return null

  return (
    <Marker latitude={coordinates.latitude} longitude={coordinates.longitude} draggable={false}>
      <PopupCard>
        {isShowDamsModeSelected ? <DamCard data={data} /> : <LotCard data={data} />}
      </PopupCard>
    </Marker>
  )
}
