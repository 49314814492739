/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider } from 'antd'
import { MapProvider } from 'react-map-gl'
import { init } from '@amplitude/analytics-browser'

import { theme, GlobalStyles, antdTheme } from 'styles'
import { ActiveCompanyContext, AuthContext } from 'contexts'
import { FullScreenLoader, PrivateRoute } from 'components'
import { useAuth, useBrowserLogout, useCurrentUser } from 'hooks'
import { ApolloService, I18NService } from 'services'
import { config } from 'config'

import { LoginRouter } from './LoginRouter'
import { MainRouter } from './MainRouter'

const apolloClient = ApolloService.buildApolloClient()
init(config.amplitudeKey!, undefined, { minIdLength: 1 })

const Router: React.FC = () => {
  const activeCompanyContextValue = ActiveCompanyContext.useValue()
  const { userToken, tokenLogin, userId } = useAuth(activeCompanyContextValue.resetStore)
  const [loadingApp, setLoadingApp] = useState(!!userToken)
  const { loading: loadingUser } = useCurrentUser()
  useBrowserLogout({
    resetActiveCompany: activeCompanyContextValue.resetStore,
  })
  const locale = I18NService.getAntdLocale()

  useEffect(() => {
    if (userToken && !userId) {
      const loginWithToken = async () => {
        await tokenLogin()
        setLoadingApp(false)
      }

      loginWithToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If app initialization or user are loading, we show a full screen loader. This way, we can
  // assume that when using the user in different parts of the app, we already have it.
  if (loadingApp || loadingUser) return <FullScreenLoader />

  return (
    <ConfigProvider theme={antdTheme} locale={locale}>
      <Routes>
        <Route path="login/*" element={<LoginRouter />} />
        <Route
          path="/*"
          element={
            <ActiveCompanyContext.Provider value={activeCompanyContextValue}>
              <PrivateRoute>
                <MainRouter />
              </PrivateRoute>
            </ActiveCompanyContext.Provider>
          }
        />
      </Routes>
    </ConfigProvider>
  )
}

export const App: React.FC = () => {
  const authContextValue = AuthContext.useValue()

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthContext.Provider value={authContextValue}>
          <MapProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </MapProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </ApolloProvider>
  )
}
