import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Modal, Button, InputTitle, FormInput } from 'components'

import { useUpdatePassword, Form } from './hooks'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  width: 100%;
  color: ${({ theme }) => theme.colors.errorRed};
`

const ButtonContainer = styled(Row)`
  width: 100%;
  margin-top: 22px;
  justify-content: flex-end;
`

interface Props {
  onCancel: () => void
  onSubmit: (form: Form) => void
  error?: ApolloError
  loading: boolean
}

export const ChangePasswordModal: React.FC<Props> = ({ onCancel, onSubmit, error, loading }) => {
  const { control, handleSubmit } = useUpdatePassword()
  const { t } = useTranslation('account-settings')

  return (
    <Modal
      title={t('changePasswordModal.title')}
      open
      centered
      footer={null}
      onCancel={onCancel}
      size="small"
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <InputTitle title={t('changePasswordModal.inputs.currentPassword')} />
          <FormInput
            // eslint-disable-next-line i18next/no-literal-string
            name="actualPassword"
            placeholder={t('changePasswordModal.inputs.currentPassword')}
            control={control}
            type="password"
          />
        </Col>
        <Col span={24}>
          <InputTitle title={t('changePasswordModal.inputs.newPassword')} />
          <FormInput
            // eslint-disable-next-line i18next/no-literal-string
            name="password"
            placeholder={t('changePasswordModal.inputs.newPassword')}
            control={control}
            type="password"
          />
        </Col>
        <Col span={24}>
          <InputTitle title={t('changePasswordModal.inputs.repeatPassword')} />
          <FormInput
            // eslint-disable-next-line i18next/no-literal-string
            name="passwordConfirm"
            placeholder={t('changePasswordModal.inputs.repeatPassword')}
            control={control}
            type="password"
          />
        </Col>
        {error && <Text>{t('changePasswordModal.errorText')}</Text>}
      </Row>
      <ButtonContainer>
        <Button type="primary" loading={loading} onClick={handleSubmit(onSubmit)}>
          {t('buttons.save')}
        </Button>
      </ButtonContainer>
    </Modal>
  )
}
