import { useState } from 'react'

import { ToolMode } from '../types'

export const useTools = () => {
  const [toolMode, setToolMode] = useState<ToolMode>(null)
  const isCompareModeSelected = toolMode === 'compare'
  const isShowDamsModeSelected = toolMode === 'show-dams'

  return {
    toolMode,
    setToolMode,
    isCompareModeSelected,
    isShowDamsModeSelected,
  }
}
