import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntAvatar } from 'antd'
import { ComponentProps, forwardRef } from 'react'
import styled from 'styled-components'

import { useCurrentUser } from 'hooks'

type Props = ComponentProps<typeof AntAvatar> & {
  onClick?: () => void
}

const Avatar: React.FC<Props> = forwardRef((props, ref) => {
  const { user } = useCurrentUser()
  return (
    <AntAvatar src={user?.profilePicture} ref={ref} {...props} size={48} icon={<UserOutlined />} />
  )
})

export const UserAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.grey3};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  border: 2px solid ${({ theme }) => theme.colors.white};
`
