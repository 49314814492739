import { Input } from 'antd'
import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

interface BallProps {
  $backgroundColor?: string
}
const Ball = styled.div<BallProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

const StyledInput = styled(Input)`
  border: none;
`

interface Props {
  ballBackground?: string
}

export const InputRender: React.FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({
  ballBackground,
  ...props
}) => {
  return (
    <StyledInput
      {...props}
      size="small"
      prefix={props.value && <Ball $backgroundColor={ballBackground} />}
    />
  )
}
