import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateRiceLot, UpdateRiceLotVariables, UPDATE_RICE_LOT } from './api'

export const useUpdateRiceLot = () => {
  const [updateRiceLot] = useMutation<UpdateRiceLot, UpdateRiceLotVariables>(UPDATE_RICE_LOT, {
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }) => {
      if (!response) return
      const seasonLot: StoreObject = {
        id: response.updateRiceLot.riceLot.id,
        __typename: 'SeasonLotRiceLot',
      }
      const {
        preparationDate,
        burningDate,
        plantingDate,
        emergenceDate,
        firstIrrigationStartDate,
        firstIrrigationEndDate,
        differentiationDate,
        floweringDate,
        earAppearanceDate,
        physiologicalMaturityDate,
        harvestDate,
        yield: yieldValue,
        emergenceUpdateType,
        plantingUpdateType,
        floweringUpdateType,
        harvestUpdateType,
        preparationUpdateType,
        burningUpdateType,
        earAppearanceUpdateType,
        differentiationUpdateType,
        estimatedYield,
      } = response.updateRiceLot.riceLot

      cache.modify({
        id: cache.identify(seasonLot),
        fields: {
          preparationDate: () => preparationDate,
          burningDate: () => burningDate,
          plantingDate: () => plantingDate,
          emergenceDate: () => emergenceDate,
          firstIrrigationStartDate: () => firstIrrigationStartDate,
          firstIrrigationEndDate: () => firstIrrigationEndDate,
          differentiationDate: () => differentiationDate,
          floweringDate: () => floweringDate,
          earAppearanceDate: () => earAppearanceDate,
          physiologicalMaturityDate: () => physiologicalMaturityDate,
          harvestDate: () => harvestDate,
          yield: () => yieldValue,
          emergenceUpdateType: () => emergenceUpdateType,
          plantingUpdateType: () => plantingUpdateType,
          floweringUpdateType: () => floweringUpdateType,
          harvestUpdateType: () => harvestUpdateType,
          preparationUpdateType: () => preparationUpdateType,
          burningUpdateType: () => burningUpdateType,
          earAppearanceUpdateType: () => earAppearanceUpdateType,
          differentiationUpdateType: () => differentiationUpdateType,
          estimatedYield: () => estimatedYield,
        },
      })
    },
  })

  return { updateRiceLot }
}
