import { Col, Row } from 'antd'

import { LogoColoredWithTextIcon } from 'assets/icons'

export const ContentHeader = () => (
  <Row justify="center">
    <Col>
      <LogoColoredWithTextIcon />
    </Col>
  </Row>
)
