import { memo } from 'react'
import { Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useCompanySeasons } from 'hooks'
import { ActiveCompanyContext } from 'contexts'
import { CompanySeasons } from 'api'

const Select = styled(AntSelect)`
  min-width: 200px;
  .ant-select-selector {
    font-size: 13px;
  }
` as typeof AntSelect

interface Props {
  onChange: (seasonId?: number) => void
  seasonIdToOmit?: number
  selectedSeasonId?: number
  isDisabled: boolean
}

export const SeasonSelect: React.FC<Props> = memo(
  ({ onChange, seasonIdToOmit, selectedSeasonId, isDisabled }) => {
    const { t } = useTranslation('lot-settings')

    const onCompleted = (companySeasons: CompanySeasons) => {
      const filteredCompanySeasons = companySeasons?.seasons.results.filter(
        season => season.id !== seasonIdToOmit && season.seasonFields.length,
      )
      if (!filteredCompanySeasons.length) return
      onChange(filteredCompanySeasons[0].id)
    }

    const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
    const { data, loading } = useCompanySeasons(activeCompanyId, onCompleted)

    const seasons = data?.seasons.results.filter(season => season.id !== seasonIdToOmit)

    return (
      <Select
        loading={loading}
        value={selectedSeasonId}
        onChange={id => onChange(Number(id))}
        disabled={isDisabled}
        placeholder={isDisabled ? t('reuseLotsModal.seasonSelectPlaceholder') : undefined}
      >
        {seasons?.map(season => (
          <Select.Option key={season.id} value={season.id}>
            {season.name}
          </Select.Option>
        ))}
      </Select>
    )
  },
)
