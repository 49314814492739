import { useTranslation } from 'react-i18next'

import { ProductionType } from 'types'

export const useProductionType = () => {
  const { t } = useTranslation('common')

  const productionTypeMap: {
    [key in ProductionType]: { label: string; value: ProductionType }
  } = {
    CONVENTIONAL: {
      label: t('productionType.conventional'),
      value: ProductionType.CONVENTIONAL,
    },
    ORGANIC: {
      label: t('productionType.organic'),
      value: ProductionType.ORGANIC,
    },
  }

  return { productionTypeMap }
}
