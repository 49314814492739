import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { DeleteDam, DeleteDamVariables, DELETE_DAM } from './useDams/api'

export const useDeleteDam = (onCompleted?: (data: DeleteDam) => void) => {
  const [deleteDam, { loading }] = useMutation<DeleteDam, DeleteDamVariables>(DELETE_DAM, {
    onCompleted,
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }, { variables }) => {
      if (!variables || !response?.deleteDam.success) return

      const deletedLot: StoreObject = { id: variables.id, __typename: 'Dam' }

      cache.evict({ id: cache.identify(deletedLot) })
      cache.gc()
    },
  })

  return { deleteDam, loading }
}
