import { Layout as BaseLayout, Row, Typography, Col } from 'antd'
import styled from 'styled-components'
import { PropsWithChildren } from 'react'

import { Header as AppHeader } from '../Header'

const { Content: BaseContent } = BaseLayout
const { Title: AntTitle, Text: AntText } = Typography

const LayoutContainer = styled(BaseContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled(BaseContent)`
  padding: 50px 30px 0 30px;
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  overflow-y: auto;
  @media (min-width: 1300px) {
    padding-right: 70px;
    padding-left: 70px;
  }
  @media (min-width: 1600px) {
    padding-right: 120px;
    padding-left: 120px;
  }
  @media (max-height: 900px) {
    padding-top: 25px;
  }
`

const Header = styled(Row)`
  margin-bottom: 40px;
`

const Image = styled.img`
  background-position: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive};
  margin-bottom: 0px !important;
  line-height: 44px;
`

const SubTitle = styled(AntText)`
  line-height: 24px;
  font-size: 13px !important;
  margin-top: 10px !important;
  color: ${({ theme }) => theme.colors.olive5};
`

const SubTitleContainer = styled(Col)`
  margin-top: 5px !important;
`

interface Props {
  showAppHeader?: boolean
  appHeaderItems?: JSX.Element[]
  headerTitle: string
  headerImage: string
  actionButton?: JSX.Element
  className?: string
  subtitle?: string
}

export const Layout: React.FC<PropsWithChildren<Props>> = ({
  showAppHeader,
  appHeaderItems,
  headerImage,
  headerTitle,
  children,
  actionButton,
  className,
  subtitle,
}) => (
  <LayoutContainer>
    {showAppHeader && <AppHeader items={appHeaderItems} />}
    <Content className={className}>
      <Header align="middle" justify="space-between">
        <Col>
          <Row align="middle">
            <Image src={headerImage} />
            <Title level={3}>{headerTitle}</Title>
          </Row>
        </Col>
        {actionButton && <Col>{actionButton}</Col>}
        {subtitle && (
          <SubTitleContainer span={24}>
            <SubTitle>{subtitle}</SubTitle>
          </SubTitleContainer>
        )}
      </Header>
      {children}
    </Content>
  </LayoutContainer>
)
