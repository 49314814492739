import { track as amplitudeTrack } from '@amplitude/analytics-browser'
import { useCallback } from 'react'
import * as Sentry from '@sentry/react'

import { AmplitudeEventsName, Properties } from 'types'

import { useUser } from './useUser'

export const useTrackEvent = (eventName: AmplitudeEventsName) => {
  const user = useUser()
  const track = useCallback(
    (properties?: Properties) => {
      try {
        const baseProps = user?.organizationRole?.organization
          ? {
              email: user.email,
              fullName: `${user.name} ${user.lastName}`,
              organizationName: user.organizationRole.organization.name,
              organizationId: user.organizationRole.organization.id,
            }
          : {
              email: user.email,
              fullName: `${user.name} ${user.lastName}`,
            }

        // If we identify some generic property that is valid for all events, we can iclude inside the properties object
        // e.g. { ...properties, companyId: id }
        amplitudeTrack(eventName, {
          ...baseProps,
          ...properties,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [eventName, user],
  )
  return { track }
}
