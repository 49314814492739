import { useTranslation } from 'react-i18next'

import { StatisticCard } from '../StatisticCard'

interface Props {
  densitySurfaceYield?: number
}

export const YieldCard: React.FC<Props> = ({ densitySurfaceYield }) => {
  const { t } = useTranslation('lot-dashboard')
  const { t: commonT } = useTranslation('common')

  return (
    <StatisticCard
      title={t('tabs.secondTab.yieldCard.title')}
      value={
        densitySurfaceYield
          ? commonT('systemsFormat.surfaceDensity', {
              val: densitySurfaceYield,
            })
          : '-'
      }
    />
  )
}
