import { useQuery } from '@apollo/client'
import { Spin, Row, Col, Image as AntImage, Typography } from 'antd'
import styled from 'styled-components'
import { ShopOutlined as AntShopOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'components'

import { Company, CompanyVariables, COMPANY } from './api'

const { Title } = Typography

interface Props {
  companyId: number
  onSuccess: () => void
  onCancel: () => void
}

const ImageContainer = styled(Col)`
  width: 156px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  overflow: hidden;
`

const Image = styled(AntImage)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 156px;
`
const ShopOutlined = styled(AntShopOutlined)`
  font-size: 156px;
`

const Description = styled(Title)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 35px !important;
`

export const CompanyCreationFeedbackModal: React.FC<Props> = ({
  companyId,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation('company-settings')
  const { data, loading } = useQuery<Company, CompanyVariables>(COMPANY, {
    variables: { id: companyId },
  })

  return (
    <Modal title={data?.company.name} open onCancel={onCancel} centered footer={null}>
      {!data && loading ? (
        <Spin />
      ) : (
        data && (
          <>
            <Row gutter={32} wrap={false}>
              <Col flex={1}>
                <ImageContainer>
                  {data.company.logo ? (
                    <Image src={data.company.logo} preview={false} />
                  ) : (
                    <ShopOutlined />
                  )}
                </ImageContainer>
              </Col>
              <Col flex={3}>
                <Description level={3}>{t('feedbackModal')}</Description>
              </Col>
            </Row>
            <Row justify="end">
              <Button type="primary" onClick={onSuccess}>
                {t('buttons.next')}
              </Button>
            </Row>
          </>
        )
      )}
    </Modal>
  )
}
