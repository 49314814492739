import _ from 'lodash'

import { SeasonLot } from 'api'
import { IndicatorStatus } from 'types'

const LotIndicatorsWeights = {
  irrigation: {
    BAD: 1,
    MEDIUM: 3,
    GOOD: 6,
  },
  chlorophyll: {
    BAD: 2,
    MEDIUM: 4,
    GOOD: 6,
  },
}

const TURNED_OFF_LOTS_KEY = 'OFF'
const DEFAULT_WEIGHT = 0

const compareLots = (lotA: SeasonLot, lotB: SeasonLot) => {
  const lotAIrrigation = lotA.riceLot.indicators?.irrigation
  const lotAChlorophyl = lotA.riceLot.indicators?.chlorophyll
  const lotBIrrigation = lotB.riceLot.indicators?.irrigation
  const lotBChlorophyl = lotB.riceLot.indicators?.chlorophyll

  const lotAIrrigationWeight = lotAIrrigation
    ? LotIndicatorsWeights.irrigation[lotAIrrigation]
    : DEFAULT_WEIGHT
  const lotAChlorophyllWeight = lotAChlorophyl
    ? LotIndicatorsWeights.chlorophyll[lotAChlorophyl]
    : DEFAULT_WEIGHT

  const lotBIrrigationWeight = lotBIrrigation
    ? LotIndicatorsWeights.irrigation[lotBIrrigation]
    : DEFAULT_WEIGHT
  const lotBChlorophyllWeight = lotBChlorophyl
    ? LotIndicatorsWeights.chlorophyll[lotBChlorophyl]
    : DEFAULT_WEIGHT

  return (
    lotAIrrigationWeight + lotAChlorophyllWeight - (lotBIrrigationWeight + lotBChlorophyllWeight)
  )
}

const sortLotsByCriticality = (lots: SeasonLot[]) => {
  const lotsToSort = [...lots]

  const lotsBySemaphore = _.groupBy(
    lotsToSort,
    lot => lot.riceLot.indicators?.semaphore ?? TURNED_OFF_LOTS_KEY,
  )

  const orderedLotsBySemaphore = _.mapValues(lotsBySemaphore, semaphoreLots =>
    semaphoreLots.sort(compareLots),
  )

  return [
    ...(orderedLotsBySemaphore[IndicatorStatus.BAD] ?? []),
    ...(orderedLotsBySemaphore[IndicatorStatus.MEDIUM] ?? []),
    ...(orderedLotsBySemaphore[IndicatorStatus.GOOD] ?? []),
    ...(orderedLotsBySemaphore[TURNED_OFF_LOTS_KEY] ?? []),
  ]
}

export const SortUtils = {
  sortLotsByCriticality,
}
