import { Control, Controller } from 'react-hook-form'
import { Row, Col, Form, Input, Select as AntSelect } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'

import { InputTitle } from 'components'
import { useCountries, useFeatures, usePermissions } from 'hooks'
import { ConversionUtils } from 'utils'
import { COUNTRIES } from 'consts'

const FormContainer = styled(Row)`
  margin-top: 22px;
  flex-flow: row;
`

const Image = styled.img`
  background-position: center;
  width: 26px;
  height: 26px;
  margin-right: 13px;
`

const Select = styled(AntSelect)`
  width: 100% !important;
  & .ant-select-selector {
    height: 55px !important;
    align-items: center;
  }
` as typeof AntSelect

const FlexRow = styled(Row)`
  flex: 1;
`

interface Props {
  control: Control<{
    companyName: string
    country: string
    governmentId?: string
    subregion: number
    commercialExecutiveId?: number
    region?: number
    phoneNumber?: string
  }>
  hideLocationInputs?: boolean
  organizationCountry: string
  selectedRegionId?: number
  commercialExecutiveId?: number
  subregionOptions: { label: string; value: number }[]
  commercialExecutivesOptions?: { label: string; value: number }[]
  regionOptions?: { label: string; value: number }[]
  selectedSubregionId?: number
  isUpdateMode?: boolean
  isCommercialExecutivesLoading?: boolean
  isRegionsLoading?: boolean
  isSubregionsLoading?: boolean
  onInputNameBlur?: () => void
  onInputGovernmentIdBlur?: () => void
  onSelectSubregionBlur?: () => void
  isCommercialExecutiveSelected?: boolean
  onInputPhoneNumberBlur?: () => void
  countryIsoCode?: string
}

export const ClientForm: React.FC<Props> = ({
  control,
  organizationCountry,
  selectedRegionId,
  subregionOptions,
  selectedSubregionId,
  isUpdateMode,
  onInputNameBlur,
  onInputGovernmentIdBlur,
  onSelectSubregionBlur,
  hideLocationInputs,
  commercialExecutivesOptions,
  commercialExecutiveId,
  regionOptions,
  isCommercialExecutivesLoading,
  isRegionsLoading,
  isSubregionsLoading,
  isCommercialExecutiveSelected,
  onInputPhoneNumberBlur,
  countryIsoCode,
}) => {
  const { t } = useTranslation('company-settings')
  const { t: commonT } = useTranslation('common')
  const { countries, loading } = useCountries()
  const { organizationFeatures } = useFeatures()
  const { permissions } = usePermissions()
  const { colors, foundation } = useTheme()

  const getLocalizedNumber = (value?: string) => {
    if (!value) return ''
    const onlyNumbers = ConversionUtils.keepOnlyNumbers(value)
    if (!onlyNumbers) return ''
    const localizedNumber = commonT('systemsFormat.localizeNumber', {
      val: onlyNumbers,
    })
    return localizedNumber
  }

  return (
    <>
      <FormContainer gutter={25}>
        <FlexRow>
          <Col flex={1}>
            <Col flex={1}>
              <InputTitle title={t('inputs.fullName')} />
              <Controller
                // eslint-disable-next-line i18next/no-literal-string
                name="companyName"
                control={control}
                render={({
                  field: { onChange, onBlur, ...restFieldProps },
                  fieldState: { error: fieldError },
                }) => (
                  <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                    <Input
                      size="large"
                      onBlur={() => {
                        onInputNameBlur?.()
                        onBlur()
                      }}
                      {...restFieldProps}
                      onChange={event =>
                        onChange(ConversionUtils.autoCapitalize(event.target.value))
                      }
                    />
                  </Form.Item>
                )}
              />
            </Col>
            <Col flex={1}>
              <InputTitle title={t('inputs.phoneNumber')} />
              <Controller
                // eslint-disable-next-line i18next/no-literal-string
                name="phoneNumber"
                control={control}
                render={({
                  field: { onChange, onBlur, ...restFieldProps },
                  fieldState: { error: fieldError },
                }) => (
                  <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                    <PhoneInput
                      onChange={onChange}
                      onBlur={() => {
                        onInputPhoneNumberBlur?.()
                        onBlur()
                      }}
                      country={countryIsoCode}
                      inputStyle={{
                        border: `2px solid ${colors.grey2}`,
                        width: '100%',
                        height: '42px',
                        borderRadius: foundation.borderRadius,
                      }}
                      {...restFieldProps}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Col>
        </FlexRow>

        {organizationFeatures.crm?.enabled && (
          <Col span={12}>
            <InputTitle title={t('inputs.NIT')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="governmentId"
              control={control}
              render={({
                field: { onChange, onBlur, value, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Input
                    type="text"
                    size="large"
                    onBlur={() => {
                      onInputGovernmentIdBlur?.()
                      onBlur()
                    }}
                    value={getLocalizedNumber(value)}
                    {...restFieldProps}
                    onChange={event => onChange(getLocalizedNumber(event.target.value))}
                  />
                </Form.Item>
              )}
            />
          </Col>
        )}
      </FormContainer>

      {!isUpdateMode && organizationFeatures.crm?.enabled && (
        <Row>
          <Col flex={1}>
            <InputTitle title={t('inputs.commercialExecutive')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="commercialExecutiveId"
              control={control}
              defaultValue={commercialExecutiveId}
              render={({
                field: { onChange, onBlur, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Select
                    disabled={permissions.isCommercialUser}
                    loading={isCommercialExecutivesLoading}
                    size="large"
                    onBlur={onBlur}
                    {...restFieldProps}
                    onChange={event => onChange(event)}
                    options={commercialExecutivesOptions}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
      )}

      {!hideLocationInputs && (
        <Row gutter={24}>
          <Col span={8}>
            <InputTitle title={t('inputs.country')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="country"
              control={control}
              defaultValue={organizationCountry}
              render={({
                field: { onChange, onBlur, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Select
                    disabled
                    size="large"
                    loading={loading}
                    onBlur={() => {
                      onBlur()
                    }}
                    {...restFieldProps}
                    value={organizationCountry}
                  >
                    {countries?.map(country => (
                      <Select.Option key={country.id} value={country.id}>
                        <Image src={COUNTRIES.flags[country.id]} />
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            />
          </Col>
          <Col span={8}>
            <InputTitle title={t('inputs.region')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="region"
              control={control}
              defaultValue={selectedRegionId}
              render={({
                field: { onChange, onBlur, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Select
                    disabled={
                      !(
                        permissions.isOrganizationAdmin &&
                        organizationFeatures.subFarmersManagement?.enabled
                      )
                    }
                    loading={isRegionsLoading}
                    size="large"
                    onBlur={onBlur}
                    {...restFieldProps}
                    onChange={event => onChange(event)}
                    options={regionOptions}
                    value={selectedRegionId}
                  />
                </Form.Item>
              )}
            />
          </Col>
          <Col span={8}>
            <InputTitle title={t('inputs.subregion')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="subregion"
              control={control}
              render={({
                field: { onChange, onBlur, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item
                  validateStatus={fieldError && 'error'}
                  help={
                    organizationFeatures.crm?.enabled
                      ? isCommercialExecutiveSelected && fieldError?.message
                      : fieldError?.message
                  }
                >
                  <Select
                    size="large"
                    loading={isSubregionsLoading}
                    onBlur={() => {
                      onSelectSubregionBlur?.()
                      onBlur()
                    }}
                    disabled={organizationFeatures.crm?.enabled && !isCommercialExecutiveSelected}
                    {...restFieldProps}
                    onChange={event => onChange(event)}
                    options={subregionOptions}
                    defaultValue={selectedSubregionId}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
