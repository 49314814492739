import { Row, Card, Typography } from 'antd'
import styled from 'styled-components'

const { Title: AntTitle, Text: AntText } = Typography

export { Layout } from 'components'
export { DeleteLotConfirmationModal } from './DeleteLotConfirmationModal'
export { Control } from './Control'

interface StyledProps {
  $width?: string
}

export const MapSidebar = styled(Row)<StyledProps>`
  width: ${({ $width }) => $width || '450px'};
  position: absolute;
  flex-direction: column;
  top: 15px;
  right: 15px;
  z-index: 10;
  @media (max-height: 735px) {
    top: 25px;
    height: calc(100vh - 25px);
  }
`
export const LeftSidebar = styled(Row)`
  width: 300px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;
  @media (max-height: 735px) {
    top: 25px;
  }
`

interface Props {
  $isEditMode?: boolean
}

export const StyledCard = styled(Card)<Props>`
  background-color: ${({ theme }) => theme.colors.grey1};
  padding: ${({ theme }) => theme.card.mapCardPadding};
  & .ant-card-body {
    padding: 0;
  }
  @media (max-height: 735px) {
    ${props =>
      props.$isEditMode ? `max-height: calc(100vh - 135px);` : `max-height: calc(100vh - 90px)`};
  }
  overflow-y: auto;
`

export const Title = styled(AntTitle)`
  margin-bottom: 22px !important;
`

export const Text = styled(AntText)`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.olive};
`

export const SearchBoxContainer = styled(Row)`
  margin-bottom: 30px;

  & .mapboxgl-ctrl-geocoder {
    max-width: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.foundation.borderRadius};
  }
  @media (max-height: 735px) {
    margin-bottom: 15px;
  }
`
