import styled from 'styled-components'
import { Col, Row } from 'antd'

export { MeasureAreaDropdown } from './MeasureAreaDropdown'
export { RtkDropdown } from './RtkDropdown'

interface Props {
  $isActive?: boolean
}

export const IconContainer = styled(Col)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.white : theme.colors.new.baseColor[400]};
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
`

export const ToolbarContainer = styled(Row)`
  width: 280px;
  position: absolute;
  flex-direction: column;
  bottom: 25px;
  right: 50px;
  z-index: 10;
  align-items: flex-end;
  @media (max-height: 850px) {
    max-height: calc(100% - 50px);
  }
`
