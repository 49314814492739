import { useQuery } from '@apollo/client'

import { SeasonData, SEASON, SeasonVariables } from 'api'
import { DateUtils } from 'utils'

import { usePermissions } from './usePermissions'

export const useSeason = (id?: number) => {
  const fromDate = DateUtils.formatToUtc(new Date())
  const { permissions } = usePermissions()
  return useQuery<SeasonData, SeasonVariables>(SEASON, {
    variables: {
      id: id!,
      fromDate,
      isSatelliteImageryFlagOn: permissions.satelliteImagery,
    },
    skip: !id,
  })
}
