/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Countries } from './types/Countries'

export const COUNTRIES = gql`
  query Countries {
    countries {
      results {
        id
        name
        code
      }
    }
  }
`
