import { Dropdown, Space, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { PolygonIcon, CompareImageIcon, RtkIcon, DamIcon } from 'assets/icons'

import { IconContainer, ToolbarContainer, MeasureAreaDropdown, RtkDropdown } from './components'
import { DrawingOption, ToolMode } from '../../types'
import { LotRtkItem } from './components/RtkDropdown/types'

interface Props {
  mode: ToolMode
  onHandleCompareImages: () => void
  drawingOption: DrawingOption
  showDrawingToolDropdown: boolean
  onDrawingToolDropdownVisibleChange: () => void
  onDrawingOptionSelected: (option: DrawingOption) => void
  isDrawingToolAvailable: boolean
  lots: LotRtkItem[]
  showRtk: boolean
  isRtkLoading: boolean
  toggleShowRtk: (show?: boolean) => void
  isShowDamsModeSelected: boolean
  handleDams: () => void
  isDamsToolAvailable: boolean
}

export const Toolbar: React.FC<Props> = ({
  onHandleCompareImages,
  drawingOption,
  onDrawingOptionSelected,
  showDrawingToolDropdown,
  onDrawingToolDropdownVisibleChange,
  mode,
  isDrawingToolAvailable,
  lots,
  showRtk,
  isRtkLoading,
  toggleShowRtk,
  isShowDamsModeSelected,
  handleDams,
  isDamsToolAvailable,
}) => {
  const { t } = useTranslation('map')
  return (
    <ToolbarContainer>
      <Space size={11}>
        <Dropdown
          placement="topRight"
          dropdownRender={() => (
            <MeasureAreaDropdown
              selectedItem={drawingOption}
              onDrawingOptionSelected={onDrawingOptionSelected}
              isDrawingToolAvailable={isDrawingToolAvailable}
            />
          )}
          trigger={['click']}
          onOpenChange={onDrawingToolDropdownVisibleChange}
          open={showDrawingToolDropdown}
        >
          <IconContainer
            $isActive={mode === 'draw' || drawingOption === 'hide'}
            onClick={() => onDrawingOptionSelected('reset')}
          >
            <PolygonIcon />
          </IconContainer>
        </Dropdown>

        <IconContainer onClick={onHandleCompareImages} $isActive={mode === 'compare'}>
          <CompareImageIcon />
        </IconContainer>

        <Dropdown
          trigger={['click']}
          placement="topRight"
          dropdownRender={() => (
            <RtkDropdown
              lots={lots}
              toggleShowRtk={toggleShowRtk}
              showRtk={showRtk}
              isRtkLoading={isRtkLoading}
            />
          )}
        >
          <IconContainer $isActive={showRtk}>
            <RtkIcon />
          </IconContainer>
        </Dropdown>
        {isDamsToolAvailable ? (
          <IconContainer $isActive={isShowDamsModeSelected} onClick={handleDams}>
            <DamIcon />
          </IconContainer>
        ) : (
          <Tooltip title={t('tools.damTooltip')}>
            <IconContainer>
              <DamIcon />
            </IconContainer>
          </Tooltip>
        )}
      </Space>
    </ToolbarContainer>
  )
}
