import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateLots, UpdateLotsVariables, UPDATE_LOTS } from '../api'

export const useUpdateLots = () => {
  const [updateLots, { loading }] = useMutation<UpdateLots, UpdateLotsVariables>(UPDATE_LOTS, {
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data }) => {
      if (!data) return

      data.updateLots.results.forEach(lot => {
        // Workaraound because of this: https://github.com/apollographql/apollo-client/issues/7577
        const cacheLotId = cache.identify(lot as unknown as StoreObject)
        cache.evict({ id: cacheLotId, fieldName: 'riceLot' })
        const seasonLot: StoreObject = {
          id: lot.id,
          __typename: 'SeasonLot',
        }
        const { variety, crop } = lot
        cache.modify({
          id: cache.identify(seasonLot),
          fields: {
            variety() {
              return variety
            },
            crop() {
              return crop
            },
          },
        })
      })
    },
  })

  return { updateLots, loading }
}
