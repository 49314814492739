import styled from 'styled-components'
import { Row } from 'antd'

export { Calendar } from './Calendar'

export const Container = styled(Row)`
  width: 280px;
  position: absolute;
  flex-direction: column;
  top: 15px;
  left: 50px;
  z-index: 10;
  max-height: calc(100% - 80px);
  @media (max-height: 850px) {
    max-height: calc(100% - 50px);
  }
`
