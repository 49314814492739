import { Navigate, Route, Routes } from 'react-router-dom'

import { Dashboard, LotDashboard } from './features'

export const DashboardRouter: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="lots/:id" element={<LotDashboard />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  )
}
