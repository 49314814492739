import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'

import { useCompanyMilestones, usePermissions, useUpdateLot, useUpdateTypeStyles } from 'hooks'
import { DatePicker } from 'components'
import { UpdateRiceLotVariables } from 'hooks/useUpdateRiceLot/api'
import { MilestoneUpdateType, RiceLotStatus } from 'types'
import { DateUtils, LotUtils } from 'utils'

import { CropsSelect } from '../components'

interface MilestoneData {
  title: string
  component: JSX.Element
  completedDate: boolean
  showMilestone: boolean
  ballBackground: string
}

interface Props {
  lotId: number
  previousCropId?: number
  riceLotId: number
  emergenceDate?: string
  plantingDate?: string
  harvestDate?: string
  preparationDate?: string
  burningDate?: string
  earAppearanceDate?: string
  firstIrrigationStartDate?: string
  firstIrrigationEndDate?: string
  floweringDate?: string
  differentiationDate?: string
  physiologicalMaturityDate?: string
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
  emergenceUpdateType?: MilestoneUpdateType
  plantingUpdateType?: MilestoneUpdateType
  floweringUpdateType?: MilestoneUpdateType
  harvestUpdateType?: MilestoneUpdateType
  preparationUpdateType?: MilestoneUpdateType
  burningUpdateType?: MilestoneUpdateType
  earAppearanceUpdateType?: MilestoneUpdateType
  differentiationUpdateType?: MilestoneUpdateType
}

export const useBody = ({
  riceLotId,
  plantingDate,
  emergenceDate,
  harvestDate,
  preparationDate,
  burningDate,
  earAppearanceDate,
  lotId,
  differentiationDate,
  firstIrrigationEndDate,
  firstIrrigationStartDate,
  floweringDate,
  physiologicalMaturityDate,
  previousCropId,
  updateRiceLotMutation,
  emergenceUpdateType,
  plantingUpdateType,
  floweringUpdateType,
  harvestUpdateType,
  preparationUpdateType,
  burningUpdateType,
  earAppearanceUpdateType,
  differentiationUpdateType,
}: Props) => {
  const { t } = useTranslation('lot-dashboard')
  const { t: commonT } = useTranslation('common')
  const { colors } = useTheme()
  const { permissions } = usePermissions()
  const { activeCompanyMilestones } = useCompanyMilestones()

  const { updateLot, loading } = useUpdateLot()

  const { getBallBackground } = useUpdateTypeStyles()

  const timelineData: MilestoneData[] = [
    {
      title: t('timeline.previousCrop'),
      component: (
        <CropsSelect
          loading={loading}
          placeholder={t('timeline.selectCrop')}
          defaultValue={previousCropId}
          getAll
          onChange={value =>
            updateLot({
              variables: {
                updateLotDto: { id: lotId, previousCropId: value?.id },
              },
            })
          }
        />
      ),
      completedDate: !!previousCropId,
      showMilestone: true,
      ballBackground: colors.new.secondary[600],
    },
    {
      title: t('timeline.preparation'),
      component: (
        <DatePicker
          allowClear={false}
          defaultValue={preparationDate ? dayjs(preparationDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  preparationDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!preparationDate,
      showMilestone: activeCompanyMilestones.includes(RiceLotStatus.PREPARATION),
      ballBackground: getBallBackground(preparationUpdateType),
    },
    {
      title: t('timeline.burning'),
      component: (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(burningUpdateType)}
          defaultValue={burningDate ? dayjs(burningDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  burningDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!burningDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.BURNING),
      ballBackground: getBallBackground(burningUpdateType),
    },
    {
      title: t('timeline.planting'),
      component: (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(plantingUpdateType)}
          defaultValue={plantingDate ? dayjs(plantingDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  plantingDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!plantingDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.PLANTING),
      ballBackground: getBallBackground(plantingUpdateType),
    },
    {
      title: t('timeline.emergence'),
      component: permissions.isCompanyAdminUser ? (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(emergenceUpdateType)}
          defaultValue={emergenceDate ? dayjs(emergenceDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  emergenceDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ) : (
        <Tooltip title={commonT('messages.readOnlyAccess')}>
          <DatePicker
            disabled
            defaultValue={emergenceDate ? dayjs(emergenceDate) : undefined}
            onChange={value =>
              updateRiceLotMutation({
                variables: {
                  id: riceLotId,
                  updateRiceLotDTO: {
                    emergenceDate: DateUtils.formatValue(value ? value.toDate() : null),
                  },
                },
              })
            }
          />
        </Tooltip>
      ),
      completedDate: !!emergenceDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.EMERGENCE),
      ballBackground: getBallBackground(emergenceUpdateType),
    },
    {
      title: t('timeline.initialIrrigation'),
      component: (
        <DatePicker
          defaultValue={firstIrrigationStartDate ? dayjs(firstIrrigationStartDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  firstIrrigationStartDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!firstIrrigationStartDate,
      showMilestone: activeCompanyMilestones.includes(RiceLotStatus.START_IRRIGATION),
      ballBackground: colors.new.secondary[600],
    },
    {
      title: t('timeline.irrigationCompleted'),
      component: (
        <DatePicker
          defaultValue={firstIrrigationEndDate ? dayjs(firstIrrigationEndDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  firstIrrigationEndDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!firstIrrigationEndDate,
      showMilestone: activeCompanyMilestones.includes(RiceLotStatus.END_IRRIGATION),
      ballBackground: colors.new.secondary[600],
    },
    {
      title: t('timeline.panicleInitiation'),
      component: (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(differentiationUpdateType)}
          defaultValue={differentiationDate ? dayjs(differentiationDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  differentiationDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!differentiationDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.DIFFERENTIATION),
      ballBackground: getBallBackground(differentiationUpdateType),
    },
    {
      title: t('timeline.flowering'),
      component: (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(floweringUpdateType)}
          defaultValue={floweringDate ? dayjs(floweringDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  floweringDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!floweringDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.FLOWERING),
      ballBackground: getBallBackground(floweringUpdateType),
    },
    {
      title: t('timeline.earAppearance'),
      component: (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(earAppearanceUpdateType)}
          defaultValue={earAppearanceDate ? dayjs(earAppearanceDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  earAppearanceDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!earAppearanceDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.EAR_APPEARANCE),
      ballBackground: getBallBackground(earAppearanceUpdateType),
    },
    {
      title: t('timeline.maturation'),
      component: (
        <DatePicker
          defaultValue={physiologicalMaturityDate ? dayjs(physiologicalMaturityDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  physiologicalMaturityDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!physiologicalMaturityDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.PHYSIOLOGICAL_MATURITY),
      ballBackground: colors.new.secondary[600],
    },
    {
      title: t('timeline.harvest'),
      component: (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(harvestUpdateType)}
          defaultValue={harvestDate ? dayjs(harvestDate) : undefined}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  harvestDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
      completedDate: !!harvestDate,
      showMilestone: activeCompanyMilestones?.includes(RiceLotStatus.HARVEST),
      ballBackground: getBallBackground(harvestUpdateType),
    },
  ]

  return {
    bodyData: timelineData.filter(item => item.showMilestone),
  }
}
