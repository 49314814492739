import { useQuery } from '@apollo/client'

import { CommercialExecutives, COMMERCIAL_EXECUTIVES } from 'api'

export const useCommercialExecutives = ({
  regionId,
  skip,
}: {
  regionId?: number
  skip?: boolean
}) => {
  const { data, loading } = useQuery<CommercialExecutives>(COMMERCIAL_EXECUTIVES, {
    variables: { regionId },
    skip,
  })

  return {
    loading,
    data: data?.commercialExecutives.results,
  }
}
