import { Table as AntTable } from 'antd'
import styled from 'styled-components'

export const StyledTable = styled(AntTable)`
  & .ant-table-cell {
    border-bottom: unset;
    padding: 5px;
  }

  & th {
    background: ${({ theme }) => theme.colors.transparent} !important;
    border-radius: 0px !important;
    text-align: center !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }

  & .ant-table-cell-scrollbar {
    box-shadow: none;
  }

  .ant-table-cell-fix-left {
    background: ${({ theme }) => theme.colors.grey1} !important;
  }

  .ant-table-row .ant-table-cell {
    > span > span {
      color: ${({ theme }) => theme.colors.black};
    }
  }
` as typeof AntTable
