import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { LocaleLanguage, Selects } from 'types'
import { LANGUAGE } from 'consts'

import { Crop, CROPS, Crops, CropsVariables } from './api'

export type { Crop }

interface Props {
  placeholder?: string
  onChange?: (crop?: Crop) => void
  className?: string
  loading?: boolean
  defaultValue?: number
  value?: number
  disabled?: boolean
  dropdownClassName?: string
  popupMatchSelectWidth?: boolean
  allowClear?: boolean
  getAll?: boolean
  withAllCropsOption?: boolean
  supportedCropIds?: number[]
  onClick?: () => void
}

export const CropsSelect: React.FC<Props> = ({
  placeholder,
  onChange,
  className,
  loading,
  defaultValue,
  value,
  disabled,
  dropdownClassName,
  popupMatchSelectWidth,
  allowClear,
  getAll,
  withAllCropsOption,
  supportedCropIds,
  onClick,
}) => {
  const { data, loading: dataLoading } = useQuery<Crops, CropsVariables>(CROPS, {
    variables: { all: getAll },
  })
  const { t } = useTranslation('common')

  const allCropsOption = withAllCropsOption ? Selects.ALL_CROPS : undefined

  const options = useMemo(() => {
    if (!supportedCropIds)
      return data?.crops.results.map(crop => ({
        value: crop.id,
        label: crop.locales[LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]],
      }))

    const filteredCrops = data?.crops.results.filter(crop => supportedCropIds?.includes(crop.id))

    return filteredCrops?.map(crop => ({
      value: crop.id,
      label: crop.locales[LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]],
    }))
  }, [data?.crops.results, supportedCropIds])

  return (
    <Select
      placeholder={placeholder ?? t('inputs.crop')}
      defaultValue={defaultValue || allCropsOption}
      value={value || allCropsOption}
      loading={dataLoading || loading}
      disabled={disabled}
      className={className}
      popupClassName={dropdownClassName}
      popupMatchSelectWidth={popupMatchSelectWidth}
      allowClear={allowClear}
      onChange={id => {
        const selectedCrop = data?.crops.results.find(crop => crop.id === id)

        onChange?.(selectedCrop)
      }}
      onClick={onClick}
    >
      {withAllCropsOption && (
        <Select.Option value={Selects.ALL_CROPS}>{t('inputs.allCrops')}</Select.Option>
      )}
      {options?.map(crop => (
        <Select.Option key={crop.value} value={crop.value}>
          {crop.label}
        </Select.Option>
      ))}
    </Select>
  )
}
