import { EmptyResults, OrganizationEmptyResults } from './components'

interface Props {
  noFields?: boolean
  isOrganizationUser: boolean
  companyCreation: boolean
  isAdminUser: boolean
  fieldEntityFlag: boolean
  isCompanyViewerUser: boolean
}
export const EmptyResultsCard: React.FC<Props> = ({
  noFields,
  isOrganizationUser,
  companyCreation,
  isAdminUser,
  fieldEntityFlag,
  isCompanyViewerUser,
}) => {
  return isOrganizationUser && !fieldEntityFlag ? (
    <OrganizationEmptyResults companyCreation={companyCreation} />
  ) : (
    <EmptyResults
      noFields={noFields}
      isAdminUser={isAdminUser}
      isOrganizationUser={isOrganizationUser}
      isCompanyViewerUser={isCompanyViewerUser}
    />
  )
}
