import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { ActiveCompanyContext } from 'contexts'
import { GraphQLUtils } from 'utils'
import { useCompanySeasons } from 'hooks'

import { Fields, FieldsSettingsVariables, FIELDS_SETTINGS } from '../../../api'
import { DeleteField, DeleteFieldVariables, DELETE_FIELD } from '../api'
import { useUpdateField } from '../../../hooks'

export const useFieldsSettings = () => {
  const navigate = useNavigate()

  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { data: companySeasons } = useCompanySeasons(activeCompanyId)

  const [selectedFieldIdToDelete, setSelectedFieldIdToDelete] = useState<number>()
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false)

  const { data, loading: loadingFields } = useQuery<Fields, FieldsSettingsVariables>(
    FIELDS_SETTINGS,
    {
      variables: { companyId: activeCompanyId! },
      skip: !activeCompanyId,
    },
  )

  const [deleteField, { loading: deleteFieldLoading }] = useMutation<
    DeleteField,
    DeleteFieldVariables
  >(DELETE_FIELD, {
    update: async cache => {
      const cachedFields = cache.readQuery<Fields>({
        query: FIELDS_SETTINGS,
        variables: { companyId: activeCompanyId },
      })

      if (!cachedFields) return

      const filteredFields = cachedFields.fields.results.filter(
        field => field.id !== selectedFieldIdToDelete,
      )

      cache.writeQuery({
        query: FIELDS_SETTINGS,
        data: { fields: { ...cachedFields.fields, results: filteredFields } },
        variables: { companyId: activeCompanyId },
      })
    },
    onCompleted: () => {
      setSelectedFieldIdToDelete(undefined)
      setShowDeleteFieldModal(false)
    },
    onError: GraphQLUtils.errorHandler,
  })

  const { updateField, updateLoading: updateFieldLoading } = useUpdateField()

  const onAddField = () => navigate(`fields`)

  const onEditField = (id: number) => navigate(`fields/${id}`)

  const onDeleteField = (id: number) => {
    setSelectedFieldIdToDelete(id)
    setShowDeleteFieldModal(true)
  }

  const onConfirmDeleteField = () => {
    if (!selectedFieldIdToDelete) return
    deleteField({ variables: { id: selectedFieldIdToDelete } })
  }

  const onCancelDeleteField = () => {
    setSelectedFieldIdToDelete(undefined)
    setShowDeleteFieldModal(false)
  }

  const lotsToDeleteAndSeasonsQty = useMemo(() => {
    const seasons = companySeasons?.seasons.results
    const totalLots = seasons?.reduce(
      (accumulator, season) => {
        const seasonFields = season.seasonFields.filter(
          seasonField => seasonField.field?.id === selectedFieldIdToDelete,
        )
        const lotCount = seasonFields.reduce(
          (total, seasonField) => total + seasonField.lots.length,
          0,
        )
        return {
          lots: accumulator.lots + lotCount,
          seasons: seasonFields.length ? accumulator.seasons + 1 : accumulator.seasons,
        }
      },
      { lots: 0, seasons: 0 },
    ) ?? { lots: 0, seasons: 1 }

    return totalLots
  }, [companySeasons?.seasons.results, selectedFieldIdToDelete])

  return {
    showDeleteFieldModal: showDeleteFieldModal && !!selectedFieldIdToDelete,
    fields: data?.fields.results,
    loadingFields,
    deleteFieldLoading,
    lotsToDeleteAndSeasonsQty,
    onAddField,
    onEditField,
    onDeleteField,
    onConfirmDeleteField,
    onCancelDeleteField,
    updateField,
    updateFieldLoading,
  }
}
