import { useMutation, useQuery } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import {
  ASSIGN_IRRIGATION_WORKER,
  AssignIrrigationWorkerVariables,
  AssignIrrigationWorker,
  COMPANY_IRRIGATION_WORKERS,
  CompanyIrrigationWorkers,
  CompanyIrrigationWorkersVariables,
} from './api'

export const useIrrigationWorkers = (companyId?: number) => {
  const [assignIrrigationWorker, { loading: isAssignIrrigationWorkerLoading }] = useMutation<
    AssignIrrigationWorker,
    AssignIrrigationWorkerVariables
  >(ASSIGN_IRRIGATION_WORKER, {
    onError: GraphQLUtils.errorHandler,
  })

  const { data, loading: isCompanyIrrigationWorkersLoading } = useQuery<
    CompanyIrrigationWorkers,
    CompanyIrrigationWorkersVariables
  >(COMPANY_IRRIGATION_WORKERS, {
    variables: { id: companyId! },
    skip: !companyId,
  })

  return {
    assignIrrigationWorker,
    isAssignIrrigationWorkerLoading,
    isCompanyIrrigationWorkersLoading,
    companyIrrigationWorkers: data?.company.usersRoles,
  }
}
