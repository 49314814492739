import { Row } from 'antd'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled(Row)`
  padding: 25px;
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  display: flex;
  height: 100%;
`

export const GreyCard: React.FC<PropsWithChildren> = ({ children }) => (
  <Container>{children}</Container>
)
