import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Col, Tabs } from 'antd'
import { times } from 'lodash'
import { useTranslation } from 'react-i18next'

import { dashboardImage } from 'assets/images'
import { ConfirmationModal, FullScreenLoader } from 'components'
import { LotsMapLocationState, MilestoneUpdateType } from 'types'
import { ActiveCompanyContext } from 'contexts'
import { usePermissions, useSeason } from 'hooks'
import { NAVIGATION } from 'consts'

import { GoBackButton, Layout, Container, SummaryTab, ReportTab } from './components'
import {
  LOT_DASHBOARD,
  LotDashboard as LotDashboardType,
  LotDashboardVariables,
  AppliedFertilizer,
  AppliedPhytosanitaries,
} from './api'
import { LotOrFieldInformation } from '../components'
import {
  useCreateRiceFertilizer,
  useDeleteRiceFertilizer,
  useDeleteRicePhytosanitary,
  useCreateRicePhytosanitary,
} from './hooks'
import { DeleteAplicationData, DeleteType } from './components/types'

const INITAL_INPUT_TABLES_ELEMENTS_AMOUNT = 4

export const LotDashboard: React.FC = () => {
  const { t } = useTranslation('lot-dashboard')
  const { t: commonT } = useTranslation('common')
  const { permissions } = usePermissions()

  const [appliedFertilizers, setAppliedFertilizers] = useState<AppliedFertilizer[]>([])
  const [appliedPhytosanitaries, setAppliedPhytosanitaries] = useState<AppliedPhytosanitaries[]>([])
  const [deleteAplicationData, setDeleteAplicationData] = useState<DeleteAplicationData>()
  const [fieldId, setFieldId] = useState<number>()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { activeSeasonId, activeCountryId } = ActiveCompanyContext.useActiveCompanyContext()

  if (!id) navigate(NAVIGATION.GO_BACK)

  const { data: season, loading: seasonLoading } = useSeason(activeSeasonId)
  const { data, loading } = useQuery<LotDashboardType, LotDashboardVariables>(LOT_DASHBOARD, {
    variables: { id: Number(id), isSatelliteImageryFlagOn: permissions.satelliteImagery },
  })

  const { createRiceFertilizer, loading: createFertilizerLoading } = useCreateRiceFertilizer()
  const { createRicePhytosanitary, loading: createPhytosanitaryLoading } =
    useCreateRicePhytosanitary()
  const { deleteRiceFertilizer, loading: deleteRiceFertilizerLoading } = useDeleteRiceFertilizer(
    () => setDeleteAplicationData({ showModal: false, id: undefined, type: undefined }),
  )
  const { deleteRicePhytosanitary, loading: deleteRicePhytosanitaryLoading } =
    useDeleteRicePhytosanitary(() =>
      setDeleteAplicationData({ showModal: false, id: undefined, type: undefined }),
    )

  const lotEmergenceDate =
    data?.lot.riceLot.emergenceDate &&
    data?.lot.riceLot.emergenceUpdateType !== MilestoneUpdateType.AUTOMATIC_CONVERSION
      ? data?.lot.riceLot.emergenceDate
      : undefined

  const isHarvested = !!data?.lot.riceLot.isHarvested

  const historicalIrrigationValues = useMemo(() => {
    if (!lotEmergenceDate || !data?.lot.riceLot.historicalIrrigation?.values) return []

    return data.lot.riceLot.historicalIrrigation.values
  }, [data?.lot.riceLot.historicalIrrigation?.values, lotEmergenceDate])

  useEffect(() => {
    const seasonLot = season?.season.seasonFields.find(seasonField =>
      seasonField.lots.some(lot => lot.id === Number(id)),
    )

    if (!seasonLoading && !seasonLot) navigate('/dashboard', { replace: true })
    setFieldId(seasonLot?.field?.id)
  }, [id, navigate, season?.season.seasonFields, seasonLoading])

  useEffect(() => {
    const existingRiceFertilizers = data?.lot.riceLot.appliedFertilizers
    if (!data?.lot.riceLot || !permissions.satelliteImagery) return

    if (!existingRiceFertilizers?.length && !createFertilizerLoading)
      times(INITAL_INPUT_TABLES_ELEMENTS_AMOUNT, () => {
        createRiceFertilizer({
          variables: { createFertilizerDTO: { riceLotId: data?.lot.riceLot.id } },
        })
      })
    if (!existingRiceFertilizers) return
    setAppliedFertilizers(existingRiceFertilizers)
  }, [
    createFertilizerLoading,
    createRiceFertilizer,
    data?.lot.riceLot,
    permissions.satelliteImagery,
  ])

  useEffect(() => {
    const existingRicePhytosanitaries = data?.lot.riceLot.appliedPhytosanitaries
    if (!data?.lot.riceLot || !permissions.satelliteImagery) return

    if (!existingRicePhytosanitaries?.length && !createPhytosanitaryLoading)
      times(INITAL_INPUT_TABLES_ELEMENTS_AMOUNT, () => {
        createRicePhytosanitary({
          variables: { createPhytosanitaryDTO: { riceLotId: data?.lot.riceLot.id } },
        })
      })
    if (!existingRicePhytosanitaries) return
    setAppliedPhytosanitaries(existingRicePhytosanitaries)
  }, [
    createPhytosanitaryLoading,
    createRicePhytosanitary,
    data?.lot.riceLot,
    permissions.satelliteImagery,
  ])

  if (loading || seasonLoading) return <FullScreenLoader />

  const items = [
    {
      key: '1',
      label: t('tabs.firstTab.title'),
      visible: true,
      children: (
        <SummaryTab
          lotDashboardData={data}
          fieldId={fieldId}
          lotId={Number(id)}
          onCreateRiceFertilizer={createRiceFertilizer}
          appliedFertilizers={appliedFertilizers}
          onHandleDataToDelete={setDeleteAplicationData}
          onCreateRicePhytosanitary={createRicePhytosanitary}
          appliedPhytosanitaries={appliedPhytosanitaries}
          activeCountryId={activeCountryId}
        />
      ),
    },
    {
      key: '2',
      label: t('tabs.secondTab.title'),
      visible: permissions.satelliteImagery,
      children: (
        <ReportTab
          daysToHarvestFromEmergence={
            isHarvested && data?.lot.riceLot.daysSinceEmergence
              ? data?.lot.riceLot.daysSinceEmergence
              : undefined
          }
          densitySurfaceYield={
            data?.lot.riceLot.yield ? data?.lot.riceLot.yield / data?.lot.size : undefined
          }
          historicalIrrigationValues={historicalIrrigationValues}
          hasEmergenceDate={!!lotEmergenceDate}
          yieldName={data?.lot.riceLot.targetYieldCurve?.name}
          targetYieldCurveValues={data?.lot.riceLot.targetYieldCurve?.values}
          realYieldCurveValues={data?.lot.riceLot.realYieldCurve?.values}
          emergenceDate={lotEmergenceDate ?? undefined}
          lotId={data?.lot.id}
          fieldId={fieldId}
          lotCoordinates={data?.lot.area?.coordinates[0]}
        />
      ),
    },
  ]

  const tabItems = items.filter(item => item.visible)

  return (
    <Layout
      headerTitle={t('headerTitle')}
      headerImage={dashboardImage}
      actionButton={<GoBackButton />}
    >
      <Container>
        <Col span={24}>
          <LotOrFieldInformation
            name={data?.lot.name ?? ''}
            commercialName={
              data?.lot.commercialName ? `Sucursal ${data?.lot.commercialName}` : undefined
            }
            lotsOrHaQty={commonT('systemsFormat.area', {
              val: { area: data?.lot.size, withDecimals: 1 },
            })}
            isHarvestedLot={isHarvested}
            onSettingsClick={() => {
              const state: LotsMapLocationState = {
                seasonId: activeSeasonId,
                lotId: Number(id),
                fieldId,
              }
              return navigate('/settings/lots', {
                state,
              })
            }}
          />
        </Col>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </Container>
      {deleteAplicationData?.showModal && (
        <ConfirmationModal
          description={t('inputDeletionModal.confirmation')}
          title={t('inputDeletionModal.title')}
          onConfirm={() => {
            if (deleteAplicationData.id) {
              if (deleteAplicationData.type === DeleteType.FERTILIZER) {
                deleteRiceFertilizer({ variables: { id: deleteAplicationData.id } })
                return
              }
              deleteRicePhytosanitary({ variables: { id: deleteAplicationData.id } })
            }
          }}
          onCancel={() =>
            setDeleteAplicationData({ showModal: false, id: undefined, type: undefined })
          }
          loading={deleteRiceFertilizerLoading || deleteRicePhytosanitaryLoading}
        />
      )}
    </Layout>
  )
}
