import { gql } from '@apollo/client'

export type { SeasonLotsExportVariables, SeasonLotsExport } from './types/SeasonLotsExport'

export const SEASON_LOTS_EXPORT = gql`
  query SeasonLotsExport($lotsIds: [Int!], $startRange: DateTime!, $endRange: DateTime!) {
    seasonLotsExport(lotsIds: $lotsIds, startRange: $startRange, endRange: $endRange) {
      url
    }
  }
`
