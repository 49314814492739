import { useState } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import useDebounce from 'react-use/lib/useDebounce'
import { useTranslation } from 'react-i18next'

import { UpdateRiceLotVariables } from 'hooks/useUpdateRiceLot/api'

const { TextArea: AntTextArea } = Input

interface TextAreaProps {
  height?: string
}

const TextArea = styled(AntTextArea)<TextAreaProps>`
  height: ${({ height }) => height} !important;
`
interface Props {
  comment?: string
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
  riceLotId?: number
  height?: string
}

export const Comment: React.FC<Props> = ({
  comment,
  updateRiceLotMutation,
  riceLotId,
  height = '130px',
}) => {
  const { t } = useTranslation('lot-dashboard')
  const [newComment, setNewComment] = useState<string>()

  useDebounce(
    () => {
      if (!newComment || !riceLotId) return
      updateRiceLotMutation({
        variables: {
          id: riceLotId,
          updateRiceLotDTO: { comment: newComment },
        },
      })
    },
    1000,
    [newComment],
  )
  return (
    <TextArea
      autoSize
      defaultValue={comment}
      height={height}
      placeholder={t('commentsCard.placeholder')}
      onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
        const commentValue = event.currentTarget.value
        setNewComment(commentValue)
      }}
    />
  )
}
