import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { dashboardImage } from 'assets/images'
import { FullScreenLoader, SeasonSelect } from 'components'
import { ActiveCompanyContext } from 'contexts'
import {
  useHasCompanyOrFieldsOrLots,
  useLocalStorage,
  usePermissions,
  useSeason,
  useTrackImpression,
} from 'hooks'
import { STORAGE_KEYS } from 'consts'
import { AmplitudeEventsName } from 'types'

import {
  InformationCard,
  ScrollColumn,
  ContainerRow,
  Layout,
  SortSelect,
  EmptyResultsCard,
  FieldsAndLotsBody,
} from './components'
import { SortUtils } from './utils'
import { SELECT } from './consts'

export const Dashboard: React.FC = () => {
  const { activeSeasonId, setActiveSeason, activeCompanyId, activeCompanyName } =
    ActiveCompanyContext.useActiveCompanyContext()
  const { data, loading } = useSeason(activeSeasonId)
  const [storedOrderDashboardLotsBy, setOrderDashboardLotsBy] = useLocalStorage<string>(
    STORAGE_KEYS.ORDER_DASHBOARD_LOTS_BY,
    SELECT.VALUES.CRITICALITY,
  )
  const { t } = useTranslation('dashboard')
  const { permissions } = usePermissions()
  const { noFields, noLots } = useHasCompanyOrFieldsOrLots()

  useTrackImpression({
    eventName: AmplitudeEventsName.DASHBOARD_IMPRESSION,
    eventProperties: {
      activeCompanyId,
      activeCompanyName,
    },
    loading: !activeCompanyId || loading,
  })

  const getEstimatedRiceProduction = useCallback(
    () =>
      data?.season.seasonFields.reduce(
        (acc, { lots }) =>
          acc +
          lots.reduce((accLot, { riceLot }) => accLot + (riceLot.estimatedYield.value ?? 0), 0),
        0,
      ) ?? 0,

    [data?.season.seasonFields],
  )

  const getDeclaredRiceProduction = useCallback(
    () =>
      data?.season.seasonFields.reduce(
        (acc, { lots }) =>
          acc + lots.reduce((accLot, { riceLot }) => accLot + (riceLot.yield ?? 0), 0),
        0,
      ) ?? 0,

    [data?.season.seasonFields],
  )

  const onChangeActiveSeason = (seasonId?: number) => setActiveSeason(seasonId)

  const headerItems = [<SeasonSelect onChange={onChangeActiveSeason} />]

  return (
    <Layout
      showAppHeader
      appHeaderItems={headerItems}
      headerTitle={permissions.fieldEntity ? t('headerTitleWithField') : t('headerTitle')}
      headerImage={dashboardImage}
      actionButton={
        permissions.satelliteImagery && !noFields && !noLots ? (
          <SortSelect onChange={value => setOrderDashboardLotsBy(value)} />
        ) : undefined
      }
    >
      {loading ? (
        <FullScreenLoader />
      ) : (
        <ContainerRow>
          {noFields || noLots ? (
            <EmptyResultsCard
              noFields={noFields}
              companyCreation={permissions.companyCreation}
              isOrganizationUser={permissions.isAnOrganizationUser}
              isAdminUser={permissions.isCompanyAdminUser}
              fieldEntityFlag={permissions.fieldEntity}
              isCompanyViewerUser={permissions.isCompanyViewerUser}
            />
          ) : (
            <>
              <ScrollColumn>
                {data?.season.seasonFields
                  .filter(({ field }) => field)
                  .map(seasonField => {
                    const field = seasonField.field!
                    const { dams } = field
                    const { lots } = seasonField
                    const sortedLots =
                      storedOrderDashboardLotsBy === SELECT.VALUES.CRITICALITY
                        ? SortUtils.sortLotsByCriticality(lots)
                        : lots
                    const { productionToMill, id, millDeliveryType } = data.season
                    return (
                      <React.Fragment key={field.id}>
                        <FieldsAndLotsBody
                          declaredRiceProduction={getDeclaredRiceProduction()}
                          estimatedRiceProduction={getEstimatedRiceProduction()}
                          seasonId={id}
                          productionToMill={productionToMill ?? undefined}
                          millDeliveryType={millDeliveryType ?? undefined}
                          field={field}
                          lots={sortedLots}
                          dams={dams ?? []}
                        />
                      </React.Fragment>
                    )
                  })}
              </ScrollColumn>
              <InformationCard />
            </>
          )}
        </ContainerRow>
      )}
    </Layout>
  )
}
