import { Col, Typography, Row, Space } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ArrowClockIcon } from 'assets/icons'
import { cloudImage, clearImage } from 'assets/images'
import { WeatherType } from 'types'

const { Text: AntText } = Typography

const ColContainer = styled(Col)`
  max-width: 190px;
  height: 37%;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 24px !important;
  &.ant-col:first-child {
    padding: 24px !important;
  }
`

const SubTitle = styled(AntText)`
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  line-height: 24px;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grey1};
`
const BoldSubTitle = styled(SubTitle)`
  font-weight: 600 !important;
`

const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  background-position: center;
  width: 35px;
  height: 35px;
`

interface Props {
  weatherType?: WeatherType
  lastUpdate?: string
}

export const LastUpdateCard: React.FC<Props> = ({ weatherType, lastUpdate }) => {
  const { t } = useTranslation('lot-dashboard')
  const { t: commonT } = useTranslation('common')

  const weatherMap = {
    CLEAR: {
      url: clearImage,
      title: commonT('vocabulary.weather.clear'),
      text: t('lastUpdateCard.visibleImagery'),
    },
    CIRRUS: {
      url: cloudImage,
      title: commonT('vocabulary.weather.cloudy'),
      text: t('lastUpdateCard.affectedIndices'),
    },
    PARTIALLY_CLOUDED: {
      url: cloudImage,
      title: commonT('vocabulary.weather.cloudy'),
      text: t('lastUpdateCard.unavailableZones'),
    },
    PARTIALLY_CLOUDED_WITH_CIRRUS: {
      url: cloudImage,
      title: commonT('vocabulary.weather.cloudy'),
      text: t('lastUpdateCard.unavailableZonesAndAffectedIndices'),
    },
    CLOUDY: {
      url: cloudImage,
      title: commonT('vocabulary.weather.cloudy'),
      text: t('lastUpdateCard.unavailableZonesAndAffectedIndices'),
    },
  }

  return (
    <ColContainer>
      <Space direction="vertical" size={12}>
        <SubTitle>{t('lastUpdateCard.title')}</SubTitle>
        <Row align="middle">
          <IconContainer>
            <ArrowClockIcon />
          </IconContainer>
          <BoldSubTitle>
            {lastUpdate ? t('lastUpdateCard.date', { date: new Date(lastUpdate) }) : '-'}
          </BoldSubTitle>
        </Row>
        <Row>
          {weatherType && (
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <Image src={weatherMap[weatherType].url} />
              </Col>
              <Col>
                <BoldSubTitle>{weatherMap[weatherType].title}</BoldSubTitle>
              </Col>
            </Row>
          )}
        </Row>
        {weatherType && <SubTitle>{weatherMap[weatherType].text}</SubTitle>}
      </Space>
    </ColContainer>
  )
}
