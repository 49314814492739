import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useTheme } from 'styled-components'

export const Loader: React.FC = () => {
  const { colors } = useTheme()
  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: colors.primary }} spin />} />
  )
}
