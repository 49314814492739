import { Modal as AntModal, ModalProps } from 'antd'

type ModalSize = 'small' | 'medium' | 'large'

type Props = ModalProps & { size?: ModalSize }

const MODAL_WIDTH: { [key in ModalSize]: number } = {
  small: 500,
  medium: 750,
  large: 900,
}

export const Modal: React.FC<Props> = ({ children, size, ...props }) => {
  const width = size ? MODAL_WIDTH[size] : 750
  return (
    <AntModal width={width} {...props} closable={false} maskClosable>
      {children}
    </AntModal>
  )
}
