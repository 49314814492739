/* eslint-disable i18next/no-literal-string */
import { Form, Space } from 'antd'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ConversionUtils } from 'utils'
import { useCountries, useCurrentUser, useFeatures, usePermissions } from 'hooks'
import { COUNTRIES } from 'consts'

import {
  FormContainer as FormSectionContainer,
  FormItemContainer,
  Input,
  Select,
  Image,
} from '../../..'

interface Props {
  control: Control<{
    companyName: string
    country: string
    governmentId?: string
    subregion: number
    commercialExecutiveId?: number
    region?: number
  }>
  organizationCountry: string
  selectedRegion?: number
  regionOptions?: { label: string; value: number }[]
  subregionOptions: { label: string; value: number }[]
  commercialExecutivesOptions?: { label: string; value: number }[]
  isCommercialExecutivesLoading?: boolean
  isRegionsLoading?: boolean
  isSubregionsLoading?: boolean
}

export const CreateClientForm: React.FC<Props> = ({
  control,
  subregionOptions,
  organizationCountry,
  selectedRegion,
  commercialExecutivesOptions,
  regionOptions,
  isCommercialExecutivesLoading,
  isRegionsLoading,
  isSubregionsLoading,
}) => {
  const { t } = useTranslation('company-settings')
  const { t: commonT } = useTranslation('common')
  const { countries, loading } = useCountries()
  const { user } = useCurrentUser()
  const { organizationFeatures } = useFeatures()
  const { permissions } = usePermissions()

  const getLocalizedNumber = (value?: string) => {
    if (!value) return ''
    const onlyNumbers = ConversionUtils.keepOnlyNumbers(value)
    if (!onlyNumbers) return ''
    const localizedNumber = commonT('systemsFormat.localizeNumber', {
      val: onlyNumbers,
    })
    return localizedNumber
  }

  return (
    <Space direction="vertical" size={35} style={{ width: '100%' }}>
      <FormSectionContainer>
        <FormItemContainer>
          <Controller
            name="companyName"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Input
                  placeholder={t('inputs.fullName')}
                  size="large"
                  onBlur={onBlur}
                  {...restFieldProps}
                  onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
                />
              </Form.Item>
            )}
          />
        </FormItemContainer>
        {organizationFeatures.crm?.enabled && (
          <FormItemContainer>
            <Controller
              name="governmentId"
              control={control}
              render={({
                field: { onChange, onBlur, value, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Input
                    placeholder={t('inputs.NIT')}
                    type="text"
                    size="large"
                    onBlur={onBlur}
                    value={getLocalizedNumber(value)}
                    {...restFieldProps}
                    onChange={event => onChange(getLocalizedNumber(event.target.value))}
                  />
                </Form.Item>
              )}
            />
          </FormItemContainer>
        )}
      </FormSectionContainer>
      {organizationFeatures.crm?.enabled && (
        <FormSectionContainer>
          <FormItemContainer>
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="commercialExecutiveId"
              control={control}
              defaultValue={permissions.isCommercialUser ? user?.id : undefined}
              render={({
                field: { onChange, onBlur, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Select
                    loading={isCommercialExecutivesLoading}
                    disabled={permissions.isCommercialUser}
                    placeholder={t('inputs.commercialExecutive')}
                    size="large"
                    onBlur={onBlur}
                    {...restFieldProps}
                    onChange={event => onChange(event)}
                    options={commercialExecutivesOptions}
                  />
                </Form.Item>
              )}
            />
          </FormItemContainer>
        </FormSectionContainer>
      )}

      <FormSectionContainer>
        <FormItemContainer>
          <Controller
            name="country"
            control={control}
            defaultValue={organizationCountry}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  disabled
                  placeholder={t('inputs.country')}
                  size="large"
                  loading={loading}
                  onBlur={onBlur}
                  {...restFieldProps}
                  value={organizationCountry}
                  onChange={event => onChange(event)}
                  popupClassName="custom-select-dropdown"
                >
                  {countries?.map(country => (
                    <Select.Option key={country.id} value={country.id}>
                      <Image src={COUNTRIES.flags[country.id]} />
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          />
        </FormItemContainer>
        <FormItemContainer>
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="region"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  disabled={
                    !(
                      permissions.isOrganizationAdmin &&
                      organizationFeatures.subFarmersManagement?.enabled
                    )
                  }
                  loading={isRegionsLoading}
                  placeholder={t('inputs.region')}
                  size="large"
                  onBlur={onBlur}
                  {...restFieldProps}
                  onChange={event => onChange(event)}
                  options={regionOptions}
                  defaultValue={selectedRegion}
                  value={selectedRegion}
                  popupClassName="custom-select-dropdown"
                />
              </Form.Item>
            )}
          />
        </FormItemContainer>
        <FormItemContainer>
          <Controller
            name="subregion"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  disabled={!selectedRegion}
                  loading={isSubregionsLoading}
                  placeholder={t('inputs.subregion')}
                  size="large"
                  onBlur={onBlur}
                  {...restFieldProps}
                  onChange={event => onChange(event)}
                  options={subregionOptions}
                  popupClassName="custom-select-dropdown"
                />
              </Form.Item>
            )}
          />
        </FormItemContainer>
      </FormSectionContainer>
    </Space>
  )
}
