import { Col, Row, Typography } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ProductionType } from 'types'
import { AbandonedSizeInput, ProductionTypeSelect } from 'components'
import { UpdateLotByIdVariables } from 'hooks/useUpdateLot'

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive5};
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 8px !important;
`

interface Props {
  productionType: ProductionType
  lotId?: number
  abandonedSize?: number
  onAbandonedSizeChange: ({ variables }: { variables: UpdateLotByIdVariables }) => void
}

export const ProductionTypeAndAbandonedSizeCard: React.FC<Props> = ({
  productionType,
  onAbandonedSizeChange,
  lotId,
  abandonedSize,
}) => {
  const { t } = useTranslation('lot-dashboard')
  return (
    <Row gutter={[0, 30]} justify="center" align="middle">
      <Col span={24}>
        <Title level={5}> {t('productionTypeCard.title')} </Title>
        <ProductionTypeSelect viewMode value={productionType} />
      </Col>
      <Col span={24}>
        <Title level={5}> {t('productionTypeCard.abandoned')} </Title>
        <AbandonedSizeInput
          onChange={onAbandonedSizeChange}
          abandonedSize={abandonedSize}
          lotId={lotId}
        />
      </Col>
    </Row>
  )
}
