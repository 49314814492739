import { Col, Typography } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ImageIcon } from 'assets/icons'

const { Text } = Typography

const UploadContainer = styled(Col)`
  width: 156px;
  height: 156px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  overflow: hidden;
`

const UploadPicture = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 21px 32px;
`

const Image = styled.img`
  width: 100%;
`

const PictureBoxText = styled(Text)`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey2};
`

interface Props {
  image?: string
}

export const LargeUploadBox: React.FC<Props> = ({ image }) => {
  const { t } = useTranslation('account-settings')

  return (
    <UploadContainer>
      {image ? (
        <Image src={image} alt={t('profilePicture.title')} />
      ) : (
        <UploadPicture>
          <PictureBoxText>{t('profilePicture.description')}</PictureBoxText>
          <ImageIcon />
        </UploadPicture>
      )}
    </UploadContainer>
  )
}
