import { useEffect } from 'react'
import useCustomCompareEffect from 'react-use/lib/useCustomCompareEffect'
import { isEqual } from 'lodash'

import { AmplitudeEventsName, Properties } from 'types'

import { useTrackEvent } from './useTrackEvent'

export const useTrackImpression = ({
  eventName,
  eventProperties,
  loading,
}: {
  eventName: AmplitudeEventsName
  eventProperties?: Properties
  loading?: boolean
}) => {
  const { track } = useTrackEvent(eventName)

  useEffect(() => {
    // It is tracking when user returns to the tab
    const handleFocus = () => track(eventProperties)

    window.addEventListener('focus', handleFocus)
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [eventProperties, track])

  useCustomCompareEffect(
    () => {
      if (loading) return
      track(eventProperties)
    },
    [loading, eventProperties],
    (prevDeps, nextDeps) => {
      const prevLotDeps = prevDeps?.[0]
      const nextLotDeps = nextDeps?.[0]

      const areDepsEqual = isEqual(prevLotDeps, nextLotDeps) && isEqual(prevDeps[1], nextDeps[1])

      return areDepsEqual
    },
  )
}
