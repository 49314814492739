/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { UpdateRiceLot, UpdateRiceLotVariables } from './types/UpdateRiceLot'

export const UPDATE_RICE_LOT = gql`
  mutation UpdateRiceLot($id: Int!, $updateRiceLotDTO: UpdateRiceLotInput!) {
    updateRiceLot(id: $id, updateRiceLotDTO: $updateRiceLotDTO) {
      code
      riceLot {
        id
        plantingDate
        emergenceDate
        firstIrrigationStartDate
        firstIrrigationEndDate
        floweringDate
        harvestDate
        preparationDate
        burningDate
        earAppearanceDate
        yield
        physiologicalMaturityDate
        differentiationDate
        comment
        emergenceUpdateType
        plantingUpdateType
        floweringUpdateType
        harvestUpdateType
        preparationUpdateType
        burningUpdateType
        earAppearanceUpdateType
        differentiationUpdateType
        daysSinceEmergence
        isHarvested
        historicalIrrigation {
          values {
            date
            percentage
            daysSinceEmergence
          }
        }
        indicators {
          semaphore
          sufficientWaterPercentage
          irrigation
          chlorophyll
          weatherType
          lastUpdate
          grainHumidity {
            isAvailable
            lastUpdate
            emergenceDaysActivation
            levels {
              one {
                lowEdge
                highEdge
                percentage
              }
              two {
                lowEdge
                highEdge
                percentage
              }
              three {
                lowEdge
                highEdge
                percentage
              }
              four {
                lowEdge
                highEdge
                percentage
              }
              five {
                lowEdge
                highEdge
                percentage
              }
              six {
                lowEdge
                highEdge
                percentage
              }
              seven {
                lowEdge
                highEdge
                percentage
              }
            }
          }
        }
        targetYieldCurve {
          id
          yield
          values {
            daysSinceEmergence
            chlorophyll
          }
        }
        realYieldCurve {
          values {
            daysSinceEmergence
            chlorophyll
          }
        }
        estimatedYield {
          isAvailable
          value
        }
      }
    }
  }
`
