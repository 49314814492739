import { useQuery } from '@apollo/client'

import { REGIONS, Regions } from './api'

export const useOrganizationRegions = () => {
  const { data, loading } = useQuery<Regions>(REGIONS)

  const regionsOptions = data?.regions.results.map(region => ({
    value: region.id,
    label: region.name,
  }))

  return {
    loading,
    regionsOptions,
    regions: data?.regions.results,
  }
}
