import { Col, Row, Input as AntInput, Typography } from 'antd'
import styled from 'styled-components'

export { DeliverySelect } from './DeliverySelect'

export const ContainerCard = styled(Row)`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 300px;
  width: 288px;
  padding: 24px;
`
export const HeaderCard = styled(Col)`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledRow = styled(Row)`
  align-items: center;
  gap: 5px;
`

interface BallPops {
  paint?: boolean
}
export const Ball = styled.div<BallPops>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme, paint }) =>
    paint ? theme.colors.new.secondary[500] : theme.colors.new.baseColor[300]};
  margin: 2px;
`

const { Title: AntTitle, Text: AntText } = Typography

export const Title = styled(AntTitle)`
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.grey1} !important;
`

export const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white} !important;
  font-size: 14px !important;
  font-weight: 600;
`
export const SubText = styled(Text)`
  color: ${({ theme }) => theme.colors.new.baseColor[300]} !important;
  font-weight: 400;
`
export const Input = styled(AntInput)`
  width: 75px;
  height: 24px;
  padding: 0px 6px;
`
