import { Col } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MapIndex, Lot } from 'types'

import {
  Card,
  BarIndicator,
  BoxContainer,
  FlexCol,
  FlexRow,
  BoxSquareContainer,
  Square,
} from './components'
import { useDescriptionCard } from './hooks'

interface Props {
  selectedMapIndex: MapIndex
  lots: Lot[]
}

export const DescriptionCard: React.FC<Props> = ({ selectedMapIndex, lots }) => {
  const { t } = useTranslation('common')

  const { isHistogramNotAvailable, getSelectedIndexColors, cardContent } = useDescriptionCard({
    lots,
    selectedMapIndex,
  })

  return (
    <>
      {selectedMapIndex !== 'realImage' && (
        <Card>
          {selectedMapIndex === 'weeds' ? (
            <BoxSquareContainer>
              {cardContent[selectedMapIndex].map(cardData => (
                <Square color={cardData.boxBackground} />
              ))}
            </BoxSquareContainer>
          ) : (
            <Col>
              <BarIndicator colorsGradient={getSelectedIndexColors()} />
            </Col>
          )}
          <Col span={23}>
            {cardContent[selectedMapIndex].map(cardData => {
              return (
                <React.Fragment key={cardData.boxBackground}>
                  <FlexRow>
                    <BoxContainer span={isHistogramNotAvailable ? 6 : 9}>
                      {!isHistogramNotAvailable && (
                        <FlexCol>
                          {t('systemsFormat.area', { val: { area: cardData.area } })}
                        </FlexCol>
                      )}
                    </BoxContainer>
                    <FlexCol span={15}> {cardData.item} </FlexCol>
                  </FlexRow>
                </React.Fragment>
              )
            })}
          </Col>
        </Card>
      )}
    </>
  )
}
