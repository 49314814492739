import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { FormInput } from 'components'
import { useQueryParams } from 'hooks'

import { LoginTextCta, Content, FormEmailInput, FormPasswordInput } from '../../components'
import { SignupButton, Space } from './components'
import { useSignup, useSignupForm } from './hooks'
import { useFormIconsStyle } from '../../hooks'

export const Signup: React.FC = () => {
  const iconStyle = useFormIconsStyle()
  const { t: commonT } = useTranslation('common')
  const { t, i18n } = useTranslation('login')

  const query = useQueryParams()
  const token = query.get('token')
  const email = query.get('email')
  const { control, handleSubmit } = useSignupForm({ email: email ?? '' })
  const [signup, { loading }] = useSignup()
  return (
    <Content>
      <Space direction="vertical" size={13}>
        <FormInput
          // eslint-disable-next-line i18next/no-literal-string
          name="name"
          placeholder={commonT('inputs.firstName')}
          control={control}
          prefixIcon={<UserOutlined style={iconStyle} />}
          size="small"
          capitalize
        />
        <FormInput
          // eslint-disable-next-line i18next/no-literal-string
          name="lastName"
          placeholder={commonT('inputs.lastName')}
          control={control}
          size="small"
          capitalize
          prefixIcon={<UserOutlined style={iconStyle} />}
        />
        <FormEmailInput control={control} iconStyle={iconStyle} size="small" isReadOnly={!!email} />
        <FormPasswordInput control={control} iconStyle={iconStyle} size="small" />
        <SignupButton
          loading={loading}
          type="primary"
          onClick={handleSubmit(createUserDTO =>
            signup({ variables: { createUserDTO, token, locale: i18n.language } }),
          )}
        >
          {t('signupPage.buttonText')}
        </SignupButton>
      </Space>
      <LoginTextCta />
    </Content>
  )
}
