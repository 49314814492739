import React from 'react'

import { IndicatorStatus } from 'types'

import { TrafficLightBall } from '../TrafficLightBall'
import { BallColorsTypes } from '../../types'

const NeutralTrafficLight = ({ size }: { size?: 'small' | 'large' }) => {
  return (
    <>
      <TrafficLightBall size={size} />
      <TrafficLightBall size={size} />
      <TrafficLightBall size={size} />
    </>
  )
}

interface Props {
  hasEmergenceDate: boolean
  semaphoreIndicator?: IndicatorStatus
  size?: 'small' | 'large'
}

export const TrafficLights: React.FC<Props> = ({ hasEmergenceDate, semaphoreIndicator, size }) => {
  if (!hasEmergenceDate) return <NeutralTrafficLight size={size} />

  return (
    <>
      <TrafficLightBall
        size={size}
        type={
          semaphoreIndicator === IndicatorStatus.GOOD
            ? BallColorsTypes.good
            : BallColorsTypes.neutral
        }
      />
      <TrafficLightBall
        size={size}
        type={
          semaphoreIndicator === IndicatorStatus.MEDIUM
            ? BallColorsTypes.alert
            : BallColorsTypes.neutral
        }
      />
      <TrafficLightBall
        size={size}
        type={
          semaphoreIndicator === IndicatorStatus.BAD
            ? BallColorsTypes.danger
            : BallColorsTypes.neutral
        }
      />
    </>
  )
}
