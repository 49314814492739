import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  ComponentType,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeList as _FixedSizeList, FixedSizeListProps } from 'react-window'
import dayjs from 'dayjs'

import { usePermissions, useUser } from 'hooks'
import { UserUserCompanyRole as UserCompanyRoleType } from 'api/user'
import { MiscUtils } from 'utils'
import { HistoricalSeasonSelect } from 'components'
import { HistoricalSeason } from 'types'
import { ActiveCompanyContext } from 'contexts'

import {
  Divider,
  Container,
  UserSettings,
  PopoverSearchInput,
  OrganizationItem,
  ActionButton,
  StyledPlusCircleOutlined,
  StyledSettingOutlined,
  VirtualizedListItem,
} from './components'
import { useListHeight } from './hooks'

const MAX_COMPANIES_ROLES = 3

const FixedSizeList = _FixedSizeList as ComponentType<FixedSizeListProps>
interface Props {
  onItemActionClick: (company: { id: number; isAdmin: boolean }) => void
  onCreateCompany: () => void
  setIsFirstCompany: Dispatch<SetStateAction<boolean>>
  onConfigureAccount: () => void
  onManageLotsButtonPress: () => void
}

export const CompaniesPopoverContent: React.FC<Props> = ({
  onItemActionClick,
  onCreateCompany,
  setIsFirstCompany,
  onConfigureAccount,
  onManageLotsButtonPress,
}) => {
  const user = useUser()
  const { permissions } = usePermissions()
  const { t } = useTranslation('navbar')
  const height = useListHeight()

  const [companiesRoles, setCompaniesRoles] = useState<UserCompanyRoleType[]>()
  const {
    setSelectedSeasonRangeToFilterOrganizationCompanies,
    selectedSeasonRangeToFilterOrganizationCompanies,
  } = ActiveCompanyContext.useActiveCompanyContext()

  const createCompanyTexts = {
    primaryText: permissions.isAnOrganizationUser
      ? t('user.menu.companies.newClient.part1')
      : t('user.menu.companies.newCompany.part1'),
    secondaryTexts: permissions.isAnOrganizationUser
      ? [t('user.menu.companies.newClient.part2'), t('user.menu.companies.newClient.part3')]
      : [t('user.menu.companies.newCompany.part2'), t('user.menu.companies.newCompany.part3')],
  }

  const actionButtons = [
    {
      name: 'createCompany',
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setIsFirstCompany(false)
        e.stopPropagation()
        onCreateCompany()
      },
      Icon: StyledPlusCircleOutlined,
      primaryTextButton: createCompanyTexts.primaryText,
      secondaryTextButtonParts: createCompanyTexts.secondaryTexts,
      disabled: !permissions.companyCreation,
    },
    {
      name: 'manageLots',
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        onManageLotsButtonPress()
      },
      Icon: StyledSettingOutlined,
      primaryTextButton: t('user.menu.companies.manageLots.part1'),
      secondaryTextButtonParts: [
        t('user.menu.companies.manageLots.part2'),
        t('user.menu.companies.manageLots.part3'),
      ],
    },
  ]

  const filterCompanySeasonsByHistoricalSeasonRange = useCallback(
    (historicalSeason: HistoricalSeason) => {
      return user.companiesRoles?.filter(companyRole => {
        return companyRole.company.seasons?.some(season => {
          const seasonStartDate = dayjs.utc(season.startDate)
          const selectedHistoricalSeasonStartRangeDate = dayjs.utc(historicalSeason.startRange)
          const selectedHistoricalSeasonEndRangeDate = dayjs.utc(historicalSeason.endRange)

          const isSeasonStartDateSameOrAfter =
            seasonStartDate.isSame(selectedHistoricalSeasonStartRangeDate) ||
            seasonStartDate.isAfter(selectedHistoricalSeasonStartRangeDate)

          const isSeasonStartDateSameOrBefore =
            seasonStartDate.isSame(selectedHistoricalSeasonEndRangeDate) ||
            seasonStartDate.isBefore(selectedHistoricalSeasonEndRangeDate)

          return isSeasonStartDateSameOrAfter && isSeasonStartDateSameOrBefore
        })
      })
    },
    [user.companiesRoles],
  )

  const handleSelectedHistoricalSeason = useCallback(
    (historicalSeason: HistoricalSeason) => {
      const filteredCompaniesRoles = filterCompanySeasonsByHistoricalSeasonRange(historicalSeason)
      setCompaniesRoles(filteredCompaniesRoles)
      setSelectedSeasonRangeToFilterOrganizationCompanies(historicalSeason)
    },
    [
      filterCompanySeasonsByHistoricalSeasonRange,
      setSelectedSeasonRangeToFilterOrganizationCompanies,
    ],
  )

  const filteredCompaniesRolesBySelectedSeasonRange = useMemo(() => {
    if (!selectedSeasonRangeToFilterOrganizationCompanies) return []

    return filterCompanySeasonsByHistoricalSeasonRange(
      selectedSeasonRangeToFilterOrganizationCompanies,
    )
  }, [
    filterCompanySeasonsByHistoricalSeasonRange,
    selectedSeasonRangeToFilterOrganizationCompanies,
  ])

  const showPopoverSearchInput = useMemo(() => {
    if (!permissions.isAnOrganizationUser) {
      return (user?.companiesRoles?.length ?? 0) > MAX_COMPANIES_ROLES
    }
    if (
      !selectedSeasonRangeToFilterOrganizationCompanies?.startRange ||
      !selectedSeasonRangeToFilterOrganizationCompanies.endRange
    )
      return false
    return (filteredCompaniesRolesBySelectedSeasonRange?.length ?? 0) > MAX_COMPANIES_ROLES
  }, [
    filteredCompaniesRolesBySelectedSeasonRange,
    permissions,
    selectedSeasonRangeToFilterOrganizationCompanies,
    user,
  ])

  useEffect(() => {
    if (user.companiesRoles?.length && !permissions.isAnOrganizationUser) {
      setCompaniesRoles(user.companiesRoles)
    } else {
      if (!selectedSeasonRangeToFilterOrganizationCompanies) return
      handleSelectedHistoricalSeason(selectedSeasonRangeToFilterOrganizationCompanies)
    }
  }, [
    user.companiesRoles,
    permissions.isAnOrganizationUser,
    handleSelectedHistoricalSeason,
    selectedSeasonRangeToFilterOrganizationCompanies,
  ])

  const onSearchChange = useCallback(
    (value: string) => {
      const inputValue = MiscUtils.removeAccents(value).toLowerCase()
      const companies = !permissions.isAnOrganizationUser
        ? user.companiesRoles
        : filteredCompaniesRolesBySelectedSeasonRange
      const filterCompanies = companies?.filter(({ company }) => {
        const companyName = MiscUtils.removeAccents(company.name).toLowerCase()
        return companyName.includes(inputValue)
      })
      setCompaniesRoles(filterCompanies)
    },
    [
      filteredCompaniesRolesBySelectedSeasonRange,
      permissions.isAnOrganizationUser,
      user.companiesRoles,
    ],
  )

  return (
    <>
      {permissions.isAnOrganizationUser && (
        <Container style={{ paddingBottom: '10px' }}>
          <HistoricalSeasonSelect
            onChange={handleSelectedHistoricalSeason}
            selectedHistoricalSeason={selectedSeasonRangeToFilterOrganizationCompanies}
          />
        </Container>
      )}
      {showPopoverSearchInput && (
        <Container style={{ paddingBottom: '20px' }}>
          <PopoverSearchInput onChange={onSearchChange} />
        </Container>
      )}
      <FixedSizeList
        height={height}
        itemCount={companiesRoles?.length ?? 0}
        itemSize={70}
        width={400}
      >
        {props => (
          <VirtualizedListItem
            {...props}
            data={{ companyRole: companiesRoles?.[props.index], onItemActionClick }}
          />
        )}
      </FixedSizeList>

      <Divider />

      <Container>
        {actionButtons.map(actionButtonData => {
          return (
            <React.Fragment key={actionButtonData.name}>
              {!actionButtonData.disabled && (
                <ActionButton
                  onClick={actionButtonData.onClick}
                  Icon={actionButtonData.Icon}
                  primaryTextButton={actionButtonData.primaryTextButton}
                  secondaryTextButtonParts={actionButtonData.secondaryTextButtonParts}
                />
              )}
            </React.Fragment>
          )
        })}
      </Container>

      {permissions.isAnOrganizationUser && (
        <>
          <Divider />
          <Container>
            <OrganizationItem
              name={user.organizationRole?.organization.name ?? ''}
              logo={user.organizationRole?.organization.logo}
            />
          </Container>
        </>
      )}
      <Divider />
      <Container>
        <UserSettings
          name={user.name}
          lastName={user.lastName}
          onConfigureAccount={onConfigureAccount}
        />
      </Container>
    </>
  )
}
