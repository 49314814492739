import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthContext } from 'contexts'
import { STORAGE_KEYS } from 'consts'
import { useLocalStorage } from 'hooks'
import { GraphQLUtils } from 'utils'

import { SIGNUP, SignUp, SignUpVariables } from '../api'

const DUPLICATED_EMAIL_ERROR = 'users_email_unique'

export const useSignup = () => {
  const [, setUserToken] = useLocalStorage(STORAGE_KEYS.USER_TOKEN)
  const navigate = useNavigate()
  const { t } = useTranslation('login')

  const { login } = AuthContext.useAuthContext()
  return useMutation<SignUp, SignUpVariables>(SIGNUP, {
    onCompleted: ({ signup: signupResponse }) => {
      if (signupResponse.user?.isVerified) {
        login({
          id: signupResponse.user.id,
          email: signupResponse.user.email,
          name: signupResponse.user.name,
          lastName: signupResponse.user.lastName,
          organizationId: signupResponse.user.organizationRole?.organization.id,
          organizationName: signupResponse.user.organizationRole?.organization.name,
        })
        return setUserToken(signupResponse.token)
      }

      return navigate('/login/verify-email')
    },
    onError: (error, clientOptions) => {
      const isDuplicatedEmail =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.graphQLErrors?.[0]?.extensions.exception?.constraint === DUPLICATED_EMAIL_ERROR

      GraphQLUtils.errorHandler(
        error,
        clientOptions,
        isDuplicatedEmail ? t('signupPage.duplicatedEmail') : undefined,
      )
    },
  })
}
