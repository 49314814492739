import { MutationFunctionOptions, useQuery } from '@apollo/client'
import { Col, Row, Space } from 'antd'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import { usePermissions, useUpdateLot, useUpdateRiceLot } from 'hooks'
import { CountryId, LocaleLanguage, MilestoneUpdateType, ProductionType } from 'types'
import { GraphQLUtils } from 'utils'
import { GreyCard, GreyCardWithTitle } from 'components'
import { LANGUAGE } from 'consts'

import {
  Comment,
  ContainerCol,
  EmergenceDateCard,
  FertilizerTable,
  GrainHumidity,
  IrrigationMonitorCard,
  LastUpdateCard,
  PhytosanitaryTable,
  ProductionTypeAndAbandonedSizeCard,
  RowContainer,
  StyledCol,
  TablesSpace,
  Timeline,
  TrafficLight,
  Yield,
  YieldCardContainer,
} from './components'
import { MapImage } from '../../../components'
import { InformationCard } from '../../../Dashboard/components'
import {
  LotDashboard,
  RiceUnitsNutrientsAndIngredients,
  RiceUnitsNutrientsAndIngredientsVariables,
  RICE_UNITS_NUTRIENTS_AND_INGREDIENTS,
  CreateRiceFertilizerVariables,
  CreateRiceFertilizer,
  AppliedFertilizer,
  CreateRicePhytosanitary,
  CreateRicePhytosanitaryVariables,
  AppliedPhytosanitaries,
} from '../../api'
import { DeleteAplicationData } from '../types'
import { TargetYield } from '../TargetYield'

interface Props {
  lotDashboardData?: LotDashboard
  fieldId?: number
  lotId: number
  onCreateRiceFertilizer: (
    options?: MutationFunctionOptions<CreateRiceFertilizer, CreateRiceFertilizerVariables>,
  ) => void
  appliedFertilizers: AppliedFertilizer[]
  onHandleDataToDelete: (data: DeleteAplicationData) => void
  onCreateRicePhytosanitary: (
    options?: MutationFunctionOptions<CreateRicePhytosanitary, CreateRicePhytosanitaryVariables>,
  ) => void
  appliedPhytosanitaries: AppliedPhytosanitaries[]
  activeCountryId?: CountryId
}

export const SummaryTab: React.FC<Props> = ({
  lotDashboardData,
  fieldId,
  lotId,
  onCreateRiceFertilizer,
  appliedFertilizers,
  onHandleDataToDelete,
  onCreateRicePhytosanitary,
  appliedPhytosanitaries,
  activeCountryId,
}) => {
  const { t } = useTranslation('lot-dashboard')

  const { permissions } = usePermissions()
  const { updateRiceLot } = useUpdateRiceLot()
  const { updateLot } = useUpdateLot()

  const { data: riceUnitsNutrientsAndIngredients } = useQuery<
    RiceUnitsNutrientsAndIngredients,
    RiceUnitsNutrientsAndIngredientsVariables
  >(RICE_UNITS_NUTRIENTS_AND_INGREDIENTS, {
    variables: { countryId: activeCountryId! },
    skip: !activeCountryId,
  })

  const emergenceDate =
    lotDashboardData?.lot.riceLot.emergenceUpdateType !== MilestoneUpdateType.AUTOMATIC_CONVERSION
      ? lotDashboardData?.lot.riceLot.emergenceDate
      : undefined

  const riceUnits = riceUnitsNutrientsAndIngredients?.riceUnits.results
  const riceNutrients = riceUnitsNutrientsAndIngredients?.riceNutrients.results
  const riceActiveIngredients = riceUnitsNutrientsAndIngredients?.riceActiveIngredients.results
  const grainHumidityLastUpdate =
    lotDashboardData?.lot.riceLot.indicators?.grainHumidity?.lastUpdate

  const isHarvested = !!lotDashboardData?.lot.riceLot.isHarvested

  const daysSinceEmergence = lotDashboardData?.lot.riceLot.daysSinceEmergence?.toString() ?? '-'

  // TODO: Split this component into smaller components
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Row gutter={24} wrap={false}>
          <ContainerCol>
            <Space direction="vertical" size={24}>
              <Row gutter={[24, 24]} wrap={false}>
                {permissions.satelliteImagery && (
                  <Col>
                    <TrafficLight
                      hasEmergenceDate={!!emergenceDate}
                      semaphoreIndicator={
                        lotDashboardData?.lot.riceLot.indicators?.semaphore ?? undefined
                      }
                    />
                  </Col>
                )}
                <Col>
                  <EmergenceDateCard
                    varietyId={lotDashboardData?.lot.variety?.id}
                    crop={
                      lotDashboardData?.lot.crop.locales[
                        LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]
                      ] ?? '-'
                    }
                    cropId={lotDashboardData?.lot.crop.id}
                    daysSinceEmergenceDate={daysSinceEmergence}
                    lotId={lotDashboardData?.lot.id}
                    onVarietyChange={updateLot}
                  />
                </Col>
                {permissions.satelliteImagery && (
                  <Col>
                    <IrrigationMonitorCard
                      sufficientWaterPercentage={
                        Number(
                          lotDashboardData?.lot.riceLot.indicators?.sufficientWaterPercentage?.toFixed(),
                        ) ?? 0
                      }
                      irrigationIndicator={
                        lotDashboardData?.lot.riceLot.indicators?.irrigation ?? undefined
                      }
                      hasEmergenceDate={!!emergenceDate}
                      isHarvested={isHarvested}
                    />
                  </Col>
                )}
                <Col>
                  <MapImage
                    fieldId={fieldId}
                    lotId={lotDashboardData?.lot.id}
                    lotCoordinates={lotDashboardData?.lot.area?.coordinates[0]}
                    width="288px"
                    height="157px"
                  />
                </Col>
              </Row>
              {permissions.satelliteImagery && (
                <Row>
                  <TargetYield
                    emergenceDate={emergenceDate ?? undefined}
                    yieldName={lotDashboardData?.lot.riceLot.targetYieldCurve?.name}
                    targetYieldCurveValues={lotDashboardData?.lot.riceLot.targetYieldCurve?.values}
                    realYieldCurveValues={lotDashboardData?.lot.riceLot.realYieldCurve?.values}
                    chlorophyllIndicator={
                      lotDashboardData?.lot.riceLot.indicators?.chlorophyll ?? undefined
                    }
                    showIndicator
                  />
                </Row>
              )}
            </Space>
          </ContainerCol>
          {permissions.satelliteImagery && (
            <Col hidden={!permissions.satelliteImagery}>
              <Space direction="vertical" size={24}>
                <LastUpdateCard
                  weatherType={lotDashboardData?.lot.riceLot.indicators?.weatherType}
                  lastUpdate={lotDashboardData?.lot.riceLot.indicators?.lastUpdate}
                />
                <InformationCard isLotDashboard />
              </Space>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={24}>
          <Row gutter={24} wrap={false}>
            <Col>
              {lotDashboardData?.lot.riceLot.id && (
                <Timeline
                  lotId={lotId}
                  previousCropId={lotDashboardData?.lot.previousCrop?.id}
                  riceLotId={lotDashboardData?.lot.riceLot.id}
                  emergenceDate={lotDashboardData?.lot.riceLot.emergenceDate ?? undefined}
                  plantingDate={lotDashboardData?.lot.riceLot.plantingDate ?? undefined}
                  firstIrrigationStartDate={
                    lotDashboardData?.lot.riceLot.firstIrrigationStartDate ?? undefined
                  }
                  firstIrrigationEndDate={
                    lotDashboardData?.lot.riceLot.firstIrrigationEndDate ?? undefined
                  }
                  floweringDate={lotDashboardData?.lot.riceLot.floweringDate ?? undefined}
                  harvestDate={lotDashboardData?.lot.riceLot.harvestDate ?? undefined}
                  differentiationDate={
                    lotDashboardData?.lot.riceLot.differentiationDate ?? undefined
                  }
                  physiologicalMaturityDate={
                    lotDashboardData?.lot.riceLot.physiologicalMaturityDate ?? undefined
                  }
                  preparationDate={lotDashboardData?.lot.riceLot.preparationDate ?? undefined}
                  burningDate={lotDashboardData?.lot.riceLot.burningDate ?? undefined}
                  earAppearanceDate={lotDashboardData?.lot.riceLot.earAppearanceDate ?? undefined}
                  updateRiceLotMutation={updateRiceLot}
                  emergenceUpdateType={
                    lotDashboardData.lot.riceLot.emergenceUpdateType ?? undefined
                  }
                  plantingUpdateType={lotDashboardData.lot.riceLot.plantingUpdateType ?? undefined}
                  floweringUpdateType={
                    lotDashboardData.lot.riceLot.floweringUpdateType ?? undefined
                  }
                  harvestUpdateType={lotDashboardData.lot.riceLot.harvestUpdateType ?? undefined}
                  preparationUpdateType={
                    lotDashboardData.lot.riceLot.preparationUpdateType ?? undefined
                  }
                  burningUpdateType={lotDashboardData.lot.riceLot.burningUpdateType ?? undefined}
                  earAppearanceUpdateType={
                    lotDashboardData.lot.riceLot.earAppearanceUpdateType ?? undefined
                  }
                  differentiationUpdateType={
                    lotDashboardData.lot.riceLot.differentiationUpdateType ?? undefined
                  }
                />
              )}
            </Col>

            {permissions.satelliteImagery ? (
              <StyledCol flex={1}>
                <TablesSpace>
                  <GreyCardWithTitle
                    title={t('fertilizersTable.title')}
                    onCreate={() => {
                      if (!lotDashboardData?.lot.riceLot.id) return GraphQLUtils.errorHandler
                      return onCreateRiceFertilizer({
                        variables: {
                          createFertilizerDTO: { riceLotId: lotDashboardData?.lot.riceLot.id },
                        },
                      })
                    }}
                  >
                    <FertilizerTable
                      appliedFertilizers={appliedFertilizers}
                      onDelete={onHandleDataToDelete}
                      riceNutrients={riceNutrients ?? []}
                      riceUnits={riceUnits ?? []}
                    />
                  </GreyCardWithTitle>
                  <GreyCardWithTitle
                    onCreate={() => {
                      if (!lotDashboardData?.lot.riceLot.id) return GraphQLUtils.errorHandler
                      return onCreateRicePhytosanitary({
                        variables: {
                          createPhytosanitaryDTO: { riceLotId: lotDashboardData?.lot.riceLot.id },
                        },
                      })
                    }}
                    title={t('phytosanitariesTable.title')}
                  >
                    <PhytosanitaryTable
                      appliedPhytosanitaries={appliedPhytosanitaries}
                      riceIngredients={riceActiveIngredients ?? []}
                      riceUnits={riceUnits ?? []}
                      onDelete={onHandleDataToDelete}
                    />
                  </GreyCardWithTitle>
                </TablesSpace>
              </StyledCol>
            ) : (
              <ContainerCol>
                <RowContainer gutter={[24, 24]}>
                  <Col span={6}>
                    <GreyCard>
                      <ProductionTypeAndAbandonedSizeCard
                        onAbandonedSizeChange={updateLot}
                        lotId={lotDashboardData?.lot.id}
                        abandonedSize={lotDashboardData?.lot.abandonedSize ?? undefined}
                        productionType={
                          lotDashboardData?.lot.productionType ?? ProductionType.CONVENTIONAL
                        }
                      />
                    </GreyCard>
                  </Col>
                  <Col span={18}>
                    <GreyCardWithTitle title={t('commentsCard.title')}>
                      <Comment
                        updateRiceLotMutation={updateRiceLot}
                        comment={lotDashboardData?.lot.riceLot.comment ?? undefined}
                        riceLotId={lotDashboardData?.lot.riceLot.id}
                      />
                    </GreyCardWithTitle>
                  </Col>
                </RowContainer>

                <RowContainer>
                  <YieldCardContainer>
                    <GreyCardWithTitle
                      title={t('yieldCard.title')}
                      subTitle={t('yieldCard.subtitle')}
                    >
                      <Yield
                        estimatedYield={lotDashboardData?.lot.riceLot.estimatedYield}
                        size={lotDashboardData?.lot.size ?? 0}
                        yieldValue={lotDashboardData?.lot.riceLot.yield ?? undefined}
                        varietyName={lotDashboardData?.lot.variety?.name}
                        emergenceDate={emergenceDate ?? undefined}
                        updateRiceLotMutation={updateRiceLot}
                        riceLotId={lotDashboardData?.lot.riceLot.id}
                      />
                    </GreyCardWithTitle>
                  </YieldCardContainer>
                </RowContainer>
              </ContainerCol>
            )}
          </Row>
          {permissions.satelliteImagery && (
            <>
              <GreyCardWithTitle title={t('yieldCard.title')} subTitle={t('yieldCard.subtitle')}>
                <Yield
                  estimatedYield={lotDashboardData?.lot.riceLot.estimatedYield}
                  size={lotDashboardData?.lot.size ?? 0}
                  yieldValue={lotDashboardData?.lot.riceLot.yield ?? undefined}
                  varietyName={lotDashboardData?.lot.variety?.name}
                  emergenceDate={emergenceDate ?? undefined}
                  updateRiceLotMutation={updateRiceLot}
                  riceLotId={lotDashboardData?.lot.riceLot.id}
                />
              </GreyCardWithTitle>
              <Row gutter={24}>
                <Col flex="0 0 290px">
                  <GreyCardWithTitle
                    title={t('grainHumidityCard.title')}
                    subTitle={
                      grainHumidityLastUpdate
                        ? t('lastUpdateCard.date', { date: new Date(grainHumidityLastUpdate) })
                        : '-'
                    }
                  >
                    <GrainHumidity
                      data={lotDashboardData?.lot.riceLot.indicators?.grainHumidity}
                      hasAbsoluteHumidity={
                        !!lotDashboardData?.lot.variety?.name &&
                        lotDashboardData?.lot.variety.isGrainHumidityAbsolute
                      }
                      isHarvested={isHarvested}
                    />
                  </GreyCardWithTitle>
                </Col>
                <Col flex="0 0 250px">
                  <GreyCard>
                    <ProductionTypeAndAbandonedSizeCard
                      onAbandonedSizeChange={updateLot}
                      lotId={lotDashboardData?.lot.id}
                      abandonedSize={lotDashboardData?.lot.abandonedSize ?? undefined}
                      productionType={
                        lotDashboardData?.lot.productionType ?? ProductionType.CONVENTIONAL
                      }
                    />
                  </GreyCard>
                </Col>
                <Col flex="1">
                  <GreyCardWithTitle title={t('commentsCard.title')}>
                    <Comment
                      updateRiceLotMutation={updateRiceLot}
                      comment={lotDashboardData?.lot.riceLot.comment ?? undefined}
                      riceLotId={lotDashboardData?.lot.riceLot.id}
                    />
                  </GreyCardWithTitle>
                </Col>
              </Row>
            </>
          )}
        </Space>
      </Col>
    </Row>
  )
}
