import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

import { useProductionType } from 'hooks'
import { ProductionType } from 'types'

interface Props {
  disabled?: boolean
  onChange?: (productionType: ProductionType) => void
  value: ProductionType
  viewMode?: boolean
}

export const ProductionTypeSelect: React.FC<Props> = ({ disabled, onChange, value, viewMode }) => {
  const { t } = useTranslation('common')
  const { productionTypeMap } = useProductionType()

  const options = Object.values(productionTypeMap)

  return (
    <Select
      disabled={viewMode || disabled}
      onChange={onChange}
      value={value}
      placeholder={t('inputs.productionType')}
      style={{ width: '100%' }}
      className={viewMode ? 'custom-disabled-select' : undefined}
    >
      {options.map(option => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  )
}
