import { Row, Tooltip } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { UserAvatar } from 'components'
import { theme } from 'styles'
import { useHasCompanyOrFieldsOrLots, usePermissions, useIsProdEnv } from 'hooks'
import { ActiveCompanyContext } from 'contexts'
import { LogoKunaIcon } from 'assets/icons'

import {
  CompaniesPopoverContent,
  Popover,
  FlexCol,
  FlexStartCol,
  FlexEndCol,
  CompanySettingsModal,
  NavLink,
  NavLinkRow,
  NavRow,
  StyledMapIcon,
  StyledSettingsIcon,
  AccountSettingsModal,
  RowIconContainer,
  StyledDashboardIcon,
} from './components'
import {
  CreateCompanyModal,
  CompanyCreationFeedbackModal,
  CreateClientModal,
} from '../../components'

export const SideNavbar: React.FC = () => {
  const navigate = useNavigate()
  const isProdEnv = useIsProdEnv()
  const { permissions } = usePermissions()

  const [showAccountSettingsModal, setShowAccountSettingsModal] = useState(false)
  const [showCompanySettingsModal, setShowCompanySettingsModal] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [showUserTooltip, setShowUserTooltip] = useState(false)
  const [editingCompany, setEditingCompany] = useState<{ id: number; isAdmin: boolean }>()
  const [showCompanyModal, setShowCompanyModal] = useState(false)
  const [isFirstCompany, setIsFirstCompany] = useState<boolean>(false)
  const { isNewCompany, setIsNewCompany, activeCompanyId } =
    ActiveCompanyContext.useActiveCompanyContext()

  const { noCompanyOrFieldsOrLots } = useHasCompanyOrFieldsOrLots()
  const { t } = useTranslation('navbar')

  return (
    <FlexCol>
      <FlexStartCol>
        <NavRow justify="center">
          <LogoKunaIcon />
        </NavRow>
        <Tooltip placement="right" title={t('dashboard.title')}>
          <NavLinkRow>
            <NavLink to="/dashboard">
              <StyledDashboardIcon />
            </NavLink>
          </NavLinkRow>
        </Tooltip>
        {noCompanyOrFieldsOrLots ? (
          <>
            {permissions.satelliteImagery && (
              <Tooltip placement="right" title={t('map.noLots')}>
                <NavRow justify="center">
                  <RowIconContainer>
                    <StyledMapIcon color="white" />
                  </RowIconContainer>
                </NavRow>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {permissions.satelliteImagery && (
              <Tooltip placement="right" title={t('map.title')}>
                <NavLinkRow>
                  <NavLink to="/map">
                    <StyledMapIcon />
                  </NavLink>
                </NavLinkRow>
              </Tooltip>
            )}
          </>
        )}
      </FlexStartCol>
      <FlexEndCol>
        {/* TODO: turn this back on after Diana's demo */}
        {false && !isProdEnv && (
          <Tooltip placement="right" title={t('internalMetrics.title')}>
            <NavLinkRow>
              <NavLink to="/internal/metrics">
                <StyledDashboardIcon />
              </NavLink>
            </NavLinkRow>
          </Tooltip>
        )}
        {permissions.isCompanyAdminUser && (
          <Tooltip placement="right" title={t('settings.title')}>
            <NavLinkRow>
              <NavLink to="/settings">
                <StyledSettingsIcon />
              </NavLink>
            </NavLinkRow>
          </Tooltip>
        )}

        <Tooltip placement="right" title={t('user.title')} open={showUserTooltip}>
          <Row
            justify="center"
            onMouseEnter={() => setShowUserTooltip(true)}
            onMouseLeave={() => setShowUserTooltip(false)}
          >
            <Popover
              trigger="click"
              open={showPopover}
              onOpenChange={visible => {
                if (visible) {
                  setShowUserTooltip(false)
                }
                setShowPopover(visible)
              }}
              placement="right"
              content={
                <CompaniesPopoverContent
                  onCreateCompany={() => {
                    setShowCompanyModal(true)
                    setShowPopover(false)
                  }}
                  setIsFirstCompany={setIsFirstCompany}
                  onItemActionClick={company => {
                    setEditingCompany(company)
                    setShowPopover(false)
                    setShowCompanySettingsModal(true)
                  }}
                  onConfigureAccount={() => {
                    setShowAccountSettingsModal(true)
                    setShowPopover(false)
                  }}
                  onManageLotsButtonPress={() => {
                    setShowPopover(false)
                    navigate('/lots-management')
                  }}
                />
              }
              overlayInnerStyle={{
                overflow: 'hidden',
                marginBottom: '20px',
                background: theme.colors.new.baseColor[850],
              }}
            >
              <UserAvatar />
            </Popover>
          </Row>
        </Tooltip>
        {showCompanySettingsModal && editingCompany && (
          <CompanySettingsModal
            id={editingCompany.id}
            onCancel={() => setShowCompanySettingsModal(false)}
          />
        )}
        {showCompanyModal &&
          permissions.companyCreation &&
          (!permissions.isAnOrganizationUser ? (
            <CreateCompanyModal
              isFirstCompany={isFirstCompany}
              onSuccess={() => {
                setShowCompanyModal(false)
                setIsNewCompany(true)
              }}
              onCancel={() => setShowCompanyModal(false)}
            />
          ) : (
            <CreateClientModal
              onSuccess={() => {
                setShowCompanyModal(false)
                if (!permissions.fieldEntity)
                  return navigate('/settings/lots', { state: { goTo: '/map' } })

                return navigate('/settings/fields')
              }}
              onCancel={() => setShowCompanyModal(false)}
            />
          ))}
        {isNewCompany && activeCompanyId && !permissions.isAnOrganizationUser && (
          <CompanyCreationFeedbackModal
            companyId={activeCompanyId}
            onSuccess={() => {
              setIsNewCompany(false)
              navigate('/dashboard', { replace: true })
            }}
            onCancel={() => setIsNewCompany(false)}
          />
        )}
        {showAccountSettingsModal && (
          <AccountSettingsModal onCancel={() => setShowAccountSettingsModal(false)} />
        )}
      </FlexEndCol>
    </FlexCol>
  )
}
