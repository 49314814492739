import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useFeatures, usePermissions } from 'hooks'

interface FormType {
  companyName: string
  commercialExecutiveId?: number
  country: string
  governmentId?: string
  subregion: number
  region?: number
  phoneNumber?: string
}

export const useCreateOrganizationCompanyForm = () => {
  const { t } = useTranslation('common')
  const { permissions } = usePermissions()
  const { organizationFeatures } = useFeatures()

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('validations.required')),
    country: yup.string().required(t('validations.required')),
    region: organizationFeatures.subFarmersManagement?.enabled
      ? yup.number().required(t('validations.required'))
      : yup.number(),
    subregion: yup.number().required(t('validations.required')),
    governmentId: permissions.isFeatureSetGrupoDiana
      ? yup.string().required(t('validations.required'))
      : yup.string(),
    commercialExecutiveId: organizationFeatures.crm?.enabled
      ? yup.number().required(t('validations.required'))
      : yup.number(),
    phoneNumber: yup.string(),
  })

  return useForm({ resolver: yupResolver<FormType>(validationSchema) })
}
