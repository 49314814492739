import { useState, createContext, useContext, Dispatch, SetStateAction } from 'react'

import { DateState, MapIndex, SatelliteProviders } from 'types'

interface MapContextType {
  selectedCalendarDate?: string
  setSelectedCalendarDate: (date?: string) => void
  selectedMapIndex: MapIndex
  setSelectedMapIndex: (mapIndex: MapIndex) => void
  selectedCompareMapIndex: MapIndex
  setSelectedCompareMapIndex: (mapIndex: MapIndex) => void
  selectedCompareCalendarDate?: string
  setSelectedCompareCalendarDate: (date?: string) => void
  dates: DateState
  setDates: Dispatch<SetStateAction<DateState>>
  damDates: DateState
  setDamDates: Dispatch<SetStateAction<DateState>>
  selectedDamCalendarDate?: string
  setSelectedDamCalendarDate: (date?: string) => void
  selectedSatellite: SatelliteProviders
  setSelectedSatellite: (provider: SatelliteProviders) => void
  selectedCompareSatellite: SatelliteProviders
  setSelectedCompareSatellite: (provider: SatelliteProviders) => void
  isCalendarOpen: boolean
  setIsCalendarOpen: Dispatch<SetStateAction<boolean>>
  isCompareCalendarOpen: boolean
  setIsCompareCalendaropen: Dispatch<SetStateAction<boolean>>
}

// TODO: Move the logic from the map and the timeline, that we think is important to be here, in order to optimize these components and make the code easier to read than it is.
const Context = createContext<MapContextType>({
  selectedMapIndex: 'irrigation',
  selectedCompareMapIndex: 'irrigation',
  setSelectedCalendarDate: () => {
    throw new Error(
      'Attempted to use default setSelectedCalendarDate function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedMapIndex: () => {
    throw new Error(
      'Attempted to use default setSelectedMapIndex function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedCompareMapIndex: () => {
    throw new Error(
      'Attempted to use default setSelectedCompareMapIndex function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedCompareCalendarDate: () => {
    throw new Error(
      'Attempted to use default setSelectedCompareCalendarDate function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setDates: () => {
    throw new Error(
      'Attempted to use default setDates function for MapContext, make sure to pass a custom function implementation',
    )
  },
  dates: {
    fromYearMonth: undefined,
    toYearMonth: undefined,
    newDates: false,
  },
  setDamDates: () => {
    throw new Error(
      'Attempted to use default setDamDates function for MapContext, make sure to pass a custom function implementation',
    )
  },
  damDates: {
    fromYearMonth: undefined,
    toYearMonth: undefined,
  },
  setSelectedDamCalendarDate: () => {
    throw new Error(
      'Attempted to use default setSelectedDamCalendarDate function for MapContext, make sure to pass a custom function implementation',
    )
  },
  selectedSatellite: 'ALL',
  setSelectedSatellite: () => {
    throw new Error(
      'Attempted to use default setSelectedSatellite function for MapContext, make sure to pass a custom function implementation',
    )
  },
  selectedCompareSatellite: 'ALL',
  setSelectedCompareSatellite: () => {
    throw new Error(
      'Attempted to use default setSelectedCompareSatellite function for MapContext, make sure to pass a custom function implementation',
    )
  },
  isCalendarOpen: false,
  setIsCalendarOpen: () => {
    throw new Error(
      'Attempted to use default setIsCalendarOpen function for MapContext, make sure to pass a custom function implementation',
    )
  },
  isCompareCalendarOpen: false,
  setIsCompareCalendaropen: () => {
    throw new Error(
      'Attempted to use default setIsCompareCalendaropen function for MapContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): MapContextType => {
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<string>()
  const [selectedMapIndex, setSelectedMapIndex] = useState<MapIndex>('irrigation')
  const [selectedCompareMapIndex, setSelectedCompareMapIndex] = useState<MapIndex>('irrigation')
  const [selectedCompareCalendarDate, setSelectedCompareCalendarDate] = useState<string>()
  const [dates, setDates] = useState<DateState>({
    fromYearMonth: undefined,
    toYearMonth: undefined,
    newDates: false,
  })
  const [damDates, setDamDates] = useState<DateState>({
    fromYearMonth: undefined,
    toYearMonth: undefined,
  })
  const [selectedDamCalendarDate, setSelectedDamCalendarDate] = useState<string>()
  const [selectedSatellite, setSelectedSatellite] = useState<SatelliteProviders>('ALL')
  const [selectedCompareSatellite, setSelectedCompareSatellite] =
    useState<SatelliteProviders>('ALL')
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isCompareCalendarOpen, setIsCompareCalendaropen] = useState(false)

  return {
    selectedCalendarDate,
    setSelectedCalendarDate,
    selectedMapIndex,
    setSelectedMapIndex,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    selectedCompareCalendarDate,
    setSelectedCompareCalendarDate,
    dates,
    setDates,
    damDates,
    setDamDates,
    selectedDamCalendarDate,
    setSelectedDamCalendarDate,
    selectedSatellite,
    setSelectedSatellite,
    selectedCompareSatellite,
    setSelectedCompareSatellite,
    isCalendarOpen,
    setIsCalendarOpen,
    isCompareCalendarOpen,
    setIsCompareCalendaropen,
  }
}

const useMapContext = () => {
  return useContext(Context)
}

export const MapContext = {
  Provider: Context.Provider,
  useValue,
  useMapContext,
}
