import { ActiveCompanyContext } from 'contexts'
import {
  FeatureSet,
  OrganizationFeatures,
  OrganizationGeneralModuleFlag,
  UserCompanyRole,
  UserOrganizationRole,
} from 'types'

import { useCurrentUser } from './useCurrentUser'
import { useFeatures } from './useFeatures'

const allowedOrganizationRolesCompanyCreation = {
  [OrganizationFeatures.CRM]: [UserOrganizationRole.COMMERCIAL, UserOrganizationRole.ADMIN],
  [OrganizationFeatures.SUB_FARMERS_MANAGEMENT]: [UserOrganizationRole.ADMIN],
}

export const usePermissions = () => {
  // TODO: analyze why user can be undefined if this is used when user is already logged in
  // we should use useUser hook
  const { user } = useCurrentUser()
  const { organizationFeatures } = useFeatures()
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const isAnOrganizationUser = !!user?.organizationRole

  const userOrganizationRole = user?.organizationRole?.role

  const isFeatureSetGrupoDiana =
    user?.organizationRole?.organization.featureSetId === FeatureSet.GRUPO_DIANA

  const activeCompany = user?.companiesRoles?.find(
    companyRole => companyRole.company.id === activeCompanyId,
  )

  const isCompanyViewerUser = activeCompany?.role === UserCompanyRole.VIEWER

  const isCompanyAdminUser =
    (activeCompany?.role ?? UserCompanyRole.VIEWER) === UserCompanyRole.ADMIN

  const isIrrigationWorkerUser = activeCompany?.role === UserCompanyRole.IRRIGATION_WORKER

  const satelliteImageryFlag = activeCompany?.company.satelliteImageryFlag ?? !isAnOrganizationUser

  const activeCompanyFieldEntityFlag =
    activeCompany?.company.organization?.featureFlags.general.flags.includes(
      OrganizationGeneralModuleFlag.FIELD_ENTITY,
    )

  const isCommercialNameEnabled = !!activeCompany?.company.organization?.isCommercialNameEnabled
  const fieldEntityFlag =
    activeCompanyFieldEntityFlag ??
    organizationFeatures.general?.flags.includes(OrganizationGeneralModuleFlag.FIELD_ENTITY) ??
    true

  const hasAnOrganizationCompany = user?.companiesRoles?.some(
    company => company.company?.organization?.id,
  )

  const isCommercialUser = user?.organizationRole?.role === UserOrganizationRole.COMMERCIAL
  const isOrganizationAdmin = user?.organizationRole?.role === UserOrganizationRole.ADMIN

  const checkIfUserNotAllowedToCreateCompanyOrSeason = () => {
    if (hasAnOrganizationCompany && !userOrganizationRole) return true

    if (userOrganizationRole) {
      return !Object.entries(allowedOrganizationRolesCompanyCreation).some(
        ([feature, roles]) =>
          organizationFeatures[feature as OrganizationFeatures]?.enabled &&
          roles.includes(userOrganizationRole),
      )
    }

    return false
  }

  const permissions = {
    satelliteImagery: satelliteImageryFlag,
    fieldEntity: fieldEntityFlag,
    companyCreation: !checkIfUserNotAllowedToCreateCompanyOrSeason(),
    seasonCreation: !checkIfUserNotAllowedToCreateCompanyOrSeason(),
    isCompanyAdminUser,
    isAnOrganizationUser,
    isFeatureSetGrupoDiana,
    isCommercialUser,
    isOrganizationAdmin,
    isCompanyViewerUser,
    isIrrigationWorkerUser,
    isCommercialNameEnabled,
  }

  return { permissions }
}
