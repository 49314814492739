import { Marker } from 'react-map-gl'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Typography } from 'antd'

import { DrawArea } from 'features/MainRouter/features/Map/types'

const { Text: AntText } = Typography

export const TooltipText = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-family: Inter;
  font-size: 12px;
`

const TooltipTextContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

interface Props {
  drawAreaInformation: DrawArea
}
export const AreaTooltip: React.FC<Props> = ({ drawAreaInformation }) => {
  const { t: commonT } = useTranslation('common')

  return (
    <Marker
      key={drawAreaInformation.feature.id}
      latitude={drawAreaInformation.polygonCenter.geometry.coordinates[1]}
      longitude={drawAreaInformation.polygonCenter.geometry.coordinates[0]}
      draggable={false}
    >
      <TooltipTextContainer>
        <TooltipText>
          {commonT('systemsFormat.area', { val: { area: drawAreaInformation.area } })}
        </TooltipText>
      </TooltipTextContainer>
    </Marker>
  )
}
