import { Typography, Space } from 'antd'
import styled from 'styled-components'

const { Text } = Typography

const CenteredRow = styled(Space)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
`

const TextContainer = styled.div`
  max-width: 350px;
  text-align: center;
`

const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.new.baseColor[400]} !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: 400 !important;
`

const Title = styled(Text)`
  font-style: normal !important;
  font-family: Inter;
  font-size: 20px !important;
  font-weight: 600 !important;
`

interface Props {
  companyId?: number
  title: string
  description: string
}
export const InvitedUserCard: React.FC<Props> = ({ title, description }) => {
  return (
    <CenteredRow size={10}>
      <Title> {title}</Title>
      <TextContainer>
        <Description>{description}</Description>
      </TextContainer>
    </CenteredRow>
  )
}
