import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import { settingsImage } from 'assets/images'
import {
  InputTitle,
  Layout,
  GreyCard,
  UpdateTypeInformation,
  HistoricalSeasonSelect,
} from 'components'
import { usePermissions } from 'hooks'
import { HistoricalSeason } from 'types'

import { ColumnsSelect, Table, ExportButton, StyledRow, FlexCol } from './components'
import { COLUMNS } from './components/consts'

const VIEWER_COLUMNS = [
  COLUMNS.FIELD_NAME,
  COLUMNS.SEASON_NAME,
  COLUMNS.VARIETY,
  COLUMNS.HARVEST_DATE,
  COLUMNS.YIELD,
  COLUMNS.ESTIMATED_YIELD,
]

const ADMIN_COLUMNS = [COLUMNS.EMERGENCE_DATE, ...VIEWER_COLUMNS]

const ORGANIZATION_DEFAULT_COLUMNS = [
  COLUMNS.PRODUCTION_TYPE,
  COLUMNS.ABANDONED_SIZE,
  COLUMNS.DELIVERY_CONDITION,
  COLUMNS.DELIVERY_MILL,
]

export const LotsManagement: React.FC = () => {
  const { t } = useTranslation('lots-management')
  const { permissions } = usePermissions()
  const { isAnOrganizationUser, fieldEntity, isCompanyAdminUser, isOrganizationAdmin } = permissions
  const isAnAdminUser = isCompanyAdminUser || isOrganizationAdmin
  const [selectedColumns, setSelectedColumns] = useState(
    isAnAdminUser ? ADMIN_COLUMNS : VIEWER_COLUMNS,
  )
  const [filteredLotIds, setFilteredLotIds] = useState<number[]>()
  const [selectedHistoricalSeason, setSelectedHistoricalSeason] = useState<HistoricalSeason>()

  useEffect(() => {
    if (isAnOrganizationUser) {
      const columns = fieldEntity
        ? selectedColumns
        : selectedColumns.filter(column => column !== COLUMNS.FIELD_NAME)
      setSelectedColumns([...columns, ...ORGANIZATION_DEFAULT_COLUMNS])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnOrganizationUser])

  return (
    <Layout
      headerTitle={t('headerTitle')}
      subtitle={t('headerSubtitle')}
      headerImage={settingsImage}
    >
      <Row gutter={[0, 24]} style={{ paddingBottom: '25px' }}>
        <Col span={24}>
          <Row>
            <Col md={{ span: 9 }} lg={{ span: 7 }}>
              <Row gutter={[0, 6]}>
                <Col span={24}>
                  <InputTitle title={t('select.season')} />
                </Col>
                <Col span={24}>
                  <HistoricalSeasonSelect
                    onChange={setSelectedHistoricalSeason}
                    selectedHistoricalSeason={selectedHistoricalSeason}
                    withRefreshPolicy
                  />
                </Col>
              </Row>
            </Col>
            <Col md={{ span: 9 }} lg={{ span: 7 }}>
              <Row gutter={[0, 6]}>
                <Col span={24}>
                  <Row gutter={10}>
                    <Col>
                      <InputTitle title={t('select.columns')} />
                    </Col>
                    <FlexCol>
                      <UpdateTypeInformation title={t('updateTypeInformationTitle')} />
                    </FlexCol>
                  </Row>
                </Col>
                <Col span={24}>
                  <ColumnsSelect
                    onChange={setSelectedColumns}
                    selectedColumns={selectedColumns}
                    placeholder={t('select.columnsPlaceholder')}
                    isAnAdminUser={isAnAdminUser}
                  />
                </Col>
              </Row>
            </Col>

            <Col flex="auto">
              <StyledRow justify="end" align="bottom">
                <ExportButton
                  lotIds={filteredLotIds}
                  selectedHistoricalSeason={selectedHistoricalSeason}
                />
              </StyledRow>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <GreyCard>
            <Table
              selectedColumns={selectedColumns}
              onFilterLots={setFilteredLotIds}
              selectedHistoricalSeason={selectedHistoricalSeason}
              isAnOrganizationUser={isAnOrganizationUser}
              isFieldEntityFlagEnabled={fieldEntity}
            />
          </GreyCard>
        </Col>
      </Row>
    </Layout>
  )
}
