import { Card, Typography } from 'antd'
import styled from 'styled-components'

import { Button } from 'components'

export { LotsList } from './LotsList'
export { DropdownMenu } from './DropdownMenu'
export { ReuseLotsModal } from './ReuseLotsModal'

const { Title: AntTitle, Text: AntText } = Typography

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => theme.card.mapCardPadding};
  & .ant-card-body {
    padding: 0;
  }
  @media (max-height: 735px) {
    padding: 30px 40px !important;
    max-height: calc(100vh - 105px);
  }
  overflow-y: auto;
`

export const AddLotButton = styled(Button).attrs({
  type: 'primary',
})`
  margin: 22px 0;
  min-width: 150px;
`

export const ConfirmLotsButton = styled(Button).attrs({
  type: 'primary',
})`
  margin-top: 32px;
`

export const Title = styled(AntTitle)`
  margin-bottom: 22px !important;
`
export const Text = styled(AntText)`
  font-size: 16px;
  font-style: normal;
`

export const CancelButton = styled(Button)`
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  color: ${({ theme }) => theme.colors.primary};
  margin: 32px 16px 0 0;
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }
`
