import { Row, Col, Typography } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { useFormIconsStyle } from '../../hooks'
import { LoginTextCta } from '../LoginTextCTA'

const { Title: AntTitle } = Typography

const Content = styled(Col)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled(AntTitle)`
  color: ${props => props.theme.colors.olive} !important;
  text-align: center;
  margin-bottom: 30px !important;
  margin-top: 20px !important;
  font-weight: 600;
  line-height: 40px;
`

interface Props {
  title: string
  showLoginText?: boolean
}

export const FeedbackCard: React.FC<Props> = ({ title, showLoginText = true }) => {
  const iconStyle = useFormIconsStyle()
  return (
    <Content>
      <Row justify="center">
        <Row>
          <Title level={3}>{title}</Title>
        </Row>
        <Row>
          <MailOutlined style={{ fontSize: '50px', ...iconStyle }} />
        </Row>
      </Row>
      {showLoginText && <LoginTextCta />}
    </Content>
  )
}
