import { Space, Typography } from 'antd'
import styled from 'styled-components'

import { Button } from 'components'

const { Title } = Typography

export { CompanyUsersTable } from './CompanyUsersTable'
export { InviteUserModal } from './InviteUserModal'
export { LeaveCompanyModal } from './LeaveCompanyModal'
export { FarmerInformation } from './FarmerInformation'
export { UpdateCompanyForm } from './UpdateCompanyForm'

export const UsersSectionTitle = styled(Title)`
  margin-bottom: 0px !important;
`

export const ButtonsContainer = styled(Space).attrs({
  size: 'large',
})``

export const StyledButton = styled(Button)`
  width: 100%;
`

export const LeaveCompanyButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.grey3};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey3} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    border-color: ${({ theme }) => theme.colors.grey3} !important;
  }
`

export const Container = styled(Space).attrs({
  direction: 'vertical',
  size: 20,
})`
  width: 100%;
`
