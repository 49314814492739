import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body #root {
    height: 100%;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 3px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .ant-col:first-child {
    padding-left: 0 !important;
  }

  .ant-col:last-child {
    padding-right: 0 !important;
  }

  .ant-upload.ant-upload-select {
    display: block;
  }
 
  .ant-table-thead > tr > th {
    &:before {
      width: 0px !important
    }
    &:first-child {
      border-bottom-left-radius: 11px
    }
    &:last-child {
      border-bottom-right-radius: 11px
    }
  }

  .ant-table-tbody > tr {
    &:last-child {
      >td {
        border-bottom: 0
      }
    }
  
    > td {
      border-bottom: ${({ theme }) => `5px solid ${theme.colors.grey2}`};
      &:first-child {
        border-bottom-left-radius: 2px
      }
      &:last-child {
        border-bottom-right-radius: 2px;
      }
    }
  }

  .ant-select-selector {
    align-items: center;
    border-width: 2px !important;
  }

  .ant-select-item .anticon-check {
    color: white !important;
  } 

  .custom-select-dropdown {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
    border-radius: ${({ theme }) => theme.foundation.smallRadius};
  }
  .custom-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) => theme.colors.new.baseColor[700]} !important;
  }
  .custom-select-dropdown .ant-select-item-option-content { 
    color: white;
    font-weight: 600 !important;
  }
  .custom-select-dropdown .ant-select-item-option-selected {
    background-color: ${({ theme }) => theme.colors.new.baseColor[700]} !important;
  }
  .custom-select-dropdown .ant-select-item {
    padding: 9px 11px !important;
    margin: 3px 0px !important;
    border-radius: 0;
  }
  .ant-select-dropdown {
    padding: 0;
  }
  .ant-picker-panel-container {
    border-radius: ${({ theme }) => theme.foundation.smallRadius}
  }

  .ant-select-multiple .ant-select-selection-item {
    border: none;
  }

  .ant-select-selection-item-remove > * {
    display: none;
  }

  .custom-disabled-select .ant-select-selector {
    background-color: white !important;
    color:  ${({ theme }) => theme.colors.new.baseColor[900]} !important;
  }

  .custom-disabled-select  .ant-select-arrow {
    display: none !important
  }

  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-header {
    padding: 30px 48px 22px;
  }
  
  .ant-modal-body {
    padding: 0 48px 30px;
  }

  .ant-picker {
    border-width: 2px !important;
  }
  
  .ant-input {
    border-width: 2px !important;
  }
  
  .ant-input-number {
    border-width: 2px !important;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .ant-input-number-disabled {
    background-color: ${({ theme }) => theme.colors.grey2} !important;
  }

  .react-tel-input { 
    .form-control:focus { 
      box-shadow: none;
      border-color: ${({ theme }) => theme.colors.inputOrange} !important;
    }
    .form-control:hover { 
      border-color: ${({ theme }) => theme.colors.inputOrange} !important;
    }
  }

`
