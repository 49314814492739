import { useState, createContext, useContext, useEffect, useCallback } from 'react'
import useCustomCompareEffect from 'react-use/lib/useCustomCompareEffect'
import { useTranslation } from 'react-i18next'
import { isEqual, orderBy } from 'lodash'

import { useCurrentUser, useCompanySeasons, useHistoricalSeasons } from 'hooks'
import { CountryId, HistoricalSeason } from 'types'
import { CompanyUtils } from 'utils'
import { User } from 'api'
import { HistoricalSeasons } from 'hooks/useHistoricalSeasons/api'

type ActiveCompanyType = { id?: number; countryId?: CountryId; language?: string; name?: string }

interface ActiveCompanyContextType {
  loadingCompanySeasons: boolean
  activeCompanyId?: number
  setActiveCompany: (company: ActiveCompanyType) => void
  activeSeasonId?: number
  setActiveSeason: (id?: number) => void
  isNewCompany?: boolean
  setIsNewCompany: (isNewCompany: boolean) => void
  activeCountryId?: CountryId
  activeCompanyName?: string
  resetStore: () => void
  selectedSeasonRangeToFilterOrganizationCompanies?: HistoricalSeason
  setSelectedSeasonRangeToFilterOrganizationCompanies: (
    selectedHistoricalSeason?: HistoricalSeason,
  ) => void
  hasCompanies: boolean
}

const Context = createContext<ActiveCompanyContextType>({
  loadingCompanySeasons: false,
  setActiveCompany: () => {
    throw new Error(
      'Attempted to use default setActiveCompany function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  setActiveSeason: () => {
    throw new Error(
      'Attempted to use default setActiveSeason function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  setIsNewCompany: () => {
    throw new Error(
      'Attempted to use default setIsNewCompany function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  resetStore: () => {
    throw new Error(
      'Attempted to use default resetStore function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedSeasonRangeToFilterOrganizationCompanies: () => {
    throw new Error(
      'Attempted to use default setSelectedSeasonRangeToFilterOrganizationCompanies function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  hasCompanies: true,
})

const useValue = (): ActiveCompanyContextType => {
  const [activeCompanyId, setActiveCompanyId] = useState<number>()
  const [activeCountryId, setactiveCountryId] = useState<CountryId>()
  const [activeSeasonId, setActiveSeasonId] = useState<number>()
  const [isNewCompany, setIsNewCompany] = useState<boolean>()
  const [activeCompanyName, setActiveCompanyName] = useState<string>()
  const [
    selectedSeasonRangeToFilterOrganizationCompanies,
    setSelectedSeasonRangeToFilterOrganizationCompanies,
  ] = useState<HistoricalSeason>()

  const { user } = useCurrentUser()
  const { i18n } = useTranslation()
  const { data: seasonsData, loading } = useCompanySeasons(activeCompanyId)

  const { data: historicalSeasonsData } = useHistoricalSeasons({
    onChange: setSelectedSeasonRangeToFilterOrganizationCompanies,
    selectedHistoricalSeason: selectedSeasonRangeToFilterOrganizationCompanies,
    skip: !user?.organizationRole,
    withRefreshPolicy: true,
  })

  const setActiveCompany = ({ id, countryId, language, name }: ActiveCompanyType) => {
    setActiveCompanyId(id)
    setactiveCountryId(countryId)
    setActiveCompanyName(name)
    const appLanguage =
      user?.companiesRoles?.find(company => company.company.id === id)?.company.country.code ??
      language

    i18n.changeLanguage(appLanguage)
  }

  useCustomCompareEffect(
    () => {
      if (user && user.companiesRoles?.length) {
        if (user.organizationRole && historicalSeasonsData) {
          const firstHistoricalSeason = historicalSeasonsData.historicalSeasons[0]
          const filteredCompanies = CompanyUtils.filterCompanySeasonsByHistoricalSeasonRange(
            firstHistoricalSeason,
            user.companiesRoles,
          )

          const firstFilteredCompany = filteredCompanies?.[0]?.company
          if (firstFilteredCompany) {
            setActiveCompany({
              id: firstFilteredCompany.id,
              countryId: firstFilteredCompany.country.id,
              name: firstFilteredCompany.name,
            })
            return
          }
        }

        setActiveCompany({
          id: user.companiesRoles[0].company.id,
          countryId: user.companiesRoles[0].company.country.id,
          name: user.companiesRoles[0].company.name,
        })
      }
    },
    [user, historicalSeasonsData],
    (prevDeps, nextDeps) => {
      const userPrevDep = prevDeps[0] as User['user'] | undefined
      const userNextDep = nextDeps[0] as User['user'] | undefined
      const historicalSeasonsDataPrevDep = prevDeps[1] as HistoricalSeasons | undefined
      const historicalSeasonsDataNextDep = nextDeps[1] as HistoricalSeasons | undefined

      const equalId = userPrevDep?.id === userNextDep?.id

      return equalId && isEqual(historicalSeasonsDataPrevDep, historicalSeasonsDataNextDep)
    },
  )

  const getActiveSeason = useCallback(() => {
    const selectedSeason = seasonsData?.seasons.results.find(season => season.id === activeSeasonId)

    if (selectedSeason) return selectedSeason

    const currentDate = new Date()

    const earliestSeasons = seasonsData?.seasons.results.filter(
      season => new Date(season.startDate) <= currentDate,
    )
    // This is for case there are no seasons with a start date before or equal to the current one
    if (!earliestSeasons?.length) return seasonsData?.seasons.results[0]

    const orderedSeasons = orderBy(earliestSeasons, 'startDate', 'desc')

    return orderedSeasons[0]
  }, [seasonsData?.seasons.results, activeSeasonId])

  useEffect(() => {
    if (seasonsData) {
      const activeSeason = getActiveSeason()

      setActiveSeasonId(activeSeason?.id ?? undefined)
    }
  }, [getActiveSeason, seasonsData])

  const resetStore = () => {
    setActiveCompany({})
    setActiveSeasonId(undefined)
    setactiveCountryId(undefined)
    setSelectedSeasonRangeToFilterOrganizationCompanies(undefined)
  }

  const hasCompanies = !!user?.companiesRoles?.length

  return {
    loadingCompanySeasons: loading,
    activeCompanyId,
    setActiveCompany,
    activeSeasonId,
    setActiveSeason: setActiveSeasonId,
    isNewCompany,
    setIsNewCompany,
    activeCountryId,
    activeCompanyName,
    resetStore,
    selectedSeasonRangeToFilterOrganizationCompanies,
    setSelectedSeasonRangeToFilterOrganizationCompanies,
    hasCompanies,
  }
}

const useActiveCompanyContext = () => {
  return useContext(Context)
}

export const ActiveCompanyContext = {
  Provider: Context.Provider,
  useValue,
  useActiveCompanyContext,
}
