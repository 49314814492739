import { Control, Controller } from 'react-hook-form'
import { Row, Col, Form, Input as AntdInput, Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { InputTitle, Upload } from 'components'
import { useCountries } from 'hooks'
import { ConversionUtils } from 'utils'
import { COUNTRIES } from 'consts'

const FormContainer = styled(Row)`
  margin-top: 22px;
  flex-flow: row;
`

const Image = styled.img`
  background-position: center;
  width: 26px;
  height: 26px;
  margin-right: 13px;
`

const Select = styled(AntSelect)`
  max-width: 187px;
  & .ant-select-selector {
    height: 55px !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
` as typeof AntSelect

const Input = styled(AntdInput)`
  height: 55px !important;
`

interface Props {
  image?: string
  onImageChange: (imageResult: string, file: File) => void
  control: Control<{ companyName: string; country: string }>
  onInputNameBlur?: () => void
  isReadyOnly?: boolean
  isCreateCompanyMode: boolean
  selectedCompanyCountry?: string
}

export const CompanyForm: React.FC<Props> = ({
  image,
  onImageChange,
  control,
  onInputNameBlur,
  isReadyOnly,
  isCreateCompanyMode,
  selectedCompanyCountry,
}) => {
  const { t } = useTranslation('company-settings')
  const { countries, loading } = useCountries()

  return (
    <FormContainer gutter={25}>
      <Col>
        <Upload
          title={t('inputs.logo')}
          image={image}
          onChange={onImageChange}
          isReadOnly={isReadyOnly}
          size="small"
        />
      </Col>
      <Col flex={1}>
        <InputTitle title={t('inputs.companyName')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="companyName"
          control={control}
          render={({
            field: { onChange, onBlur, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                disabled={isReadyOnly}
                size="large"
                onBlur={() => {
                  onInputNameBlur?.()
                  onBlur()
                }}
                {...restFieldProps}
                onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Col flex="200px">
        <InputTitle title={t('inputs.country')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="country"
          control={control}
          render={({
            field: { onChange, onBlur, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Select
                disabled={!isCreateCompanyMode || isReadyOnly}
                size="large"
                loading={loading}
                onBlur={() => {
                  onBlur()
                }}
                {...restFieldProps}
                onChange={event => onChange(event)}
                defaultValue={selectedCompanyCountry}
              >
                {countries?.map(country => (
                  <Select.Option key={country.id} value={country.id}>
                    <Image src={COUNTRIES.flags[country.id]} />
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />
      </Col>
    </FormContainer>
  )
}
