import { useMutation } from '@apollo/client'
import { Button, Space as AntSpace } from 'antd'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GraphQLUtils } from 'utils'
import { useQueryParams } from 'hooks'

import { CenteredText, Content, FormPasswordInput, LoginTextCta } from '../../components'
import { useChangePasswordForm } from './hooks'
import { ChangePassword, ChangePasswordVariables, CHANGE_PASSWORD } from './api'
import { useFormIconsStyle } from '../../hooks'

const CreateNewPasswordButton = styled(Button)`
  width: 100%;
`

const Space = styled(AntSpace)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const PasswordChange: React.FC = () => {
  const iconStyle = useFormIconsStyle()
  const { t } = useTranslation('login')

  const navigate = useNavigate()
  const query = useQueryParams()
  const token = query.get('token') ?? ''

  const { control, handleSubmit } = useChangePasswordForm()

  const [changePassword, { loading, error }] = useMutation<ChangePassword, ChangePasswordVariables>(
    CHANGE_PASSWORD,
    {
      onCompleted: () => navigate('/login'),
      onError: GraphQLUtils.errorHandler,
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    },
  )

  return (
    <Content>
      <Space direction="vertical" size="small">
        <FormPasswordInput control={control} iconStyle={iconStyle} />
        <CreateNewPasswordButton
          loading={loading}
          type="primary"
          onClick={handleSubmit(({ password }) =>
            changePassword({ variables: { token, password } }),
          )}
        >
          {t('passwordChangePage.buttonText')}
        </CreateNewPasswordButton>
        {error && <CenteredText error>{t('passwordChangePage.errorText')}</CenteredText>}
      </Space>
      <LoginTextCta />
    </Content>
  )
}
