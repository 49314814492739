import { DefaultTheme } from 'styled-components'

import { Colors } from './colors'
import { Typography } from './typography'
import { Foundation } from './foundation'
import { Card } from './card'
import { Input } from './input'

export { antdTheme } from './antd-theme'

export const theme: DefaultTheme = {
  colors: Colors,
  foundation: Foundation,
  typography: Typography,
  card: Card,
  input: Input,
}
