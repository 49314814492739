/* eslint-disable i18next/no-literal-string */
import { Form } from 'antd'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Upload } from 'components'
import { ConversionUtils } from 'utils'
import { useCountries } from 'hooks'
import { COUNTRIES } from 'consts'

import { FormContainer, FormItemContainer, Input, Select, Image } from '../../..'

interface Props {
  image?: string
  control: Control<{ companyName: string; country: string }>
  onImageChange: (imageResult: string, file: File) => void
  onInputNameBlur?: () => void
}

export const CreateCompanyForm: React.FC<Props> = ({
  image,
  control,
  onImageChange,
  onInputNameBlur,
}) => {
  const { t } = useTranslation('company-settings')
  const { countries, loading } = useCountries()

  return (
    <FormContainer>
      <Upload size="small" version="v2" image={image} onChange={onImageChange} />
      <FormItemContainer>
        <Controller
          name="companyName"
          control={control}
          render={({
            field: { onChange, onBlur, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                placeholder={t('inputs.companyName')}
                size="large"
                onBlur={() => {
                  onInputNameBlur?.()
                  onBlur()
                }}
                {...restFieldProps}
                onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
              />
            </Form.Item>
          )}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Controller
          name="country"
          control={control}
          render={({
            field: { onChange, onBlur, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Select
                placeholder={t('inputs.country')}
                size="large"
                loading={loading}
                onBlur={() => {
                  onInputNameBlur?.()
                  onBlur()
                }}
                {...restFieldProps}
                onChange={event => onChange(event)}
                popupClassName="custom-select-dropdown"
              >
                {countries?.map(country => (
                  <Select.Option key={country.id} value={country.id}>
                    <Image src={COUNTRIES.flags[country.id]} />
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />
      </FormItemContainer>
    </FormContainer>
  )
}
