import styled from 'styled-components'
import { Typography, Progress as AntProgress, Col, Row } from 'antd'

const { Title: AntTitle, Text: AntText } = Typography

export const Title = styled(AntTitle)`
  font-weight: normal !important;
  margin-bottom: 0px !important;
  line-height: 24px;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.grey1} !important;
`

export const SubTitle = styled(AntText)`
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: ${({ theme }) => theme.colors.grey1} !important;
`

export const Text = styled(SubTitle)`
  font-weight: 600 !important;
`

export const Progress = styled(AntProgress)`
  & .ant-progress-text {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

export const Box = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px !important;
  border-radius: 2px;
  flex: 1;
  background: ${({ theme }) => theme.colors.grey2} !important;
`

export const StyledRow = styled(Row)`
  .ant-col:first-child {
    padding-left: 5px !important;
  }
`
export const MarginRow = styled(Row)`
  margin-top: 10px;
`

export const CenteredCol = styled(Col)`
  align-items: center;
  display: flex;
`
