import { Language, Locale, LocaleLanguage } from 'types/i18n'
/**
 * Locale format lng-REGION (es-AR)
 * Language => 2 letters, ISO 639-1
 * REGION => optional, 2 letters, uppercase, ISO 3166-1 alpha-2
 */

type LanguageCode = {
  [K in LocaleLanguage]: Language
}

export const LANGUAGE: LanguageCode = {
  en: 'en',
  es: 'es',
  'es-PA': 'es',
}

export const LANGUAGE_CODES = {
  ES: 'es',
  US: 'en',
}

export const LOCALE_CODES: { ES_PA: Locale } = {
  ES_PA: 'es-PA',
}
