import { Navigate } from 'react-router-dom'
import { PropsWithChildren } from 'react'

import { OrganizationCrmModuleFlag, OrganizationGeneralModuleFlag } from 'types'
import { usePermissions } from 'hooks'

interface Props {
  flags: (OrganizationGeneralModuleFlag | OrganizationCrmModuleFlag)[]
}
export const ProtectedRoute: React.FC<PropsWithChildren<Props>> = ({ flags, children }) => {
  const { permissions } = usePermissions()

  if (
    !permissions.satelliteImagery &&
    flags.includes(OrganizationGeneralModuleFlag.SATELLITE_IMAGERY)
  )
    return <Navigate to="/dashboard" />

  return <>{children}</>
}
