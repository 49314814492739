import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

import { HistoricalSeason } from 'types'
import { useHistoricalSeasons } from 'hooks'

const Select = styled(AntSelect)`
  min-width: 220px;

  .ant-select-selector {
    height: 38px;
    font-size: 13px;
    font-weight: 600;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-selection-item {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
` as typeof AntSelect

const SELECT_NEW_UI_CLASSNAME = 'custom-select-dropdown'

interface Props {
  selectedHistoricalSeason?: HistoricalSeason
  onChange: (historicalSeason: HistoricalSeason) => void
  withRefreshPolicy?: boolean
}

export const HistoricalSeasonSelect: React.FC<Props> = ({
  selectedHistoricalSeason,
  onChange,
  withRefreshPolicy,
}) => {
  const { data, loading } = useHistoricalSeasons({
    onChange,
    selectedHistoricalSeason,
    withRefreshPolicy,
  })

  return (
    <Select
      popupMatchSelectWidth={false}
      popupClassName={SELECT_NEW_UI_CLASSNAME}
      loading={loading}
      value={selectedHistoricalSeason?.name}
      onChange={seasonName => {
        const selectedSeason = data?.historicalSeasons?.find(season => season.name === seasonName)
        if (!selectedSeason) return
        const { name, endRange, startRange } = selectedSeason

        onChange({
          endRange,
          name,
          startRange,
        })
      }}
    >
      {data?.historicalSeasons.map(season => (
        <Select.Option key={season.name} value={season.name}>
          {season.name}
        </Select.Option>
      ))}
    </Select>
  )
}
