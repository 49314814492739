import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import {
  UpdateRicePhytosanitaryVariables,
  UpdateRicePhytosanitary,
  UPDATE_RICE_PHITOSANITARY,
} from '../api'

export const useUpdateRicePhytosanitary = () => {
  const [updateRicePhytosanitary, { loading }] = useMutation<
    UpdateRicePhytosanitary,
    UpdateRicePhytosanitaryVariables
  >(UPDATE_RICE_PHITOSANITARY, {
    onError: GraphQLUtils.errorHandler,
  })

  return { updateRicePhytosanitary, loading }
}
