import ReactMapGL, {
  Source,
  Layer,
  ViewStateChangeEvent,
  useMap,
  MapLayerMouseEvent,
} from 'react-map-gl'
import bbox from '@turf/bbox'
import { Feature, multiLineString, Polygon, polygon, Properties } from '@turf/helpers'
import React from 'react'
import { useTheme } from 'styled-components'

import { MAP } from 'consts'
import { config } from 'config'
import { Lot, MapIndex, SatelliteProviders } from 'types'
import { GeoUtils } from 'utils'
import { AreaTooltip } from 'features/MainRouter/components'
import { Dam } from 'hooks/useQueryDams/api'
import { useImageUrl } from 'hooks'

import { InformationPopup, Control } from './components'
import { DrawArea, DrawingOption, Features, PopupInfo, ToolMode } from '../../types'
import { LotsUtils } from '../../utils'
import { DrawMode } from '../../hooks/useDrawingTool'
import { AreaSource } from '../AreaSource'

interface Props {
  selectedLots: Lot[]
  viewport: {
    latitude: number
    longitude: number
    zoom: number
  }
  onHandleMove: (evt: ViewStateChangeEvent) => void
  onHandleClick: (evt: MapLayerMouseEvent) => void
  onHandleMouseEnter: (evt: MapLayerMouseEvent) => void
  onHandleMouseLeave: (evt: MapLayerMouseEvent) => void
  popupInfo?: PopupInfo
  interactiveMapLayersIds: string[]
  isCompareModeSelected: boolean
  drawMode: DrawMode
  cursor: string
  onHandleCreateFeature: ({ features }: { features: Features }) => void
  onHandleUpdateFeature: ({ features }: { features: Features }) => void
  onHandleDeleteFeature: (featureId: string) => void
  drawnAreasFeatures: Feature<Polygon, Properties>[]
  drawingOption: DrawingOption
  selectedCalendarDate?: string
  rtkLots?: { id: number; rtk?: number[][][] }[]
  selectedMapIndex: MapIndex
  drawnAreas: DrawArea[]
  toolMode: ToolMode
  showRtk: boolean
  showPopup: boolean
  popupCoordinates?: number[]
  dams: Dam[]
  isShowDamsModeSelected: boolean
  selectedSatellite: SatelliteProviders
}

export const DefaultMap: React.FC<Props> = ({
  onHandleMove,
  popupInfo,
  interactiveMapLayersIds,
  isCompareModeSelected,
  viewport,
  onHandleClick,
  onHandleMouseLeave,
  onHandleMouseEnter,
  drawMode,
  cursor,
  onHandleCreateFeature,
  onHandleUpdateFeature,
  onHandleDeleteFeature,
  drawnAreasFeatures,
  drawingOption,
  selectedCalendarDate,
  selectedLots,
  rtkLots,
  selectedMapIndex,
  drawnAreas,
  toolMode,
  showRtk,
  showPopup,
  popupCoordinates,
  dams,
  isShowDamsModeSelected,
  selectedSatellite,
}) => {
  const { colors } = useTheme()
  const { map } = useMap()
  const { getUrlWithToken } = useImageUrl()

  return (
    <ReactMapGL
      onMove={onHandleMove}
      interactiveLayerIds={popupInfo ? undefined : interactiveMapLayersIds}
      mapStyle={MAP.STYLES.SATELLITE_STREET}
      {...(!isCompareModeSelected && viewport)}
      id="map"
      onClick={onHandleClick}
      onMouseEnter={onHandleMouseEnter}
      onMouseLeave={onHandleMouseLeave}
      cursor={drawMode === 'draw_polygon' ? 'crosshair' : cursor}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <Control
        mapOptions={{ displayControlsDefault: false }}
        onCreate={onHandleCreateFeature}
        onUpdate={onHandleUpdateFeature}
        onDeleteFeature={onHandleDeleteFeature}
        features={drawnAreasFeatures}
        mode={drawMode}
        drawingOption={drawingOption}
      />

      {isShowDamsModeSelected
        ? map &&
          dams.map(dam => {
            const selectedDamDate = dam.calendar.find(date => date.date === selectedCalendarDate)
            const isImageAvailable =
              selectedDamDate?.mapUrls.waterSurface?.isAvailable &&
              selectedDamDate?.mapUrls?.waterSurface?.url

            const imageUrl = isImageAvailable
              ? getUrlWithToken(selectedDamDate!.mapUrls!.waterSurface!.url!)
              : undefined

            return (
              <React.Fragment key={dam.id}>
                <AreaSource
                  lotImageUrl={imageUrl}
                  lotId={dam.id.toString()}
                  area={polygon(dam.area.coordinates)}
                  mapRef={map}
                />
                {config.isSentinelImageryOn && (
                  <Source
                    type="image"
                    url={imageUrl}
                    coordinates={GeoUtils.bboxCoords(bbox(dam.area))}
                  >
                    <Layer
                      type="raster"
                      beforeId={dam.id.toString()}
                      id={`image-dam-${dam.id.toString()}`}
                      paint={{}}
                    />
                  </Source>
                )}
              </React.Fragment>
            )
          })
        : selectedCalendarDate &&
          selectedLots.map(lot => {
            const baseUrl = LotsUtils.getImageUrl(
              lot,
              selectedMapIndex,
              selectedCalendarDate,
              selectedSatellite,
            )
            const lotImageUrl = baseUrl ? getUrlWithToken(baseUrl) : undefined

            const rtk = rtkLots?.find(rtkLot => rtkLot.id === lot.id)?.rtk

            const lotRtk = rtk ? multiLineString(rtk) : undefined

            return (
              <React.Fragment key={lot.id}>
                {map && (
                  <AreaSource
                    lotImageUrl={lotImageUrl}
                    lotId={lot.id.toString()}
                    area={polygon(lot.area.coordinates)}
                    mapRef={map}
                  />
                )}

                {config.isSentinelImageryOn && lotImageUrl && (
                  <Source
                    type="image"
                    url={lotImageUrl}
                    coordinates={GeoUtils.bboxCoords(bbox(lot.area))}
                  >
                    <Layer
                      type="raster"
                      beforeId={lot.id.toString()}
                      id={`image-${lot.id.toString()}`}
                      paint={{}}
                    />
                  </Source>
                )}
                {!drawnAreas.length && toolMode !== 'draw' && showRtk && lotRtk && (
                  <Source
                    key={`rtk-${lot.id.toString()}`}
                    type="geojson"
                    id={`rtk-${lot.id.toString()}`}
                    data={lotRtk}
                  >
                    <Layer
                      beforeId="country-label"
                      id={`lot-rtk-${lot.id}`}
                      type="line"
                      paint={{
                        'line-color': colors.white,
                        'line-width': 1.5,
                      }}
                    />
                  </Source>
                )}
              </React.Fragment>
            )
          })}
      {showPopup && popupInfo && (
        <InformationPopup
          data={popupInfo}
          isShowDamsModeSelected={isShowDamsModeSelected}
          coordinates={{
            latitude: popupCoordinates?.[1],
            longitude: popupCoordinates?.[0],
          }}
        />
      )}
      {drawingOption !== 'hide' &&
        drawnAreas?.map(drawnArea => <AreaTooltip drawAreaInformation={drawnArea} />)}
    </ReactMapGL>
  )
}
