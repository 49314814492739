import { useMemo } from 'react'
import useMedia from 'react-use/lib/useMedia'

import { usePermissions } from 'hooks'

export const useListHeight = () => {
  const mediumHeight = useMedia('(min-height: 700px)')
  const maxHeight = useMedia('(min-height: 900px)')
  const { permissions } = usePermissions()

  const height = useMemo(() => {
    if (maxHeight) return 250
    if (mediumHeight) return 200

    return permissions.isAnOrganizationUser ? 90 : 170
  }, [maxHeight, mediumHeight, permissions.isAnOrganizationUser])

  return height
}
