/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { DamsVariables, Dams, Dams_dams_results as Dam } from './types/Dams'

export const DAMS = gql`
  query Dams(
    $fieldId: Int!
    $includeCalendar: Boolean!
    $toYearMonth: String
    $fromYearMonth: String
    $includeMapUrls: Boolean!
  ) {
    dams(fieldId: $fieldId) {
      results {
        id
        name
        area {
          type
          coordinates
        }
        location {
          coordinates
          type
        }
        calendar(fromYearMonth: $fromYearMonth, toYearMonth: $toYearMonth)
          @include(if: $includeCalendar) {
          date
          mapUrls {
            waterSurface {
              url @include(if: $includeMapUrls)
              isAvailable
              weatherType
              provider
              area
              volume
              percentage
            }
          }
        }
      }
    }
  }
`
