import { memo, useEffect, useState } from 'react'
import { PieChart, Pie, ResponsiveContainer } from 'recharts'
import { useTheme } from 'styled-components'
import { maxBy } from 'lodash'

import { Props, ChartDataObject } from '../../../../../../../types'
import { RenderActiveBorder, RenderActiveShape } from './components'

const getMaxSizeIndex = (chartDataObject: ChartDataObject[]) => {
  const selectedMaxValue = maxBy(chartDataObject, 'size')

  const index = chartDataObject.findIndex(data => data.name === selectedMaxValue?.name)
  if (index === -1) return undefined

  return index
}

interface OnMouseEnterData {
  name: string
  payload: ChartDataObject
  percent: number
  size: number
  value: number
  showCropChart: boolean
}

export const Chart: React.FC<Props> = memo(({ varietiesData, cropData, showCropChart }) => {
  const [activeCropIndex, setActiveCropIndex] = useState<number>()
  const [activeVarietiesIndex, setActiveVarietiesIndex] = useState<number[]>()
  const { colors } = useTheme()

  const onCropChartMouseEnter = (_cropData: OnMouseEnterData, index: number) => {
    setActiveVarietiesIndex(undefined)
    setActiveCropIndex(index)
  }

  const onVarietiesChartMouseEnter = (_: OnMouseEnterData, index: number) => {
    setActiveCropIndex(undefined)
    setActiveVarietiesIndex([index])
  }

  const getTotalOFHa = (chartObject?: ChartDataObject[]) =>
    chartObject?.reduce((accumulator, { size }) => {
      const totalHa = accumulator + size
      return totalHa
    }, 0)

  useEffect(() => {
    if (showCropChart) {
      const defaultCropIndex = getMaxSizeIndex(cropData)
      if (typeof defaultCropIndex !== 'number') return
      setActiveCropIndex(defaultCropIndex)
      return
    }

    const defaultVarietyIndex = getMaxSizeIndex(varietiesData)
    if (typeof defaultVarietyIndex !== 'number') return

    setActiveVarietiesIndex([defaultVarietyIndex])
  }, [cropData, showCropChart, varietiesData])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={120} height={120}>
        {showCropChart && (
          <Pie
            activeShape={props => (
              <RenderActiveShape
                {...props}
                totalHa={getTotalOFHa(varietiesData)}
                fill={colors.new.secondary[600]}
                showCropName
              />
            )}
            data={cropData}
            dataKey="size"
            activeIndex={activeCropIndex}
            cx="50%"
            cy="50%"
            onMouseEnter={onCropChartMouseEnter}
            fill={colors.new.baseColor[700]}
            outerRadius={80}
            innerRadius={70}
            stroke={colors.new.baseColor[500]}
          />
        )}
        <Pie
          data={varietiesData}
          activeShape={props => {
            if (activeCropIndex !== undefined)
              return <RenderActiveBorder {...props} fill={colors.new.primary[600]} />
            return (
              <RenderActiveShape
                {...props}
                totalHa={getTotalOFHa(varietiesData)}
                fill={colors.new.primary[600]}
                y={showCropChart ? 75 : 100}
                showCropName={showCropChart}
              />
            )
          }}
          activeIndex={activeVarietiesIndex}
          onMouseEnter={onVarietiesChartMouseEnter}
          cx="50%"
          cy="50%"
          outerRadius={showCropChart ? 70 : 80}
          innerRadius={showCropChart ? 60 : 70}
          fill={colors.new.baseColor[700]}
          dataKey="size"
          stroke={colors.new.baseColor[500]}
        />
      </PieChart>
    </ResponsiveContainer>
  )
})
