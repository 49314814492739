/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { UpdateLotById, UpdateLotByIdVariables } from './types/UpdateLotById'

export const UPDATE_LOT = gql`
  mutation UpdateLotById($updateLotDto: UpdateLotInput!) {
    updateLot(updateLotDTO: $updateLotDto) {
      lot {
        id
        name
        commercialName
        area {
          type
          coordinates
        }
        rtk {
          coordinates
        }
        location {
          type
          coordinates
        }
        size
        crop {
          id
        }
        previousCrop {
          id
        }
        variety {
          id
          name
          locales {
            es
            en
          }
        }
        productionType
        abandonedSize
        deliveryCondition
        deliveryMill {
          id
        }
      }
    }
  }
`
