import styled from 'styled-components'
import { Row } from 'antd'
import { PropsWithChildren } from 'react'

interface RowProps {
  height?: string
}

const ContainerRow = styled(Row)<RowProps>`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: ${({ height = '300px' }) => height};
  width: 288px;
  padding: 24px;
`

type PropsWithChildrenAndRow = PropsWithChildren<RowProps>

export const Card: React.FC<PropsWithChildrenAndRow> = ({ children, height }) => {
  return (
    <ContainerRow gutter={10} height={height}>
      {children}
    </ContainerRow>
  )
}
