import dayjs from 'dayjs'

import { DATE_FORMATS } from 'consts'

const formatToUtc = (date: Date) => {
  const dateTimezone = date.getTimezoneOffset()
  if (dateTimezone < 0)
    return dayjs(date).subtract(dateTimezone, 'minute').format(DATE_FORMATS.DATE_WITH_SEPARATOR)

  return dayjs(date).add(dateTimezone, 'minute').format(DATE_FORMATS.DATE_WITH_SEPARATOR)
}

const formatToUtcAndSubtractOneDay = (date: Date) => {
  const subTractOneDay = dayjs(date).subtract(1, 'day').toDate()
  return formatToUtc(subTractOneDay)
}

const parseDate = (date?: string) => {
  if (!date) return undefined
  return dayjs(formatToUtc(dayjs(date).toDate())).toDate()
}

const formatValue = (value: Date | null) => {
  if (!value) return null
  return dayjs(value).format(DATE_FORMATS.DATE_WITH_SEPARATOR)
}

export const DateUtils = {
  formatToUtc,
  formatToUtcAndSubtractOneDay,
  parseDate,
  formatValue,
}
