import { useTranslation } from 'react-i18next'

import { DeliveryCondition } from 'types'

export const useDeliveryCondition = () => {
  const { t } = useTranslation('common')

  const deliveryConditionMap: {
    [key in DeliveryCondition]: { label: string; value: DeliveryCondition }
  } = {
    DRY: {
      label: t('deliveryCondition.dry'),
      value: DeliveryCondition.DRY,
    },
    WET: {
      label: t('deliveryCondition.wet'),
      value: DeliveryCondition.WET,
    },
  }

  return { deliveryConditionMap }
}
