import { useQuery } from '@apollo/client'

import { REUSE_LOTS, ReuseLots, ReuseLotsVariables } from './api'

interface Props {
  seasonId?: number
  fieldId?: number
}

export const useReuseLots = ({ seasonId, fieldId }: Props) => {
  const { data, loading } = useQuery<ReuseLots, ReuseLotsVariables>(REUSE_LOTS, {
    variables: {
      seasonId: seasonId!,
      fieldId: fieldId!,
    },
    skip: !fieldId || !seasonId,
  })

  return { reusableLots: data?.lotsV2.results, loading }
}
