import { gql } from '@apollo/client'

export type { CompanyFields, CompanyFieldsVariables } from './types/CompanyFields'

export const COMPANY_FIELDS = gql`
  query CompanyFields($id: Int!) {
    company(id: $id) {
      id
      usersRoles {
        role
        user {
          id
        }
      }
      fields {
        id
      }
    }
  }
`
