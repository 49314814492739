import { Typography, Space, Col } from 'antd'
import styled from 'styled-components'

export { ChangePasswordModal } from './ChangePasswordModal'

const { Title } = Typography

export const Description = styled(Title)`
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 26px !important;
  margin-bottom: 22px !important;
`

export const InputsCol = styled(Col)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ButtonsContainer = styled(Space).attrs({
  size: 20,
})`
  width: 100%;
  margin-top: 22px;
  justify-content: flex-end;
`
