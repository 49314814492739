import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px !important;
  font-weight: 600 !important;
`

export const IconText: React.FC<{ letter: string }> = ({ letter }) => {
  return <Text>{letter}</Text>
}
