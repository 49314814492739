import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'
import { ConversionUtils } from 'utils'
import { CountryId } from 'types'

import { Modal, Description } from '../components'
import { ClientForm } from './components'
import { useCreateOrganizationCompany } from '../../../hooks'

interface Props {
  onSuccess: () => void
  onCancel: () => void
}

export const CreateClientModal: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('company-settings')
  const {
    createCompany,
    loading,
    control,
    handleSubmit,
    selectedRegion,
    subregionOptions,
    commercialExecutivesOptions,
    permissions,
    user,
    regionsOptions,
    isCommercialExecutivesLoading,
    isRegionsLoading,
    isSubregionsLoading,
    isCommercialExecutiveSelected,
  } = useCreateOrganizationCompany({ onSuccess })

  return (
    <Modal
      title={t('newCompany.agrosalesModalTitle')}
      open
      footer={null}
      onCancel={onCancel}
      centered
    >
      <Description level={5}>{t('newCompany.agrosalesDescription')}</Description>
      <ClientForm
        control={control}
        selectedRegionId={selectedRegion}
        subregionOptions={subregionOptions ?? []}
        commercialExecutiveId={permissions.isCommercialUser ? user.id : undefined}
        commercialExecutivesOptions={commercialExecutivesOptions ?? []}
        organizationCountry={user.organizationRole?.organization.country.id ?? ''}
        countryIsoCode={user.organizationRole?.organization.country.isoCode.toLowerCase()}
        regionOptions={regionsOptions ?? []}
        isCommercialExecutivesLoading={isCommercialExecutivesLoading}
        isRegionsLoading={isRegionsLoading}
        isSubregionsLoading={isSubregionsLoading}
        isCommercialExecutiveSelected={isCommercialExecutiveSelected}
      />
      <Row justify="end">
        <Button
          type="primary"
          loading={loading}
          onClick={handleSubmit(
            ({
              companyName,
              country,
              governmentId,
              subregion,
              commercialExecutiveId,
              phoneNumber,
            }) =>
              createCompany(
                {
                  name: companyName,
                  country: country as CountryId,
                  organizationId: user.organizationRole?.organization.id,
                  governmentId: governmentId
                    ? ConversionUtils.keepOnlyNumbers(governmentId)
                    : undefined,
                  subregionId: subregion,
                  phoneNumber: phoneNumber ? `+${phoneNumber}` : undefined,
                },
                permissions.isOrganizationAdmin && commercialExecutiveId
                  ? commercialExecutiveId
                  : user.id,
              ),
          )}
        >
          {t('buttons.createClient')}
        </Button>
      </Row>
    </Modal>
  )
}
