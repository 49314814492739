import { gql } from '@apollo/client'

export type {
  RequestPasswordChange,
  RequestPasswordChangeVariables,
} from './types/RequestPasswordChange'

export const REQUEST_PASSWORD_CHANGE = gql`
  mutation RequestPasswordChange($email: String!, $locale: String) {
    requestPasswordChange(email: $email, locale: $locale) {
      success
    }
  }
`
