import { notification } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'

import { HiddenEyeIcon } from 'assets/icons'
import { Loader } from 'components'
import { MiscUtils } from 'utils'
import { FILE_EXTENSIONS } from 'consts'
import { useUpdateLot } from 'hooks'

import {
  Container,
  Box,
  MenuItem,
  ItemContainer,
  List,
  ListItem,
  Upload,
  Text,
  HeaderText,
  StyledUploadIcon,
} from './components'
import { LotRtkItem } from './types'

interface Props {
  lots: LotRtkItem[]
  showRtk: boolean
  isRtkLoading: boolean
  toggleShowRtk: (show?: boolean) => void
}
export const RtkDropdown: React.FC<Props> = ({ lots, showRtk, isRtkLoading, toggleShowRtk }) => {
  const { t } = useTranslation('map')

  const { updateLot, loading: isLoading } = useUpdateLot()

  const [lotIdUpdated, setLotIdUpdated] = useState<number>()

  const handleRtkKml = async ({
    uploadFile,
    lotId,
  }: {
    uploadFile: UploadFile<File>
    lotId: number
  }) => {
    try {
      const file = uploadFile as unknown as File
      if (!MiscUtils.checkFileExtension(file.name, FILE_EXTENSIONS.KML)) {
        throw new Error()
      }

      setLotIdUpdated(lotId)
      updateLot({ variables: { updateLotDto: { id: lotId, rtkFile: file } } })
      toggleShowRtk(true)
    } catch (err) {
      notification.error({
        message: t('tools.uploadKMLErrorText'),
      })
    }
  }

  return (
    <Container>
      <Box>
        <HeaderText>{t('tools.rtkDescription')}</HeaderText>
      </Box>
      <MenuItem $isActive={!showRtk} $disabled={isRtkLoading}>
        <ItemContainer onClick={isRtkLoading ? undefined : () => toggleShowRtk()}>
          <HiddenEyeIcon />
          <Text>{t('tools.rtkHideButton')}</Text>
          {isRtkLoading && <Loader />}
        </ItemContainer>
      </MenuItem>
      <List>
        {lots.map(lot => (
          <ItemContainer key={lot.id}>
            <ListItem $isActive={lot.hasRtk}>
              <Text>{lot.name}</Text>
              <Upload
                beforeUpload={() => false}
                onChange={({ file }: UploadChangeParam<UploadFile>) =>
                  handleRtkKml({ lotId: lot.id, uploadFile: file })
                }
                showUploadList={false}
                accept=".kml"
              >
                {lotIdUpdated === lot.id && isLoading ? (
                  <Loader />
                ) : (
                  <StyledUploadIcon color="white" />
                )}
              </Upload>
            </ListItem>
          </ItemContainer>
        ))}
      </List>
    </Container>
  )
}
