import styled from 'styled-components'
import { Col, Space } from 'antd'

export { Chart } from './Chart'
export { NotAvailableText } from './NotAvailableText'

interface BallProps {
  color: string
}

export const Ball = styled.div<BallProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

export const StyledSpace = styled(Space)`
  & .ant-space-item {
    height: 20px;
  }
  & span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.colors.olive};
  }
`

export const CenterCol = styled(Col)`
  display: flex;
  align-items: center;
`

export const ChartContainer = styled(Col)`
  height: 125px;
  width: 125px;
`
