/* eslint-disable no-nested-ternary */
import { Col, Row, Space } from 'antd'
import { ShopOutlined, CheckOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { ActiveCompanyContext } from 'contexts'
import { EllipsisTitle } from 'components'
import { UserUserCompanyRole } from 'api/user'
import { UserCompanyRole } from 'types'

import { Container, EditButton, FullWidthRow, Image, ListItem, LogoContainer } from './components'

interface Data {
  onItemActionClick: (company: { id: number; isAdmin: boolean }) => void
  companyRole?: UserUserCompanyRole
}

interface Props {
  data: Data
  style: React.CSSProperties
}

export const VirtualizedListItem: React.FC<Props> = ({ data, style }) => {
  const { t } = useTranslation('navbar')
  const { t: commonT } = useTranslation('common')
  const { colors } = useTheme()
  const { activeCompanyId, setActiveCompany, setActiveSeason } =
    ActiveCompanyContext.useActiveCompanyContext()

  const { onItemActionClick, companyRole } = data

  if (!companyRole) return null

  const { company, role } = companyRole

  const isAnActiveCompany = company.id === activeCompanyId

  return (
    <div style={style} key={company.id}>
      <Container>
        <ListItem
          onClick={() => {
            if (activeCompanyId === company.id) return
            setActiveSeason(undefined)
            setActiveCompany({
              id: company.id,
              countryId: company.country.id,
              name: company.name,
            })
          }}
        >
          <FullWidthRow wrap={false}>
            <Col>
              <Space>
                <LogoContainer $isActive={isAnActiveCompany}>
                  {company.organization?.id ? (
                    isAnActiveCompany && (
                      <CheckOutlined style={{ fontSize: '25px', color: 'white' }} />
                    )
                  ) : company.logo ? (
                    <Image src={company.logo} alt={commonT('misc.logoImageAlt')} />
                  ) : (
                    <ShopOutlined style={{ color: 'white' }} />
                  )}
                </LogoContainer>
                <Row>
                  <Col>
                    <EllipsisTitle
                      level={5}
                      title={company.name}
                      overlayInnerStyle={{
                        color: colors.white,
                        width: '140px',
                        fontSize: '14px',
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <EditButton
                      onClick={e => {
                        e.stopPropagation()
                        onItemActionClick({
                          id: company.id,
                          isAdmin: role === UserCompanyRole.ADMIN,
                        })
                      }}
                    >
                      {t('user.menu.companies.settingsButtonText')}
                    </EditButton>
                  </Col>
                </Row>
              </Space>
            </Col>
          </FullWidthRow>
        </ListItem>
      </Container>
    </div>
  )
}
