/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import {
  CreateRicePhytosanitaryVariables,
  CreateRicePhytosanitary,
  CREATE_RICE_PHYTOSANITARY,
  LotDashboard,
  LotDashboardVariables,
  LOT_DASHBOARD,
} from '../api'

export const useCreateRicePhytosanitary = () => {
  const [createRicePhytosanitary, { loading }] = useMutation<
    CreateRicePhytosanitary,
    CreateRicePhytosanitaryVariables
  >(CREATE_RICE_PHYTOSANITARY, {
    update: (cache, { data: response }, { variables }) => {
      if (!variables) return
      if (!response) return

      const firstCreatedRicePhytosanitary = variables.createPhytosanitaryDTO
      const { riceLotId } = firstCreatedRicePhytosanitary

      const cachedLot = cache.readQuery<LotDashboard, LotDashboardVariables>({
        query: LOT_DASHBOARD,
        variables: { id: riceLotId, isSatelliteImageryFlagOn: true },
      })

      const newAppliedRicePhytosanitary = response.createRicePhytosanitary.phytosanitary
      cache.writeQuery<LotDashboard, LotDashboardVariables>({
        query: LOT_DASHBOARD,
        variables: { id: riceLotId, isSatelliteImageryFlagOn: true },
        data: {
          lot: {
            ...cachedLot?.lot!,
            riceLot: {
              ...cachedLot?.lot.riceLot!,
              appliedPhytosanitaries: cachedLot?.lot.riceLot.appliedPhytosanitaries
                ? [...cachedLot?.lot.riceLot.appliedPhytosanitaries, newAppliedRicePhytosanitary]
                : [newAppliedRicePhytosanitary],
              __typename: 'RiceLot',
            },
            __typename: 'LotV2',
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  return { createRicePhytosanitary, loading }
}
