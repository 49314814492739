import { useTranslation } from 'react-i18next'

const MILLION = 1000000
const THOUSAND = 1000
export const useFormatNumbersWithScale = () => {
  const { t } = useTranslation('common')

  const formatNumberWithScale = (value: number) => {
    if (value >= MILLION)
      return {
        value: (value / MILLION).toFixed(1),
        scale: t('scales.millions'),
      }
    return value >= THOUSAND
      ? {
          value: (value / THOUSAND).toFixed(),
          scale: t('scales.thousands'),
        }
      : { value: value.toString(), scale: '' }
  }

  return { formatNumberWithScale }
}
