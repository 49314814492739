import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'

export { CreateCompanyModal, CreateClientModal } from './create-company-modals'
export { CompanyCreationFeedbackModal } from './CompanyCreationFeedbackModal'
export { Indicator } from './Indicator'
export { AreaTooltip } from './AreaTooltip'

export const Layout = styled(AntLayout)`
  height: 100%;
`

export const Content = styled(AntLayout.Content)`
  position: relative;
`

export const Sider = styled(AntLayout.Sider)`
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]} !important;
`
