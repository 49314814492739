import { Col } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ImageFileIcon } from 'assets/icons'

const UploadContainer = styled(Col)`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
`

interface Props {
  image?: string
}

export const SmallUploadBoxV2: React.FC<Props> = ({ image }) => {
  const { t } = useTranslation('common')

  return (
    <UploadContainer>
      {image ? <Image src={image} alt={t('misc.logoImageAlt')} /> : <ImageFileIcon />}
    </UploadContainer>
  )
}
