/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CountryId {
  ARGENTINA = "ARGENTINA",
  BRAZIL = "BRAZIL",
  COLOMBIA = "COLOMBIA",
  DOMINICAN_REPUBLIC = "DOMINICAN_REPUBLIC",
  PANAMA = "PANAMA",
  PARAGUAY = "PARAGUAY",
  UNITED_STATES = "UNITED_STATES",
  URUGUAY = "URUGUAY",
}

export enum DeliveryCondition {
  DRY = "DRY",
  WET = "WET",
}

export enum FeatureSet {
  GRUPO_DIANA = "GRUPO_DIANA",
}

export enum IndicatorStatus {
  BAD = "BAD",
  GOOD = "GOOD",
  MEDIUM = "MEDIUM",
}

export enum IrrigationWorkersAccessMode {
  ASSIGNED_LOT = "ASSIGNED_LOT",
  FIELD = "FIELD",
}

export enum MilestoneUpdateType {
  AUTOMATIC_CONVERSION = "AUTOMATIC_CONVERSION",
  AUTOMATIC_SENTINEL1 = "AUTOMATIC_SENTINEL1",
  MANUAL = "MANUAL",
}

export enum OrganizationCrmModuleFlag {
  AUTOMATED_MILESTONES = "AUTOMATED_MILESTONES",
  COMMERCIAL_AGENDA = "COMMERCIAL_AGENDA",
}

export enum OrganizationGeneralModuleFlag {
  FIELD_ENTITY = "FIELD_ENTITY",
  SATELLITE_IMAGERY = "SATELLITE_IMAGERY",
}

export enum ProductionType {
  CONVENTIONAL = "CONVENTIONAL",
  ORGANIC = "ORGANIC",
}

export enum RiceLotStatus {
  BURNING = "BURNING",
  DIFFERENTIATION = "DIFFERENTIATION",
  EAR_APPEARANCE = "EAR_APPEARANCE",
  EMERGENCE = "EMERGENCE",
  END_IRRIGATION = "END_IRRIGATION",
  FLOWERING = "FLOWERING",
  HARVEST = "HARVEST",
  INITIAL_FLOOD = "INITIAL_FLOOD",
  PHYSIOLOGICAL_MATURITY = "PHYSIOLOGICAL_MATURITY",
  PLANTING = "PLANTING",
  PREPARATION = "PREPARATION",
  PRE_EAR_APPEARANCE = "PRE_EAR_APPEARANCE",
  START_IRRIGATION = "START_IRRIGATION",
  VEGETATIVE_STATE_THREE = "VEGETATIVE_STATE_THREE",
}

export enum SeasonMillDeliveryType {
  NO_DELIVERY = "NO_DELIVERY",
  PARTIAL = "PARTIAL",
  TOTAL = "TOTAL",
}

export enum SentinelHubProvider {
  LANDSAT89 = "LANDSAT89",
  LANDSAT89_PLANET_FUSION = "LANDSAT89_PLANET_FUSION",
  PLANET = "PLANET",
  SENTINEL1 = "SENTINEL1",
  SENTINEL2 = "SENTINEL2",
  SENTINEL2_PLANET_FUSION = "SENTINEL2_PLANET_FUSION",
}

export enum UserCompanyRole {
  ADMIN = "ADMIN",
  IRRIGATION_WORKER = "IRRIGATION_WORKER",
  VIEWER = "VIEWER",
}

export enum UserOrganizationRole {
  ADMIN = "ADMIN",
  COMMERCIAL = "COMMERCIAL",
  MILL = "MILL",
}

export enum WeatherType {
  CIRRUS = "CIRRUS",
  CLEAR = "CLEAR",
  CLOUDY = "CLOUDY",
  PARTIALLY_CLOUDED = "PARTIALLY_CLOUDED",
  PARTIALLY_CLOUDED_WITH_CIRRUS = "PARTIALLY_CLOUDED_WITH_CIRRUS",
}

export interface CreateCompanyInput {
  name: string;
  logo?: Upload | null;
  country: CountryId;
  subregionId?: number | null;
  organizationId?: number | null;
  governmentId?: string | null;
  phoneNumber?: string | null;
}

export interface CreateDamDTO {
  name: string;
  area: PolygonInput;
}

export interface CreateDamInput {
  name: string;
  area: PolygonInput;
  fieldId: number;
}

export interface CreateFieldInput {
  name: string;
  location: PointInput;
  directions?: string | null;
  companyId: number;
  productionType?: ProductionType | null;
  deliveryCondition?: DeliveryCondition | null;
  createDamsDTOs?: CreateDamDTO[] | null;
}

export interface CreateLotInput {
  name: string;
  commercialName?: string | null;
  area?: PolygonInput | null;
  size?: number | null;
  seasonId: number;
  fieldId: number;
  previousCropId?: number | null;
  cropId: number;
  varietyId?: number | null;
  rtk?: MultiLineStringInput | null;
  templateLotId?: number | null;
}

export interface CreateRiceAppliedFertilizerInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  riceLotId: number;
  fertilizerGrades?: FertilizerGradeInput[] | null;
}

export interface CreateRiceAppliedPhytosanitaryInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  riceLotId: number;
  activeIngredients?: number[] | null;
}

export interface CreateSeasonInput {
  name: string;
  startDate: DateTime;
  endDate?: DateTime | null;
  description?: string | null;
  companyId: number;
  productionToMill?: number | null;
  millDeliveryType?: string | null;
}

export interface CreateUserInput {
  email: string;
  password: string;
  name: string;
  lastName: string;
  profilePicture?: Upload | null;
}

export interface FertilizerGradeInput {
  grade?: number | null;
  nutrientId?: number | null;
}

export interface InviteUserInput {
  email: string;
  role: UserCompanyRole;
  companyId: number;
}

export interface MultiLineStringInput {
  type: string;
  coordinates: number[][][];
}

export interface PointInput {
  type: string;
  coordinates: number[];
}

export interface PolygonInput {
  type: string;
  coordinates: number[][][];
}

export interface UpdateCompanyInput {
  name?: string | null;
  logo?: Upload | null;
  subregionId?: number | null;
  organizationId?: number | null;
  governmentId?: string | null;
  phoneNumber?: string | null;
  irrigationWorkersAccessMode?: IrrigationWorkersAccessMode | null;
}

export interface UpdateDamInput {
  id: number;
  name?: string | null;
  area?: PolygonInput | null;
}

export interface UpdateFieldInput {
  name?: string | null;
  location?: PointInput | null;
  directions?: string | null;
  productionType?: ProductionType | null;
  deliveryCondition?: DeliveryCondition | null;
  createDamsDTOs?: CreateDamDTO[] | null;
}

export interface UpdateLotInput {
  name?: string | null;
  commercialName?: string | null;
  area?: PolygonInput | null;
  size?: number | null;
  previousCropId?: number | null;
  cropId?: number | null;
  varietyId?: number | null;
  rtk?: MultiLineStringInput | null;
  id: number;
  rtkFile?: Upload | null;
  irrigationWorkerId?: number | null;
  abandonedSize?: number | null;
  deliveryMillId?: number | null;
}

export interface UpdateRiceAppliedFertilizerInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  fertilizerGrades?: FertilizerGradeInput[] | null;
}

export interface UpdateRiceAppliedPhytosanitaryInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  activeIngredients?: number[] | null;
}

export interface UpdateRiceLotInput {
  plantingDate?: DateTime | null;
  emergenceDate?: DateTime | null;
  firstIrrigationStartDate?: DateTime | null;
  firstIrrigationEndDate?: DateTime | null;
  differentiationDate?: DateTime | null;
  floweringDate?: DateTime | null;
  physiologicalMaturityDate?: DateTime | null;
  harvestDate?: DateTime | null;
  yield?: number | null;
  comment?: string | null;
  preparationDate?: DateTime | null;
  burningDate?: DateTime | null;
  earAppearanceDate?: DateTime | null;
  estimatedYield?: number | null;
}

export interface UpdateSeasonInput {
  name?: string | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  description?: string | null;
  companyId?: number | null;
  productionToMill?: number | null;
  millDeliveryType?: string | null;
}

export interface UpdateUserInput {
  password?: string | null;
  name?: string | null;
  lastName?: string | null;
  profilePicture?: Upload | null;
  isVerified?: boolean | null;
  actualPassword?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
