import styled from 'styled-components'
import { Typography } from 'antd'

const { Text: AntText } = Typography

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
  background-color: ${({ theme }) => theme.colors.new.baseColor[600]};
  padding: 2px 6px;
`

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
`

interface Props {
  text: string
  containerStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
}
export const TagText: React.FC<Props> = ({ text, containerStyle, textStyle }) => {
  return (
    <TagContainer style={containerStyle}>
      <Text style={textStyle}>{text}</Text>
    </TagContainer>
  )
}
