import styled from 'styled-components'
import { Typography } from 'antd'

import { Modal as ModalBase } from 'components'

const { Title } = Typography

export const Modal = styled(ModalBase)`
  width: 700px !important;
  height: 340px !important;
`

export const Description = styled(Title)`
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 26px !important;
`
