import { Row, Space, Typography } from 'antd'
import styled from 'styled-components'

export const Container = styled(Row)`
  flex-direction: column;
`

export const Header = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
`

export const Body = styled(Space).attrs({
  direction: 'vertical',
  size: 8,
})`
  padding: 16px;
  justify-content: space-between;
`

export const FullWidthRow = styled(Row)`
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  width: 100%;
`

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  font-size: 11px !important;
  font-family: Inter;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`
