import { StoreObject, useMutation } from '@apollo/client'

import { ActiveCompanyContext } from 'contexts'
import { CreateCompanyInput, UserCompanyRole } from 'types'
import { GraphQLUtils } from 'utils'
import { useUser } from 'hooks'
import { User, UserUserCompanyRole } from 'api/user'

import { CreateCompany, CreateCompanyVariables, CREATE_COMPANY } from './api'

export const useCreateCompany = (onSuccess: () => void) => {
  const { setActiveCompany } = ActiveCompanyContext.useActiveCompanyContext()
  const user = useUser()

  const [createCompanyMutation, mutationResult] = useMutation<
    CreateCompany,
    CreateCompanyVariables
  >(CREATE_COMPANY, {
    onCompleted: data => {
      const companyId = data.createCompany.company.id
      const countryId = data.createCompany.company.country.id
      const companyLanguage = data.createCompany.company.country.code
      const companyName = data.createCompany.company.name
      setActiveCompany({ id: companyId, countryId, language: companyLanguage, name: companyName })
      onSuccess()
    },
    update: (cache, { data: response }) => {
      if (!response) return

      cache.modify<User['user']>({
        // Workaraound because of this: https://github.com/apollographql/apollo-client/issues/7577
        id: cache.identify(user as unknown as StoreObject),
        fields: {
          companiesRoles(cachedCompaniesRoles) {
            const newCompanyRole = {
              role: UserCompanyRole.ADMIN,
              company: response.createCompany.company,
              __typename: 'CompanyRole',
            }
            if (!cachedCompaniesRoles) return [newCompanyRole] as UserUserCompanyRole[]
            return [...cachedCompaniesRoles, newCompanyRole] as UserUserCompanyRole[]
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  const createCompany = (companyDTO: CreateCompanyInput, founderId: number) => {
    createCompanyMutation({ variables: { companyDTO, founderId } })
  }

  return { createCompany, ...mutationResult }
}
