import styled from 'styled-components'
import { Col, Menu, Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'

const MenuContainer = styled(Menu)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: none;
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-selected {
    background-color: transparent !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey1} !important;
      span {
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
  .ant-dropdown-menu-item {
    display: flex;
    padding: 0 15px;
    align-items: center;
    margin: 0 !important;
    height: 52px !important;
  }
`

const UploaderTextContainer = styled.div``

interface Props {
  onAddDam: () => void
  handleKml: (file: UploadChangeParam<UploadFile>) => void
}

export const DropdownMenu: React.FC<Props> = ({ onAddDam, handleKml }) => {
  const { t } = useTranslation('field-settings')

  const dataSource = [
    {
      key: 'draw',
      label: <Col>{t('drawDamButtonText')}</Col>,
      onClick: onAddDam,
    },
    {
      key: 'kml',
      label: (
        <Upload
          beforeUpload={() => false}
          onChange={handleKml}
          showUploadList={false}
          accept=".kml"
        >
          <UploaderTextContainer>{t('uploadKMLText')}</UploaderTextContainer>
        </Upload>
      ),
    },
  ]

  return <MenuContainer items={dataSource} />
}
