import { Col, Select as AntSelect, Space, Tooltip, Typography, SelectProps } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  CameraIcon,
  ChlorophyllIcon,
  DropIcon,
  GrainHumidityIndicatorIcon,
  WaterIcon,
  WeedsIcon,
} from 'assets/icons'
import { MapIndex } from 'types'

interface StyledProps {
  $width?: string
}

export const Select = styled(AntSelect)<StyledProps>`
  width: ${({ $width }) => $width || '265px'};
  & .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    height: 48px !important;
    border-radius: ${({ theme }) => theme.foundation.smallRadius} !important;
    font-size: 13px;
    border: none !important;
    box-shadow: none !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
` as unknown as React.FC<SelectProps & StyledProps> & { Option: typeof AntSelect.Option }

export const StyledSpace = styled(Space)`
  width: 100%;
`

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px !important;
`
export const IconContainer = styled(Col)`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  grainHumidityHasNotEmergenceDaysActivation: boolean
  isWaterAvailable: boolean
  onChange: (value: MapIndex) => void
  selectedMapIndex: MapIndex
  bordered?: boolean
  width?: string
  isGrainHumidityAvailable: boolean
  isWeedsAvailable: boolean
}

export const SatelliteInformationSelect: React.FC<Props> = ({
  grainHumidityHasNotEmergenceDaysActivation,
  onChange,
  selectedMapIndex,
  bordered,
  width,
  isWaterAvailable,
  isGrainHumidityAvailable,
  isWeedsAvailable,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation('map')

  const dataSource = [
    {
      Logo: DropIcon,
      title: t('indicators.irrigation'),
      mapIndex: 'irrigation' as MapIndex,
    },
    {
      Logo: WaterIcon,
      title: t('indicators.water'),
      mapIndex: 'water' as MapIndex,
      showIndex: true,
      isNotAvailable: !isWaterAvailable,
    },
    {
      Logo: ChlorophyllIcon,
      title: t('indicators.chlorophyll'),
      mapIndex: 'chlorophyll' as MapIndex,
    },
    {
      Logo: CameraIcon,
      title: t('indicators.realImage'),
      mapIndex: 'realImage' as MapIndex,
    },
    {
      Logo: GrainHumidityIndicatorIcon,
      title: t('indicators.grainHumidity'),
      mapIndex: 'grainHumidity' as MapIndex,
      disabled: !isGrainHumidityAvailable,
      tooltipText: t('indicators.tooltips.isGrainHumidityNotAvailable', {
        context:
          grainHumidityHasNotEmergenceDaysActivation &&
          'grainHumidityHasNotEmergenceDaysActivation',
      }),
    },
    {
      Logo: WeedsIcon,
      title: t('indicators.weeds'),
      mapIndex: 'weeds' as MapIndex,
      disabled: !isWeedsAvailable,
    },
  ]

  return (
    <Select
      onChange={onChange}
      value={selectedMapIndex}
      bordered={bordered}
      popupClassName="custom-select-dropdown"
      $width={width}
      listHeight={400}
    >
      {dataSource.map(data => {
        const { Logo, title, disabled, mapIndex, tooltipText, isNotAvailable } = data

        return (
          !isNotAvailable && (
            <Select.Option key={mapIndex} value={mapIndex} disabled={disabled}>
              {disabled ? (
                <Tooltip title={tooltipText} placement="left" overlayStyle={{ maxWidth: '310px' }}>
                  <Space size={10}>
                    <IconContainer>
                      <Logo color={colors.white} />
                    </IconContainer>
                    <Text>{title}</Text>
                  </Space>
                </Tooltip>
              ) : (
                <StyledSpace size={10}>
                  <IconContainer>
                    <Logo color={colors.white} />
                  </IconContainer>
                  <Text>{title}</Text>
                </StyledSpace>
              )}
            </Select.Option>
          )
        )
      })}
    </Select>
  )
}
