import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import { ActiveCompanyContext } from 'contexts'
import { GraphQLUtils } from 'utils'
import { DATE_FORMATS } from 'consts'
import { usePermissions } from 'hooks'

import { Season, Seasons, SeasonsSettingsVariables, SEASONS_SETTINGS } from '../../../api'
import {
  CreateSeason,
  CreateSeasonVariables,
  CREATE_SEASON,
  DeleteSeason,
  DeleteSeasonVariables,
  DELETE_SEASON,
} from '../api'

// The min quantity of seasons to enable the delete button
const MIN_SEASONS_QTY_FOR_ENABLE_DELETION = 1

export const useSeasonsSettings = () => {
  const navigate = useNavigate()
  const { permissions } = usePermissions()

  const getNewSeasonName = () => {
    const today = dayjs()
    const currentYear = today.year()

    if (permissions.isFeatureSetGrupoDiana) {
      const MID_YEAR_MONTH = 6
      const FIRST_SEMESTER = 'A'
      const SECOND_SEMESTER = 'B'
      const semester = today.month() >= MID_YEAR_MONTH ? SECOND_SEMESTER : FIRST_SEMESTER
      return `${currentYear} ${semester}`
    }

    return currentYear.toString()
  }

  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const [selectedSeasonToDelete, setSelectedSeasonToDelete] = useState<Season>()
  const [showDeleteSeasonModal, setShowDeleteSeasonModal] = useState(false)

  const { data: seasonsData, loading: loadingSeasons } = useQuery<
    Seasons,
    SeasonsSettingsVariables
  >(SEASONS_SETTINGS, {
    variables: { companyId: activeCompanyId! },
    skip: !activeCompanyId,
  })

  const [createSeason, { loading: createSeasonLoading }] = useMutation<
    CreateSeason,
    CreateSeasonVariables
  >(CREATE_SEASON, {
    update: (cache, { data: response }) => {
      if (!response || !activeCompanyId) return

      const cachedSeasons = cache.readQuery<Seasons, SeasonsSettingsVariables>({
        query: SEASONS_SETTINGS,
        variables: { companyId: activeCompanyId },
      })

      const newSeason = response.createSeason.season
      const seasons: Seasons['seasons'] = cachedSeasons
        ? { ...cachedSeasons.seasons, results: [...cachedSeasons.seasons.results, newSeason] }
        : { results: [newSeason], __typename: 'Seasons' }

      cache.writeQuery<Seasons, SeasonsSettingsVariables>({
        query: SEASONS_SETTINGS,
        data: { seasons },
        variables: { companyId: activeCompanyId },
      })
    },
    onCompleted: data => navigate(`season/${data.createSeason.season.id}`),
    onError: GraphQLUtils.errorHandler,
  })

  const [deleteSeason, { loading: deleteSeasonLoading }] = useMutation<
    DeleteSeason,
    DeleteSeasonVariables
  >(DELETE_SEASON, {
    update: async cache => {
      const cachedSeasons = cache.readQuery<Seasons>({
        query: SEASONS_SETTINGS,
        variables: { companyId: activeCompanyId },
      })

      if (!cachedSeasons) return

      const filteredSeasons = cachedSeasons.seasons.results.filter(
        season => season.id !== selectedSeasonToDelete?.id,
      )

      cache.writeQuery({
        query: SEASONS_SETTINGS,
        data: { seasons: { ...cachedSeasons, results: filteredSeasons } },
        variables: { companyId: activeCompanyId },
      })
    },
    onCompleted: () => {
      setSelectedSeasonToDelete(undefined)
      setShowDeleteSeasonModal(false)
    },
    onError: GraphQLUtils.errorHandler,
  })

  const onAddSeason = () => {
    if (!activeCompanyId) return
    createSeason({
      variables: {
        seasonDTO: {
          companyId: activeCompanyId,
          name: getNewSeasonName(),
          startDate: dayjs().format(DATE_FORMATS.DATE_WITH_SEPARATOR),
        },
      },
    })
  }

  const onEditSeason = (id: number) => navigate(`season/${id}`)

  const onDeleteSeason = (id: number) => {
    const selectedSeason = seasonsData?.seasons.results.find(season => season.id === id)
    if (!selectedSeason) return
    setSelectedSeasonToDelete(selectedSeason)
    setShowDeleteSeasonModal(true)
  }

  const onConfirmDeleteSeason = () => {
    if (!selectedSeasonToDelete) return
    deleteSeason({ variables: { id: selectedSeasonToDelete.id } })
  }

  const onCancelDeleteSeason = () => {
    setSelectedSeasonToDelete(undefined)
    setShowDeleteSeasonModal(false)
  }

  const seasons = useMemo(() => {
    if (!seasonsData?.seasons.results.length) return []
    if (seasonsData.seasons.results.length > MIN_SEASONS_QTY_FOR_ENABLE_DELETION)
      return seasonsData?.seasons.results

    return seasonsData?.seasons.results.map(season => {
      return {
        ...season,
        isDisabled: true,
      }
    })
  }, [seasonsData])

  return {
    selectedSeasonToDelete,
    showDeleteSeasonModal: showDeleteSeasonModal && !!selectedSeasonToDelete,
    seasons,
    loadingSeasons,
    createSeasonLoading,
    deleteSeasonLoading,
    onAddSeason,
    onEditSeason,
    onDeleteSeason,
    onConfirmDeleteSeason,
    onCancelDeleteSeason,
  }
}
