import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UPDATE_DAMS, UpdateDams, UpdateDamsVariables } from './useDams/api'

export const useUpdateDams = () => {
  const [updateDams, { loading }] = useMutation<UpdateDams, UpdateDamsVariables>(UPDATE_DAMS, {
    onError: GraphQLUtils.errorHandler,
  })

  return { updateDams, loading }
}
