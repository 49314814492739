import { Col, Row } from 'antd'

import { MapImage } from '../../../../../components'
import { HarvestDateCard, YieldCard } from './components'

interface Props {
  daysToHarvestFromEmergence?: number
  densitySurfaceYield?: number
  lotCoordinates?: number[][]
  fieldId?: number
  lotId?: number
}

export const LeftCardsContainer: React.FC<Props> = ({
  daysToHarvestFromEmergence,
  densitySurfaceYield,
  fieldId,
  lotId,
  lotCoordinates,
}) => {
  return (
    <Row gutter={[0, 24]}>
      <Col flex={1}>
        <MapImage
          fieldId={fieldId}
          lotId={lotId}
          lotCoordinates={lotCoordinates}
          width="100%"
          height="130px"
        />
      </Col>
      <Col flex={1}>
        <HarvestDateCard daysToHarvestFromEmergence={daysToHarvestFromEmergence} />
      </Col>
      <Col flex={1}>
        <YieldCard densitySurfaceYield={densitySurfaceYield} />
      </Col>
    </Row>
  )
}
