import { Select as AntSelect } from 'antd'
import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Lot } from '../../hooks/useLots/api'

const Select = styled(AntSelect)`
  min-width: 206px;
` as typeof AntSelect

interface Props {
  fieldId?: number
  defaultId?: number
  onChange: (lots: Lot[]) => void
  className?: string
  lots: Lot[]
  loading: boolean
  dropdownClassName?: string
  popupMatchSelectWidth?: boolean
  value?: number
  disabled?: boolean
}

const ONLY_ONE_LOT = 1

export const LotsSelect: React.FC<Props> = ({
  fieldId,
  defaultId,
  onChange,
  className,
  loading,
  lots,
  dropdownClassName,
  popupMatchSelectWidth,
  value,
  disabled,
}) => {
  const { t } = useTranslation('map')

  const initLotsEffectCalled = useRef(false)

  useEffect(() => {
    const selectedLot = lots.find(lot => lot.id === defaultId)

    if (!selectedLot || initLotsEffectCalled.current) return
    onChange([selectedLot])
    initLotsEffectCalled.current = true
  }, [onChange, lots, defaultId])

  const defaultValue =
    // eslint-disable-next-line no-nested-ternary
    (loading && !lots.length) || !fieldId
      ? undefined
      : lots.length > ONLY_ONE_LOT
      ? value ?? 'all-lots'
      : lots[0]?.id

  return (
    <Select
      popupClassName={dropdownClassName}
      popupMatchSelectWidth={popupMatchSelectWidth}
      loading={loading}
      className={className}
      defaultValue={defaultValue}
      value={value ?? 'all-lots'}
      key={`${fieldId}-${defaultValue}`}
      disabled={disabled}
      onChange={id => {
        const selectedLot = lots.find(lot => lot.id === id)
        if (!selectedLot) {
          onChange(lots)
          return
        }

        onChange([selectedLot])
      }}
    >
      {(lots.length > ONLY_ONE_LOT || loading) && (
        <Select.Option value="all-lots">{t('lotsSelect.allLots')}</Select.Option>
      )}

      {lots.map(lot => (
        <Select.Option key={lot.id} value={lot.id}>
          {lot.name}
        </Select.Option>
      ))}
    </Select>
  )
}
