import { Col, Row } from 'antd'
import styled from 'styled-components'

export { Table } from './Table'
export { ColumnsSelect } from './ColumnsSelect'
export { ExportButton } from './ExportButton'

export const StyledRow = styled(Row)`
  height: 100%;
`

export const FlexCol = styled(Col)`
  display: flex;
  align-items: center;
`
