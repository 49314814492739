import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PlusCircleOutlined } from '@ant-design/icons'

import { LotsMapLocationState } from 'types'
import { ActiveCompanyContext } from 'contexts'

const { Text: AntText } = Typography

const IconContainer = styled(Row)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  z-index: 99;
  height: 100%;
  width: 152px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`

const StyledPlusCircle = styled(PlusCircleOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.new.primary[600]};
`

const Text = styled(AntText)`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.new.primary[600]} !important;
`

const MarginCol = styled(Col)`
  margin-top: 5px;
`

interface Props {
  lotId: number
  fieldId: number
}

export const AddPerimeter: React.FC<Props> = ({ lotId, fieldId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('dashboard')
  const { activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()

  const handleAddPerimeter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    const state: LotsMapLocationState = {
      seasonId: activeSeasonId,
      lotId,
      fieldId,
    }
    return navigate('/settings/lots', {
      state,
    })
  }

  return (
    <IconContainer onClick={handleAddPerimeter}>
      <Col>
        <StyledPlusCircle />
      </Col>
      <MarginCol>
        <Text>{t('addPerimeter')}</Text>
      </MarginCol>
    </IconContainer>
  )
}
