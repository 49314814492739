import { Control } from 'react-hook-form'
import { UnlockOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { FormInput } from 'components'

interface Props extends Pick<React.ComponentProps<typeof FormInput>, 'onKeyUp'> {
  // TODO Fix type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  iconStyle: React.CSSProperties
  size?: 'small' | 'middle' | 'large'
}

export const FormPasswordInput: React.FC<Props> = ({ control, iconStyle, ...restProps }) => {
  const { t } = useTranslation('common')

  return (
    <FormInput
      {...restProps}
      // eslint-disable-next-line i18next/no-literal-string
      name="password"
      placeholder={t('inputs.password')}
      control={control}
      prefixIcon={<UnlockOutlined style={iconStyle} />}
      type="password"
    />
  )
}
