import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { forEach as lodashForEach } from 'lodash'

import { MapContext } from 'features/MainRouter/contexts'
import { DateUtils } from 'utils'
import {
  WeatherDataObject,
  MapIndex,
  CalendarDate,
  CalendarDatesMapIndices,
  MapIndexInfo,
  CalendarDateIndex,
  CalendarDatesMapIndicesWithMultipleProviders,
  SatelliteProviders,
} from 'types'
import { Dam } from 'hooks/useQueryDams/api'

import { Lot } from './useLots/api'
import { IndicesUtils, LotsUtils } from '../utils'
import { PopupInfo } from '../types'

const IMAGE_NOT_AVAILABLE = 'IMAGE_NOT_AVAILABLE'
const indices = [
  'realImage',
  'chlorophyll',
  'water',
  'grainHumidity',
  'irrigation',
  'weeds',
] as MapIndex[]

interface Props {
  selectedLots: Lot[]
  dams?: Dam[]
  isShowDamsModeSelected: boolean
  popupInfo?: PopupInfo
  setPopupInfo: Dispatch<SetStateAction<PopupInfo | undefined>>
}

export const useLotsFeatures = ({
  selectedLots,
  dams,
  isShowDamsModeSelected,
  setPopupInfo,
  popupInfo,
}: Props) => {
  const {
    selectedMapIndex,
    setSelectedMapIndex,
    selectedCalendarDate,
    setSelectedCalendarDate,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    selectedCompareCalendarDate,
    setDates,
    setSelectedCompareCalendarDate,
    setDamDates,
    selectedDamCalendarDate,
    setSelectedDamCalendarDate,
    selectedSatellite,
    setSelectedCompareSatellite,
    setSelectedSatellite,
    selectedCompareSatellite,
  } = MapContext.useMapContext()

  const emergenceDaysActivationIndices = useMemo(
    () => LotsUtils.getEmergenceDaysActivationIndices(selectedLots),
    [selectedLots],
  )

  const getCalendarDateIndices = useCallback(
    (satellite: SatelliteProviders) => {
      const allCalendarDates = selectedLots.map(lot => lot.riceLot.calendar).flat()

      const allCalendarDatesIndices = allCalendarDates.reduce(
        (result: CalendarDatesMapIndicesWithMultipleProviders, calendarDate: CalendarDate) => {
          indices.forEach(mapIndex => {
            const hasWeaterType = calendarDate.mapUrls?.[mapIndex]?.some(
              mapUrl => mapUrl.weatherType,
            )
            if (calendarDate.mapUrls?.[mapIndex] && hasWeaterType) {
              // eslint-disable-next-line no-param-reassign
              result[mapIndex] = [
                ...result[mapIndex],
                {
                  date: calendarDate.date,
                  mapIndexInfo: calendarDate.mapUrls[mapIndex] as MapIndexInfo[],
                },
              ]
            }
          })

          return result
        },
        {
          realImage: [],
          chlorophyll: [],
          water: [],
          grainHumidity: [],
          irrigation: [],
          weeds: [],
        },
      )

      const calendarDatesMapIndices = {
        realImage: [],
        chlorophyll: [],
        water: [],
        grainHumidity: [],
        irrigation: [],
        weeds: [],
      } as CalendarDatesMapIndices

      lodashForEach(allCalendarDatesIndices, (calendar, index) => {
        const mapindex = index as MapIndex
        calendarDatesMapIndices[mapindex] = LotsUtils.removeDuplicatedDatesByIndex(
          calendar,
          satellite,
        )
      })
      return calendarDatesMapIndices
    },
    [selectedLots],
  )

  const calendarDatesIndicesBySelectedSatellite = useMemo(() => {
    const calendarDatesMapIndices = getCalendarDateIndices(selectedSatellite)
    return calendarDatesMapIndices
  }, [getCalendarDateIndices, selectedSatellite])

  const allCalendarDatesIndices = useMemo(() => {
    const calendarDatesMapIndices = getCalendarDateIndices('ALL')
    return calendarDatesMapIndices
  }, [getCalendarDateIndices])

  const compareCalendarDateIndices = useMemo(() => {
    const calendarDatesMapIndices = getCalendarDateIndices(selectedCompareSatellite)
    return calendarDatesMapIndices
  }, [getCalendarDateIndices, selectedCompareSatellite])

  const damsCalendar = useMemo(() => {
    const allCalendarDates = dams?.map(dam => dam.calendar).flat()
    const damsCalendarIndex =
      allCalendarDates?.map(calendarDate => {
        return {
          date: calendarDate.date,
          mapIndexInfo: [{ ...calendarDate.mapUrls.waterSurface }] as MapIndexInfo[],
        }
      }) ?? []
    return LotsUtils.removeDuplicatedDatesByIndex(damsCalendarIndex, 'ALL')
  }, [dams])

  const isGrainHumidityAvailable = useMemo(() => {
    const grainHumidityCalendarDates = allCalendarDatesIndices.grainHumidity
    return !!grainHumidityCalendarDates.length
  }, [allCalendarDatesIndices])

  const isWaterAvailable = useMemo(() => {
    const waterCalendarDates = allCalendarDatesIndices.water
    return !!waterCalendarDates.length
  }, [allCalendarDatesIndices])

  const isWeedsAvailable = useMemo(() => {
    const weedsCalendarDates = allCalendarDatesIndices.weeds
    return !!weedsCalendarDates.length
  }, [allCalendarDatesIndices])

  useEffect(() => {
    const mapIndexAvailability = {
      irrigation: true,
      chlorophyll: true,
      realImage: true,
      grainHumidity: isGrainHumidityAvailable,
      water: isWaterAvailable,
      weeds: isWeedsAvailable,
    }

    const availableMapIndex = IndicesUtils.getAvailableIndex({
      selectedMapIndex,
      mapIndexAvailability,
    })
    setSelectedMapIndex(availableMapIndex)

    const availableCompareMapIndex = IndicesUtils.getAvailableIndex({
      selectedMapIndex: selectedCompareMapIndex,
      mapIndexAvailability,
    })

    setSelectedCompareMapIndex(availableCompareMapIndex)
  }, [
    isGrainHumidityAvailable,
    isWaterAvailable,
    isWeedsAvailable,
    selectedCompareMapIndex,
    selectedMapIndex,
    setSelectedCompareMapIndex,
    setSelectedMapIndex,
  ])

  const grainHumidityHasNotEmergenceDaysActivation = useMemo(
    () =>
      !selectedLots.every(lot => {
        const lotCalendarDatesQty = lot.riceLot.calendar.length
        if (!lotCalendarDatesQty) return true
        return lot.riceLot.calendar[lotCalendarDatesQty - 1]?.mapUrls?.grainHumidity?.[0]
          .emergenceDaysActivation
      }),
    [selectedLots],
  )
  const sortCalendarDates = useCallback(
    (calendarDateA: CalendarDateIndex, calendarDateB: CalendarDateIndex, selectedDate: string) => {
      const dateA = new Date(calendarDateA.date)
      const dateB = new Date(calendarDateB.date)
      const targetDate = new Date(selectedDate)

      const diffA = Math.abs(targetDate.valueOf() - dateA.valueOf())
      const diffB = Math.abs(targetDate.valueOf() - dateB.valueOf())

      return diffA - diffB
    },
    [],
  )

  const getFirstAvailableDate = useCallback(
    (calendar: CalendarDateIndex[], selectedDate?: string) => {
      const availableCalendarDates = calendar.filter(
        calendarDate => calendarDate.mapIndexInfo.isAvailable,
      )
      if (!calendar.length) return undefined

      if (!selectedDate) return availableCalendarDates[0]

      const closestDate = availableCalendarDates.sort((calendarDateA, calendarDateB) =>
        sortCalendarDates(calendarDateA, calendarDateB, selectedDate),
      )

      return closestDate[0]
    },
    [sortCalendarDates],
  )

  useEffect(() => {
    if (isShowDamsModeSelected) return
    const calendarDates = calendarDatesIndicesBySelectedSatellite[selectedMapIndex]
    const firstAvailableDate = getFirstAvailableDate(calendarDates, selectedCalendarDate)
    if (!firstAvailableDate) return

    const firstAvailableDateHasUrls = LotsUtils.dateHasUrls(firstAvailableDate)

    if (firstAvailableDate && !firstAvailableDateHasUrls) {
      const firstLotAvailableDate = DateUtils.parseDate(firstAvailableDate.date)

      if (firstLotAvailableDate) {
        setDates(prevState => ({
          ...prevState,
          fromYearMonth: firstLotAvailableDate,
          toYearMonth: firstLotAvailableDate,
        }))
      }
    }

    const selectedDateIsAvailable = calendarDates.some(
      calendarDate =>
        calendarDate.date === selectedCalendarDate && calendarDate.mapIndexInfo.isAvailable,
    )
    if (!selectedCalendarDate || !selectedDateIsAvailable) {
      setSelectedCalendarDate(firstAvailableDate?.date)
    }
  }, [
    calendarDatesIndicesBySelectedSatellite,
    getFirstAvailableDate,
    selectedCalendarDate,
    selectedLots,
    selectedMapIndex,
    setDates,
    setSelectedCalendarDate,
    isShowDamsModeSelected,
  ])

  useEffect(() => {
    const firstAvailableDate = getFirstAvailableDate(damsCalendar, selectedDamCalendarDate)

    const firstAvailableDateHasUrls = LotsUtils.dateHasUrls(firstAvailableDate)

    if (firstAvailableDate && !firstAvailableDateHasUrls) {
      const firstDamAvailableDate = DateUtils.parseDate(firstAvailableDate.date)

      if (firstDamAvailableDate) {
        setDamDates(prevState => ({
          ...prevState,
          fromYearMonth: firstDamAvailableDate,
          toYearMonth: firstDamAvailableDate,
        }))
      }
    }

    const selectedDateIsAvailable = damsCalendar.some(
      calendarDate =>
        calendarDate.date === selectedDamCalendarDate && calendarDate.mapIndexInfo.isAvailable,
    )
    if (!selectedDamCalendarDate || !selectedDateIsAvailable) {
      setSelectedDamCalendarDate(firstAvailableDate?.date)
    }
  }, [
    damsCalendar,
    getFirstAvailableDate,
    selectedDamCalendarDate,
    setDamDates,
    setSelectedDamCalendarDate,
  ])

  const weatherDataForAllLots = useMemo(() => {
    return selectedLots.reduce<WeatherDataObject>((result, lot) => {
      calendarDatesIndicesBySelectedSatellite[selectedMapIndex].forEach(calendar => {
        const key = calendar.date
        // eslint-disable-next-line no-param-reassign
        result[key] = result[key] ?? []
        const calendarLot = lot.riceLot.calendar.find(date => {
          const isAllOptionsSelected = selectedSatellite === 'ALL'
          const sameDate = date.date === calendar.date
          const sameProvider = calendar.mapIndexInfo.provider === selectedSatellite
          return isAllOptionsSelected ? sameDate : sameDate && sameProvider
        })

        const calendarLotMapurlsByIndex = LotsUtils.getCalendarDateByProvider(
          selectedSatellite,
          selectedMapIndex,
          calendarLot?.mapUrls,
        )

        if (!calendarLotMapurlsByIndex) {
          // eslint-disable-next-line no-param-reassign
          result[key] = [...result[key], { name: lot.name ?? '', weatherType: IMAGE_NOT_AVAILABLE }]

          return
        }

        const isImageNotAvailable =
          !calendarLotMapurlsByIndex.weatherType && !calendarLotMapurlsByIndex.isAvailable

        const weatherType = isImageNotAvailable
          ? IMAGE_NOT_AVAILABLE
          : calendarLotMapurlsByIndex.weatherType ?? IMAGE_NOT_AVAILABLE

        const satelliteProvider = calendarLotMapurlsByIndex.provider as SatelliteProviders

        // eslint-disable-next-line no-param-reassign
        result[key] = [...result[key], { name: lot.name ?? '', weatherType, satelliteProvider }]
      })

      return result
    }, {})
  }, [calendarDatesIndicesBySelectedSatellite, selectedLots, selectedMapIndex, selectedSatellite])

  const weatherDataForDams = useMemo(() => {
    return (
      dams?.reduce<WeatherDataObject>((result, dam) => {
        damsCalendar.forEach(calendar => {
          const key = calendar.date
          // eslint-disable-next-line no-param-reassign
          result[key] = result[key] ?? []
          const calendarDam = dam.calendar.find(date => date.date === calendar.date)
          if (!calendarDam) {
            // eslint-disable-next-line no-param-reassign
            result[key] = [
              ...result[key],
              { name: dam.name ?? '', weatherType: IMAGE_NOT_AVAILABLE },
            ]

            return
          }

          const isImageNotAvailable =
            !calendarDam?.mapUrls.waterSurface?.weatherType &&
            !calendarDam?.mapUrls.waterSurface?.isAvailable

          const weatherType = isImageNotAvailable
            ? IMAGE_NOT_AVAILABLE
            : calendarDam?.mapUrls.waterSurface?.weatherType ?? IMAGE_NOT_AVAILABLE

          // eslint-disable-next-line no-param-reassign
          result[key] = [...result[key], { name: dam.name ?? '', weatherType }]
        })

        return result
      }, {}) ?? {}
    )
  }, [dams, damsCalendar])

  const onHandleCompareSelectedMapIndexChange = useCallback(
    (index: MapIndex) => {
      const compareCalendarDatesWithoutDuplicates = compareCalendarDateIndices[index]
      const hasCalendarDates = !!compareCalendarDatesWithoutDuplicates.length

      if (!hasCalendarDates) {
        setSelectedCompareSatellite('ALL')
      }

      const calendarDates = hasCalendarDates
        ? compareCalendarDatesWithoutDuplicates
        : allCalendarDatesIndices[index]

      // TODO: Move to own function
      const firstCompareCalendarDateAvailable = getFirstAvailableDate(
        calendarDates,
        selectedCompareCalendarDate,
      )

      const firstCompareAvailableDateHasUrls = LotsUtils.dateHasUrls(
        firstCompareCalendarDateAvailable,
      )

      if (!firstCompareAvailableDateHasUrls) {
        const firstCompareLotAvailableDate = DateUtils.parseDate(
          firstCompareCalendarDateAvailable?.date,
        )

        if (firstCompareLotAvailableDate) {
          setDates(prevState => ({
            ...prevState,
            fromYearMonth: firstCompareLotAvailableDate,
            toYearMonth: firstCompareLotAvailableDate,
          }))
        }
      }

      setSelectedCompareCalendarDate(firstCompareCalendarDateAvailable?.date)
      setSelectedCompareMapIndex(index)
    },
    [
      allCalendarDatesIndices,
      compareCalendarDateIndices,
      getFirstAvailableDate,
      selectedCompareCalendarDate,
      setDates,
      setSelectedCompareCalendarDate,
      setSelectedCompareMapIndex,
      setSelectedCompareSatellite,
    ],
  )

  const handleSatelliteChange = (satellite: SatelliteProviders) => {
    setSelectedSatellite(satellite)
    const calendarIndices = getCalendarDateIndices(satellite)
    const calendarDates = calendarIndices[selectedMapIndex]

    if (!calendarDates.length) return

    const firstAvailableDate = getFirstAvailableDate(calendarDates, selectedCalendarDate)
    const lot = selectedLots?.find(lotToFind => lotToFind.id === Number(popupInfo?.id))

    if (!lot) return

    const newDate = lot.riceLot.calendar.find(
      calendarDate => calendarDate.date === firstAvailableDate?.date,
    )

    const irrigationIndicator = LotsUtils.getCalendarDateByProvider(
      satellite,
      'irrigation',
      newDate?.mapUrls,
    )

    const chlorophyllIndicator = LotsUtils.getCalendarDateByProvider(
      satellite,
      'chlorophyll',
      newDate?.mapUrls,
    )
    setPopupInfo(prevState => {
      const newInfo = {
        ...prevState,
        irrigationIndicator: irrigationIndicator?.indicator,
        chlorophyllIndicator: chlorophyllIndicator?.indicator,
        daysSinceEmergencyDate: newDate?.daysSinceEmergence ?? undefined,
      } as PopupInfo

      return newInfo
    })
  }

  const handleCompareSelectedSatelliteChange = (satellite: SatelliteProviders) => {
    setSelectedCompareSatellite(satellite)

    const calendarIndices = getCalendarDateIndices(satellite)
    const compareCalendarDatesWithoutDuplicates = calendarIndices[selectedCompareMapIndex]

    if (!compareCalendarDatesWithoutDuplicates.length) {
      return
    }

    const firstCompareCalendarDateAvailable = getFirstAvailableDate(
      compareCalendarDatesWithoutDuplicates,
      selectedCompareCalendarDate,
    )
    const firstCompareAvailableDateHasUrls = LotsUtils.dateHasUrls(
      firstCompareCalendarDateAvailable,
    )

    if (!firstCompareAvailableDateHasUrls) {
      const firstCompareLotAvailableDate = DateUtils.parseDate(
        firstCompareCalendarDateAvailable?.date,
      )

      if (firstCompareLotAvailableDate) {
        setDates(prevState => ({
          ...prevState,
          fromYearMonth: firstCompareLotAvailableDate,
          toYearMonth: firstCompareLotAvailableDate,
        }))
      }
    }

    setSelectedCompareCalendarDate(firstCompareCalendarDateAvailable?.date)
  }

  const setCompareMapData = () => {
    const isIrrigationSelected = selectedMapIndex === 'irrigation'
    const compareIndex = isIrrigationSelected ? 'chlorophyll' : 'irrigation'
    setSelectedCompareSatellite(selectedSatellite)

    onHandleCompareSelectedMapIndexChange(compareIndex)
  }

  const availableSatellites = useMemo(() => {
    const isLandsatSatelliteAvailable = allCalendarDatesIndices[selectedMapIndex].some(
      calendarDate => calendarDate.mapIndexInfo.provider === 'LANDSAT89',
    )

    const isSentinelSatelliteAvailable = allCalendarDatesIndices[selectedMapIndex].some(
      calendarDate => calendarDate.mapIndexInfo.provider === 'SENTINEL2',
    )

    const isPlanetSatelliteAvailable = allCalendarDatesIndices[selectedMapIndex].some(
      calendarDate => calendarDate.mapIndexInfo.provider === 'PLANET',
    )

    const isComparePlanetSatelliteAvailable = allCalendarDatesIndices[selectedCompareMapIndex].some(
      calendarDate => calendarDate.mapIndexInfo.provider === 'PLANET',
    )

    const isCompareLandsatSatelliteAvailable = allCalendarDatesIndices[
      selectedCompareMapIndex
    ].some(calendarDate => calendarDate.mapIndexInfo.provider === 'LANDSAT89')

    const isCompareSentinelSatelliteAvailable = allCalendarDatesIndices[
      selectedCompareMapIndex
    ].some(calendarDate => calendarDate.mapIndexInfo.provider === 'SENTINEL2')

    return {
      planet: isPlanetSatelliteAvailable,
      sentinel: isSentinelSatelliteAvailable,
      landsat: isLandsatSatelliteAvailable,
      comparePlanet: isComparePlanetSatelliteAvailable,
      compareSentinel: isCompareSentinelSatelliteAvailable,
      compareLandsat: isCompareLandsatSatelliteAvailable,
    }
  }, [allCalendarDatesIndices, selectedCompareMapIndex, selectedMapIndex])

  return {
    grainHumidityHasNotEmergenceDaysActivation,
    emergenceDaysActivationIndices,
    calendarDatesIndices: calendarDatesIndicesBySelectedSatellite,
    weatherDataForAllLots,
    isGrainHumidityAvailable,
    isWaterAvailable,
    isWeedsAvailable,
    setCompareMapData,
    onHandleCompareSelectedMapIndexChange,
    damsCalendar,
    weatherDataForDams,
    compareCalendarDateIndices,
    handleCompareSelectedSatelliteChange,
    availableSatellites,
    handleSatelliteChange,
  }
}
