import { Col, Row, List } from 'antd'
import styled from 'styled-components'

export const ListItem = styled(List.Item)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 0 !important;
`

export const FullWidthRow = styled(Row)`
  flex: 1;
  width: 100%;
`

interface UploadProps {
  $isActive?: boolean
}

export const LogoContainer = styled(Col)<UploadProps>`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: ${({ $isActive, theme }) =>
    $isActive
      ? `4px solid ${theme.colors.new.secondary[800]}`
      : `2px solid ${theme.colors.new.baseColor[200]}`};
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const EditButton = styled.span`
  padding: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
  max-height: 26px;
  font-size: 12px;
  font-style: normal;
  &:hover {
    color: ${({ theme }) => theme.colors.new.secondary[400]};
  }
`

export const Container = styled.div`
  padding: 0 30px;
`
