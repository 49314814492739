import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { LotsMapLocationState } from 'types'
import { ActiveCompanyContext } from 'contexts'

import { CompanyUserInvited, CompanyUserInvitedVariables, COMPANY_USER_INVITED } from './api'
import { Card } from '../Card'
import { InvitedUserCard } from '../InvitedUserCard'

interface Props {
  noFields?: boolean
  isAdminUser: boolean
  isOrganizationUser?: boolean
  isCompanyViewerUser: boolean
}
export const EmptyResults: React.FC<Props> = ({
  noFields,
  isAdminUser,
  isOrganizationUser,
  isCompanyViewerUser,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('settings')
  const { activeSeasonId, activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { data } = useQuery<CompanyUserInvited, CompanyUserInvitedVariables>(COMPANY_USER_INVITED, {
    variables: { id: activeCompanyId! },
    skip: !activeCompanyId,
  })

  if (!isAdminUser)
    return (
      <InvitedUserCard
        title={isCompanyViewerUser ? data?.company.name ?? '' : t('cards.irrigationWorker.title')}
        description={
          isCompanyViewerUser
            ? t('cards.invitedUser.notFields')
            : t('cards.irrigationWorker.description')
        }
      />
    )

  return noFields ? (
    <Card
      title={t('modals.firstField.title')}
      description={
        isOrganizationUser
          ? t('modals.firstField.descriptionClient')
          : t('modals.firstField.description')
      }
      onClick={() => navigate('/settings/fields')}
      buttonText={t('modals.firstField.buttonText')}
    />
  ) : (
    <Card
      title={t('modals.firstLot.title')}
      description={
        isOrganizationUser
          ? t('modals.firstClientLot.description')
          : t('modals.firstLot.description')
      }
      onClick={() => {
        const state: LotsMapLocationState = { seasonId: activeSeasonId, goTo: '/map' }
        return navigate('/settings/lots', {
          state,
        })
      }}
      buttonText={t('modals.firstLot.buttonText')}
    />
  )
}
