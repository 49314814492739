import { Typography } from 'antd'
import styled from 'styled-components'

interface Props {
  title: string
}

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-size: 13px !important;
  font-weight: normal !important;
  margin-bottom: 0 !important;
`

export const InputTitle: React.FC<Props> = ({ title }) => {
  return <Title level={5}>{title}</Title>
}
