import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

interface FormType {
  companyName: string
  country: string
  governmentId?: string
  region?: number
  subregion?: number
}

export const useCreateCompanyForm = () => {
  const { t } = useTranslation('common')

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('validations.required')),
    country: yup.string().required(t('validations.required')),
  })

  return useForm<FormType>({ resolver: yupResolver(validationSchema) })
}
