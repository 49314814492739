import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from 'antd/lib/table'
import { difference, uniq, uniqBy } from 'lodash'
import { Row, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useTheme } from 'styled-components'

import { DateUtils, LotUtils } from 'utils'
import { AbandonedSizeInput, EllipsisText } from 'components'
import {
  useUpdateRiceLot,
  useUpdateLot,
  useUpdateTypeStyles,
  useProductionType,
  useDeliveryCondition,
} from 'hooks'
import { HistoricalSeason } from 'types'

import {
  VarietiesSelect,
  CropsSelect,
  DatePicker,
  StyledTable,
  StyledInput,
  DeliveryMillSelect,
  InputRender,
} from './components'
import { COLUMNS } from '../consts'
import { useSeasonLots } from './hooks'
import { DataType, ColumnType, FieldFilter, LotFilter } from './types'

interface Props {
  selectedColumns: string[]
  isAnOrganizationUser: boolean
  onFilterLots: (filteredLotIds?: number[]) => void
  selectedHistoricalSeason?: HistoricalSeason
  isFieldEntityFlagEnabled: boolean
}

export const Table: React.FC<Props> = ({
  selectedColumns,
  onFilterLots,
  selectedHistoricalSeason,
  isAnOrganizationUser,
  isFieldEntityFlagEnabled,
}) => {
  const [filteredLotIds, setFilterLotIds] = useState<number[]>()
  const [filteredFieldIds, setFilteredFieldIds] = useState<number[]>()
  const [filteredCompanyIds, setFilteredCompanyIds] = useState<number[]>()

  const { t } = useTranslation('lots-management')
  const { t: commonT } = useTranslation('common')
  const { updateRiceLot } = useUpdateRiceLot()
  const { colors } = useTheme()
  const { updateLot } = useUpdateLot()
  const { dataSource, allcompanyValues, allFieldValues, allLotValues } = useSeasonLots({
    endRange: selectedHistoricalSeason?.endRange,
    startRange: selectedHistoricalSeason?.startRange,
  })

  const fieldFiltersList = useMemo(() => {
    if (!filteredCompanyIds?.length) return allFieldValues
    const filteredFieldsByCompanies = allFieldValues.filter(field =>
      filteredCompanyIds.includes(field.companyId),
    )
    return filteredFieldsByCompanies
  }, [allFieldValues, filteredCompanyIds])

  const lotFilterList = useMemo(() => {
    if (!filteredFieldIds?.length && !filteredCompanyIds) return allLotValues

    const filteredLotsByCompanies = allLotValues.filter(lot =>
      filteredCompanyIds?.includes(lot.companyId),
    )
    const filteredLotsByFields = allLotValues.filter(lot => filteredFieldIds?.includes(lot.fieldId))

    return filteredFieldIds?.length ? filteredLotsByFields : filteredLotsByCompanies
  }, [filteredFieldIds, filteredCompanyIds, allLotValues])

  const { getBallBackground } = useUpdateTypeStyles()

  const { productionTypeMap } = useProductionType()
  const { deliveryConditionMap } = useDeliveryCondition()

  const defaultColumns: ColumnType<DataType>[] = [
    {
      key: COLUMNS.COMPANY,
      title: isAnOrganizationUser ? t('table.columns.farmerName') : t('table.columns.companyName'),
      fixed: 'left',
      render: ({ companyName }: DataType) => (
        <EllipsisText text={companyName} overlayInnerStyle={{ width: '100%' }} />
      ),
      width: '150px',
      filters: allcompanyValues,
      onFilter: (value, record) => record.companyId === value,
      sorter: {
        compare: (a, b) => a.companyName.localeCompare(b.companyName),
        multiple: 4,
      },
      filteredValue: filteredCompanyIds ?? [],
    },
    {
      key: COLUMNS.LOT_NAME,
      title: t('table.columns.lotName'),
      fixed: 'left',
      render: ({ lotName }: DataType) => (
        <EllipsisText text={lotName} overlayInnerStyle={{ width: '100%' }} />
      ),
      width: '150px',
      filters: lotFilterList,
      onFilter: (value, record) => record.lotId === value,
      sorter: {
        compare: (a, b) => a.lotName.localeCompare(b.lotName),
        multiple: 1,
      },
      filteredValue: filteredLotIds ?? [],
    },
  ]

  const selectableColumns: ColumnType<DataType>[] = [
    {
      key: COLUMNS.FIELD_NAME,
      title: t('table.columns.fieldName'),
      render: ({ fieldName }: DataType) => (
        <EllipsisText text={fieldName} overlayInnerStyle={{ width: '100%' }} />
      ),
      filters: fieldFiltersList,
      onFilter: (value, record) => record.fieldId === value,
      sorter: {
        compare: (a, b) => a.fieldName.localeCompare(b.fieldName),
        multiple: 3,
      },
      width: '150px',
      hide: !isFieldEntityFlagEnabled,
      filteredValue: filteredFieldIds ?? [],
    },
    {
      key: COLUMNS.SEASON_NAME,
      title: t('table.columns.seasonName'),
      render: ({ seasonName }: DataType) => (
        <EllipsisText text={seasonName} overlayInnerStyle={{ width: '100%' }} />
      ),
      sorter: {
        compare: (a, b) => a.seasonName.localeCompare(b.seasonName),
        multiple: 2,
      },
      width: '150px',
    },
    {
      key: COLUMNS.PREVIOUS_CROP,
      title: t('table.columns.previousCrop'),
      width: '153px',
      render: ({ lotId, previousCropId }: DataType) => (
        <CropsSelect
          defaultValue={previousCropId}
          getAll
          onChange={value =>
            updateLot({
              variables: {
                updateLotDto: { id: lotId, previousCropId: value?.id },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.PREPARATION_DATE,
      title: t('table.columns.preparation'),
      width: '153px',
      render: ({ riceLotId, preparationDate, preparationUpdateType }: DataType) => (
        <DatePicker
          allowClear={false}
          defaultValue={preparationDate ? dayjs(preparationDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(preparationUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  preparationDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.BURNING_DATE,
      title: t('table.columns.burning'),
      width: '153px',
      render: ({ riceLotId, burningDate, burningUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(burningUpdateType)}
          defaultValue={burningDate ? dayjs(burningDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(burningUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  burningDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.PLANTING_DATE,
      title: t('table.columns.planting'),
      width: '153px',
      render: ({ riceLotId, plantingDate, plantingUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(plantingUpdateType)}
          defaultValue={plantingDate ? dayjs(plantingDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(plantingUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  plantingDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.EMERGENCE_DATE,
      title: t('table.columns.emergence'),
      width: '153px',
      render: ({ riceLotId, emergenceDate, emergenceUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(emergenceUpdateType)}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(emergenceUpdateType)} />
          )}
          defaultValue={emergenceDate ? dayjs(emergenceDate) : undefined}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  emergenceDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.FIRST_IRRIGATION_START_DATE,
      title: t('table.columns.initialIrrigation'),
      width: '153px',
      render: ({ riceLotId, firstIrrigationStartDate }: DataType) => (
        <DatePicker
          defaultValue={firstIrrigationStartDate ? dayjs(firstIrrigationStartDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={colors.new.secondary[600]} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  firstIrrigationStartDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.FIRST_IRRIGATION_END_DATE,
      title: t('table.columns.irrigationCompleted'),
      width: '153px',
      render: ({ riceLotId, firstIrrigationEndDate }: DataType) => (
        <DatePicker
          inputRender={props => (
            <InputRender {...props} ballBackground={colors.new.secondary[600]} />
          )}
          defaultValue={firstIrrigationEndDate ? dayjs(firstIrrigationEndDate) : undefined}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  firstIrrigationEndDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.DIFFERENTIATION_DATE,
      title: t('table.columns.panicleInitiation'),
      width: '153px',
      render: ({ riceLotId, differentiationDate, differentiationUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(differentiationUpdateType)}
          defaultValue={differentiationDate ? dayjs(differentiationDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(differentiationUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  differentiationDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.FLOWERING_DATE,
      title: t('table.columns.flowering'),
      width: '153px',
      render: ({ riceLotId, floweringDate, floweringUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(floweringUpdateType)}
          defaultValue={floweringDate ? dayjs(floweringDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(floweringUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  floweringDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.PHYSIOLOGICAL_MATURITY_DATE,
      title: t('table.columns.maturation'),
      width: '153px',
      render: ({ riceLotId, physiologicalMaturityDate }: DataType) => (
        <DatePicker
          defaultValue={physiologicalMaturityDate ? dayjs(physiologicalMaturityDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={colors.new.secondary[600]} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  physiologicalMaturityDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.EAR_APPEARANCE_DATE,
      title: t('table.columns.earAppearance'),
      width: '153px',
      render: ({ riceLotId, earAppearanceDate, earAppearanceUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(earAppearanceUpdateType)}
          defaultValue={earAppearanceDate ? dayjs(earAppearanceDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(earAppearanceUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  earAppearanceDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.HARVEST_DATE,
      title: t('table.columns.harvest'),
      width: '153px',
      render: ({ riceLotId, harvestDate, harvestUpdateType }: DataType) => (
        <DatePicker
          allowClear={LotUtils.checkIfAllowedClearDate(harvestUpdateType)}
          defaultValue={harvestDate ? dayjs(harvestDate) : undefined}
          inputRender={props => (
            <InputRender {...props} ballBackground={getBallBackground(harvestUpdateType)} />
          )}
          onChange={value =>
            updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: {
                  harvestDate: DateUtils.formatValue(value ? value.toDate() : null),
                },
              },
            })
          }
        />
      ),
    },
    {
      key: COLUMNS.CROP,
      title: t('table.columns.crop'),
      width: '153px',
      render: ({ lotId, cropId }: DataType) => {
        return (
          <Tooltip title={t('tooltipText')}>
            <Row>
              <CropsSelect value={cropId} key={lotId} disabled />
            </Row>
          </Tooltip>
        )
      },
    },
    {
      key: COLUMNS.VARIETY,
      title: t('table.columns.variety'),
      width: '153px',
      render: ({ lotId, cropId, varietyId, companyId }: DataType) => (
        <VarietiesSelect
          cropId={cropId}
          defaultValue={varietyId}
          companyId={companyId}
          onChange={variety => {
            if (!variety) return
            updateLot({
              variables: {
                updateLotDto: { id: lotId, varietyId: variety.id },
              },
            })
          }}
        />
      ),
    },
    {
      key: COLUMNS.ESTIMATED_YIELD,
      title: t('table.columns.estimatedYield'),
      width: '120px',
      render: ({ estimatedYield, riceLotId, size }: DataType) => {
        return (
          <StyledInput
            defaultValue={estimatedYield ? Math.round(estimatedYield / size) : undefined}
            suffix={commonT('systems.surfaceDensity')}
            onBlur={(event: React.FormEvent<HTMLInputElement>) => {
              const newEstimatedYield = event.currentTarget.value
              return updateRiceLot({
                variables: {
                  id: riceLotId,
                  updateRiceLotDTO: { estimatedYield: Number(newEstimatedYield) * size },
                },
              })
            }}
          />
        )
      },
    },
    {
      key: COLUMNS.YIELD,
      title: t('table.columns.yield'),
      width: '110px',
      render: ({ yield: yieldValue, riceLotId, size }: DataType) => (
        <StyledInput
          defaultValue={yieldValue ? Math.round(yieldValue / size) : undefined}
          suffix={commonT('systems.surfaceDensity')}
          onBlur={(event: React.FormEvent<HTMLInputElement>) => {
            const newYield = event.currentTarget.value
            return updateRiceLot({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { yield: Number(newYield) * size },
              },
            })
          }}
        />
      ),
    },
    {
      key: COLUMNS.PRODUCTION_TYPE,
      title: t('table.columns.productionType'),
      width: '180px',
      render: ({ productionType }: DataType) => (
        <EllipsisText
          text={productionType ? productionTypeMap[productionType].label : ''}
          overlayInnerStyle={{ width: '100%' }}
        />
      ),
    },
    {
      key: COLUMNS.ABANDONED_SIZE,
      title: t('table.columns.abandonedSize'),
      width: '180px',
      render: ({ lotId, abandonedSize }: DataType) => {
        return (
          <AbandonedSizeInput abandonedSize={abandonedSize} onChange={updateLot} lotId={lotId} />
        )
      },
    },
    {
      key: COLUMNS.DELIVERY_CONDITION,
      title: t('table.columns.deliveryCondition'),
      width: '160px',
      render: ({ deliveryCondition }: DataType) => (
        <EllipsisText
          text={deliveryCondition ? deliveryConditionMap[deliveryCondition].label : ''}
          overlayInnerStyle={{ width: '100%' }}
        />
      ),
    },
    {
      key: COLUMNS.DELIVERY_MILL,
      title: t('table.columns.deliveryMill'),
      width: '160px',
      render: ({ lotId, deliveryMillId }: DataType) => {
        return (
          <DeliveryMillSelect
            value={deliveryMillId}
            onChange={mill => {
              updateLot({
                variables: { updateLotDto: { id: lotId, deliveryMillId: mill ?? null } },
              })
            }}
          />
        )
      },
    },
  ]

  const columns = selectableColumns.filter(selectableColumn => {
    return selectedColumns.includes(selectableColumn.key as string) && !selectableColumn.hide
  })

  const resetFiltersIds = () => {
    setFilteredCompanyIds(undefined)
    setFilteredFieldIds(undefined)
    setFilterLotIds(undefined)
  }

  const handleChange: TableProps<DataType>['onChange'] = (
    _,
    filters,
    ___,
    tableCurrentDataSource,
  ) => {
    const lotsIds = tableCurrentDataSource.currentDataSource.map(filteredLot => filteredLot.lotId)
    const {
      'company-name': companiesFilter,
      'field-name': fieldsFilter,
      'lot-name': lotsFilter,
    } = filters

    const lotsTableFilter = lotsFilter ? (lotsFilter as number[]) : undefined
    const fieldsTableFilter = fieldsFilter ? (fieldsFilter as number[]) : undefined
    const companiesTableFilter = companiesFilter ? (companiesFilter as number[]) : undefined

    onFilterLots(lotsIds)

    if (
      lotsIds.length === dataSource.length ||
      (filteredCompanyIds?.length && !companiesTableFilter)
    ) {
      resetFiltersIds()
      return
    }

    const selectedFieldFilters: FieldFilter[] = []
    fieldsTableFilter?.forEach(fieldId => {
      const fieldItem = allFieldValues.find(field => fieldId === field.value)
      if (fieldItem) selectedFieldFilters.push(fieldItem)
    })

    const companiesFields = uniqBy(selectedFieldFilters, 'companyId').map(
      ({ companyId }) => companyId,
    )

    const selectedLotFilters: LotFilter[] = []
    lotsTableFilter?.forEach(lotId => {
      const lotItem = allLotValues.find(lot => lotId === lot.value)
      if (lotItem) selectedLotFilters.push(lotItem)
    })

    const lotCompanies = uniqBy(selectedLotFilters, 'companyId').map(({ companyId }) => companyId)

    const selectedCompanies = uniq([
      ...companiesFields,
      ...(companiesTableFilter ?? []),
      ...lotCompanies,
    ])

    const lotFields = uniqBy(selectedLotFilters, 'fieldId').map(({ fieldId }) => fieldId)
    const selectedFields = uniq([...lotFields, ...(fieldsTableFilter ?? [])])

    if (difference(filteredCompanyIds, companiesTableFilter ?? []).length) {
      setFilteredFieldIds(undefined)
      setFilterLotIds(undefined)
      setFilteredCompanyIds(companiesTableFilter)
      return
    }

    if (difference(filteredFieldIds, fieldsTableFilter ?? []).length) {
      setFilteredFieldIds(selectedFields)
      setFilterLotIds(undefined)
      return
    }

    setFilteredCompanyIds(selectedCompanies)
    setFilteredFieldIds(selectedFields)
    setFilterLotIds(lotsTableFilter)
  }

  return (
    <StyledTable
      columns={[...defaultColumns.filter(column => !column.hide), ...columns]}
      dataSource={dataSource}
      rowKey={lot => lot.lotId}
      scroll={{ x: 1300 }}
      onChange={handleChange}
      pagination={{
        pageSizeOptions: ['5', '10', '20'],
        defaultPageSize: 10,
        showSizeChanger: true,
      }}
    />
  )
}
