import { Statistic } from 'antd'

import { GreyCard } from 'components'

interface Props {
  title: string
  value: string
}

export const StatisticCard: React.FC<Props> = ({ title, value }) => {
  return (
    <GreyCard>
      <Statistic title={title} value={value} />
    </GreyCard>
  )
}
