import { StoreObject, useMutation } from '@apollo/client'

import { CompanySeason, SeasonField } from 'api'
import { GraphQLUtils } from 'utils'

import {
  CreateLots,
  CreateLotsVariables,
  CREATE_LOTS,
  MAP_LOTS,
  MapLots,
  MapLotsVariables,
} from '../api'

export const useCreateLots = () => {
  const [createLots, { loading }] = useMutation<CreateLots, CreateLotsVariables>(CREATE_LOTS, {
    update: (cache, { data: response }, { variables }) => {
      if (!variables) return
      if (!response) return

      const firstCreatedLot = variables.createLotDTOs[0]
      const { seasonId, fieldId } = firstCreatedLot

      const season: StoreObject = { id: seasonId, __typename: 'Season' }
      const newLots = response.createLots.results
      cache.modify<CompanySeason>({
        id: cache.identify(season),
        fields: {
          seasonFields(cachedData) {
            const cachedSeasonFields = cachedData as SeasonField[] | undefined
            const cachedSeasonField = cachedSeasonFields?.find(
              seasonField => seasonField.field?.id === fieldId,
            )
            const newSeasonField = cachedSeasonField
              ? {
                  field: cachedSeasonField.field,
                  lots: [...cachedSeasonField.lots, ...newLots],
                  __typename: 'SeasonField',
                }
              : {
                  field: { id: fieldId },
                  lots: newLots,
                  __typename: 'SeasonField',
                }

            return cachedSeasonFields
              ? ([
                  ...cachedSeasonFields.filter(seasonField => seasonField.field?.id === fieldId),
                  newSeasonField,
                ] as SeasonField[])
              : ([newSeasonField] as SeasonField[])
          },
        },
      })

      const cachedLots = cache.readQuery<MapLots, MapLotsVariables>({
        query: MAP_LOTS,
        variables: { fieldId, seasonId },
      })

      cache.writeQuery<MapLots, MapLotsVariables>({
        query: MAP_LOTS,
        variables: { fieldId, seasonId },
        data: {
          lotsV2: {
            ...cachedLots?.lotsV2,
            results: cachedLots?.lotsV2.results
              ? [...cachedLots.lotsV2.results, ...newLots]
              : newLots,
            __typename: 'LotsV2',
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  return { createLots, loading }
}
