import { gql } from '@apollo/client'

export type { Company, CompanyVariables } from './types/Company'

export const COMPANY = gql`
  query Company($id: Int!) {
    company(id: $id) {
      id
      name
      logo
    }
  }
`
