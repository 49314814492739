export enum DeleteType {
  PHYTOSANITARY = 'PHYTOSANITARY',
  FERTILIZER = 'FERTILIZER',
}

export interface DeleteAplicationData {
  showModal: boolean
  id?: number
  type?: DeleteType
}
