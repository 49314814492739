import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'
import { DAMS, DamsVariables } from 'hooks/useQueryDams/api'

import { CREATE_DAMS, CreateDams, CreateDamsVariables, Dams } from './useDams/api'

export const useCreateDams = () => {
  const [createDams, { loading }] = useMutation<CreateDams, CreateDamsVariables>(CREATE_DAMS, {
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }, { variables }) => {
      if (!variables || !response) return

      const firstDam = response.createDams.results?.[0]

      const fieldId = firstDam?.fieldId

      if (!fieldId) return

      const cachedDataDams = cache.readQuery<Dams, DamsVariables>({
        query: DAMS,
        variables: { fieldId, includeCalendar: false, includeMapUrls: false },
      })
      const cachedDams = cachedDataDams?.dams.results ?? []

      const newDams = response.createDams.results.map(dam => ({
        ...dam,
        calendar: [],
      }))

      cache.writeQuery<Dams, DamsVariables>({
        query: DAMS,
        variables: { fieldId, includeCalendar: false, includeMapUrls: false },
        data: {
          dams: {
            ...cachedDataDams?.dams,
            results: [...cachedDams, ...newDams],
            __typename: 'Dams',
          },
        },
      })
    },
  })

  return { createDams, loading }
}
