import { lineString } from '@turf/helpers'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MAP } from 'consts'
import { config } from 'config'

interface ImgProps {
  width?: string
  height?: string
  isOnClickDisabled?: boolean
}

const Image = styled.img<ImgProps>`
  background-position: center;
  cursor: ${({ isOnClickDisabled }) => !isOnClickDisabled && 'pointer'};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  width: ${({ width = '150px' }) => width};
  height: ${({ height = '85px' }) => height};
`
interface Props extends ImgProps {
  lotCoordinates?: number[][]
  fieldId?: number
  lotId?: number
  isOnClickDisabled?: boolean
}

export const MapImage: React.FC<Props> = ({
  lotCoordinates,
  width,
  height,
  fieldId,
  lotId,
  isOnClickDisabled,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('lot-dashboard')

  const getImageUrl = (coordinates: string) => {
    return `${MAP.STATIC_IMAGES.URL}geojson({"type":"FeatureCollection","features":[{"type":"Feature","properties":{"stroke":"%23ffffff","stroke-width":7, "stroke-opacity": 1, "fill":"%239c9a37","fill-opacity":1},"geometry":{"type": "Polygon","coordinates":[${coordinates}]}}]})/auto/${MAP.STATIC_IMAGES.SIZE}?padding=30,30,40&logo=false&access_token=${config.mapboxToken}`
  }

  if (!lotCoordinates) return null

  return (
    <Image
      width={width}
      height={height}
      isOnClickDisabled={isOnClickDisabled}
      onClick={() => {
        if (isOnClickDisabled) return

        navigate({
          pathname: '/map',
          search: fieldId && lotId ? `?fieldId=${fieldId}&lotId=${lotId}` : undefined,
        })
      }}
      src={getImageUrl(JSON.stringify(lineString(lotCoordinates).geometry.coordinates))}
      alt={t('lotMinimapAlt')}
    />
  )
}
