import { gql } from '@apollo/client'

export type {
  User,
  UserVariables,
  // eslint-disable-next-line camelcase
  User_user_companiesRoles as UserUserCompanyRole,
} from './types/User'

export type { UpdateUser, UpdateUserVariables } from './types/UpdateUser'

export const USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      email
      name
      lastName
      profilePicture
      isOnboarded
      companiesRoles {
        role
        company {
          id
          name
          logo
          satelliteImageryFlag
          phoneNumber
          seasons {
            id
            startDate
          }
          organization {
            id
            featureFlags {
              general {
                flags
              }
            }
            isCommercialNameEnabled
          }
          subregion {
            id
            location {
              coordinates
            }
          }
          country {
            id
            lng
            code
            isoCode
          }
          milestonesTimeline
        }
      }
      organizationRole {
        role
        organization {
          id
          name
          logo
          featureSetId
          featureFlags {
            general {
              flags
            }
            crm {
              enabled
              flags
            }
            subFarmersManagement {
              enabled
            }
          }
          country {
            id
            lng
            code
            isoCode
          }
        }
      }
      regions {
        id
        name
        location {
          type
          coordinates
        }
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $updateUserDTO: UpdateUserInput!) {
    updateUser(id: $id, updateUserDTO: $updateUserDTO) {
      user {
        id
        name
        lastName
        profilePicture
      }
    }
  }
`

export type { CommercialExecutives } from './types/CommercialExecutives'

export const COMMERCIAL_EXECUTIVES = gql`
  query CommercialExecutives {
    commercialExecutives {
      results {
        id
        name
        lastName
        regions {
          id
          name
        }
      }
    }
  }
`
