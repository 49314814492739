/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { LotHistoricData, LotHistoricDataVariables } from './types/LotHistoricData'

export const LOT_HISTORIC_DATA = gql`
  query LotHistoricData(
    $companyName: String!
    $fieldName: String!
    $lotName: String!
    $varietyName: String!
    $yield: String!
    $yieldUnit: String!
    $fromTime: DateTime!
    $toTime: DateTime!
    $emergenceDate: DateTime!
    $area: PolygonInput!
  ) {
    lotHistoricData(
      companyName: $companyName
      fieldName: $fieldName
      lotName: $lotName
      varietyName: $varietyName
      yield: $yield
      yieldUnit: $yieldUnit
      fromTime: $fromTime
      toTime: $toTime
      emergenceDate: $emergenceDate
      area: $area
    ) {
      url
    }
  }
`
