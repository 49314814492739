import { nanoid } from 'nanoid'
import { kml, Response } from '@tmcw/togeojson'
import path from 'path'
import { Feature, GeometryCollection, Polygon, featureCollection } from '@turf/helpers'
import { flatMap } from 'lodash'

const NANO_ID_SIZE = 5

const generateId = () => nanoid(NANO_ID_SIZE)

const convertGeometryCollectionToPolygon = (feature: Feature<Polygon | GeometryCollection>) => {
  if (feature.geometry.type === 'GeometryCollection') {
    const polygonGeometries = feature.geometry.geometries.map(geometriaPolygon => ({
      type: geometriaPolygon.type,
      coordinates: [...geometriaPolygon.coordinates],
    }))

    return polygonGeometries.map(geometry => ({
      type: 'Feature',
      geometry,
      properties: {},
    }))
  }

  return [feature]
}

const kmlFileToGeoJson = async (file: File) => {
  const text = await file.text()
  const parser = new DOMParser()
  const doc = parser.parseFromString(text, 'text/xml')

  // The library doesn't support networklinks (https://github.com/placemark/togeojson#why-doesnt-togeojson-support-networklinks)
  // in this cases the uploaded KML will be without features. One of the differences with this kind of documents is
  // that They have the "xsi:schemaLocation" property inside of Document markup <Document xsi:schemaLocation="...">
  // We can try delete this property manually and maybe the document works.
  const geoJson = kml(doc)

  const transformedFeatures = flatMap(
    geoJson.features,
    convertGeometryCollectionToPolygon,
  ) as unknown as Feature<Polygon>[]

  const newGeoJson = featureCollection(transformedFeatures) as unknown as Response

  // this workaround is to remove the z-axis, which is set to 0 by default.
  newGeoJson.features.forEach(feature =>
    feature.geometry.coordinates[0].forEach(coordinate => {
      // If we have z-axis we remove it
      if (coordinate.length === 3) {
        coordinate.pop()
      }
    }),
  )
  return newGeoJson
}

const removeAccents = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const checkFileExtension = (fileName: string, extension: string) =>
  path.extname(fileName) === extension

export const MiscUtils = {
  generateId,
  kmlFileToGeoJson,
  removeAccents,
  checkFileExtension,
}
