import i18n from 'i18next'
import { initReactI18next, getI18n } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import spanishLocale from 'antd/locale/es_ES'
import unitedStatesLocale from 'antd/locale/en_US'
import 'dayjs/locale/es'

import { Locales } from 'assets/locales'
import { ConversionUtils } from 'utils'
import { LANGUAGE_CODES, LOCALE_CODES } from 'consts'

/**
 * This should match the json files under /public/locales/{lng}
 * Add a new json file for each page or group of related page and subpages
 * All translations for each group should be within that json except for common ones that should
 * go in commmon.json file
 * If json represents multiple pages/subpages, we can group under a key each page/subpage
 * Add the new namespace to the <i18next.d.ts> file to get typed translations
 */
const NAMESPACES = {
  COMMON: 'common',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  LOT_DASHBOARD: 'lot-dashboard',
  ACCOUNT_SETTINGS: 'account-settings',
  FIELD_SETTINGS: 'field-settings',
  SETTINGS: 'settings',
  NAVBAR: 'navbar',
  COMPANY_SETTINGS: 'company-settings',
  LOT_SETTINGS: 'lot-settings',
  SEASON_SETTINGS: 'season-settings',
  MAP: 'map',
  INTERNAL_METRICS: 'internal-metrics',
  ONBOARDING: 'onboarding',
  LOTS_MANAGEMENT: 'lots-management',
}

const init = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        [LANGUAGE_CODES.ES]: {
          [NAMESPACES.COMMON]: Locales.commonEsLocales,
          [NAMESPACES.LOGIN]: Locales.loginEsLocales,
          [NAMESPACES.DASHBOARD]: Locales.dashboardEsLocales,
          [NAMESPACES.LOT_DASHBOARD]: Locales.lotDashboardEsLocales,
          [NAMESPACES.ACCOUNT_SETTINGS]: Locales.accountSettingsEsLocales,
          [NAMESPACES.FIELD_SETTINGS]: Locales.fieldSettingsEsLocales,
          [NAMESPACES.SETTINGS]: Locales.settingsEsLocales,
          [NAMESPACES.NAVBAR]: Locales.navbarEsLocales,
          [NAMESPACES.COMPANY_SETTINGS]: Locales.companySettingsEsLocales,
          [NAMESPACES.LOT_SETTINGS]: Locales.lotSettingsEsLocales,
          [NAMESPACES.SEASON_SETTINGS]: Locales.seasonSettingsEsLocales,
          [NAMESPACES.MAP]: Locales.mapEsLocales,
          [NAMESPACES.INTERNAL_METRICS]: Locales.internalMetricsEsLocales,
          [NAMESPACES.ONBOARDING]: Locales.onboardingEsLocales,
          [NAMESPACES.LOTS_MANAGEMENT]: Locales.lotsManagementEsLocales,
        },
        [LANGUAGE_CODES.US]: {
          [NAMESPACES.COMMON]: Locales.commonEnLocales,
          [NAMESPACES.LOGIN]: Locales.loginEnLocales,
          [NAMESPACES.DASHBOARD]: Locales.dashboardEnLocales,
          [NAMESPACES.LOT_DASHBOARD]: Locales.lotDashboardEnLocales,
          [NAMESPACES.ACCOUNT_SETTINGS]: Locales.accountSettingsEnLocales,
          [NAMESPACES.FIELD_SETTINGS]: Locales.fieldSettingsEnLocales,
          [NAMESPACES.SETTINGS]: Locales.settingsEnLocales,
          [NAMESPACES.NAVBAR]: Locales.navbarEnLocales,
          [NAMESPACES.COMPANY_SETTINGS]: Locales.companySettingsEnLocales,
          [NAMESPACES.LOT_SETTINGS]: Locales.lotSettingsEnLocales,
          [NAMESPACES.SEASON_SETTINGS]: Locales.seasonSettingsEnLocales,
          [NAMESPACES.MAP]: Locales.mapEnLocales,
          [NAMESPACES.INTERNAL_METRICS]: Locales.internalMetricsEnLocales,
          [NAMESPACES.ONBOARDING]: Locales.onboardingEnLocales,
          [NAMESPACES.LOTS_MANAGEMENT]: Locales.lotsManagementEnLocales,
        },
        [LOCALE_CODES.ES_PA]: {
          [NAMESPACES.COMMON]: Locales.commonEsPaLocales,
          [NAMESPACES.LOT_DASHBOARD]: Locales.lotDashboardEsPaLocales,
        },
      },
      fallbackLng: LANGUAGE_CODES.ES,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    })

  i18n.services.formatter?.add('area', value => {
    return ConversionUtils.formatArea(value, i18n.resolvedLanguage)
  })
  i18n.services.formatter?.add('degree', value => {
    return ConversionUtils.formatDegree(value, i18n.resolvedLanguage)
  })
  i18n.services.formatter?.add('surfaceDensity', (value, _, options) => {
    return ConversionUtils.formatSurfaceDensity(value, {
      lng: i18n.resolvedLanguage,
      noUnit: options.noUnit,
    })
  })
}

const getAntdLocale = () => {
  if (getI18n().resolvedLanguage === LANGUAGE_CODES.US) {
    return unitedStatesLocale
  }

  return spanishLocale
}

export const I18NService = {
  init,
  getAntdLocale,
  NAMESPACES,
}
