import { useTranslation } from 'react-i18next'
import { Row, Button } from 'antd'

import { LogoColoredWithTextIcon } from 'assets/icons'
import { useAuth, usePermissions } from 'hooks'

import {
  Layout,
  Container,
  Title,
  Description,
  CreateClientCard,
  CreateCompanyCard,
} from './components'

export const Onboarding = () => {
  const { t } = useTranslation('onboarding')

  const { permissions } = usePermissions()
  const { logout } = useAuth()

  const translationType = permissions.isAnOrganizationUser ? 'firstClient' : 'firstCompany'

  return (
    <Layout>
      <Container direction="vertical" size={60}>
        <LogoColoredWithTextIcon color="white" />
        <div>
          <Title level={3}>{t(`${translationType}.title`)}</Title>
          <Description level={5}>{t(`${translationType}.description`)}</Description>
        </div>
        {permissions.isAnOrganizationUser ? <CreateClientCard /> : <CreateCompanyCard />}
        <Row justify="end">
          <Button type="link" onClick={logout}>
            {t('logoutButtonText')}
          </Button>
        </Row>
      </Container>
    </Layout>
  )
}
