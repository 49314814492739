import styled from 'styled-components'
import { Tooltip } from 'antd'

import { TrashIcon } from 'assets/icons'

interface ButtonProps {
  disabled: boolean
}

export const Button = styled.div<ButtonProps>`
  width: 40px;
  height: 40px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey2 : theme.colors.grey3};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.grey3 : theme.colors.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`

interface Props {
  onClick?: () => void
  disabled: boolean
  toolTipText?: string
}

export const DeleteButton: React.FC<Props> = ({ onClick, disabled, toolTipText }) =>
  toolTipText ? (
    <Tooltip title={toolTipText}>
      <Button onClick={onClick} disabled={disabled}>
        <TrashIcon />
      </Button>
    </Tooltip>
  ) : (
    <Button onClick={onClick} disabled={disabled}>
      <TrashIcon />
    </Button>
  )
