import { STORAGE_KEYS } from 'consts'

import { useLocalStorage } from './useLocalStorage'

const CUSTOM_API_KEY = 'custom_api_key'

export const useImageUrl = () => {
  const [userToken] = useLocalStorage<string>(STORAGE_KEYS.USER_TOKEN)

  const getUrlWithToken = (baseUrl: string) => {
    return baseUrl.includes(CUSTOM_API_KEY) ? baseUrl : `${baseUrl}&${CUSTOM_API_KEY}=${userToken}`
  }

  return { getUrlWithToken }
}
