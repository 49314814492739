export enum AmplitudeEventsName {
  LOGIN = 'Login',
  DASHBOARD_IMPRESSION = 'Impression:Dashboard',
  INDEX_CHANGE = 'Index change',
  SELECTED_DATE_CHANGE = 'Selected date change',
  MAP_IMPRESSION = 'Impression: Map',
  APP_OPENING = 'App opening',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Properties = Record<string, any>
