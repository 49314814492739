import { useUser } from './useUser'

export const useFeatures = () => {
  const user = useUser()

  const organizationFeatureFlags = user?.organizationRole?.organization?.featureFlags

  return {
    organizationFeatures: {
      general: organizationFeatureFlags?.general,
      crm: organizationFeatureFlags?.crm,
      subFarmersManagement: organizationFeatureFlags?.subFarmersManagement,
    },
  }
}
