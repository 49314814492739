import { useQuery } from '@apollo/client'

import { USER, User, UserVariables } from 'api'
import { AuthContext } from 'contexts'

export const useCurrentUser = () => {
  const { userId } = AuthContext.useAuthContext()

  const { data, loading } = useQuery<User, UserVariables>(USER, {
    variables: { id: userId! },
    skip: !userId,
  })

  return { user: data?.user, loading }
}
