import styled from 'styled-components'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'

import { HiddenEyeIcon, PolygonIcon, ToolbarTrashIcon } from 'assets/icons'
import { DrawingOption } from 'features/MainRouter/features/Map/types'

const MenuContainer = styled(Menu)`
  width: 220px;
  border-radius: ${({ theme }) => theme.foundation.smallRadius} !important;
  border: none;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]} !important;
  .ant-dropdown-menu-item {
    font-size: 13px !important;
    display: flex;
    gap: 17px;
    padding: 0 17px;
    align-items: center;
    font-weight: 600;
    margin: 0 !important;
    height: 52px !important;
    color: ${({ theme }) => theme.colors.new.baseColor[400]} !important;
  }
  .ant-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 4px !important;
  }
  &:hover {
    .ant-dropdown-menu-item-disabled {
      background-color: transparent !important;
    }
  }
`

interface Props {
  selectedItem: DrawingOption
  onDrawingOptionSelected: (option: DrawingOption) => void
  isDrawingToolAvailable: boolean
}

export const MeasureAreaDropdown: React.FC<Props> = ({
  selectedItem,
  onDrawingOptionSelected,
  isDrawingToolAvailable,
}) => {
  const { t } = useTranslation('map')

  const dataSource = [
    {
      key: 'draw',
      label: t('tools.draw'),
      icon: <PolygonIcon />,
    },
    {
      key: 'hide',
      label: t('tools.hide'),
      icon: <HiddenEyeIcon />,
      disabled: isDrawingToolAvailable,
    },
    {
      key: 'delete',
      label: t('tools.delete'),
      icon: <ToolbarTrashIcon />,
      disabled: isDrawingToolAvailable,
    },
    {
      key: 'delete-all',
      label: t('tools.deleteAll'),
      icon: <ToolbarTrashIcon />,
      disabled: isDrawingToolAvailable,
    },
  ]

  return (
    <MenuContainer
      selectedKeys={selectedItem ? [selectedItem] : []}
      onClick={e => onDrawingOptionSelected(e.key as DrawingOption)}
      items={dataSource}
    />
  )
}
