import { Popover } from 'antd'
import { useTheme } from 'styled-components'

import { InformationIcon } from 'assets/icons'

import { Content } from './components'

export const UpdateTypeInformation = ({
  title,
  maxWidth = 230,
}: {
  title?: string
  maxWidth?: number
}) => {
  const { colors } = useTheme()
  return (
    <Popover
      arrow={false}
      content={() => <Content title={title} />}
      overlayInnerStyle={{
        background: colors.new.baseColor[500],
        maxWidth,
        borderRadius: 16,
        padding: 16,
      }}
    >
      <InformationIcon />
    </Popover>
  )
}
