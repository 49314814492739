import { Col } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const UploadContainer = styled(Col)`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
`

const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.grey2};
`

interface Props {
  image?: string
}

export const SmallUploadBox: React.FC<Props> = ({ image }) => {
  const { t } = useTranslation('common')

  return (
    <UploadContainer>
      {image ? <Image src={image} alt={t('misc.logoImageAlt')} /> : <StyledPlusCircleOutlined />}
    </UploadContainer>
  )
}
