import { Col, Row, Space } from 'antd'
import styled from 'styled-components'
import { ComponentType } from 'react'

const FullWidthRow = styled(Row)`
  flex: 1;
  cursor: pointer;
`

const IconContainer = styled(Col)`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Button = styled(Col)`
  padding: 0;
  display: flex;
  column-gap: 4px;
  flex-wrap: wrap;
  align-items: center !important;
  min-height: 100%;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
  & span {
    font-style: normal;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.new.secondary[400]};
  }
`

const TextButton = styled.span`
  color: ${({ theme }) => theme.colors.white};
`
interface Props {
  Icon: ComponentType
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  primaryTextButton: string
  secondaryTextButtonParts: string[]
}
export const ActionButton: React.FC<Props> = ({
  Icon,
  onClick,
  primaryTextButton,
  secondaryTextButtonParts,
}) => {
  return (
    <FullWidthRow wrap={false} onClick={onClick}>
      <Col flex={1}>
        <Space size="middle">
          <IconContainer>
            <Icon />
          </IconContainer>
          <Col span={24}>
            <Button>
              {primaryTextButton}
              {secondaryTextButtonParts.map(secondaryTextButtonPart => (
                <TextButton key={secondaryTextButtonPart}>{secondaryTextButtonPart}</TextButton>
              ))}
            </Button>
          </Col>
        </Space>
      </Col>
    </FullWidthRow>
  )
}
