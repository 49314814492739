import styled, { useTheme } from 'styled-components'

import { ChlorophyllIcon, DropIcon } from 'assets/icons'
import { IndicatorStatus, Indicators } from 'types'

interface IconProps {
  background: string
}
const IconContainer = styled.div<IconProps>`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background }) => background};
`
interface Props {
  type: Indicators
  indicatorStatus?: IndicatorStatus
}

export const Indicator: React.FC<Props> = ({ type, indicatorStatus }) => {
  const { colors } = useTheme()

  const indicatorBackground = {
    [IndicatorStatus.GOOD]: colors.new.trafficLightGreen,
    [IndicatorStatus.MEDIUM]: colors.new.trafficLightYellow,
    [IndicatorStatus.BAD]: colors.new.trafficLightRed,
  }

  const getIndicatorBackground = (indicator?: IndicatorStatus) => {
    if (!indicator) return colors.grey2
    return indicatorBackground[indicator]
  }

  return (
    <IconContainer background={getIndicatorBackground(indicatorStatus)}>
      {type === Indicators.CHLOROPHYLL ? (
        <ChlorophyllIcon color="white" />
      ) : (
        <DropIcon color="white" />
      )}
    </IconContainer>
  )
}
