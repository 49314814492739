import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LotsMapLocationState } from 'types'
import { ActiveCompanyContext } from 'contexts'

import { Card } from '../Card'

interface Props {
  companyCreation: boolean
}
export const OrganizationEmptyResults: React.FC<Props> = ({ companyCreation }) => {
  const navigate = useNavigate()
  const { activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation('settings')

  if (!companyCreation) return null

  return (
    <Card
      title={t('modals.firstClientLot.title')}
      description={t('modals.firstClientLot.description')}
      onClick={() => {
        const state: LotsMapLocationState = { seasonId: activeSeasonId, goTo: '/map' }
        return navigate('/settings/lots', {
          state,
        })
      }}
      buttonText={t('modals.firstClientLot.buttonText')}
    />
  )
}
