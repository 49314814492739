import { DateState, MapIndex, CalendarDatesMapIndices, SatelliteProviders } from 'types'
import { AvailableSatellites } from 'features/MainRouter/features/Map/types'
import { MapContext } from 'features/MainRouter/contexts'

import { SatelliteInformationSelect } from '../SatelliteInformationSelect'
import { CompareCalendar, CompareCalendarContainer } from './components'
import { SatelliteSelect } from '../SatelliteSelect'

interface Props {
  calendarDatesIndices: CalendarDatesMapIndices
  selectedCompareMapIndex: MapIndex
  selectedMapIndex: MapIndex
  onHandleCompareCalendarDateChange: (date?: string) => void
  selectedCompareCalendarDate?: string
  selectedCalendarDate?: string
  setDates: React.Dispatch<React.SetStateAction<DateState>>
  grainHumidityHasNotEmergenceDaysActivation: boolean
  onHandleCompareMapIndexChange: (mapIndex: MapIndex) => void
  isWaterAvailable: boolean
  isWeedsAvailable: boolean
  isGrainHumidityAvailable: boolean
  onHandleCalendarDateChange: (date?: string) => void
  onHandleMapIndexChange: (mapIndex: MapIndex) => void
  onHandleSatelliteChange: (value: SatelliteProviders) => void
  selectedSatellite: SatelliteProviders
  onHandleCompareSatelliteChange: (value: SatelliteProviders) => void
  selectedCompareSatellite: SatelliteProviders
  compareCalendarDateIndices: CalendarDatesMapIndices
  availableSatellites: AvailableSatellites
}
export const CompareTopBars: React.FC<Props> = ({
  calendarDatesIndices,
  selectedCompareMapIndex,
  selectedCompareCalendarDate,
  setDates,
  grainHumidityHasNotEmergenceDaysActivation,
  onHandleCompareMapIndexChange,
  isWaterAvailable,
  isWeedsAvailable,
  isGrainHumidityAvailable,
  selectedMapIndex,
  onHandleCalendarDateChange,
  selectedCalendarDate,
  onHandleMapIndexChange,
  onHandleCompareCalendarDateChange,
  onHandleSatelliteChange,
  selectedSatellite,
  onHandleCompareSatelliteChange,
  selectedCompareSatellite,
  compareCalendarDateIndices,
  availableSatellites,
}) => {
  const { isCalendarOpen, setIsCalendarOpen, isCompareCalendarOpen, setIsCompareCalendaropen } =
    MapContext.useMapContext()
  return (
    <>
      <CompareCalendarContainer>
        <CompareCalendar
          calendarDates={compareCalendarDateIndices[selectedCompareMapIndex]}
          onChange={onHandleCompareCalendarDateChange}
          defaultValue={selectedCompareCalendarDate}
          setDates={setDates}
        >
          <SatelliteInformationSelect
            grainHumidityHasNotEmergenceDaysActivation={grainHumidityHasNotEmergenceDaysActivation}
            onChange={onHandleCompareMapIndexChange}
            selectedMapIndex={selectedCompareMapIndex}
            bordered={false}
            isWaterAvailable={isWaterAvailable}
            isGrainHumidityAvailable={isGrainHumidityAvailable}
            isWeedsAvailable={isWeedsAvailable}
            width="215px"
          />
          <SatelliteSelect
            onChange={onHandleCompareSatelliteChange}
            selectedSatellite={selectedCompareSatellite}
            width="0px"
            popupMatchSelectWidth={false}
            isCompareMapMode
            isPlanetAvailable={availableSatellites.comparePlanet}
            isLandsatAvailable={availableSatellites.compareLandsat}
            isSentinelAvailable={availableSatellites.compareSentinel}
            isOpen={isCompareCalendarOpen}
            handleOpen={setIsCompareCalendaropen}
          />
        </CompareCalendar>
      </CompareCalendarContainer>
      <CompareCalendarContainer position="right">
        <CompareCalendar
          calendarDates={calendarDatesIndices[selectedMapIndex]}
          onChange={onHandleCalendarDateChange}
          defaultValue={selectedCalendarDate}
          setDates={setDates}
        >
          <SatelliteInformationSelect
            grainHumidityHasNotEmergenceDaysActivation={grainHumidityHasNotEmergenceDaysActivation}
            onChange={onHandleMapIndexChange}
            selectedMapIndex={selectedMapIndex}
            bordered={false}
            isWaterAvailable={isWaterAvailable}
            isGrainHumidityAvailable={isGrainHumidityAvailable}
            isWeedsAvailable={isWeedsAvailable}
            width="215px"
          />
          <SatelliteSelect
            onChange={onHandleSatelliteChange}
            selectedSatellite={selectedSatellite}
            width="0px"
            popupMatchSelectWidth={false}
            isCompareMapMode
            isPlanetAvailable={availableSatellites.planet}
            isLandsatAvailable={availableSatellites.landsat}
            isSentinelAvailable={availableSatellites.sentinel}
            isOpen={isCalendarOpen}
            handleOpen={setIsCalendarOpen}
          />
        </CompareCalendar>
      </CompareCalendarContainer>
    </>
  )
}
