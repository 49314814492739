import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { RiceIcon } from 'assets/icons'
import { useUpdateSeason } from 'hooks'
import { SeasonMillDeliveryType } from 'types'
import { UNITS } from 'consts'

import {
  ContainerCard,
  HeaderCard,
  StyledRow,
  Ball,
  Title,
  Text,
  SubText,
  Input,
  DeliverySelect,
} from './components'

interface Props {
  seasonId: number
  estimated: number
  declared: number
  delivered?: number
  millDeliveryType?: SeasonMillDeliveryType
}
export const RiceProductionCard: React.FC<Props> = ({
  seasonId,
  estimated,
  declared,
  delivered,
  millDeliveryType,
}) => {
  const { t } = useTranslation('dashboard')

  const { updateSeason } = useUpdateSeason()

  const onChangeDeliveryType = (value: SeasonMillDeliveryType) => {
    updateSeason({
      variables: {
        id: seasonId,
        updateSeasonDTO: {
          millDeliveryType: value,
        },
      },
    })
  }

  const onBlurDeliveredInput = (value: number) => {
    updateSeason({
      variables: {
        id: seasonId,
        updateSeasonDTO: {
          productionToMill: value / UNITS.KG_TONS_RATIO,
        },
      },
    })
  }

  return (
    <ContainerCard>
      <Space direction="vertical" size={24}>
        <HeaderCard>
          <RiceIcon />
          <Title level={5}>{t('productionCard.title')}</Title>
        </HeaderCard>
        <Space direction="vertical" size={5}>
          <StyledRow>
            <Ball paint={!!estimated} />
            <Text>{t('productionCard.estimated')}</Text>
            <SubText>
              {estimated === 0 ? '-' : estimated?.toFixed()} {t('productionCard.tons')}
            </SubText>
          </StyledRow>
          <StyledRow>
            <Ball paint={!!declared} />
            <Text>{t('productionCard.declared')}</Text>
            <SubText>
              {declared === 0 ? '-' : declared?.toFixed()} {t('productionCard.tons')}
            </SubText>
          </StyledRow>
          <StyledRow>
            <Ball paint={!!delivered} />
            <Text>{t('productionCard.delivered')}</Text>
            <Input
              type="number"
              min={0}
              style={{ textAlign: 'center' }}
              placeholder={delivered ? undefined : '-'}
              defaultValue={delivered}
              onBlur={e => onBlurDeliveredInput(Number(e.target.value))}
            />
            <SubText>{t('productionCard.tons')}</SubText>
          </StyledRow>
        </Space>
        <DeliverySelect value={millDeliveryType} onChange={onChangeDeliveryType} />
      </Space>
    </ContainerCard>
  )
}
