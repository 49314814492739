import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BROADCAST_CHANNEL } from 'consts'

import { useAuth } from './useAuth'

interface Props {
  resetActiveCompany: () => void
}

export const useBrowserLogout = ({ resetActiveCompany }: Props) => {
  const { authChannel, clearUserData } = useAuth()
  const navigate = useNavigate()

  const authChannelHandler = useCallback(
    async (e: MessageEvent) => {
      if (e.data === BROADCAST_CHANNEL.AUTH.LOGOUT) {
        resetActiveCompany()
        await clearUserData()
        navigate('/login', { replace: true })
      }
    },
    [clearUserData, navigate, resetActiveCompany],
  )

  useEffect(() => {
    return () => {
      authChannel?.close()
    }
  }, [authChannel])

  useEffect(() => {
    authChannel?.addEventListener('message', authChannelHandler)

    return () => {
      authChannel?.removeEventListener('message', authChannelHandler)
    }
  }, [authChannel, authChannelHandler])
}
