import styled from 'styled-components'
import { Typography } from 'antd'

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive};
  font-weight: 500 !important;
  margin-bottom: 0 !important;
`

interface Props {
  title: string
  level?: 5 | 1 | 2 | 3 | 4
  overlayInnerStyle?: React.CSSProperties
}

export const EllipsisTitle: React.FC<Props> = ({ title, level, overlayInnerStyle }) => (
  <span translate="no">
    <Title level={level} ellipsis={{ tooltip: title }} style={overlayInnerStyle}>
      {title}
    </Title>
  </span>
)
