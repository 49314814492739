import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { useFormatNumbersWithScale } from 'hooks'

import {
  Title,
  Progress,
  SubTitle,
  Box,
  StyledRow,
  Text,
  MarginRow,
  CenteredCol,
} from './components'

interface Props {
  area?: number
  volume?: number
  percentage?: number
  name: string
}

export const DamCard: React.FC<Props> = ({ area, volume, percentage, name }) => {
  const { t } = useTranslation('dashboard')
  const { colors } = useTheme()
  const { formatNumberWithScale } = useFormatNumbersWithScale()

  const areaInfo = area ? formatNumberWithScale(area) : undefined
  const volumeInfo = volume ? formatNumberWithScale(volume) : undefined

  return (
    <>
      <Col>
        <Title level={5}> {`${t('damCard.title')} - ${name}`} </Title>
      </Col>
      <Col span={24}>
        <Row>
          <CenteredCol span={10}>
            <Progress
              type="circle"
              percent={percentage}
              size={90}
              strokeLinecap="square"
              strokeColor={colors.primary}
              trailColor={colors.grey2}
              format={percent => (percentage ? `${percent} %` : '-')}
            />
          </CenteredCol>
          <Col span={14}>
            <Row>
              <Col span={24}>
                <SubTitle> {t('damCard.area')} </SubTitle>
              </Col>

              <StyledRow wrap={false} gutter={10} align="middle">
                <Box flex={1}>{areaInfo ? areaInfo.value : '-'}</Box>
                <Col span="auto">
                  <Text>{areaInfo && ` ${areaInfo.scale} m2`}</Text>
                </Col>
              </StyledRow>
            </Row>
            <MarginRow>
              <Col span={24}>
                <SubTitle> {t('damCard.volume')} </SubTitle>
              </Col>
              <StyledRow wrap={false} align="middle" gutter={10}>
                <Box flex={1}>{volumeInfo ? volumeInfo.value : '-'}</Box>
                <Col flex="auto">
                  <Text>{volumeInfo && ` ${volumeInfo.scale} m3`}</Text>
                </Col>
              </StyledRow>
            </MarginRow>
          </Col>
        </Row>
      </Col>
    </>
  )
}
