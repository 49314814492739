import { Table as AntTable, Button as BaseButton, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { UserAvatar } from 'components'
import { TABLE } from 'consts'
import { UserCompanyRole } from 'types'
import { ShareArrowIcon } from 'assets/icons'

import { Invitation, UserRole, CompanyUserOrganizationRole } from '../../api'
import { RoleSelect } from '../RoleSelect'
import { useCompanyResendInvitation } from './hooks'

const { Text } = Typography

const StyledRoleSelect = styled(RoleSelect)`
  width: 100%;
`

const Button = styled(BaseButton).attrs({
  type: 'primary',
})`
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Table = styled(AntTable)`
  & .ant-table-header {
    padding-bottom: ${({ scroll }) => scroll && '16px '};
  }
  .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.colors.grey2};
    &:before {
      width: 0px !important;
    }
  }
  & .ant-table-cell-scrollbar {
    box-shadow: none;
  }

  .ant-table-tbody .ant-table-cell {
    border-bottom: ${({ theme }) => `5px solid ${theme.colors.grey2}`};
  }
  .ant-table-row {
    &:last-child {
      .ant-table-cell {
        border-bottom: 0 !important;
      }
    }
  }
` as typeof AntTable

interface TableUser {
  key: string
  id?: number
  fullname?: string
  email: string
  role: UserCompanyRole
  profilePicture?: string
  organizationRole?: CompanyUserOrganizationRole
  invitationId?: number
}

interface Props {
  users: UserRole[]
  invitations: Invitation[]
  isReadOnly?: boolean
  onUserRoleChange: (userId: number, role: UserCompanyRole) => void
  currentUserId: number
  isAnOrganizationCompany: boolean
  isAnOrganizationUser: boolean
}

export const CompanyUsersTable: React.FC<Props> = ({
  users,
  invitations,
  isReadOnly,
  onUserRoleChange,
  currentUserId,
  isAnOrganizationCompany,
  isAnOrganizationUser,
}) => {
  const { t } = useTranslation('company-settings')
  const { resendCompanyInvitation, inviteLoadingId } = useCompanyResendInvitation()

  const mappedUsers: TableUser[] = users.map(({ user, role }) => ({
    id: user.id,
    key: user.id.toString(),
    fullname: `${user.name} ${user.lastName}`,
    email: user.email,
    profilePicture: user.profilePicture ?? undefined,
    role,
    organizationRole: user.organizationRole ?? undefined,
  }))
  const mappedInvitations: TableUser[] = invitations.map(invitation => ({
    key: invitation.email,
    email: invitation.email,
    role: invitation.role,
    invitationId: invitation.id,
  }))

  const isDataSourceBiggerThanMaxTableUsers =
    mappedUsers.length + mappedInvitations.length > TABLE.MAX_TABLE_DATA
  const columns: ColumnsType<TableUser> = [
    {
      title: t('usersTable.member'),
      width: isDataSourceBiggerThanMaxTableUsers ? '16%' : undefined,
      render: (user: TableUser) => <UserAvatar src={user.profilePicture ?? null} />,
    },
    {
      render: (user: TableUser) => <Text strong>{user.fullname ?? t('usersTable.guestUser')}</Text>,
      width: isDataSourceBiggerThanMaxTableUsers ? '25%' : undefined,
    },
    {
      render: (user: TableUser) => <Text>{user.email}</Text>,
      width: isDataSourceBiggerThanMaxTableUsers ? '40%' : undefined,
    },
    {
      title: t('usersTable.role'),
      width: '30%',
      render: (invitedUser: TableUser) => (
        <StyledRoleSelect
          defaultValue={invitedUser.role}
          disabled={
            isReadOnly ||
            !invitedUser.id ||
            currentUserId === invitedUser.id ||
            (!isAnOrganizationUser && invitedUser.organizationRole?.role && isAnOrganizationCompany)
          }
          onChange={newRole => onUserRoleChange(invitedUser.id!, newRole)}
        />
      ),
    },
    {
      width: '10%',
      render: (invitedUser: TableUser) => {
        return (
          <>
            {!isReadOnly && invitedUser.invitationId && (
              <Button
                icon={<ShareArrowIcon color="white" />}
                loading={inviteLoadingId === invitedUser.invitationId}
                onClick={() => resendCompanyInvitation(invitedUser.invitationId!)}
              />
            )}
          </>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={[...mappedUsers, ...mappedInvitations]}
      columns={columns}
      pagination={false}
      scroll={isDataSourceBiggerThanMaxTableUsers ? { y: 240 } : undefined}
    />
  )
}
