import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Loader } from 'components'
import { useIsProdEnv } from 'hooks'

import { FormEmailInput, FormPasswordInput, CenteredText } from '../../components'
import { useLogin, useLoginForm, useVerifyAccount } from './hooks'
import { LoginButton, MainText, Space, StyledLink, LoginErrorText } from './components'
import { useFormIconsStyle } from '../../hooks'

export const Login: React.FC = () => {
  const iconStyle = useFormIconsStyle()
  const { t } = useTranslation('login')

  const { control, handleSubmit } = useLoginForm()
  const [login, { loading, error }] = useLogin()
  const {
    loading: loadingVerification,
    accountVerificationText,
    isAccountVerified,
    isVerification,
    emailToValidate,
  } = useVerifyAccount()
  const isProdEnv = useIsProdEnv()

  const verification = loadingVerification ? <Loader /> : accountVerificationText

  const onLogin = handleSubmit(credentials => login({ variables: credentials }))

  const onPasswordInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onLogin()
    }
  }

  return (
    <>
      {!isProdEnv && (
        <MainText>
          {t('loginPage.createAccountText.part1')}
          <StyledLink to="signup">{t('loginPage.createAccountText.part2')}</StyledLink>
          {t('loginPage.createAccountText.part3')}
        </MainText>
      )}
      <Space direction="vertical" size={18}>
        <FormEmailInput
          control={control}
          iconStyle={iconStyle}
          defaultValue={isAccountVerified && emailToValidate ? emailToValidate : undefined}
        />
        <FormPasswordInput control={control} iconStyle={iconStyle} onKeyUp={onPasswordInputKeyUp} />
        <LoginButton loading={loading} type="primary" onClick={onLogin}>
          {t('loginPage.loginButton')}
        </LoginButton>
      </Space>
      {error && (
        <LoginErrorText error>
          {t('loginPage.errorText.part1')} <br />
          {t('loginPage.errorText.part2')}
          {/*  eslint-disable-next-line i18next/no-literal-string */}
          <a href="mailto:info@kuna.app">info@kuna.app</a>
        </LoginErrorText>
      )}
      <CenteredText error={isVerification && !isAccountVerified}>
        {isVerification && verification}
      </CenteredText>
      <CenteredText>
        <Link to="forgot-password">{t('loginPage.forgotPassword')}</Link>
      </CenteredText>
    </>
  )
}
