import styled from 'styled-components'

import {
  SeasonFieldsSelect as BaseSeasonFieldsSelect,
  CropsSelect as BaseCropsSelect,
  VarietiesSelect as BaseVarietiesSelect,
} from 'components'

import { LotsSelect as BaseLotsSelect } from './LotsSelect'

export { MapPopup } from './MapPopup'
export { Toolbar } from './Toolbar'
export { CompareImagesMap } from './CompareImagesMap'
export { TopSplitPanels } from './TopSplitPanels'
export { DefaultMap } from './DefaultMap'

export const SeasonFieldsSelect = styled(BaseSeasonFieldsSelect)`
  min-width: 100px;
  max-width: 150px;
  @media (min-width: 900px) {
    max-width: 250px;
  }
  .ant-select-selector {
    font-size: 13px;
    color: white;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
`

export const LotsSelect = styled(BaseLotsSelect)`
  min-width: 100px;
  max-width: 150px;
  @media (min-width: 900px) {
    max-width: 250px;
  }
  .ant-select-selector {
    font-size: 13px;
    color: white;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
`

export const CropsSelect = styled(BaseCropsSelect)`
  min-width: 100px;
  max-width: 150px;
  @media (min-width: 900px) {
    max-width: 250px;
  }
  .ant-select-selector {
    font-size: 13px;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-clear {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
`

export const MapContainer = styled.div`
  width: calc(100vw - 80px);
  height: calc(100vh - 48px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const Container = styled.div`
  position: relative;
  height: 100%;
`

export const VarietiesSelect = styled(BaseVarietiesSelect)<{ disabled: boolean }>`
  min-width: 100px;
  max-width: 150px;
  @media (min-width: 900px) {
    max-width: 250px;
  }
  .ant-select-selector {
    font-size: 13px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.grey3 : theme.colors.white} !important;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-clear {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
`
