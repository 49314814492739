import { useTheme } from 'styled-components'

import { MilestoneUpdateType } from 'types'

export const useUpdateTypeStyles = () => {
  const { colors } = useTheme()

  const getBallBackground = (milestoneType?: MilestoneUpdateType) => {
    if (milestoneType === MilestoneUpdateType.MANUAL) return colors.new.secondary[600]
    return milestoneType === MilestoneUpdateType.AUTOMATIC_SENTINEL1
      ? colors.new.timelineLightBlue
      : colors.new.timelineViolet
  }

  return { getBallBackground }
}
