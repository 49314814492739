import { Navigate } from 'react-router-dom'

import { useAuth } from 'hooks'

interface Props {
  children: React.ReactElement
}
export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { userId } = useAuth()

  if (!userId) return <Navigate to="/login" replace />

  return children
}
