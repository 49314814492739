import { useMutation } from '@apollo/client'

import { UpdateSeason, UpdateSeasonVariables, UPDATE_SEASON } from 'api'
import { GraphQLUtils } from 'utils'

export const useUpdateSeason = () => {
  const [updateSeason, { loading }] = useMutation<UpdateSeason, UpdateSeasonVariables>(
    UPDATE_SEASON,
    {
      onError: GraphQLUtils.errorHandler,
    },
  )

  return { updateSeason, loading }
}
