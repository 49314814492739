import { Space, Col, Row } from 'antd'
import styled from 'styled-components'

export { EmergenceDateCard } from './EmergenceDateCard'
export { IrrigationMonitorCard } from './IrrigationMonitorCard'
export { TrafficLight } from './TrafficLight'
export { Timeline } from './Timeline'
export { LastUpdateCard } from './LastUpdateCard'
export { PhytosanitaryTable } from './PhytosanitaryTable'
export { FertilizerTable } from './FertilizerTable'
export { Comment } from './Comment'
export { GrainHumidity } from './GrainHumidity'
export { Yield } from './Yield'
export { ProductionTypeAndAbandonedSizeCard } from './ProductionTypeAndAbandonedSizeCard'

export const ContainerCol = styled(Col)`
  height: 100%;
`

export const StyledCol = styled(Col)`
  overflow: hidden;
`

export const TablesSpace = styled(Space).attrs({
  direction: 'vertical',
  size: 24,
})`
  height: 100%;
  > .ant-space-item {
    flex: 1;
  }
`
export const RowContainer = styled(Row)`
  height: 100%;
`

export const YieldCardContainer = styled(Col)`
  height: 100%;
  margin-top: 24px;
`
