import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { unionBy } from 'lodash'
import dayjs from 'dayjs'

import { DATE_FORMATS } from 'consts'

import { LotsVariables, LOTS, Lots, Lot } from './api'

interface Props {
  seasonId?: number
  fieldId?: number
  pollInterval?: number
  fromYearMonth?: Date
  toYearMonth?: Date
  setSelectedLots: (lots: Lot[]) => void
  selectedLots: Lot[]
  setSelectedCropId: (id?: number) => void
  onLotWithoutCalendar: () => void
}

export const useLots = ({
  seasonId,
  fieldId,
  pollInterval,
  fromYearMonth,
  toYearMonth,
  selectedLots,
  setSelectedLots,
  setSelectedCropId,
  onLotWithoutCalendar,
}: Props) => {
  const { t } = useTranslation('common')
  const [lots, setLots] = useState<Lot[]>([])

  const { data, loading } = useQuery<Lots, LotsVariables>(LOTS, {
    variables: {
      seasonId: seasonId!,
      fieldId: fieldId!,
      includeMapUrls: !!fromYearMonth && !!toYearMonth,
      fromYearMonth: fromYearMonth
        ? dayjs(fromYearMonth).format(DATE_FORMATS.YEAR_MONTH)
        : undefined,
      toYearMonth: toYearMonth ? dayjs(toYearMonth).format(DATE_FORMATS.YEAR_MONTH) : undefined,
    },
    pollInterval: pollInterval ?? 0,
    onError: error => {
      Sentry.captureException(error)

      notification.error({
        duration: 0,
        message: t('messages.genericErrorRefreshPage'),
      })
    },
    skip: !fieldId || !seasonId,
  })

  const checkCropEquality = (_lots: Lot[]) => {
    const cropIds = _lots.map(lot => lot.crop.id)
    return cropIds.every(val => val === _lots[0].crop.id)
  }

  useEffect(() => {
    const newLots = data?.lotsV2.results.map(queryLot => {
      const { riceLot: queryRiceLot, ...lot } = queryLot
      const { calendarV3, ...riceLot } = queryRiceLot

      return {
        ...lot,
        riceLot: {
          ...riceLot,
          calendar: unionBy(
            calendarV3,
            lots.find(stateLot => stateLot?.id === lot.id)?.riceLot.calendar ?? [],
            'date',
          ),
        },
      }
    })

    if (!newLots?.length) return

    const hasLotWithoutCalendar = data?.lotsV2.results.some(lot => !lot.riceLot.calendarV3)

    if (hasLotWithoutCalendar) onLotWithoutCalendar()

    setLots(newLots)
    const selectedLot =
      selectedLots.length === 1 && newLots.find(lot => lot.id === selectedLots[0].id)

    if (!selectedLot) {
      setSelectedLots(newLots)
      const allLotsSameCropId = checkCropEquality(newLots)
      const cropId = allLotsSameCropId ? newLots[0].crop.id : undefined
      setSelectedCropId(cropId)
      return
    }

    setSelectedLots([selectedLot])
    setSelectedCropId(selectedLot.crop.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return { lots, setLots, loading }
}
