import { yupResolver } from '@hookform/resolvers/yup'
import { Row, Col, Form, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal as ModalBase, InputTitle, Button } from 'components'
import { UserCompanyRole } from 'types'

import { RoleSelect } from '../RoleSelect'

const StyledRoleSelect = styled(RoleSelect)`
  width: 100%;
`

const Description = styled(Row)`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
`

const Modal = styled(ModalBase)`
  width: 600px !important;
`

interface Props {
  isAnOrganizationCompany?: boolean
  onInvite: (email: string, role: UserCompanyRole) => void
  onCancel: () => void
}

export const InviteUserModal: React.FC<Props> = ({
  isAnOrganizationCompany,
  onInvite,
  onCancel,
}) => {
  const { t: commonT } = useTranslation('common')

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(commonT('validations.email'))
      .required(commonT('validations.required')),
    role: yup
      .string()
      .is([...Object.keys(UserCompanyRole)])
      .required(commonT('validations.required')),
  })

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { role: UserCompanyRole.VIEWER },
  })

  const { t } = useTranslation('company-settings')

  return (
    <Modal title={t('newMemberModal.title')} open onCancel={onCancel} centered footer={null}>
      <Description>
        {isAnOrganizationCompany
          ? t('newMemberModal.descriptionClient')
          : t('newMemberModal.description')}
      </Description>
      <Row gutter={28}>
        <Col flex={1}>
          <InputTitle title={t('newMemberModal.inputs.email')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="email"
            control={control}
            render={({
              field: { onChange, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Input onChange={event => onChange(event.target.value)} {...restFieldProps} />
              </Form.Item>
            )}
          />
        </Col>
        <Col span={6}>
          <InputTitle title={t('newMemberModal.inputs.role')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="role"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledRoleSelect defaultValue={value} onChange={role => onChange(role)} />
            )}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Button type="primary" onClick={handleSubmit(({ email, role }) => onInvite(email, role))}>
          {t('buttons.invite')}
        </Button>
      </Row>
    </Modal>
  )
}
