import styled from 'styled-components'
import { Col, Typography, Space, Divider as AntDivider } from 'antd'

const { Title: AntTitle, Text: AntText, Link: AntLink } = Typography

export const Title = styled(AntTitle)`
  font-weight: normal !important;
  margin-bottom: 0px !important;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.grey1} !important;
`

export const FlexCol = styled(Col)`
  display: flex;
`
export const StyledCol = styled(Col)`
  height: 230px;
  width: 100%;
  margin-top: 24px;
`
export const Divider = styled(AntDivider)`
  margin: 0 !important;
  background: rgba(246, 246, 246, 0.1) !important;
`

// Prefix the prop name with a dollar sign ($) is a workaround for prevent error : React not recognizing prop
// https://styled-components.com/docs/api#transient-props
interface Props {
  $isToday?: boolean
}

export const WeatherContainer = styled(Space).attrs({
  size: 12,
  direction: 'vertical',
})<Props>`
  border: 2px solid
    ${({ theme, $isToday }) => ($isToday ? theme.colors.grey1 : 'rgba(246, 246, 246, 0.1)')};
  width: 100%;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 12px 8px;
`

export const DayText = styled(AntText)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.colors.grey1};
  text-transform: capitalize;
`

export const TempMaxText = styled(DayText)`
  font-size: 20px;
`

export const TempMinText = styled(DayText)`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey2};
`

export const RainProbText = styled(DayText)`
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
`

export const SponsorText = styled(AntLink)`
  font-size: 8px;
  font-style: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    color: ${({ theme }) => theme.colors.white} !important;
    text-decoration: underline !important;
  }
`
