import styled from 'styled-components'
import { Row, Space, Typography } from 'antd'

export { DescriptionCard } from './DescriptionCard'
export { IndexInformationCard } from './IndexInformationCard'

const { Text: AntText } = Typography

export const SideBar = styled(Row)`
  width: 280px;
  position: absolute;
  flex-direction: column;
  top: 15px;
  right: 50px;
  z-index: 10;
  max-height: calc(100% - 80px);
  @media (max-height: 850px) {
    max-height: calc(100% - 50px);
  }
`

export const StyledCol = styled(Space)`
  width: 100%;
  overflow-y: auto;
`

export const Image = styled.img`
  background-position: center;
  width: 22px;
  height: 22px;
`

export const Text = styled(AntText)`
  word-break: normal;
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  width: 100px;
`
