import { Select as AntSelect, Space } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { STORAGE_KEYS } from 'consts'
import { useLocalStorage } from 'hooks'

import { SELECT } from '../../consts'

const StyledSpan = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.colors.olive5};
`

const Select = styled(AntSelect)`
  width: 184px;
  height: 38px;
` as typeof AntSelect

interface Props {
  onChange: (value: string) => void
}

export const SortSelect: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('dashboard')
  const [storedOrderDashboardLotsBy] = useLocalStorage<string>(STORAGE_KEYS.ORDER_DASHBOARD_LOTS_BY)

  const data = [
    { label: t('sortSelect.criticality'), value: SELECT.VALUES.CRITICALITY },
    { label: t('sortSelect.fieldName'), value: SELECT.VALUES.NAMES },
  ]

  return (
    <Space size={12}>
      <StyledSpan>{t('sortSelect.orderBy')}</StyledSpan>
      <Select
        defaultValue={storedOrderDashboardLotsBy ?? SELECT.VALUES.CRITICALITY}
        options={data}
        onChange={onChange}
      />
    </Space>
  )
}
