import { Routes, Route } from 'react-router-dom'

import { FieldsMap, LotsMap, Settings, Season } from './features'

export const SettingsRouter: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Settings />} />
      <Route path="fields/:id?" element={<FieldsMap />} />
      <Route path="lots" element={<LotsMap />} />
      <Route path="season/:id" element={<Season />} />
    </Routes>
  )
}
