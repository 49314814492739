import { Select } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { usePermissions } from 'hooks'
import { UserCompanyRole } from 'types'

type Props = Omit<React.ComponentProps<typeof Select>, 'onChange'> & {
  onChange: (role: UserCompanyRole) => void
}

export const RoleSelect: React.FC<Props> = ({ onChange, ...restProps }) => {
  const { t } = useTranslation('common')
  const { permissions } = usePermissions()

  const localizeRole = (role: UserCompanyRole) => t(`roles.${role}`)

  const roles = useMemo(() => {
    const allRoles = Object.values(UserCompanyRole)
    if (permissions.satelliteImagery) {
      return allRoles
    }

    return allRoles.filter(role => role !== UserCompanyRole.IRRIGATION_WORKER)
  }, [permissions.satelliteImagery])

  return (
    <Select
      onChange={newRole => {
        onChange(newRole as UserCompanyRole)
      }}
      {...restProps}
    >
      {roles.map(role => (
        <Select.Option key={role} value={role}>
          {localizeRole(role)}
        </Select.Option>
      ))}
    </Select>
  )
}
