import { Typography } from 'antd'
import styled from 'styled-components'

import { Modal, Button } from 'components'

export { Table } from './Table'
export { SeasonSelect } from './SeasonSelect'
export { EmptyResults } from './EmptyResults'

const { Text } = Typography

export const CancelButton = styled(Button)`
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  margin-right: 32px;
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }
`

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding-bottom: 5px !important;
  }
`
export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.olive5} !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-size: 13px !important;
`

export const FieldNameContainer = styled.div`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid #dddfdd;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.grey2}`};
  background: ${({ theme }) => theme.colors.grey2};
  display: flex;
  min-width: 200px;
  max-height: 38px;
  padding: 8px 16px;
  justify-content: center;
`

export const FieldName = styled(Text)`
  color: ${({ theme }) => theme.colors.olive5} !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  font-size: 14px !important;
`
