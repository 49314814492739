import { gql } from '@apollo/client'

export type { UpdateField, UpdateFieldVariables } from './types/UpdateField'

export const UPDATE_FIELD = gql`
  mutation UpdateField($id: Int!, $updateFieldDTO: UpdateFieldInput!) {
    updateField(id: $id, updateFieldDTO: $updateFieldDTO) {
      field {
        id
        name
        directions
        location {
          coordinates
        }
        productionType
        deliveryCondition
      }
    }
  }
`
