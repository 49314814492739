import { Button, Col, Select, Space as AntSpace } from 'antd'
import styled from 'styled-components'

export { LastModificationCallout } from './LastModificationCallout'

export const Space = styled(AntSpace)`
  width: 100%;
`

export const DatePickerCol = styled(Col)`
  flex: 1;

  .ant-picker {
    width: 100%;
  }
`

export const ConfirmButton = styled(Button)`
  height: 55px;
  width: 100%;
`

export const StyledSelect = styled(Select)`
  & .ant-select-selector {
    height: 55px !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
` as typeof Select
