import { MapIndex } from 'types'

type MapIndexAvailability = {
  [index in MapIndex]: boolean
}

interface AvailableIndexVariables {
  selectedMapIndex: MapIndex
  mapIndexAvailability: MapIndexAvailability
}

const getAvailableIndex = ({ selectedMapIndex, mapIndexAvailability }: AvailableIndexVariables) => {
  if (!mapIndexAvailability[selectedMapIndex]) return 'irrigation'
  return selectedMapIndex
}

export const IndicesUtils = {
  getAvailableIndex,
}
