import { gql } from '@apollo/client'

export type {
  UpdateRiceFertilizerVariables,
  UpdateRiceFertilizer,
} from './types/UpdateRiceFertilizer'

export const UPDATE_RICE_FERTILIZER = gql`
  mutation UpdateRiceFertilizer(
    $id: Int!
    $updateFertilizerDTO: UpdateRiceAppliedFertilizerInput!
  ) {
    updateRiceFertilizer(id: $id, updateFertilizerDTO: $updateFertilizerDTO) {
      code
      fertilizer {
        id
        name
        brand
        date
        quantity
        unit {
          id
          name
          locales {
            es
            en
          }
        }
        grades {
          grade
          nutrient {
            id
            name
            symbol
          }
        }
      }
    }
  }
`
