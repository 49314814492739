import { createRoot } from 'react-dom/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import 'antd/dist/reset.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-phone-input-2/lib/bootstrap.css'

import { SentryService, I18NService } from './services'
import { App } from './features/App'
import reportWebVitals from './reportWebVitals'

import 'styles/theme/fonts.css'

SentryService.init()
I18NService.init()

dayjs.extend(utc)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
