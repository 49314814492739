import { Row } from 'antd'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { GrainHumidityIcon } from 'assets/icons'

import { Chart, ChartContainer, CenterCol, StyledSpace, Ball, NotAvailableText } from './components'
import { GrainHumidity as GrainHumidityType } from '../../../../api'

interface Props {
  data?: GrainHumidityType | null
  hasAbsoluteHumidity: boolean
  isHarvested: boolean
}

export const GrainHumidity: React.FC<Props> = ({ data, hasAbsoluteHumidity, isHarvested }) => {
  const { t } = useTranslation('lot-dashboard')
  const { colors } = useTheme()

  const grainHumidityStatus = useMemo(() => {
    const levels = data?.levels
    const low = levels?.one

    const mediumLow = {
      lowEdge: levels?.two?.lowEdge,
      highEdge: levels?.three?.highEdge,
      percentage: (levels?.two?.percentage ?? 0) + (levels?.three?.percentage ?? 0),
    }

    const mediumHigh = {
      lowEdge: levels?.four?.lowEdge,
      highEdge: levels?.six?.highEdge,
      percentage:
        (levels?.four?.percentage ?? 0) +
        (levels?.five?.percentage ?? 0) +
        (levels?.six?.percentage ?? 0),
    }

    const high = levels?.seven

    return { low, mediumLow, mediumHigh, high }
  }, [data?.levels])

  const varietyData = [
    {
      color: colors.grainHumedityBrown,
      value: hasAbsoluteHumidity
        ? `<${grainHumidityStatus.low?.highEdge}%`
        : t('grainHumidityCard.status.dry'),
    },
    {
      color: colors.grainHumedityLightBrown,
      value: hasAbsoluteHumidity
        ? `${grainHumidityStatus.mediumLow?.lowEdge} ${t('grainHumidityCard.to')} ${
            grainHumidityStatus.mediumLow?.highEdge
          }%`
        : t('grainHumidityCard.status.midDry'),
    },
    {
      color: colors.grainHumedityYellow,
      value: hasAbsoluteHumidity
        ? `${grainHumidityStatus.mediumHigh?.lowEdge} ${t('grainHumidityCard.to')} ${
            grainHumidityStatus.mediumHigh?.highEdge
          }%`
        : t('grainHumidityCard.status.mid'),
    },
    {
      color: colors.grainHumedityLightGreen,
      value: hasAbsoluteHumidity
        ? `> ${grainHumidityStatus.high?.lowEdge}%`
        : t('grainHumidityCard.status.humid'),
    },
  ]

  const chartData = [
    { name: 'low', value: grainHumidityStatus.low?.percentage, color: colors.grainHumedityBrown },
    {
      name: 'mediumLow',
      value: grainHumidityStatus.mediumLow.percentage,
      color: colors.grainHumedityLightBrown,
    },
    {
      name: 'mediumHigh',
      value: grainHumidityStatus.mediumHigh.percentage,
      color: colors.grainHumedityYellow,
    },
    {
      name: 'high',
      value: grainHumidityStatus.high?.percentage,
      color: colors.grainHumedityLightGreen,
    },
  ]

  return (
    <Row wrap={false} gutter={24}>
      <ChartContainer>
        {data?.isAvailable ? <Chart data={chartData} /> : <GrainHumidityIcon />}
      </ChartContainer>
      {!isHarvested && (
        <CenterCol>
          {data?.isAvailable ? (
            <StyledSpace size={10} direction="vertical">
              {varietyData.map(variety => {
                return (
                  <StyledSpace size={8} key={variety.color}>
                    <Ball color={variety.color} />
                    <span>{variety.value}</span>
                  </StyledSpace>
                )
              })}
            </StyledSpace>
          ) : (
            <NotAvailableText
              emergenceDaysActivation={data?.emergenceDaysActivation ?? undefined}
            />
          )}
        </CenterCol>
      )}
    </Row>
  )
}
