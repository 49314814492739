import { Card, Row, Col, Typography } from 'antd'
import styled from 'styled-components'

import { loginCardRiceImage } from 'assets/images'
import { MixinsStyles } from 'styles'

const { Title, Text: AntText } = Typography

export { ContentHeader } from './ContentHeader'
export { LoginTextCta } from './LoginTextCTA'
export { FormEmailInput } from './FormEmailInput'
export { FormPasswordInput } from './FormPasswordInput'
export { FeedbackCard } from './FeedbackCard'

export const BackgroundImage = styled(Row)`
  ${MixinsStyles.coverImage}
  background-image: url(${loginCardRiceImage});
  background-position: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledCard = styled(Card)`
  width: 988px;
  height: 550px;
  border: none;
  background-color: transparent;
`

export const Overlay = styled(Row)`
  flex: 1;
  width: 100%;
  background-color: ${props => props.theme.colors.black};
  opacity: 0.3;
`

export const CardContainer = styled(Row)`
  width: 100%;
`

export const CardContent = styled(Row)`
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), 5px 5px 50px rgba(0, 0, 0, 0.5);
`

export const CardImage = styled(Row)`
  flex: 1;
  ${MixinsStyles.coverImage}
  padding: 0 0 21px 40px;
  align-items: flex-end;
`

export const CardImageTitle = styled(Title)`
  width: 70%;
  color: ${props => props.theme.colors.white} !important;
  font-size: 48px !important;
`

export const ContentContainer = styled.div`
  width: 400px;
  display: flex;
  padding: 20px 46px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
`

export const Content = styled(Col)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

interface TextProps extends React.ComponentProps<typeof AntText> {
  error?: boolean
}

const Text: React.FC<TextProps> = ({ error, ...restProps }) => {
  return <AntText {...restProps} />
}

export const CenteredText = styled(Text)<TextProps>`
  width: 100%;
  text-align: center;
  color: ${props => (props.error ? props.theme.colors.errorRed : undefined)};
`
