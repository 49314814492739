import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { Button } from 'components'

import {
  CancelButton,
  Description,
  FieldNameContainer,
  StyledModal,
  Table,
  SeasonSelect,
  FieldName,
  EmptyResults,
} from './components'
import { useReuseLots } from './hooks'
import { ReuseLot } from './hooks/useReuseLots/api'

interface Props {
  fieldName?: string
  seasonId?: number
  fieldId: number
  onSuccess: (lots: ReuseLot[]) => void
  onCancel: () => void
}

export const ReuseLotsModal: React.FC<Props> = ({
  fieldName,
  onSuccess,
  onCancel,
  fieldId,
  seasonId,
}) => {
  const { t } = useTranslation('lot-settings')
  const [selectedSeasonId, setSelectedSeasonId] = useState<number>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { loading, reusableLots } = useReuseLots({ fieldId, seasonId: selectedSeasonId })

  const onSeasonChange = (id?: number) => {
    setSelectedSeasonId(id)
    setSelectedRowKeys([])
  }

  return (
    <StyledModal title={t('reuseLotsModal.title')} open onCancel={onCancel} centered footer={null}>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Description>{t('reuseLotsModal.description')}</Description>
        </Col>
        <Row gutter={32}>
          <Col>
            <FieldNameContainer>
              <FieldName>{fieldName}</FieldName>
            </FieldNameContainer>
          </Col>
          <Col>
            <SeasonSelect
              onChange={onSeasonChange}
              seasonIdToOmit={seasonId}
              selectedSeasonId={selectedSeasonId}
              isDisabled={!loading && !selectedSeasonId}
            />
          </Col>
        </Row>
        <Col span={24}>
          {!loading && !selectedSeasonId ? (
            <EmptyResults description={t('reuseLotsModal.emptyResultsText')} />
          ) : (
            <Table
              reusableLots={reusableLots}
              isLoading={loading}
              onSelectChange={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
            />
          )}
        </Col>
        <Col span={24}>
          <Row justify="end" gutter={[32, 32]}>
            <CancelButton type="link" onClick={onCancel}>
              {t('reuseLotsModal.buttons.cancel')}
            </CancelButton>
            <Button
              type="primary"
              disabled={!selectedRowKeys.length}
              onClick={() => {
                const lots = reusableLots?.filter(reusableLot =>
                  selectedRowKeys.includes(reusableLot.id),
                )
                if (!lots?.length) return
                onSuccess(lots)
              }}
            >
              {t('reuseLotsModal.buttons.confirm')}
            </Button>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  )
}
