import {
  MapIndex,
  Lot,
  CalendarDatesMapIndices,
  WeatherDataObject,
  DateState,
  CalendarDateIndex,
  SatelliteProviders,
} from 'types'
import { AvailableSatellites } from 'features/MainRouter/features/Map/types'

import { LeftBar, RightBar } from './components'

interface Props {
  grainHumidityHasNotEmergenceDaysActivation: boolean
  onHandleMapIndexChange: (mapIndex: MapIndex) => void
  selectedMapIndex: MapIndex
  isWaterAvailable: boolean
  isGrainHumidityAvailable: boolean
  isWeedsAvailable: boolean
  onHandleCalendarDateChange: (date?: string) => void
  setDates: React.Dispatch<React.SetStateAction<DateState>>
  calendarDatesIndices: CalendarDatesMapIndices
  selectedCalendarDate?: string
  weatherDataForAllLots: WeatherDataObject
  selectedLots: Lot[]
  emergenceDaysActivationIndices: { water: number; grainHumidity: number; weeds: number }
  isShowDamsModeSelected: boolean
  damsCalendar: CalendarDateIndex[]
  weatherDataForDams: WeatherDataObject
  onHandleSatelliteChange: (value: SatelliteProviders) => void
  selectedSatellite: SatelliteProviders
  availableSatellites: AvailableSatellites
}
export const TopBars: React.FC<Props> = ({
  calendarDatesIndices,
  grainHumidityHasNotEmergenceDaysActivation,
  isGrainHumidityAvailable,
  isWaterAvailable,
  isWeedsAvailable,
  selectedMapIndex,
  onHandleCalendarDateChange,
  setDates,
  selectedCalendarDate,
  onHandleMapIndexChange,
  weatherDataForAllLots,
  selectedLots,
  emergenceDaysActivationIndices,
  isShowDamsModeSelected,
  damsCalendar,
  weatherDataForDams,
  onHandleSatelliteChange,
  selectedSatellite,
  availableSatellites,
}) => {
  return (
    <>
      <LeftBar
        grainHumidityHasNotEmergenceDaysActivation={grainHumidityHasNotEmergenceDaysActivation}
        onHandleMapIndexChange={onHandleMapIndexChange}
        selectedMapIndex={selectedMapIndex}
        isWaterAvailable={isWaterAvailable}
        isGrainHumidityAvailable={isGrainHumidityAvailable}
        isWeedsAvailable={isWeedsAvailable}
        onHandleCalendarDateChange={onHandleCalendarDateChange}
        setDates={setDates}
        calendarDatesIndices={calendarDatesIndices}
        selectedCalendarDate={selectedCalendarDate}
        isShowDamsModeSelected={isShowDamsModeSelected}
        damsCalendar={damsCalendar}
        onHandleSatelliteChange={onHandleSatelliteChange}
        selectedSatellite={selectedSatellite}
        availableSatellites={availableSatellites}
      />
      <RightBar
        weatherDataForAllLots={weatherDataForAllLots}
        selectedCalendarDate={selectedCalendarDate}
        selectedLots={selectedLots}
        emergenceDaysActivationIndices={emergenceDaysActivationIndices}
        selectedMapIndex={selectedMapIndex}
        isShowDamsModeSelected={isShowDamsModeSelected}
        weatherDataForDams={weatherDataForDams}
      />
    </>
  )
}
