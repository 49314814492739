import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components'
import { ConversionUtils } from 'utils'
import { useCreateOrganizationCompany } from 'features/MainRouter/hooks'
import { CountryId } from 'types'

import { CreateClientForm } from './components'

export const CreateClientCard = () => {
  const { t } = useTranslation('onboarding')
  const navigate = useNavigate()

  const onSuccess = () => {
    navigate('/dashboard', { replace: true })
  }

  const {
    createCompany,
    loading,
    control,
    handleSubmit,
    selectedRegion,
    subregionOptions,
    commercialExecutivesOptions,
    permissions,
    user,
    regionsOptions,
    isCommercialExecutivesLoading,
    isRegionsLoading,
    isSubregionsLoading,
  } = useCreateOrganizationCompany({ onSuccess })

  return (
    <Space direction="vertical" size={60} style={{ width: '100%' }}>
      <CreateClientForm
        control={control}
        organizationCountry={user.organizationRole?.organization.country.id ?? ''}
        selectedRegion={selectedRegion}
        subregionOptions={subregionOptions ?? []}
        commercialExecutivesOptions={commercialExecutivesOptions}
        regionOptions={regionsOptions ?? []}
        isCommercialExecutivesLoading={isCommercialExecutivesLoading}
        isRegionsLoading={isRegionsLoading}
        isSubregionsLoading={isSubregionsLoading}
      />
      <Button
        style={{ width: '100%' }}
        type="primary"
        loading={loading}
        onClick={handleSubmit(
          ({ companyName, country, governmentId, subregion, commercialExecutiveId }) =>
            createCompany(
              {
                name: companyName,
                country: country as CountryId,
                organizationId: user.organizationRole?.organization.id,
                governmentId: governmentId
                  ? ConversionUtils.keepOnlyNumbers(governmentId)
                  : undefined,
                subregionId: subregion,
              },
              permissions.isOrganizationAdmin && commercialExecutiveId
                ? commercialExecutiveId
                : user.id,
            ),
        )}
      >
        {t('firstClient.buttonText')}
      </Button>
    </Space>
  )
}
