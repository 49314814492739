import { useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'

import { GraphQLUtils } from 'utils'

import {
  RESEND_COMPANY_INVITATION,
  ResendCompanyInvitation,
  ResendCompanyInvitationVariables,
} from '../../../api'

export const useCompanyResendInvitation = () => {
  const [updatedId, setUpdatedId] = useState<number>()

  const [resend] = useMutation<ResendCompanyInvitation, ResendCompanyInvitationVariables>(
    RESEND_COMPANY_INVITATION,
    {
      onError: error => {
        setUpdatedId(undefined)
        GraphQLUtils.errorHandler(error)
      },
      onCompleted: () => {
        setUpdatedId(undefined)
      },
    },
  )

  const resendCompanyInvitation = useCallback(
    (invitationId: number) => {
      setUpdatedId(invitationId)
      resend({ variables: { id: invitationId } })
    },
    [resend],
  )

  return { resendCompanyInvitation, inviteLoadingId: updatedId }
}
