import { useCallback, useState } from 'react'
import { Polygon, points, polygon } from '@turf/helpers'
import center from '@turf/center'
import { useMap } from 'react-map-gl'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { MAP } from 'consts'
import { ConversionUtils, GeoUtils, MiscUtils } from 'utils'
import { Action } from 'types'
import { usePermissions, useQueryDams } from 'hooks'

import { Dams } from './api'
import { useFeatures } from './useFeatures'
import { Dam } from './types'
import { useDeleteDam } from '../useDeleteDam'

export const useDams = (fieldId?: number) => {
  const [dams, setDams] = useState<Dam[]>([])
  const [showDeleteDamModal, setShowDeleteDamModal] = useState(false)
  const [selectedDamToDelete, setSelectedDamToDelete] = useState<Dam>()
  const { t } = useTranslation('field-settings')
  const { permissions } = usePermissions()

  const { fieldsMap } = useMap()
  const {
    selectedDamIndex,
    onCreateFeature,
    onUpdateFeature,
    onDelete,
    onSelectionChange,
    mode,
    setMode,
    setSelectedDamIndex,
  } = useFeatures({
    handleDams: setDams,
    handleShowDeleteModal: setShowDeleteDamModal,
    handleSelectedDamToDelete: setSelectedDamToDelete,
  })

  const { deleteDam, loading: loadingDeleteDam } = useDeleteDam()

  const onCompleted = (data: Dams) => {
    const newDams = data.dams.results.map(dam => ({
      dam: {
        id: dam.id.toString(),
        action: 'update' as Action,
        name: dam.name,
      },
      feature: polygon(dam.area.coordinates),
    }))
    setDams(newDams)
  }

  const { loading } = useQueryDams({
    fieldId,
    onCompleted,
    includeCalendar: false,
    skip: !permissions.satelliteImagery,
  })

  const onChangeDamTransition = useCallback(
    ({ longitude, latitude, zoom }: { longitude?: number; latitude?: number; zoom?: number }) => {
      if (!latitude || !longitude) return
      fieldsMap?.flyTo({
        center: [longitude, latitude],
        duration: MAP.DEFAULT_TRANSITION.transitionDuration,
        zoom,
        essential: true,
      })
    },
    [fieldsMap],
  )

  const goToDam = useCallback(
    (dam?: Polygon) => {
      if (dam) {
        const featurePoint = points(dam.coordinates[0])
        const damCenter = center(featurePoint)
        const longitude = damCenter.geometry.coordinates[0]
        const latitude = damCenter.geometry.coordinates[1]
        if (fieldsMap) onChangeDamTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
      }
    },
    [fieldsMap, onChangeDamTransition],
  )

  const handleSelectedDamChange = (damIndex: number) => {
    const dam = dams[damIndex]
    // This is for the edge case when a user creates a dam, selects from the card another dam
    // before drawing the recently created one and then comes back to that freshly created dam
    if (!dam.feature) {
      setMode({ mode: 'draw_polygon' })
      return
    }
    const damGeometry = dam.feature?.geometry
    if (damGeometry) goToDam(damGeometry)
    setSelectedDamIndex(damIndex)
    setMode({ mode: 'simple_select' })
  }

  const handleDamChange = (dam: Dam['dam'], damIndex: number) =>
    setDams(prevDams => {
      const newDams = [...prevDams]
      newDams[damIndex].dam = dam
      return newDams
    })

  const handleDrawButtonClick = useCallback(() => {
    const damWithoutFeature = dams.some(dam => !dam.feature)
    if (damWithoutFeature) return
    setDams(prevDams => [
      ...prevDams,
      {
        dam: {
          id: MiscUtils.generateId(),
          action: 'create',
        },
        feature: undefined,
      },
    ])
    setMode({ mode: 'draw_polygon' })
    setSelectedDamIndex(dams.length)
  }, [dams, setMode, setSelectedDamIndex])

  const onCancelDeleteDam = () => {
    if (!selectedDamToDelete) return
    setMode({ mode: 'simple_select' })
    const existingDam = dams.find(({ dam }) => dam.id === selectedDamToDelete.dam.id)

    // Workaround for when a user deletes a dam by pressing 'backspace', we need to set that dam again in the state
    if (!existingDam) {
      // It covers the case when user has only one dam and is deleting by bakspace, to no add a new empty dam
      if (dams.length === 1 && !dams[0].feature) {
        setDams([selectedDamToDelete])
      } else {
        setDams(prevDams => [...prevDams, selectedDamToDelete])
      }
    }

    setSelectedDamToDelete(undefined)
    setShowDeleteDamModal(false)
  }

  const onConfirmDeleteDam = async () => {
    if (!selectedDamToDelete) return
    const damIndex = dams.findIndex(({ dam }) => dam.id === selectedDamToDelete.dam.id)

    if (selectedDamToDelete.dam.action === 'update')
      await deleteDam({ variables: { id: Number(selectedDamToDelete.dam.id) } })
    const existingDam = dams.find(({ dam }) => dam.id === selectedDamToDelete.dam.id)
    setDams(prevDams => {
      const newDams = [...prevDams]
      newDams.splice(damIndex, 1)
      // Workaround for when a user deletes a dam by pressing 'backspace', the dam has already been filtered from state
      // in the onDelete function
      return !existingDam ? prevDams : newDams
    })
    setSelectedDamToDelete(undefined)
    setShowDeleteDamModal(false)
  }

  const handleDeleteDam = (damIndex: number) => {
    const damToDelete = dams[damIndex]
    setSelectedDamToDelete(damToDelete)
    setShowDeleteDamModal(true)
  }

  const handleDamKml = async ({ file: uploadFile }: UploadChangeParam<UploadFile>) => {
    try {
      const file = uploadFile as unknown as File
      const geoJson = await MiscUtils.kmlFileToGeoJson(file)

      const kmlDams: Dam[] = geoJson.features.map(feature => ({
        feature: {
          geometry: feature.geometry,
          properties: {},
          type: 'Feature',
        },
        dam: {
          ...GeoUtils.getPolygonCenterAndArea(feature.geometry.coordinates),
          id: MiscUtils.generateId(),
          name: feature.properties.name && ConversionUtils.autoCapitalize(feature.properties.name),
          action: 'create',
        },
      }))
      setMode({ mode: 'simple_select' })

      setDams(prevDams => {
        const filterDams = prevDams.filter(dam => dam.feature !== undefined)
        const newDams = [...filterDams, ...kmlDams]
        setSelectedDamIndex(newDams.length - 1)
        goToDam(newDams[newDams.length - 1].feature?.geometry)
        return newDams
      })
    } catch (err) {
      notification.error({
        message: t('uploadKMLErrorText'),
      })
    }
  }

  return {
    loading,
    dams,
    onCreateFeature,
    onUpdateFeature,
    onDelete,
    onSelectionChange,
    mode,
    selectedDamIndex,
    showDeleteDamModal,
    setShowDeleteDamModal,
    handleDamChange,
    handleSelectedDamChange,
    handleDrawButtonClick,
    onCancelDeleteDam,
    onConfirmDeleteDam,
    loadingDeleteDam,
    handleDeleteDam,
    selectedDamToDelete,
    setMode,
    handleDamKml,
  }
}
