/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  SeasonLotsVariables,
  SeasonLots,
  SeasonLots_seasonLots_results as SeasonLot,
} from './types/SeasonLots'

export const SEASON_LOTS = gql`
  query SeasonLots($startRange: DateTime!, $endRange: DateTime!) {
    seasonLots(startRange: $startRange, endRange: $endRange) {
      results {
        id
        name
        size
        previousCrop {
          id
        }
        variety {
          id
        }
        crop {
          id
        }
        riceLot {
          id
          preparationDate
          burningDate
          plantingDate
          emergenceDate
          firstIrrigationStartDate
          firstIrrigationEndDate
          differentiationDate
          floweringDate
          earAppearanceDate
          physiologicalMaturityDate
          harvestDate
          estimatedYield {
            lotValue
          }
          yield
          emergenceUpdateType
          plantingUpdateType
          floweringUpdateType
          harvestUpdateType
          preparationUpdateType
          burningUpdateType
          earAppearanceUpdateType
          differentiationUpdateType
          daysSinceEmergence
          isHarvested
        }
        season {
          id
          name
        }
        company {
          id
          name
        }
        field {
          id
          name
        }
        productionType
        abandonedSize
        deliveryCondition
        deliveryMill {
          id
        }
      }
    }
  }
`
