import { gql } from '@apollo/client'

export type { CreateField, CreateFieldVariables } from './types/CreateField'

export const CREATE_FIELD = gql`
  mutation CreateField($createFieldDTO: CreateFieldInput!) {
    createField(createFieldDTO: $createFieldDTO) {
      field {
        id
        name
        productionType
        deliveryCondition
      }
    }
  }
`
