import { Input as AntInput } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SearchIcon } from 'assets/icons'

const SearchInput = styled(AntInput)`
  width: 100%;
  height: 40px;
  border: none;
  box-shadow: none !important;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]} !important;
  .ant-input-prefix {
    margin-right: 14px;
  }
  .ant-input {
    background-color: ${({ theme }) => theme.colors.new.baseColor[900]} !important;
    color: ${({ theme }) => theme.colors.new.baseColor[200]} !important;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey3} !important;
  }
`

interface Props {
  onChange: (value: string) => void
}
export const PopoverSearchInput: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('common')
  return (
    <SearchInput
      prefix={<SearchIcon />}
      placeholder={t('inputs.search')}
      onChange={event => onChange(event.target.value)}
    />
  )
}
