/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Subregions, SubregionsVariables } from './types/Subregions'

export const SUBREGIONS = gql`
  query Subregions($regionId: Int!) {
    subregions(regionId: $regionId) {
      results {
        id
        name
      }
    }
  }
`
