import styled from 'styled-components'
import { PropsWithChildren } from 'react'

import { CloseIcon as BaseCloseIcon } from 'assets/icons'

const Container = styled.div`
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  width: 200px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CloseIcon = styled(BaseCloseIcon)`
  cursor: pointer;
`

interface Props {
  onClose?: () => void
}

export const PopupCard: React.FC<PropsWithChildren<Props>> = ({ children, onClose }) => {
  return (
    <Container>
      {onClose && (
        <IconContainer>
          <CloseIcon onClick={onClose} />
        </IconContainer>
      )}
      {children}
    </Container>
  )
}
