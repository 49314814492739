import React, { ComponentProps } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  width: 75%;
`

type Props = ComponentProps<typeof Row>

export const Container: React.FC<Props> = ({ children }) => <StyledRow>{children}</StyledRow>
