import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { DELETE_RICE_FERTILIZER, DeleteRiceFertilizerVariables, DeleteRiceFertilizer } from '../api'

export const useDeleteRiceFertilizer = (onCompleted?: () => void) => {
  const [deleteRiceFertilizer, { loading }] = useMutation<
    DeleteRiceFertilizer,
    DeleteRiceFertilizerVariables
  >(DELETE_RICE_FERTILIZER, {
    onCompleted,
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }, { variables }) => {
      if (!variables || !response?.deleteRiceFertilizer.success) return

      const deletedRiceFertilizer: StoreObject = {
        id: variables.id,
        __typename: 'RiceAppliedFertilizer',
      }

      cache.evict({ id: cache.identify(deletedRiceFertilizer) })
      cache.gc()
    },
  })

  return { deleteRiceFertilizer, loading }
}
