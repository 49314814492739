import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import { DATE_FORMATS } from 'consts'

import { DAMS, Dams, DamsVariables } from './api'

interface Props {
  fieldId?: number
  onCompleted?: (dams: Dams) => void
  includeCalendar: boolean
  fromYearMonth?: Date
  toYearMonth?: Date
  skip?: boolean
}

export const useQueryDams = ({
  fieldId,
  onCompleted,
  includeCalendar,
  fromYearMonth,
  toYearMonth,
  skip,
}: Props) => {
  const { data, loading } = useQuery<Dams, DamsVariables>(DAMS, {
    variables: {
      fieldId: fieldId!,
      includeCalendar,
      includeMapUrls: !!fromYearMonth && !!toYearMonth,
      fromYearMonth: fromYearMonth
        ? dayjs(fromYearMonth).format(DATE_FORMATS.YEAR_MONTH)
        : undefined,
      toYearMonth: toYearMonth ? dayjs(toYearMonth).format(DATE_FORMATS.YEAR_MONTH) : undefined,
    },
    skip: !fieldId || skip,
    onCompleted,
  })

  return {
    dams: data?.dams.results,
    loading,
  }
}
