import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { VarietiesIcon } from 'assets/icons'

import { Chart, Title, FlexCol, StyledCol } from './components'
import { Props } from '../../../../../types'

export const CropsAndVarietiesCard: React.FC<Props> = ({
  varietiesData,
  cropData,
  showCropChart,
}) => {
  const { t } = useTranslation('dashboard')
  return (
    <>
      <FlexCol>
        <VarietiesIcon />
      </FlexCol>
      <Col>
        <Title level={5}>
          {showCropChart
            ? t('varietiesAndCropsCard.title')
            : t('varietiesAndCropsCard.varietiesTitle')}
        </Title>
      </Col>
      <StyledCol>
        <Chart varietiesData={varietiesData} cropData={cropData} showCropChart={showCropChart} />
      </StyledCol>
    </>
  )
}
