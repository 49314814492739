import { memo } from 'react'
import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

import { useCompanySeasons } from 'hooks'
import { ActiveCompanyContext } from 'contexts'

const Select = styled(AntSelect)`
  min-width: 100px;
  .ant-select-selector {
    font-size: 13px;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
` as typeof AntSelect

interface Props {
  onChange: (seasonId?: number) => void
}

export const SeasonSelect: React.FC<Props> = memo(({ onChange }) => {
  const { activeCompanyId, activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { data, loading } = useCompanySeasons(activeCompanyId)

  const seasons = data?.seasons.results

  const SELECT_NEW_UI_CLASSNAME = 'custom-select-dropdown'

  return (
    <Select
      popupMatchSelectWidth={false}
      popupClassName={SELECT_NEW_UI_CLASSNAME}
      loading={loading}
      key={activeSeasonId}
      defaultValue={activeSeasonId}
      onChange={id => onChange(Number(id))}
    >
      {seasons?.map(season => (
        <Select.Option key={season.id} value={season.id}>
          {season.name}
        </Select.Option>
      ))}
    </Select>
  )
})
