import { useMemo } from 'react'

import { useUser, useSubregions, usePermissions, useCommercialExecutives, useFeatures } from 'hooks'

import { useCreateOrganizationCompanyForm } from './useCreateOrganizationCompanyForm'
import { useOrganizationRegions } from './useOrganizationRegions'
import { useCreateCompany } from './useCreateCompany'

interface Props {
  onSuccess: () => void
}
export const useCreateOrganizationCompany = ({ onSuccess }: Props) => {
  const user = useUser()
  const { permissions } = usePermissions()
  const { organizationFeatures } = useFeatures()
  const { createCompany, loading } = useCreateCompany(onSuccess)
  const { control, handleSubmit, watch } = useCreateOrganizationCompanyForm()
  const { regionsOptions, loading: isRegionsLoading } = useOrganizationRegions()

  const { data: allCommercialExecutives, loading: isCommercialExecutivesLoading } =
    useCommercialExecutives({
      skip: permissions.isCommercialUser,
    })

  const selectedCommercialExecutiveId = watch().commercialExecutiveId
  const selectedRegionId = watch().region

  const selectedRegion = useMemo(() => {
    if (permissions.isCommercialUser) {
      return user?.regions?.[0].id
    }
    if (permissions.isOrganizationAdmin && organizationFeatures.subFarmersManagement?.enabled) {
      return selectedRegionId
    }

    if (selectedCommercialExecutiveId) {
      return allCommercialExecutives?.find(
        commercial => commercial.id === selectedCommercialExecutiveId,
      )?.regions?.[0].id
    }
    return undefined
  }, [
    allCommercialExecutives,
    organizationFeatures.subFarmersManagement?.enabled,
    permissions.isCommercialUser,
    permissions.isOrganizationAdmin,
    selectedCommercialExecutiveId,
    selectedRegionId,
    user?.regions,
  ])

  const { subregions, loading: isSubregionsLoading } = useSubregions(selectedRegion)

  const subregionOptions = subregions?.map(subregion => {
    return { label: subregion.name, value: subregion.id }
  })

  const commercialExecutivesOptions = useMemo(() => {
    const commercialExecutive = permissions.isCommercialUser
      ? { id: user.id, fullName: `${user.name} ${user.lastName}` }
      : undefined

    if (permissions.isCommercialUser && commercialExecutive) {
      const options = [{ value: commercialExecutive?.id, label: commercialExecutive?.fullName }]
      return options
    }

    return allCommercialExecutives?.map(commercial => ({
      label: `${commercial.name} ${commercial.lastName}`,
      value: commercial.id,
    }))
  }, [allCommercialExecutives, permissions.isCommercialUser, user.id, user.lastName, user.name])

  return {
    user,
    permissions,
    loading,
    control,
    createCompany,
    handleSubmit,
    commercialExecutivesOptions,
    regionsOptions,
    selectedRegion,
    subregionOptions,
    isCommercialExecutivesLoading,
    isRegionsLoading,
    isSubregionsLoading,
    isCommercialExecutiveSelected: !!selectedCommercialExecutiveId,
  }
}
