import { Row } from 'antd'
import styled from 'styled-components'

import { Button } from 'components'

export { UpdateClientForm } from './UpdateClientForm'

export const StyledRow = styled(Row)`
  margin: 6px 0 30px 0;
`

export const DeleteCompanyButton = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey3};
  color: ${({ theme }) => theme.colors.white};
`
