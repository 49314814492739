/* eslint-disable @typescript-eslint/no-var-requires */
const { Colors } = require('./colors')

const Input = {
  height: {
    small: '32px',
    large: '55px',
  },
  borderColor: Colors.grey2,
  disabledBackgroundColor: Colors.grey2,
  disabledColor: Colors.grey3,
}

module.exports = { Input }
