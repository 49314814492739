import styled from 'styled-components'
import { Space as AntSpace, Typography, Row, Col, Select as AntSelect } from 'antd'

import { CropsSelect as BaseCropsSelect } from 'components'

const { Title: AntTitle } = Typography

export const Title = styled(AntTitle)`
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.olive5};
  margin-bottom: 0 !important;
`

export const Space = styled(AntSpace)`
  background-color: ${({ theme }) => theme.colors.grey1};
  padding: 24px;
  width: 235px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

export const Divider = styled(Col)`
  width: 3px !important;
  height: 100%;
  background: ${({ theme }) => theme.colors.olive};
  padding-left: 0 !important;
`
interface BallProps {
  $completedDate: boolean
  $backgroundColor: string
}
export const Ball = styled.div<BallProps>`
  width: 20px;
  height: 26.5px;
  border-radius: 50%;
  background-color: ${({ theme, $completedDate, $backgroundColor }) =>
    $completedDate ? $backgroundColor : theme.colors.grey3};
  border: ${({ theme }) => `2px solid ${theme.colors.olive}`};
`

export const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MarginRow = styled(Row)`
  margin-bottom: 12px;
  max-width: 155px;
`

export const Select = styled(AntSelect)`
  width: 143px;
`

export const CropsSelect = styled(BaseCropsSelect)`
  width: 143px;
`
