import { StoreObject, useMutation, useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { RadioChangeEvent } from 'antd'

import { ActiveCompanyContext } from 'contexts'
import { GraphQLUtils } from 'utils'
import { UserUserCompanyRole } from 'api/user'
import { usePermissions, useUser } from 'hooks'
import { UPDATE_COMPANY, UpdateCompany, UpdateCompanyVariables, User } from 'api'
import { IrrigationWorkersAccessMode, UserCompanyRole } from 'types'

import {
  CHANGE_USER_ROLE,
  COMPANY_WITH_USERS,
  ChangeUserRole,
  ChangeUserRoleVariables,
  CompanyWithUsers,
  CompanyWithUsersVariables,
  INVITE_USER,
  InviteUser,
  InviteUserVariables,
  LEAVE_COMPANY,
  LeaveCompany,
  LeaveCompanyVariables,
} from '../api'
import { useDeleteCompany } from './useDeleteCompany'

interface Props {
  companyId: number
  onCancel: () => void
}
export const useCompanySettings = ({ companyId, onCancel }: Props) => {
  const { setActiveCompany } = ActiveCompanyContext.useActiveCompanyContext()
  const [showLeaveCompanyModal, setShowLeaveCompanyModal] = useState(false)
  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false)

  const { permissions } = usePermissions()

  const user = useUser()

  const { data } = useQuery<CompanyWithUsers, CompanyWithUsersVariables>(COMPANY_WITH_USERS, {
    variables: { id: companyId },
  })

  const company = data?.company

  const [leaveCompany, { loading: leaveCompanyLoading }] = useMutation<
    LeaveCompany,
    LeaveCompanyVariables
  >(LEAVE_COMPANY, {
    onCompleted: leaveCompanyResponse => {
      setShowLeaveCompanyModal(false)
      onCancel()
      const newActiveCompanyId =
        leaveCompanyResponse.leaveCompany.user.companiesRoles?.length &&
        leaveCompanyResponse.leaveCompany.user.companiesRoles[0].company.id
      const newActiveCountryId = leaveCompanyResponse.leaveCompany.user.companiesRoles?.length
        ? leaveCompanyResponse.leaveCompany.user.companiesRoles[0].company.country.id
        : undefined
      const newActiveCompanyName = leaveCompanyResponse.leaveCompany.user.companiesRoles?.length
        ? leaveCompanyResponse.leaveCompany.user.companiesRoles[0].company.name
        : undefined

      setActiveCompany({
        id: newActiveCompanyId,
        countryId: newActiveCountryId,
        name: newActiveCompanyName,
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  const [inviteUser, { loading: inviteUserLoading }] = useMutation<InviteUser, InviteUserVariables>(
    INVITE_USER,
    {
      onError: GraphQLUtils.errorHandler,
    },
  )

  const [changeUserRole] = useMutation<ChangeUserRole, ChangeUserRoleVariables>(CHANGE_USER_ROLE, {
    update: (cache, { data: response }) => {
      if (!response) return
      const newUserRole = response.changeUserRole.company.usersRoles?.find(
        userRole => userRole.user.id === user.id,
      )
      if (!newUserRole) return

      cache.modify<User['user']>({
        // Workaraound because of this: https://github.com/apollographql/apollo-client/issues/7577
        id: cache.identify(user as unknown as StoreObject),
        fields: {
          companiesRoles(cachedData) {
            const cachedCompaniesRoles = cachedData as UserUserCompanyRole[] | undefined
            const newCompanyRole = {
              role: newUserRole.role,
              company: response.changeUserRole.company,
              __typename: 'CompanyRole',
            } as unknown as UserUserCompanyRole
            if (!cachedCompaniesRoles) return [newCompanyRole]
            return [...cachedCompaniesRoles, newCompanyRole]
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  const [updateCompany] = useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY, {
    onError: GraphQLUtils.errorHandler,
  })

  const onUserRoleChange = (userId: number, role: UserCompanyRole) => {
    changeUserRole({ variables: { userId, role, companyId } })
  }

  const isAnOrganizationCompany = !!company?.organization?.id

  const userCompanyRole = company?.usersRoles?.find(userRole => userRole.user.id === user.id)?.role

  const irrigationWorkersAccessMode = company?.irrigationWorkersAccessMode

  const changeIrrigationWorkerAccessMode = ({ target: { value } }: RadioChangeEvent) => {
    updateCompany({
      variables: {
        id: companyId,
        updateCompanyDTO: { irrigationWorkersAccessMode: value as IrrigationWorkersAccessMode },
      },
    })
  }

  const isReadOnly = useMemo(() => {
    return (
      !permissions.isOrganizationAdmin &&
      (userCompanyRole ?? UserCompanyRole.VIEWER) !== UserCompanyRole.ADMIN
    )
  }, [permissions.isOrganizationAdmin, userCompanyRole])

  const onDeletionCompanyComplete = () => {
    setShowDeleteCompanyModal(false)
    onCancel()
  }

  const { deleteCompany, loading: isDeleteCompanyLoading } = useDeleteCompany({
    onCompleted: onDeletionCompanyComplete,
  })

  return {
    company,
    showLeaveCompanyModal,
    leaveCompany,
    leaveCompanyLoading,
    setShowLeaveCompanyModal,
    inviteUser,
    inviteUserLoading,
    onUserRoleChange,
    irrigationWorkersAccessMode,
    isReadOnly,
    changeIrrigationWorkerAccessMode,
    isAnOrganizationCompany,
    userCompanyRole,
    showDeleteCompanyModal,
    deleteCompany,
    setShowDeleteCompanyModal,
    isDeleteCompanyLoading,
  }
}
