import {
  Col,
  Row,
  Typography,
  Table as AntTable,
  Checkbox as AntCheckbox,
  Input as AntInput,
  InputNumber as AntInputNumber,
} from 'antd'
import styled from 'styled-components'
import React, { useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteButton, CropsSelect as BaseCropsSelect } from 'components'
import { TABLE } from 'consts'
import { useFeatures, usePermissions } from 'hooks'
import { ConversionUtils } from 'utils'

import { Lot } from '../../../../types'

const { Text } = Typography

const Header = styled(Row)`
  background-color: ${({ theme }) => theme.colors.grey2};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 45px;
  padding: 0 17px;
  margin-bottom: 12px;
  align-items: center;
`

const Table = styled(AntTable)<Lot>`
  & .ant-table {
    background-color: ${({ theme }) => theme.colors.grey1};
  }
  & .ant-table-cell {
    padding: 12px 2px !important;
    border-bottom: unset;
  }
` as unknown as typeof AntTable
// Workaraound to avoid losing generic typing after styling the component

const CropsSelect = styled(BaseCropsSelect)`
  width: 100%;
`

const Input = styled(AntInput)`
  padding: 6px 9px;
`

const InputNumber = styled(AntInputNumber)`
  width: 60px;
  height: 38px;
  .ant-input-number-input-wrap {
    height: 100%;
  }
  & input {
    height: 100%;
  }
`

const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    border-radius: 100%;
  }
`

interface Props {
  lots: Lot[]
  selectedLotIndex?: number
  onSelect: (lotIndex: number) => void
  onChange: (lot: Lot['lot'], lotIndex: number) => void
  onDelete: (lotIndex: number) => void
  loading?: boolean
  disableDelete: boolean
}

export const LotsList: React.FC<Props> = ({
  lots,
  selectedLotIndex,
  onSelect,
  onChange,
  onDelete,
  loading,
  disableDelete,
}) => {
  const { t } = useTranslation('lot-settings')
  const { t: commonT } = useTranslation('common')
  const { permissions } = usePermissions()
  const { organizationFeatures } = useFeatures()
  const isDataSourceBiggerThanMaxTableLots = lots.length > TABLE.MAX_TABLE_DATA

  const selectCheckBox = (index: number) => {
    if (index !== selectedLotIndex) onSelect(index)
  }

  type DataObject = {
    [key: number]: { current: HTMLDivElement | null }
  }

  const refs = useMemo(
    () =>
      lots.reduce((accumulator: DataObject, _, index) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[index] = React.createRef<HTMLDivElement>()
        return accumulator
      }, {}),
    [lots],
  )

  const handleListScroll = useCallback(
    (index: number) => {
      if (!refs[index]) return
      const ref = refs[index].current

      if (ref === null) return

      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },
    [refs],
  )

  useEffect(() => {
    if (selectedLotIndex === undefined || selectedLotIndex === -1 || selectedLotIndex === null)
      return
    handleListScroll(selectedLotIndex)
  }, [handleListScroll, selectedLotIndex])

  const columns = [
    {
      render: (_: Lot, __: Lot, index: number) => (
        <div ref={refs[index]}>
          <Checkbox checked={index === selectedLotIndex} onClick={() => onSelect(index)} />
        </div>
      ),
      width: '22px',
    },
    {
      render: ({ lot }: Lot, __: Lot, index: number) => (
        <Input
          onKeyDown={e => {
            if (e.key === 'Backspace') {
              e.stopPropagation()
            }
          }}
          placeholder={commonT('inputs.commercialName')}
          value={lot.commercialName ?? undefined}
          onClick={() => selectCheckBox(index)}
          onChange={event =>
            onChange(
              { ...lot, commercialName: ConversionUtils.autoCapitalize(event.target.value) },
              index,
            )
          }
          autoFocus={index === selectedLotIndex}
        />
      ),
      width: '65px',
      hide: !permissions.isCommercialNameEnabled,
    },
    {
      render: ({ lot }: Lot, __: Lot, index: number) => (
        <div ref={refs[index]}>
          <Input
            onKeyDown={e => {
              if (e.key === 'Backspace') {
                e.stopPropagation()
              }
            }}
            placeholder={commonT('inputs.name')}
            value={lot.name}
            onClick={() => selectCheckBox(index)}
            onChange={event =>
              onChange({ ...lot, name: ConversionUtils.autoCapitalize(event.target.value) }, index)
            }
            autoFocus={index === selectedLotIndex}
          />
        </div>
      ),
    },
    {
      render: ({ lot, feature }: Lot, __: Lot, index: number) => (
        <InputNumber
          onKeyDown={e => {
            if (e.key === 'Backspace') {
              e.stopPropagation()
            }
          }}
          placeholder={commonT('inputs.size')}
          value={lot.size ? lot.size.toFixed(0) : undefined}
          onClick={() => selectCheckBox(index)}
          onChange={event => onChange({ ...lot, size: Number(event) }, index)}
          autoFocus={index === selectedLotIndex}
          disabled={!!feature || !!lot.area}
          type="number"
          controls={false}
        />
      ),
      hide: !organizationFeatures.crm?.enabled,
      width: '65px',
    },
    {
      render: ({ lot }: Lot, __: Lot, index: number) => {
        return (
          <div style={{ width: '120px' }}>
            <CropsSelect
              value={lot.cropId}
              onChange={crop => {
                onChange({ ...lot, cropId: crop?.id, varietyId: undefined }, index)
              }}
              onClick={() => selectCheckBox(index)}
            />
          </div>
        )
      },
      width: '125px',
    },
    {
      render: (_: Lot, __: Lot, index: number) => {
        const disabledCondition = disableDelete && index !== selectedLotIndex

        return lots[index].lot.isPlanetEnabled ? (
          <DeleteButton disabled toolTipText={commonT('messages.highFrequencyLot')} />
        ) : (
          <DeleteButton
            disabled={disabledCondition}
            onClick={() => !disabledCondition && onDelete(index)}
          />
        )
      },
      width: '42px',
    },
  ]

  return (
    <Row>
      <Col flex={1}>
        <Header>
          <Text strong>{t('tableTitle')}</Text>
        </Header>
        <Table
          size="small"
          rowKey={({ lot }) => lot.id}
          dataSource={lots}
          columns={columns.filter(column => !column.hide)}
          pagination={false}
          showHeader={false}
          scroll={isDataSourceBiggerThanMaxTableLots ? { y: 200 } : undefined}
          loading={loading}
        />
      </Col>
    </Row>
  )
}
