import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Skeleton, Space } from 'antd'

import { SeasonFieldDam, SeasonFields, SeasonLot } from 'api'
import { usePermissions } from 'hooks'
import { LocaleLanguage, SeasonMillDeliveryType } from 'types'
import { LANGUAGE, UNITS } from 'consts'

import { LotOrFieldInformation } from '../../../components'
import {
  BodyContainerRow,
  Card,
  ContainerLotCards,
  ContainerLotCard,
  CropsAndVarietiesCard,
  LotCard,
  RiceProductionCard,
  Weather,
  WeatherCard,
  DamCard,
} from './components'

const ONLY_ONE_CROP = 1

interface DataObject {
  [key: string]: { name: string; value: number; size: number; cropName: string }
}

interface Props {
  field: SeasonFields['field']
  lots: SeasonLot[]
  declaredRiceProduction: number
  estimatedRiceProduction: number
  seasonId: number
  productionToMill?: number
  millDeliveryType?: SeasonMillDeliveryType
  dams: SeasonFieldDam[]
}

export const FieldsAndLotsBody: React.FC<Props> = ({
  lots,
  field,
  declaredRiceProduction,
  estimatedRiceProduction,
  seasonId,
  productionToMill,
  millDeliveryType,
  dams,
}) => {
  const navigate = useNavigate()
  const { permissions } = usePermissions()
  const { t } = useTranslation('dashboard')
  const [showLotCards, setShowLotCards] = useState(false)
  const language = LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]

  const handleChartData = (seasonLots?: SeasonLot[]) => {
    const reduced =
      seasonLots?.reduce((accumulator: DataObject, { variety, size, crop }) => {
        const key = `${crop.id}-${variety?.locales.es}`
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = accumulator[key] ?? {
          name: variety?.locales[language] ?? t('varietiesAndCropsCard.noVariety'),
          value: 0,
          size: 0,
          cropName: crop.locales[language],
        }
        // eslint-disable-next-line no-param-reassign
        accumulator[key].value += 1
        // eslint-disable-next-line no-param-reassign
        accumulator[key].size += size
        return accumulator
      }, {}) ?? {}
    const result = Object.values(reduced || '')

    return orderBy(result, 'cropName')
  }

  const cropData = useMemo(() => {
    const reduced = lots?.reduce((accumulator: DataObject, { crop, size }) => {
      const key = crop.id
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = accumulator[key] ?? {
        name: crop.locales[language],
        value: 0,
        size: 0,
      }
      // eslint-disable-next-line no-param-reassign
      accumulator[key].value += 1
      // eslint-disable-next-line no-param-reassign
      accumulator[key].size += size
      return accumulator
    }, {})
    const result = Object.values(reduced || '')
    return orderBy(result, 'name')
  }, [lots, language])

  useEffect(() => {
    // We need to implement this timeout to fix the delay rendering a big list of lots.
    // We couldn't implement virtualization for the disposition of UX.
    // We must consider in the future a better option if the UX changes.
    const timeout = setTimeout(() => {
      setShowLotCards(true)
    }, 0)

    return () => clearTimeout(timeout)
  }, [])

  if (!field) return null
  return (
    <div>
      <LotOrFieldInformation
        name={field.name}
        lotsOrHaQty={t('lotsUnits', { count: lots.length })}
        onSettingsClick={() =>
          permissions.fieldEntity ? navigate(`/settings/fields/${field.id}`) : navigate('/settings')
        }
      />
      <BodyContainerRow key={field.id} gutter={[0, 24]}>
        <Col>
          <Space size={24}>
            <Card>
              <CropsAndVarietiesCard
                varietiesData={handleChartData(lots)}
                cropData={cropData}
                showCropChart={cropData.length > ONLY_ONE_CROP}
              />
            </Card>
            {permissions.isFeatureSetGrupoDiana && (
              <RiceProductionCard
                seasonId={seasonId}
                estimated={estimatedRiceProduction * UNITS.KG_TONS_RATIO}
                declared={declaredRiceProduction * UNITS.KG_TONS_RATIO}
                delivered={productionToMill ? productionToMill * UNITS.KG_TONS_RATIO : undefined}
                millDeliveryType={millDeliveryType ?? undefined}
              />
            )}
            {!permissions.isFeatureSetGrupoDiana && (
              <WeatherCard>
                <Weather days={field.weather?.days ?? undefined} />
              </WeatherCard>
            )}
          </Space>
        </Col>
        <ContainerLotCards>
          {dams.map(dam => (
            <React.Fragment key={dam.id}>
              <Card height="208px">
                <DamCard
                  area={dam.indicators?.waterSurfaceArea ?? undefined}
                  volume={dam.indicators?.waterSurfaceVolume ?? undefined}
                  percentage={dam.indicators?.waterSurfacePercentage ?? undefined}
                  name={dam.name}
                />
              </Card>
            </React.Fragment>
          ))}
          {lots.map(lot => {
            const { indicators } = lot.riceLot

            const isHarvested = !!lot?.riceLot.isHarvested

            const daysSinceEmergence = lot.riceLot.daysSinceEmergence?.toString() ?? '-'

            return (
              <ContainerLotCard key={lot.id}>
                <Skeleton loading={!showLotCards} active>
                  <LotCard
                    name={lot.name}
                    commercialName={lot.commercialName ?? undefined}
                    id={lot.id}
                    daysSinceEmergenceDate={daysSinceEmergence}
                    size={lot.size}
                    variety={lot.variety?.locales[language]}
                    lotCoordinates={lot.area?.coordinates[0]}
                    chlorophyllIndicator={indicators?.chlorophyll ?? undefined}
                    irrigationIndicator={indicators?.irrigation ?? undefined}
                    semaphoreIndicator={indicators?.semaphore ?? undefined}
                    isHarvested={isHarvested}
                    crop={lot.crop.locales[language]}
                    fieldId={field.id}
                  />
                </Skeleton>
              </ContainerLotCard>
            )
          })}
        </ContainerLotCards>
      </BodyContainerRow>
    </div>
  )
}
