import { useQuery } from '@apollo/client'

import { HistoricalSeason } from 'types'
import { GraphQLUtils } from 'utils'

import { HISTORICAL_SEASONS, HistoricalSeasons } from './api'

interface Props {
  selectedHistoricalSeason?: HistoricalSeason
  onChange?: (historicalSeason: HistoricalSeason) => void
  withRefreshPolicy?: boolean
  skip?: boolean
}
export const useHistoricalSeasons = ({
  withRefreshPolicy,
  onChange,
  selectedHistoricalSeason,
  skip,
}: Props) => {
  const { data, loading } = useQuery<HistoricalSeasons>(HISTORICAL_SEASONS, {
    fetchPolicy: withRefreshPolicy ? 'cache-and-network' : undefined,
    onCompleted: historicalSeasonData => {
      if (!onChange) return

      if (!historicalSeasonData.historicalSeasons.length) return

      const { name, endRange, startRange } = historicalSeasonData.historicalSeasons[0]
      onChange(selectedHistoricalSeason ?? { endRange, name, startRange })
    },
    onError: GraphQLUtils.errorHandler,
    skip,
  })
  return { data, loading }
}
