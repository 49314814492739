import { useTranslation } from 'react-i18next'
import { Button as AntButton } from 'antd'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'

import { GraphQLUtils } from 'utils'
import { HistoricalSeason } from 'types'

import { SEASON_LOTS_EXPORT, SeasonLotsExportVariables, SeasonLotsExport } from './api'

const Button = styled(AntButton)`
  min-width: 125px;
`

interface Props {
  lotIds?: number[]
  selectedHistoricalSeason?: HistoricalSeason
}

export const ExportButton: React.FC<Props> = ({ lotIds, selectedHistoricalSeason }) => {
  const { t } = useTranslation('lots-management')

  const [getSeasonLotsData, { loading }] = useLazyQuery<
    SeasonLotsExport,
    SeasonLotsExportVariables
  >(SEASON_LOTS_EXPORT, {
    onError: GraphQLUtils.errorHandler,
    onCompleted: response => window.open(response.seasonLotsExport.url),
  })

  return (
    <Button
      type="primary"
      loading={loading}
      onClick={() => {
        if (!selectedHistoricalSeason?.endRange || !selectedHistoricalSeason.startRange) return
        const { endRange, startRange } = selectedHistoricalSeason
        getSeasonLotsData({
          variables: {
            lotsIds: lotIds,
            endRange,
            startRange,
          },
        })
      }}
    >
      {t('buttonText')}
    </Button>
  )
}
