import { Row, Typography } from 'antd'
import styled from 'styled-components'

export { LotsCard } from './LotsCard'
export { GeocoderControl } from './GeocoderControl'

export const FieldsSelectContainer = styled(Row)`
  margin-bottom: 28px;
`

export const Container = styled.div`
  height: 100%;
  .mapboxgl-ctrl-top-right {
    display: none;
  }
`
const { Text: AntText } = Typography

export const TooltipText = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-family: Inter;
  font-size: 14px;
  line-height: 22px;
`
export const TooltipTextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  padding: 8px 10px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  max-width: 320px;
`
