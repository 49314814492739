import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { DELIVERY_MILLS, DeliveryMills } from './api'

interface Props {
  disabled?: boolean
  onChange: (deliveryMillId?: number) => void
  value?: number
}

export const DeliveryMillSelect: React.FC<Props> = ({ disabled, onChange, value }) => {
  const { t } = useTranslation('common')

  const { data, loading } = useQuery<DeliveryMills>(DELIVERY_MILLS)

  const options = useMemo(() => {
    return (
      data?.organization.mills?.map(deliveryMill => {
        return { label: deliveryMill.name, value: deliveryMill.id }
      }) ?? []
    )
  }, [data])

  return (
    <Select
      disabled={disabled}
      onChange={onChange}
      value={value}
      placeholder={t('inputs.deliveryMill')}
      style={{ width: '100%' }}
      allowClear
      loading={loading}
      options={options}
    />
  )
}
