import {
  Layout,
  Col,
  Row,
  Typography,
  Input,
  Upload,
  Button,
  Form,
  notification,
  DatePicker,
} from 'antd'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useState } from 'react'
import { Feature, Polygon } from '@turf/helpers'
import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { InputTitle } from 'components'
import { GraphQLUtils, MiscUtils } from 'utils'
import { DATE_FORMATS } from 'consts'

import { LotHistoricData, LotHistoricDataVariables, LOT_HISTORIC_DATA } from './api'

const { Content: AntContent } = Layout
const { Title, Text, Link } = Typography

const Content = styled(AntContent)`
  height: 100%;
  overflow: auto;
`

interface FormType {
  companyName: string
  fieldName: string
  lotName: string
  varietyName: string
  yield: string
  yieldUnit: string
  fromTime: Date
  toTime: Date
  emergenceDate: Date
  area: Feature<Polygon>
}

export const InternalMetrics: React.FC = () => {
  const { t } = useTranslation('internal-metrics')
  const { t: commonT } = useTranslation('common')

  const [filename, setFilename] = useState('')

  const [getHistoricData, { loading, data }] = useLazyQuery<
    LotHistoricData,
    LotHistoricDataVariables
  >(LOT_HISTORIC_DATA, {
    onError: GraphQLUtils.errorHandler,
  })

  const validationSchema: yup.ObjectSchema<FormType> = yup.object().shape({
    companyName: yup.string().required(commonT('validations.required')),
    fieldName: yup.string().required(commonT('validations.required')),
    lotName: yup.string().required(commonT('validations.required')),
    varietyName: yup.string().required(commonT('validations.required')),
    yield: yup.string().required(commonT('validations.required')),
    yieldUnit: yup.string().required(commonT('validations.required')),
    fromTime: yup.date().required(commonT('validations.required')),
    toTime: yup
      .date()
      .required(commonT('validations.required'))
      .min(yup.ref('fromTime'), commonT('validations.earlierDate')),
    emergenceDate: yup.date().required(commonT('validations.required')),
    area: yup.object().required(commonT('validations.required')) as yup.ObjectSchema<
      FormType['area']
    >,
  })

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver<FormType>(validationSchema),
  })

  const handleKml = async (
    { file: uploadFile }: UploadChangeParam<UploadFile>,
    onChange: (area: Feature) => void,
  ) => {
    const file = uploadFile as unknown as File
    const geoJson = await MiscUtils.kmlFileToGeoJson(file)

    if (geoJson.features.length > 1) {
      notification.error({
        message: t('maxLotsError'),
      })
      return
    }

    const feature = geoJson.features[0]

    setFilename(file.name)
    if (feature.properties.name) {
      setValue('lotName', feature.properties.name)
    }
    onChange(geoJson.features[0])
  }

  return (
    <Content>
      <Title level={2}>{t('title')}</Title>
      <Row>
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="area"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Upload
                {...restFieldProps}
                beforeUpload={() => false}
                onChange={info => handleKml(info, onChange)}
                showUploadList={false}
                accept=".kml"
              >
                <Button type="primary">{t('uploadKmlButton')}</Button>
              </Upload>
            </Form.Item>
          )}
        />
        <Text>{filename}</Text>
      </Row>
      <Col>
        <InputTitle title={t('inputs.companyName')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="companyName"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                size="large"
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Col>
        <InputTitle title={t('inputs.fieldName')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="fieldName"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                size="large"
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Col>
        <InputTitle title={t('inputs.lotName')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="lotName"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                size="large"
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Col>
        <InputTitle title={t('inputs.varietyName')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="varietyName"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                size="large"
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Col>
        <InputTitle title={t('inputs.yield')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="yield"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                size="large"
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Col>
        <InputTitle title={t('inputs.yieldUnit')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="yieldUnit"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input
                size="large"
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Row gutter={24}>
        <InputTitle title={t('inputs.fromTime')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="fromTime"
          control={control}
          render={({
            field: { onChange, value, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <DatePicker
                size="large"
                placeholder={commonT('inputs.selectDate')}
                value={value ? dayjs(value) : undefined}
                allowClear={false}
                {...restFieldProps}
                onChange={date => {
                  if (date) onChange(date)
                }}
              />
            </Form.Item>
          )}
        />
        <InputTitle title={t('inputs.toTime')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="toTime"
          control={control}
          render={({
            field: { onChange, value, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <DatePicker
                size="large"
                value={value ? dayjs(value) : undefined}
                placeholder={commonT('inputs.selectDate')}
                {...restFieldProps}
                onChange={date => {
                  if (date) {
                    onChange(date)
                    return
                  }
                  onChange(null)
                }}
              />
            </Form.Item>
          )}
        />
      </Row>
      <Col>
        <InputTitle title={t('inputs.emergenceDate')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="emergenceDate"
          control={control}
          render={({
            field: { onChange, value, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <DatePicker
                size="large"
                value={value ? dayjs(value) : undefined}
                placeholder={commonT('inputs.selectDate')}
                {...restFieldProps}
                onChange={date => {
                  if (date) {
                    onChange(date)
                    return
                  }
                  onChange(null)
                }}
              />
            </Form.Item>
          )}
        />
      </Col>
      <Button
        type="primary"
        loading={loading}
        onClick={handleSubmit(form =>
          getHistoricData({
            variables: {
              companyName: form.companyName,
              fieldName: form.fieldName,
              lotName: form.lotName,
              varietyName: form.varietyName,
              yield: form.yield,
              yieldUnit: form.yieldUnit,
              fromTime: dayjs(form.fromTime).format(DATE_FORMATS.DATE),
              toTime: dayjs(form.toTime).format(DATE_FORMATS.DATE),
              emergenceDate: dayjs(form.emergenceDate).format(DATE_FORMATS.DATE),
              area: form.area.geometry,
            },
          }),
        )}
      >
        {t('generateCsvButton')}
      </Button>
      {data?.lotHistoricData.url && (
        <Row>
          <Link href={data.lotHistoricData.url}>{t('downloadLink')}</Link>
        </Row>
      )}
    </Content>
  )
}
