import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { VALIDATIONS } from 'consts'

interface Form {
  email: string
  password: string
}

export const useLoginForm = () => {
  const { t } = useTranslation('common')

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('validations.email')).required(t('validations.required')),
    password: yup
      .string()
      .min(
        VALIDATIONS.PASSWORD_MIN_LENGTH,
        t('validations.minLength', { minLength: VALIDATIONS.PASSWORD_MIN_LENGTH }),
      )
      .required(t('validations.required')),
  })

  return useForm<Form>({ resolver: yupResolver(validationSchema) })
}
