import { Radio, Space, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal, Modal } from 'components'
import { usePermissions, useUser } from 'hooks'
import { IrrigationWorkersAccessMode } from 'types'

import {
  CompanyUsersTable,
  ButtonsContainer,
  UsersSectionTitle,
  InviteUserModal,
  LeaveCompanyModal,
  StyledButton,
  FarmerInformation,
  UpdateCompanyForm,
  LeaveCompanyButton,
  Container,
} from './components'
import { useCompanySettings } from './hooks'

const { Title, Text } = Typography
interface Props {
  id: number
  onCancel: () => void
}

export const CompanySettingsModal: React.FC<Props> = ({ id, onCancel }) => {
  const [showInviteUserModal, setShowInviteUserModal] = useState(false)

  const { t } = useTranslation('company-settings')
  const { permissions } = usePermissions()

  const user = useUser()

  const {
    company,
    userCompanyRole,
    isAnOrganizationCompany,
    showLeaveCompanyModal,
    setShowLeaveCompanyModal,
    leaveCompany,
    leaveCompanyLoading,
    inviteUser,
    inviteUserLoading,
    onUserRoleChange,
    irrigationWorkersAccessMode,
    changeIrrigationWorkerAccessMode,
    isReadOnly,
    deleteCompany,
    setShowDeleteCompanyModal,
    showDeleteCompanyModal,
    isDeleteCompanyLoading,
  } = useCompanySettings({
    companyId: id,
    onCancel,
  })

  const isClientSettings = isAnOrganizationCompany

  const modalTitle = isClientSettings ? `editClient.modalTitle` : 'editCompany.modalTitle'
  const title = isClientSettings ? `editClient.title` : 'editCompany.title'
  const userSectionTitle = isClientSettings
    ? `editClient.userSectionTitle`
    : 'editCompany.userSectionTitle'

  return (
    <Modal title={t(modalTitle)} open centered footer={null} onCancel={onCancel} size="large">
      <Title level={5}>{t(title)}</Title>
      {isAnOrganizationCompany ? (
        <FarmerInformation
          companyId={id}
          data={{
            isOrganizationCompany: !!company?.organization?.id,
            companyName: company?.name,
            country: user.organizationRole?.organization.country.id ?? '',
            governmentId: company?.governmentId ?? undefined,
            subregion: company?.subregion ?? undefined,
            phoneNumber: company?.phoneNumber ?? undefined,
          }}
          onDeleteCompanyButtonClick={() => setShowDeleteCompanyModal(true)}
          isReadOnly={isReadOnly}
        />
      ) : (
        <UpdateCompanyForm
          companyId={id}
          data={{
            companyName: company?.name,
            countryId: company?.country.id,
            logo: company?.logo ?? undefined,
          }}
          isReadOnly={isReadOnly}
        />
      )}
      <Container>
        <UsersSectionTitle level={5}>{t(userSectionTitle)}</UsersSectionTitle>
        <ButtonsContainer>
          <StyledButton
            type="primary"
            loading={inviteUserLoading}
            disabled={isReadOnly}
            onClick={() => setShowInviteUserModal(true)}
          >
            {t('buttons.addMember')}
          </StyledButton>
          {userCompanyRole && (
            <LeaveCompanyButton onClick={() => setShowLeaveCompanyModal(true)}>
              {t('buttons.leaveCompany')}
            </LeaveCompanyButton>
          )}
        </ButtonsContainer>
        {permissions.satelliteImagery && permissions.isCompanyAdminUser && (
          <Space size={10}>
            <Text>{t('irrigationWorkerAccessMode.title')}</Text>
            <Radio.Group
              onChange={changeIrrigationWorkerAccessMode}
              value={irrigationWorkersAccessMode}
            >
              <Radio value={IrrigationWorkersAccessMode.FIELD}>
                {t('irrigationWorkerAccessMode.field')}
              </Radio>
              <Radio value={IrrigationWorkersAccessMode.ASSIGNED_LOT}>
                {t('irrigationWorkerAccessMode.assignedLot')}
              </Radio>
            </Radio.Group>
          </Space>
        )}
        <CompanyUsersTable
          users={company?.usersRoles ?? []}
          invitations={company?.invitations ?? []}
          isReadOnly={isReadOnly}
          onUserRoleChange={onUserRoleChange}
          currentUserId={user.id}
          isAnOrganizationCompany={isAnOrganizationCompany}
          isAnOrganizationUser={permissions.isAnOrganizationUser}
        />
      </Container>
      {showInviteUserModal && (
        <InviteUserModal
          onInvite={(email, role) => {
            setShowInviteUserModal(false)
            inviteUser({ variables: { inviteUserDTO: { email, companyId: id, role } } })
          }}
          onCancel={() => setShowInviteUserModal(false)}
          isAnOrganizationCompany={isAnOrganizationCompany}
        />
      )}
      {showLeaveCompanyModal && (
        <LeaveCompanyModal
          onLeaveCompany={() => leaveCompany({ variables: { companyId: id, userId: user.id } })}
          onCancel={() => setShowLeaveCompanyModal(false)}
          loading={leaveCompanyLoading}
          isAnOrganizationCompany={isAnOrganizationCompany}
        />
      )}
      {showDeleteCompanyModal && (
        <ConfirmationModal
          onConfirm={() => deleteCompany({ variables: { id } })}
          onCancel={() => setShowDeleteCompanyModal(false)}
          loading={isDeleteCompanyLoading}
          title={t('deleteCompanyModal.title')}
          description={t('deleteCompanyModal.description')}
        />
      )}
    </Modal>
  )
}
