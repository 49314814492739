import {
  arFlagImage,
  usaFlagImage,
  colFlagImage,
  parFlagImage,
  urFlagImage,
  brFlagImage,
  doFlagImage,
  paFlagImage,
} from 'assets/images'
import { CountryId } from 'types'

const flags: {
  [key in CountryId]: string
} = {
  ARGENTINA: arFlagImage,
  BRAZIL: brFlagImage,
  COLOMBIA: colFlagImage,
  DOMINICAN_REPUBLIC: doFlagImage,
  PANAMA: paFlagImage,
  PARAGUAY: parFlagImage,
  UNITED_STATES: usaFlagImage,
  URUGUAY: urFlagImage,
}

export const COUNTRIES = {
  flags,
}
