import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useMemo } from 'react'

import { ActiveCompanyContext } from 'contexts'
import { LocaleLanguage, Selects } from 'types'
import { LANGUAGE } from 'consts'

import { Varieties, VARIETIES, VarietiesVariables, Variety } from './api'

interface Props {
  cropId?: number
  companyId?: number
  onChange: (variety?: Variety, isNoVarietySelected?: boolean) => void
  className?: string
  onClick?: () => void
  value?: number | Selects.NO_VARIETY
  defaultValue?: number
  dropdownClassName?: string
  popupMatchSelectWidth?: boolean
  withAllVarietiesOption?: boolean
  disabled?: boolean
  supportedVarietiesIds?: (number | undefined)[]
}

export const VarietiesSelect: React.FC<Props> = ({
  cropId,
  onChange,
  className,
  onClick,
  value,
  defaultValue,
  dropdownClassName,
  popupMatchSelectWidth,
  withAllVarietiesOption,
  disabled,
  supportedVarietiesIds,
  companyId,
}) => {
  const { t } = useTranslation('common')
  const { activeCountryId, activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { data, loading } = useQuery<Varieties, VarietiesVariables>(VARIETIES, {
    variables: {
      countryId: activeCountryId!,
      cropId: cropId!,
      companyId: companyId ?? activeCompanyId,
    },
    skip: !activeCountryId || !cropId,
  })
  const allVarietiesOption = withAllVarietiesOption ? Selects.ALL_VARIETIES : undefined
  const language = LANGUAGE[i18next.resolvedLanguage as LocaleLanguage]

  const options = useMemo(() => {
    const allVarietiesOptions =
      data?.varieties.results.map(({ id, locales }) => ({
        value: id,
        label: locales[language],
      })) ?? []

    if (!supportedVarietiesIds) return allVarietiesOptions

    const filteredVarieties = data?.varieties.results.filter(variety =>
      supportedVarietiesIds?.includes(variety.id),
    )

    return filteredVarieties?.map(variety => ({
      value: variety.id,
      label: variety.locales[language],
    }))
  }, [data, language, supportedVarietiesIds])

  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={t('inputs.variety')}
      loading={loading}
      className={className}
      onClick={onClick}
      value={value || allVarietiesOption}
      defaultValue={defaultValue || allVarietiesOption}
      popupClassName={dropdownClassName}
      popupMatchSelectWidth={popupMatchSelectWidth}
      disabled={disabled}
      onChange={id => {
        const selectedVariety = data?.varieties.results.find(variety => variety.id === id)
        const isNoVarietySelected = id === Selects.NO_VARIETY
        onChange(selectedVariety, isNoVarietySelected)
      }}
    >
      {withAllVarietiesOption && (
        <Select.Option value={Selects.ALL_VARIETIES}>{t('inputs.allVarieties')}</Select.Option>
      )}
      {supportedVarietiesIds?.includes(undefined) && (
        <Select.Option value={Selects.NO_VARIETY}>{t('inputs.noVariety')}</Select.Option>
      )}
      {options?.map(variety => (
        <Select.Option key={variety.value} value={variety.value}>
          {variety.label}
        </Select.Option>
      ))}
    </Select>
  )
}
