import { gql } from '@apollo/client'

export type { UpdateCompany, UpdateCompanyVariables } from './types/UpdateCompany'

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $updateCompanyDTO: UpdateCompanyInput!) {
    updateCompany(id: $id, updateCompanyDTO: $updateCompanyDTO) {
      company {
        id
        name
        logo
        governmentId
        subregionId
        irrigationWorkersAccessMode
        phoneNumber
      }
    }
  }
`
