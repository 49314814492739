import { Space } from 'antd'
import styled from 'styled-components'

import { Layout as BaseLayout } from 'components'

export { GoBackButton } from './GoBackButton'
export { SummaryTab } from './SummaryTab'
export { ReportTab } from './ReportTab'

export const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: column;
  > div:first-child {
    min-width: 1180px;
  }
  @media (min-width: 1360px) {
    align-items: center;
  }
`

export const Container = styled(Space).attrs({
  direction: 'vertical',
  size: 24,
})`
  width: 1180px;
  padding-bottom: 50px;

  @media (max-height: 900px) {
    padding-bottom: 25px;
  }
`
