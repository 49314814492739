import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UPDATE_FIELD, UpdateField, UpdateFieldVariables } from './api'

interface Props {
  onSuccess?: (fieldName: string, fieldId?: number) => void
}
export const useUpdateField = ({ onSuccess }: Props = {}) => {
  const [updateField, { loading: updateLoading }] = useMutation<UpdateField, UpdateFieldVariables>(
    UPDATE_FIELD,
    {
      onCompleted: (data: UpdateField) => {
        onSuccess?.(data.updateField.field.name)
      },
      onError: GraphQLUtils.errorHandler,
    },
  )

  return { updateField, updateLoading }
}
