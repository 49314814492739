import { gql } from '@apollo/client'

// eslint-disable-next-line camelcase
export type { Regions, Regions_regions_results as Region } from './types/Regions'

export const REGIONS = gql`
  query Regions {
    regions {
      results {
        id
        name
      }
    }
  }
`
