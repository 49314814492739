/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  ReuseLots,
  ReuseLotsVariables,
  ReuseLots_lotsV2_results as ReuseLot,
} from './types/ReuseLots'

export const REUSE_LOTS = gql`
  query ReuseLots($seasonId: Int!, $fieldId: Int!) {
    lotsV2(seasonId: $seasonId, fieldId: $fieldId) {
      results {
        id
        name
        commercialName
        size
        area {
          coordinates
        }
        variety {
          id
          locales {
            es
            en
          }
        }
        crop {
          id
          locales {
            es
            en
          }
        }
        rtk {
          coordinates
          type
        }
      }
    }
  }
`
