import { useCurrentUser } from './useCurrentUser'

/**
 * This hook return the logged in user
 * ! Use only when the user is already logged in. For all the other cases use useCurrentUser
 */
export const useUser = () => {
  const { user } = useCurrentUser()
  return user!
}
