import { PlusCircleOutlined, SettingOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import styled from 'styled-components'

export { UserSettings } from './UserSettings'
export { PopoverSearchInput } from './PopoverSearchInput'
export { OrganizationItem } from './OrganizationItem'
export { ActionButton } from './ActionButton'
export { VirtualizedListItem } from './VirtualizedListItem'

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`

export const Divider = styled.div`
  height: 1px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey3}`};
  margin: 15px 0;
`

export const Container = styled.div`
  padding: 0 30px;
`

export const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
`

export const StyledSettingOutlined = styled(SettingOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
`
