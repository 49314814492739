import { Row } from 'antd'
import styled from 'styled-components'

export { CompareCalendar } from './CompareCalendar'

interface CompareCalendarContainerProps {
  position?: 'right'
}
export const CompareCalendarContainer = styled(Row)<CompareCalendarContainerProps>`
  width: 360px;
  position: absolute;
  padding: 10px;
  z-index: 10;
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
  background: ${({ theme }) => theme.colors.new.baseColor[900]};
  left: ${({ position }) =>
    position ? `calc(((100% - 300px) * 7.5 / 100) + 60%)` : `calc((100% - 300px) * 7.5 / 100)`};
  top: 15px;
`
