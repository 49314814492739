import styled from 'styled-components'
import { Typography } from 'antd'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { useTranslation } from 'react-i18next'

const { Text: AntText } = Typography

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const SubTitle = styled(AntText)`
  font-weight: normal !important;
  margin-bottom: 0px !important;
  line-height: 24px;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.olive};
`

interface TooltipProps {
  active?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  payload?: Payload<NameType, ValueType>[]
  label: string
}

// we only want to show tooltip for real yield curve values
const VALUE_TO_SHOW = 'real'

export const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
  const { t } = useTranslation('lot-dashboard')
  const { t: commonT } = useTranslation('common')

  if (active && payload && payload.length && payload[0].name === VALUE_TO_SHOW) {
    return (
      <Container>
        <SubTitle>{`${commonT('vocabulary.daysAfterEmergenceAbbreviation')}: ${label}`}</SubTitle>
        <SubTitle>
          {t('targetYieldCard.chart.tooltipChlorophyll', { value: payload[0].value })}
        </SubTitle>
        <SubTitle>
          {t('targetYieldCard.chart.tooltipDate', { date: payload[0].payload.date })}
        </SubTitle>
      </Container>
    )
  }

  return null
}
