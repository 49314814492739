import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

import { useLotsManagementColumns } from './hooks'

interface Props {
  placeholder: string
  onChange: (columns: string[]) => void
  selectedColumns: string[]
  className?: string
  defaultValue?: number
  isAnAdminUser: boolean
}

const Select = styled(AntSelect)`
  min-width: 220px;
  .ant-select-selector {
    height: 38px;
    font-size: 13px;
    font-weight: 600;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-selection-item {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
` as typeof AntSelect

const SELECT_NEW_UI_CLASSNAME = 'custom-select-dropdown'

export const ColumnsSelect: React.FC<Props> = ({
  placeholder,
  onChange,
  selectedColumns,
  isAnAdminUser,
}) => {
  const { columns } = useLotsManagementColumns(isAnAdminUser)

  return (
    <Select
      placeholder={placeholder}
      popupMatchSelectWidth={false}
      onChange={onChange}
      value={selectedColumns}
      popupClassName={SELECT_NEW_UI_CLASSNAME}
      filterOption={(input, option) => {
        if (!option) return false
        return (
          option.label?.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase()) ?? false
        )
      }}
      mode="multiple"
      listHeight={300}
      maxTagCount="responsive"
      options={columns}
    />
  )
}
