import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EllipsisText } from 'components'

import { RenderActiveBorder } from '../RenderActiveBorder'

interface Props {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  payload: { name: string; cropName: string }
  percent: number
  totalHa: number
  fill: string
  y?: number
  showCropName: boolean
}

export const RenderActiveShape: React.FC<Props> = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  payload,
  percent,
  totalHa,
  fill,
  y = 70,
  showCropName,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation('dashboard')
  const { t: commonT } = useTranslation('common')

  return (
    <g>
      <foreignObject x={20} y={y} width="82%" height="48">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {showCropName && (
            <EllipsisText
              text={payload.cropName}
              overlayInnerStyle={{
                fontWeight: 600,
                maxWidth: '90px',
                color: colors.white,
                lineHeight: '24px',
              }}
            />
          )}
          <EllipsisText
            text={payload.name}
            overlayInnerStyle={{
              fontWeight: 600,
              maxWidth: '90px',
              color: colors.white,
              lineHeight: '24px',
            }}
          />
        </div>
      </foreignObject>
      <text x={cx} y={cy} dy={25} textAnchor="middle" fill={colors.white}>
        {`${(percent * 100).toFixed()}%`}
      </text>
      <RenderActiveBorder
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <text
        x={cx}
        y={cy}
        dy={110}
        textAnchor="middle"
        fill={colors.white}
        stroke={colors.white}
        style={{ fontSize: '16px', fontWeight: 250 }}
      >
        {t('varietiesAndCropsCard.farmTotalSize', {
          size: commonT('systemsFormat.area', { val: { area: totalHa } }),
        })}
      </text>
    </g>
  )
}
