import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from 'antd'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components'
import { useUser } from 'hooks'
import { ActiveCompanyContext } from 'contexts'
import { CountryId } from 'types'

import { useCreateCompany, useCreateCompanyForm } from '../../../../hooks'
import { CreateCompanyForm } from './components'

export const CreateCompanyCard = () => {
  const { t } = useTranslation('onboarding')
  const navigate = useNavigate()
  const { setIsNewCompany } = ActiveCompanyContext.useActiveCompanyContext()
  const user = useUser()

  const [image, setImage] = useState<string>()
  const [imageFile, setImageFile] = useState<File>()
  const { control, handleSubmit } = useCreateCompanyForm()

  const onSuccess = () => {
    navigate('/dashboard', { replace: true })
    setIsNewCompany(true)
  }

  const { createCompany, loading } = useCreateCompany(onSuccess)

  return (
    <Space direction="vertical" size={60} style={{ width: '100%' }}>
      <CreateCompanyForm
        control={control}
        image={image}
        onImageChange={(imageResult, file) => {
          setImage(imageResult)
          setImageFile(file)
        }}
      />

      <Button
        style={{ width: '100%' }}
        type="primary"
        loading={loading}
        onClick={handleSubmit(({ companyName, country }) =>
          createCompany(
            { name: companyName, country: country as CountryId, logo: imageFile! },
            user.id,
          ),
        )}
      >
        {t('firstCompany.buttonText')}
      </Button>
    </Space>
  )
}
