import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { DeleteLot, DeleteLotVariables, DELETE_LOT } from '../api'

export const useDeleteLot = (onCompleted?: (data: DeleteLot) => void) => {
  const [deleteLot, { loading }] = useMutation<DeleteLot, DeleteLotVariables>(DELETE_LOT, {
    onCompleted,
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }, { variables }) => {
      if (!variables || !response?.deleteLot.success) return

      const deletedLot: StoreObject = { id: variables.id, __typename: 'LotV2' }
      const deletedActiveLot: StoreObject = { id: variables.id, __typename: 'ActiveLot' }

      cache.evict({ id: cache.identify(deletedLot) })
      cache.evict({ id: cache.identify(deletedActiveLot) })
      cache.gc()
    },
  })

  return { deleteLot, loading }
}
