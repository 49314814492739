import { Row, Space, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { Title, Border, ContainerNumber, UnitNumber, UnitText, Input } from './components'

interface Props {
  title: string
  valueInTn: string
  valueInKgPerHa?: string
  editMode?: boolean
  defaultValue?: number
  onValueChange?: (value: number) => void
}

export const YieldValues: React.FC<Props> = ({
  title,
  valueInTn,
  valueInKgPerHa,
  editMode,
  defaultValue,
  onValueChange,
}) => {
  const { t: commonT } = useTranslation('common')

  return (
    <Row gutter={[0, 24]} align="middle">
      <Col span={24}>
        <Title>{title}</Title>
      </Col>
      <Col>
        <Space size={10}>
          {editMode ? (
            <Border>
              <Input
                defaultValue={defaultValue}
                onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                  const newValue = event.currentTarget.value
                  onValueChange?.(Number(newValue))
                }}
              />
              <UnitText>{commonT('systems.surfaceDensity')}</UnitText>
            </Border>
          ) : (
            <Border>
              <ContainerNumber>
                <UnitNumber>{valueInKgPerHa}</UnitNumber>
              </ContainerNumber>
              <UnitText>{commonT('systems.surfaceDensity')}</UnitText>
            </Border>
          )}

          <Border>
            <ContainerNumber>
              <UnitNumber>{valueInTn}</UnitNumber>
            </ContainerNumber>
            <UnitText>{commonT('systems.tonnesAbbreviation')}</UnitText>
          </Border>
        </Space>
      </Col>
    </Row>
  )
}
