import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'

import { Button } from 'components'

const { Title: AntTitle } = Typography

const CenteredRow = styled(Row)`
  flex-grow: 1;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  margin-top: -50px;
`

const ColContainer = styled(Col)`
  width: 500px;
  background: ${({ theme }) => theme.colors.grey1};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(211, 211, 211, 0.25);
  border-radius: ${({ theme }) => theme.foundation.borderRadius};

  &.ant-col:first-child {
    padding: 30px 48px !important;
  }
`

const Description = styled(AntTitle)`
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 26px !important;
  margin-bottom: 22px !important;
`

const Title = styled(AntTitle)`
  margin-bottom: 0 !important;
`
interface Props {
  title: string
  description: string
  onClick?: () => void
  buttonText?: string
}
export const Card: React.FC<Props> = ({ title, description, onClick, buttonText }) => {
  return (
    <CenteredRow justify="center" align="middle">
      <ColContainer>
        <Title level={3}>{title} </Title>
        <Description level={5}>{description}</Description>
        <Row justify="end">
          <Button type="primary" onClick={onClick}>
            {buttonText}
          </Button>
        </Row>
      </ColContainer>
    </CenteredRow>
  )
}
