import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useForgotPasswordForm = () => {
  const { t } = useTranslation('common')

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('validations.email')).required(t('validations.required')),
  })

  return useForm({ resolver: yupResolver(validationSchema) })
}
