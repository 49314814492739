/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  Varieties,
  VarietiesVariables,
  Varieties_varieties_results as Variety,
} from './types/Varieties'

export const VARIETIES = gql`
  query Varieties($countryId: CountryId!, $cropId: Int!, $companyId: Int) {
    varieties(countryId: $countryId, cropId: $cropId, companyId: $companyId) {
      results {
        id
        name
        locales {
          es
          en
        }
      }
    }
  }
`
