import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateLotById, UpdateLotByIdVariables, UPDATE_LOT } from './api'

export type { UpdateLotById, UpdateLotByIdVariables, UPDATE_LOT } from './api'

export const useUpdateLot = () => {
  const [updateLot, { loading }] = useMutation<UpdateLotById, UpdateLotByIdVariables>(UPDATE_LOT, {
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }) => {
      if (!response) return
      const seasonLot: StoreObject = {
        id: response.updateLot.lot.id,
        __typename: 'SeasonLot',
      }

      const {
        variety,
        previousCrop,
        productionType,
        abandonedSize,
        deliveryCondition,
        deliveryMill,
      } = response.updateLot.lot

      cache.modify({
        id: cache.identify(seasonLot),
        fields: {
          variety() {
            return variety
          },
          previousCrop() {
            return previousCrop
          },
          productionType() {
            return productionType
          },
          abandonedSize() {
            return abandonedSize
          },
          deliveryCondition() {
            return deliveryCondition
          },
          deliveryMill() {
            return deliveryMill
          },
        },
      })
    },
  })

  return { updateLot, loading }
}
