import { gql } from '@apollo/client'

export type {
  AssignIrrigationWorker,
  AssignIrrigationWorkerVariables,
} from './types/AssignIrrigationWorker'
export type {
  CompanyIrrigationWorkers,
  CompanyIrrigationWorkersVariables,
} from './types/CompanyIrrigationWorkers'

export const COMPANY_IRRIGATION_WORKERS = gql`
  query CompanyIrrigationWorkers($id: Int!) {
    company(id: $id) {
      id
      usersRoles {
        role
        user {
          id
          name
          lastName
        }
      }
    }
  }
`

export const ASSIGN_IRRIGATION_WORKER = gql`
  mutation AssignIrrigationWorker($updateLotDTO: UpdateLotInput!) {
    updateLot(updateLotDTO: $updateLotDTO) {
      lot {
        id
        irrigationWorker {
          id
          name
          lastName
        }
      }
    }
  }
`
