import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateRiceFertilizerVariables, UpdateRiceFertilizer, UPDATE_RICE_FERTILIZER } from '../api'

export const useUpdateRiceFertilizer = () => {
  const [updateRiceFertilizer, { loading }] = useMutation<
    UpdateRiceFertilizer,
    UpdateRiceFertilizerVariables
  >(UPDATE_RICE_FERTILIZER, {
    onError: GraphQLUtils.errorHandler,
  })

  return { updateRiceFertilizer, loading }
}
