import { ComponentProps } from 'react'
import styled from 'styled-components'
import { Button as AntButton } from 'antd'

export const StyledButton = styled(AntButton)`
  min-width: ${({ theme }) => theme.foundation.buttonWidth};
`

type Props = ComponentProps<typeof AntButton>

export const Button: React.FC<Props> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)
