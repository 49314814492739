import { Col, Row, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { UpdateRiceLotVariables } from 'hooks/useUpdateRiceLot/api'

import { Text, TextContainer, YieldValues } from './components'

interface Values {
  inTn?: number
  kgPerHa?: number
}

interface Props {
  riceLotId?: number
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
  isFeatureSetGrupoDiana: boolean
  isAnOrganizationUser: boolean
  estimatedBySubregion: Values
  estimatedByLot: Values
  real: Values
  size: number
}

export const Body: React.FC<Props> = ({
  riceLotId,
  updateRiceLotMutation,
  isFeatureSetGrupoDiana,
  isAnOrganizationUser,
  estimatedBySubregion,
  estimatedByLot,
  real,
  size,
}) => {
  const { t } = useTranslation('lot-dashboard')
  const { t: commonT } = useTranslation('common')

  return (
    <>
      <Row wrap={false}>
        {isAnOrganizationUser && (
          <Col span={8}>
            <YieldValues
              title={t('yieldCard.expectedBySubregion')}
              valueInTn={estimatedBySubregion.inTn ? estimatedBySubregion.inTn.toFixed() : '-'}
              valueInKgPerHa={
                estimatedBySubregion.kgPerHa
                  ? commonT('systemsFormat.surfaceDensity', {
                      val: estimatedBySubregion.kgPerHa,
                      noUnit: true,
                    })
                  : '-'
              }
            />
          </Col>
        )}
        <Col span={8}>
          <YieldValues
            editMode
            title={t('yieldCard.expectedByLot')}
            valueInTn={estimatedByLot.inTn ? estimatedByLot.inTn.toFixed() : '-'}
            defaultValue={estimatedByLot.kgPerHa ? Math.round(estimatedByLot.kgPerHa) : undefined}
            onValueChange={value => {
              if (!riceLotId)
                return notification.error({
                  message: commonT('messages.genericErrorTryAgain'),
                })

              return updateRiceLotMutation({
                variables: {
                  id: riceLotId,
                  updateRiceLotDTO: { estimatedYield: Number(value) * size },
                },
              })
            }}
          />
        </Col>
        <Col span={8}>
          <YieldValues
            editMode
            title={isFeatureSetGrupoDiana ? t('yieldCard.declared') : t('yieldCard.real')}
            defaultValue={real.kgPerHa ? Math.round(real.kgPerHa) : undefined}
            valueInTn={real.inTn ? real.inTn.toFixed() : '-'}
            onValueChange={value => {
              if (!riceLotId)
                return notification.error({
                  message: commonT('messages.genericErrorTryAgain'),
                })
              return updateRiceLotMutation({
                variables: {
                  id: riceLotId,
                  updateRiceLotDTO: { yield: Number(value) * size },
                },
              })
            }}
          />
        </Col>
      </Row>
      {!isFeatureSetGrupoDiana && (
        <TextContainer>
          <Text>
            <Text strong>{t('yieldCard.important')}</Text>
            {t('yieldCard.completeYieldDescription')}
          </Text>
        </TextContainer>
      )}
    </>
  )
}
