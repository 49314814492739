import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback } from 'react'
import { Divider as AntDivider, DatePicker as BaseDatePicker } from 'antd'
import styled, { useTheme } from 'styled-components'
import dayjs from 'dayjs'

import { CalendarPanel } from 'components'
import { DATE_FORMATS } from 'consts'
import { CloudyIcon } from 'assets/icons'
import { LotsUtils } from 'features/MainRouter/features/Map/utils'
import { DateState, CalendarDateIndex } from 'types'

const Divider = styled(AntDivider)`
  height: 32px !important;
  border-left: ${({ theme }) => `2px solid ${theme.colors.new.baseColor[400]}`} !important;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const DatePicker = styled(BaseDatePicker)`
  width: 50px;
  padding: 7px 0;
  input {
    text-align: center;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.white} !important;
  }
` as unknown as typeof BaseDatePicker

interface Props {
  calendarDates: CalendarDateIndex[]
  onChange: (date?: string) => void
  defaultValue?: string
  setDates: Dispatch<SetStateAction<DateState>>
}

export const CompareCalendar: React.FC<PropsWithChildren<Props>> = ({
  children,
  calendarDates,
  onChange,
  defaultValue,
  setDates,
}) => {
  const { colors } = useTheme()

  const findDateInCalendar = useCallback(
    (date: dayjs.Dayjs) =>
      calendarDates.find(
        calendarDate =>
          dayjs(calendarDate.date).format(DATE_FORMATS.DATE) === date.format(DATE_FORMATS.DATE),
      ),
    [calendarDates],
  )

  const disabledDate = (current: dayjs.Dayjs) => {
    const isDateInCalendar = findDateInCalendar(current)

    if (!isDateInCalendar || !isDateInCalendar.mapIndexInfo.isAvailable) return true

    return false
  }

  const getDateStyleAndInfo = (date: dayjs.Dayjs) => {
    const isDateInCalendar = findDateInCalendar(date)

    if (!isDateInCalendar || !isDateInCalendar?.mapIndexInfo.isAvailable)
      return { style: { border: '0', borderRadius: '0' }, isDateInCalendar }

    return {
      style: {
        border: `1px solid ${colors.olive2}`,
        borderRadius: '100%',
        backgroundColor: 'transparent',
      },
      isDateInCalendar,
    }
  }
  return (
    <Container>
      {children}
      <Divider type="vertical" />
      <DatePicker
        disabledDate={disabledDate}
        format={DATE_FORMATS.DAY}
        value={defaultValue ? dayjs(defaultValue) : undefined}
        allowClear={false}
        placeholder=""
        bordered={false}
        showToday={false}
        suffixIcon={null}
        panelRender={originalPanel => {
          return <CalendarPanel>{originalPanel}</CalendarPanel>
        }}
        onChange={date => {
          if (!date) return
          const calendarDate = findDateInCalendar(date)

          const dateHasUrl = LotsUtils.dateHasUrls(calendarDate)

          if (!dateHasUrl) {
            const fromYearMonth = new Date(date.toDate())
            const toYearMonth = new Date(date.toDate())
            setDates({ fromYearMonth, toYearMonth, newDates: true })
          }
          onChange(dayjs(date).format(DATE_FORMATS.DATE_WITH_SEPARATOR))
        }}
        cellRender={current => {
          const style = getDateStyleAndInfo(current)
          return (
            <div className="ant-picker-cell-inner" style={style.style}>
              {style.isDateInCalendar && !style.isDateInCalendar.mapIndexInfo.isAvailable ? (
                <CloudyIcon />
              ) : (
                current.date()
              )}
            </div>
          )
        }}
      />
    </Container>
  )
}
