import { useMutation } from '@apollo/client'

import { UPDATE_USER, UpdateUser, UpdateUserVariables } from 'api'
import { GraphQLUtils } from 'utils'

export const useUpdateUser = (onCompleted?: () => void) => {
  const [updateUser, { loading, error }] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER,
    {
      onError: GraphQLUtils.errorHandler,
      onCompleted,
    },
  )

  return { updateUser, loading, error }
}
