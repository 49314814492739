import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'

const { Header: AntHeader } = Layout

const MainHeader = styled(AntHeader)`
  background: ${({ theme }) => theme.colors.new.baseColor[850]};
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 24px;
`

interface Props {
  items?: JSX.Element[]
}

export const Header: React.FC<Props> = ({ items }) => {
  return (
    <MainHeader>
      <Row>
        {items?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={index}>{item}</Col>
        ))}
      </Row>
    </MainHeader>
  )
}
