import styled from 'styled-components'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker as AntDatePicker } from 'antd'

import { DATE_FORMATS } from 'consts'

const StyledDatePicker = styled(AntDatePicker)`
  width: 143px;
`

type Props = ComponentProps<typeof AntDatePicker>

export const DatePicker: React.FC<Props> = props => {
  const { t } = useTranslation('common')

  return (
    <StyledDatePicker
      inputReadOnly
      placeholder={t('inputs.date')}
      format={DATE_FORMATS.STRING_MONTH}
      {...props}
    />
  )
}
