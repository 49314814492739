/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Fields, FieldsVariables, Fields_fields_results as Field } from './types/Fields'

export const FIELDS = gql`
  query Fields($companyId: Int!) {
    fields(companyId: $companyId) {
      results {
        id
        name
        directions
        location {
          type
          coordinates
        }
      }
    }
  }
`
