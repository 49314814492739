/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Dams, Dam } from 'hooks/useQueryDams/api'
export type { UpdateDams, UpdateDamsVariables } from './types/UpdateDams'
export type { CreateDams, CreateDamsVariables } from './types/CreateDams'
export type { DeleteDam, DeleteDamVariables } from './types/DeleteDam'

export const CREATE_DAMS = gql`
  mutation CreateDams($createDamDTOs: [CreateDamInput!]!) {
    createDams(createDamDTOs: $createDamDTOs) {
      results {
        id
        name
        area {
          type
          coordinates
        }
        location {
          type
          coordinates
        }
        fieldId
      }
    }
  }
`

export const UPDATE_DAMS = gql`
  mutation UpdateDams($updateDamsArgs: [UpdateDamInput!]!) {
    updateDams(updateDamsArgs: $updateDamsArgs) {
      results {
        id
        name
        area {
          type
          coordinates
        }
        location {
          type
          coordinates
        }
      }
    }
  }
`

export const DELETE_DAM = gql`
  mutation DeleteDam($id: Int!) {
    deleteDam(id: $id) {
      success
    }
  }
`
