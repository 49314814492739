import { List as AntList, Typography, Upload as AntUpload } from 'antd'
import styled from 'styled-components'

import { UploadIcon } from 'assets/icons'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  width: 220px;
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
`

export const Box = styled.div`
  padding: 16px 16px;
  display: flex;
  align-content: center;
`

interface MenuItemProps {
  $isActive?: boolean
  $disabled?: boolean
}
export const MenuItem = styled.div<MenuItemProps>`
  :hover {
    background-color: ${({ theme }) => theme.colors.new.baseColor[600]};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  }
  & span,
  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.white : theme.colors.new.baseColor[400]};
  }
`
export const List = styled(AntList)`
  max-height: 250px;
  overflow: hidden auto;
  .ant-list {
    padding: 10px !important;
  }
`
export const ItemContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 50px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.new.baseColor[600]};
  }
`

interface ListItemProps {
  $isActive: boolean
}
export const ListItem = styled(AntList.Item)<ListItemProps>`
  padding: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  & span,
  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.white : theme.colors.new.baseColor[400]};
  }
`
export const HeaderText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 13px;
`
export const Text = styled(Typography.Text)`
  font-weight: 600;
  font-size: 13px;
`
export const Upload = styled(AntUpload)`
  height: 20px !important;
`

export const StyledUploadIcon = styled(UploadIcon)`
  cursor: pointer;
`
