import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { Col, notification } from 'antd'
import ReactMapGL, {
  Layer,
  Marker,
  Source,
  useMap,
  MapLayerMouseEvent,
  ViewStateChangeEvent,
} from 'react-map-gl'
import { useLocation, useNavigate } from 'react-router-dom'
import { Polygon, point, points, polygon } from '@turf/helpers'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import center from '@turf/center'
import { useTheme } from 'styled-components'
import centroid from '@turf/centroid'
import { Result } from '@mapbox/mapbox-gl-geocoder'
import { isNumber } from 'lodash'

import { ConversionUtils, MiscUtils, GeoUtils } from 'utils'
import { MAP, NAVIGATION } from 'consts'
import { FieldsSelect } from 'components'
import type { SelectField } from 'components/fields-selects/types'
import { ActiveCompanyContext } from 'contexts'
import { LotsMapLocationState, DrawMode, MandatoryFeature, Features } from 'types'
import { usePermissions, useFeatures } from 'hooks'
import { config } from 'config'
import { AreaTooltip } from 'features/MainRouter/components'

import {
  DeleteLotConfirmationModal,
  LeftSidebar,
  MapSidebar,
  SearchBoxContainer,
  Control,
} from '../../components'
import {
  FieldsSelectContainer,
  LotsCard,
  Container,
  TooltipTextContainer,
  TooltipText,
  GeocoderControl,
} from './components'
import { Lot, ShowToolTipCoords } from './types'
import { useCreateLots, useLots } from './hooks'
import { useDeleteLot } from '../../hooks'
import { useUpdateLots } from './hooks/useUpdateLots'
import { ReuseLot } from './components/LotsCard/components/ReuseLotsModal/hooks/useReuseLots/api'

const DELETE_KEYS = ['Backspace', 'Esc', 'Escape', 'Delete']

export const LotsMap: React.FC = () => {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('lot-settings')
  const { t: commonT } = useTranslation('common')
  const { permissions } = usePermissions()
  const { organizationFeatures } = useFeatures()
  const { activeSeasonId, activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const {
    fieldId,
    seasonId: locationSeasonId,
    lotId,
    goTo,
  } = useLocation().state as LotsMapLocationState

  const seasonId = locationSeasonId ?? activeSeasonId

  const { createLots, loading: loadingCreateLots } = useCreateLots()
  const { updateLots, loading: loadingUpdateLots } = useUpdateLots()

  const [field, setField] = useState<SelectField>()

  const { lots: existingLots, loadingLots } = useLots(field?.id, seasonId)
  const { deleteLot, loading: deleteLotLoading } = useDeleteLot()

  const [selectedLotToDelete, setSelectedLotToDelete] = useState<Lot>()
  const [showDeleteLotModal, setShowDeleteLotModal] = useState(false)

  const { lotMap } = useMap()

  const onChangeLotTransition = useCallback(
    ({ longitude, latitude, zoom }: { longitude?: number; latitude?: number; zoom?: number }) => {
      if (!latitude || !longitude) return
      lotMap?.flyTo({
        center: [longitude, latitude],
        duration: MAP.DEFAULT_TRANSITION.transitionDuration,
        zoom,
        essential: true,
      })
    },
    [lotMap],
  )

  const [viewport, setViewport] = useState({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    zoom: MAP.ZOOM.DEFAULT,
  })
  const [lots, setLots] = useState<Lot[]>([])
  const [mode, setMode] = useState<
    | {
        mode: Extract<DrawMode, 'direct_select'>
        options: { featureId: string }
      }
    | { mode: Exclude<DrawMode, 'direct_select'> }
  >({
    mode: 'simple_select',
  })
  const initLotsEffectCalled = useRef(false)

  const goToLot = useCallback(
    (lot?: Polygon) => {
      if (lot) {
        const featurePoint = points(lot.coordinates[0])
        const lotCenter = center(featurePoint)
        const longitude = lotCenter.geometry.coordinates[0]
        const latitude = lotCenter.geometry.coordinates[1]
        if (lotMap) onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
      }
    },
    [lotMap, onChangeLotTransition],
  )

  const setSelectedLotIndex = (lotIndex: number) => {
    setLots(prevLots =>
      prevLots.map((lot, index) => ({
        ...lot,
        lot: { ...lot.lot, isSelected: index === lotIndex },
      })),
    )
  }

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!DELETE_KEYS.includes(event.key) || mode.mode !== 'draw_polygon') return

      const selectedLotIndex = lots.findIndex(lot => lot.lot.isSelected)
      const selectedLot = selectedLotIndex !== -1 ? lots[selectedLotIndex] : undefined
      if (selectedLot?.lot.size) return

      // We want to delete only the lots without size with the delete keys
      setMode({ mode: 'simple_select' })
      setLots(prevLots => {
        const newLots = [...prevLots]
        newLots.splice(selectedLotIndex, 1)
        return newLots
      })
    },
    [lots, mode.mode],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const onCreateFeature = useCallback(({ features }: { features: Features }) => {
    if (!features) return

    const createdFeature = features[0]
    const createdFeatureId = createdFeature.id
    if (!createdFeatureId) return

    setLots(prevLots => {
      const newLots = [...prevLots]
      const createdLotIndex = prevLots.findIndex(lot => lot.lot.isSelected)
      const createdLot = createdLotIndex !== -1 ? newLots[createdLotIndex] : undefined
      // Workaround for show again lot on the map when user delete last lot and cancels deletion
      if (!createdLot) return prevLots

      const { polygonCenter, area } = GeoUtils.getPolygonCenterAndArea(
        features[0].geometry.coordinates,
      )

      // If the lot has id meaning it's a lot with size but not with feature
      // because that we need to take the lot's id
      const createdLotId = createdLot.lot.id.toString() ?? createdFeatureId
      newLots[createdLotIndex] = {
        ...createdLot,
        lot: { ...createdLot.lot, id: createdLotId, polygonCenter, area, size: area },
        feature: { ...features[0], id: createdLotId },
      }
      setMode({
        mode: 'direct_select',
        options: { featureId: createdLotId },
      })
      return newLots
    })
  }, [])

  const onUpdateFeature = useCallback(({ features }: { features: Features }) => {
    if (!features) return
    const updatedFeature = features[0]

    setLots(prevLots => {
      const newLots = [...prevLots]
      const { polygonCenter, area } = GeoUtils.getPolygonCenterAndArea(
        updatedFeature.geometry.coordinates,
      )
      const updatedLotIndex = newLots.findIndex(lot => lot.lot.id === updatedFeature.id)
      if (!newLots[updatedLotIndex].lot.isPlanetEnabled) {
        newLots[updatedLotIndex] = {
          ...newLots[updatedLotIndex],
          lot: {
            ...newLots[updatedLotIndex].lot,
            polygonCenter,
            area,
            size: area,
          },
          feature: updatedFeature,
        }
      }
      return newLots
    })
  }, [])

  const onSelectLot = (lotIndex: number) => {
    const lot = lots[lotIndex]
    // This is for the edge case when a user creates a lot, selects from the card another lot
    // before drawing the recently created one and then comes back to that freshly created lot
    setSelectedLotIndex(lotIndex)
    if (!lot.feature) {
      setMode({ mode: 'draw_polygon' })
      return
    }

    const lotGeometry = lot.feature?.geometry
    if (lotGeometry) goToLot(lotGeometry)

    if (!lot.lot.isPlanetEnabled) {
      setMode({ mode: 'direct_select', options: { featureId: lot.lot.id.toString() } })
      return
    }

    setMode({ mode: 'simple_select' })
  }

  const handleCreatedLot = () => {
    setLots(prevLots => {
      const newLots = prevLots.map(lot => {
        return {
          ...lot,
          lot: { ...lot.lot, isSelected: false },
        }
      })

      return [
        ...newLots,
        {
          lot: { id: MiscUtils.generateId(), isSelected: true },
          feature: undefined,
          action: 'create',
        },
      ]
    })
  }

  const onAddLot = () => {
    handleCreatedLot()
    setMode({ mode: 'draw_polygon' })
  }

  const onAddLotWithoutPerimeter = () => {
    handleCreatedLot()
    setMode({ mode: 'simple_select' })
  }

  const onChangeLot = (lot: Lot['lot'], lotIndex: number) =>
    setLots(prevLots => {
      const newLots = [...prevLots]
      newLots[lotIndex].lot = lot
      return newLots
    })

  const onDeleteLot = (lotIndex: number) => {
    const lotToDelete = lots[lotIndex]
    setSelectedLotToDelete(lotToDelete)
    setShowDeleteLotModal(true)
  }

  const onConfirmDeleteLot = async () => {
    if (!selectedLotToDelete) return
    const lotIndex = lots.findIndex(lot => lot.lot.id === selectedLotToDelete.lot.id)
    if (selectedLotToDelete.action === 'update') {
      await deleteLot({ variables: { id: Number(selectedLotToDelete.lot.id) } })
    } else {
      const existingLot = lots.find(lot => lot.lot.id === selectedLotToDelete.lot.id)
      setLots(prevLots => {
        const newLots = [...prevLots]
        newLots.splice(lotIndex, 1)
        // Workaround for when a user deletes a lot by pressing 'backspace', the lot has already been filtered from state
        // in the onDelete function
        return !existingLot ? prevLots : newLots
      })
    }
    setSelectedLotToDelete(undefined)
    setShowDeleteLotModal(false)
  }

  const onCancelDeleteLot = () => {
    if (!selectedLotToDelete) return
    setMode({ mode: 'simple_select' })
    const existingLot = lots.find(lot => lot.lot.id === selectedLotToDelete.lot.id)
    // Workaround for when a user deletes a lot by pressing 'backspace', we need to set that lot again in the state
    if (!existingLot) {
      // It covers the case when user has only one lot and is deleting by bakspace, to no add a new empty lot
      if (lots.length === 1 && !lots[0].feature) {
        setLots([selectedLotToDelete])
      } else {
        setLots(prevLots => [...prevLots, selectedLotToDelete])
      }
    }

    setSelectedLotToDelete(undefined)
    setShowDeleteLotModal(false)
  }

  const onConfirmLots = async () => {
    if (!field || !seasonId) return

    const createLotDTOs = lots
      .filter(({ action }) => action === 'create')
      .map(({ lot, feature }) => ({
        name: lot.name!,
        commercialName: lot.commercialName ?? undefined,
        area: feature?.geometry,
        cropId: lot.cropId!,
        seasonId,
        fieldId: field.id,
        rtk: lot.rtk,
        templateLotId: lot.templateLotId,
        size: lot.size,
      }))
    const updateLotDTOs = lots
      .filter(({ action }) => action === 'update')
      .map(({ lot, feature }) => ({
        id: Number(lot.id),
        name: lot.name!,
        commercialName: lot.commercialName ?? undefined,
        cropId: lot.cropId,
        varietyId: lot.varietyId ?? null,
        area: !lot.isPlanetEnabled ? feature?.geometry : undefined,
        size: lot.size,
      }))

    if (createLotDTOs.length) {
      await createLots({ variables: { createLotDTOs } })
    }
    if (updateLotDTOs) {
      await updateLots({ variables: { updateLotDTOs } })
    }
    if (goTo) {
      navigate(goTo)
      return
    }
    navigate(NAVIGATION.GO_BACK)
  }

  const lotFeatures = useMemo(() => {
    const lotsWithFeature = lots.filter(
      ({ feature, lot }) => !!feature && !lot.isPlanetEnabled,
    ) as (Omit<Lot, 'feature'> & {
      feature: MandatoryFeature
    })[]
    return lotsWithFeature.map(({ feature, lot }) => {
      return { ...feature, id: lot.id.toString() }
    })
  }, [lots])

  const lotsWithPlanet = useMemo(
    () =>
      existingLots
        ?.filter(lot => lot.riceLot.isPlanetEnabled && !!lot.area)
        .map(lot => ({
          id: lot.id.toString(),
          feature: polygon(lot.area!.coordinates),
          area: lot.area,
        })),
    [existingLots],
  )

  const handleKml = async ({ file: uploadFile }: UploadChangeParam<UploadFile>) => {
    try {
      const file = uploadFile as unknown as File
      const geoJson = await MiscUtils.kmlFileToGeoJson(file)

      const kmlLots: Lot[] = geoJson.features.map(feature => {
        const centerAndArea = GeoUtils.getPolygonCenterAndArea(feature.geometry.coordinates)
        return {
          action: 'create',
          feature: {
            geometry: feature.geometry,
            properties: {},
            type: 'Feature',
          },
          lot: {
            ...centerAndArea,
            size: centerAndArea.area,
            id: MiscUtils.generateId(),
            name:
              feature.properties.name && ConversionUtils.autoCapitalize(feature.properties.name),
          },
        }
      })
      setMode({ mode: 'simple_select' })

      setLots(prevLots => {
        // this workaround is to remove all empty lots
        const filterLots = prevLots.filter(lot => isNumber(lot.lot.size))
        const newLots = [...filterLots, ...kmlLots]
        setSelectedLotIndex(newLots.length - 1)
        goToLot(newLots[newLots.length - 1].feature?.geometry)
        return newLots
      })
    } catch (err) {
      notification.error({
        message: t('uploadKMLErrorText'),
      })
    }
  }

  const handleChange = useCallback((newField: SelectField) => {
    // if the effect was already called once, and the field changes we filter those that lots created not confirmed
    if (initLotsEffectCalled.current)
      setLots(prevLots => prevLots.filter(lot => lot.action !== 'create'))

    setField(newField)
  }, [])

  const onMove = useCallback((evt: ViewStateChangeEvent) => setViewport(evt.viewState), [])

  const onDelete = useCallback((e: { features: Features }) => {
    setLots(prevLots => {
      const index = prevLots.findIndex(({ lot }) => lot.id === e.features?.[0].id)
      const lotToDelete = prevLots[index]
      setSelectedLotToDelete(lotToDelete)
      setShowDeleteLotModal(true)
      return prevLots.filter(lot => lot.lot.id !== lotToDelete.lot.id)
    })
  }, [])

  const onSelectionChange = useCallback((e: { features: Features }) => {
    if (e.features?.length) {
      setLots(prevLots => {
        const index = prevLots.findIndex(({ lot }) => lot.id === e.features?.[0].id)
        const selectedLot = prevLots[index]
        if (selectedLot?.lot.isPlanetEnabled) setMode({ mode: 'simple_select' })
        setSelectedLotIndex(index)
        return prevLots
      })
    }
  }, [])

  const setLotsWithNewLot = (existedLots: Lot[]) => {
    const newLots: Lot[] = [
      ...existedLots,
      {
        lot: { id: MiscUtils.generateId() },
        feature: undefined,
        action: 'create',
      },
    ]
    setLots(newLots)
    setSelectedLotIndex(newLots.length - 1)
    setMode({ mode: 'draw_polygon' })
    const latitude = field?.location.coordinates[1]
    const longitude = field?.location.coordinates[0]

    if (lotMap) onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
  }

  const setSelectedLotList = (existedLots: Lot[]) => {
    setLots(prevLots => {
      // this filter is to only set the lots created before deleting a lot from DB, and not set a new empty lot.
      const filterPrevLots = prevLots.filter(
        lot => lot.feature && lot.lot.name && lot.action === 'create',
      )
      return [...existedLots, ...filterPrevLots]
    })
    if (existingLots) {
      const lotIndex = existingLots.findIndex(lot => lot.id === lotId)
      setSelectedLotIndex(lotIndex)
      const preselectedLot = existingLots[lotIndex]
      if (!preselectedLot.location) {
        const latitude = field?.location.coordinates[1]
        const longitude = field?.location.coordinates[0]
        onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
        setMode({ mode: 'draw_polygon' })
        return
      }
      const lotCoordinates = preselectedLot.location.coordinates
      const featurePoint = point(lotCoordinates)
      const lotCenter = center(featurePoint)
      const longitude = lotCenter.geometry.coordinates[0]
      const latitude = lotCenter.geometry.coordinates[1]
      onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })

      const { isPlanetEnabled } = preselectedLot.riceLot
      if (lotId && !isPlanetEnabled)
        setMode({ mode: 'direct_select', options: { featureId: lotId.toString() } })
    }
  }

  useEffect(() => {
    if (loadingLots || !field || !lotMap) return
    if (existingLots?.length) {
      const existedLots: Lot[] = existingLots.map(lot => {
        const baseLot: Lot = {
          lot: {
            id: lot.id.toString(),
            name: lot.name,
            commercialName: lot.commercialName,
            cropId: lot.crop.id,
            varietyId: lot.variety?.id,
            isPlanetEnabled: lot.riceLot.isPlanetEnabled,
            size: lot.size,
          },
          action: 'update',
        }

        if (!lot.area?.coordinates) return baseLot

        const feature = polygon(lot.area.coordinates)
        const { polygonCenter, area } = GeoUtils.getPolygonCenterAndArea(
          feature.geometry.coordinates,
        )

        return {
          ...baseLot,
          lot: {
            ...baseLot.lot,
            polygonCenter,
            area,
          },
          feature,
        }
      })

      if (!lotId && !initLotsEffectCalled.current) {
        setLotsWithNewLot(existedLots)
      } else if (lotId && !initLotsEffectCalled.current) {
        setSelectedLotList(existedLots)
      } else {
        setLots(prevLots => {
          // this filter is to only set the lots created before deleting a lot from DB, and not set a new empty lot.
          const filterPrevLots = prevLots.filter(
            lot => lot.feature && lot.lot.name && lot.action === 'create',
          )
          return [...existedLots, ...filterPrevLots]
        })

        setSelectedLotIndex(0)
        const firstLot = existedLots[0]
        if (firstLot && firstLot.feature) {
          goToLot(firstLot.feature.geometry)
          const { isPlanetEnabled } = firstLot.lot
          if (!isPlanetEnabled)
            setMode({ mode: 'direct_select', options: { featureId: firstLot.lot.id.toString() } })
        }
      }
    } else {
      setLots([
        {
          lot: { id: MiscUtils.generateId() },
          feature: undefined,
          action: 'create',
        },
      ])
      setSelectedLotIndex(0)
      setMode({ mode: 'draw_polygon' })
      const latitude = field.location.coordinates[1]
      const longitude = field.location.coordinates[0]
      onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
    }
    initLotsEffectCalled.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingLots, field, loadingLots, lotId, lotMap])

  const [showTooltipCoords, setShowTooltipCoords] = useState<ShowToolTipCoords>()

  const onClickLot = useCallback(
    (event: MapLayerMouseEvent) => {
      if (showTooltipCoords) {
        setShowTooltipCoords(undefined)
        return
      }
      const { features } = event
      const feature = features?.[0]
      if (!feature) return

      const lot = lotsWithPlanet?.find(lotToFind => lotToFind.id === feature.layer.id)

      if (!lot) return

      const polygonLot = polygon(lot.area!.coordinates)
      const polygonCenter = centroid(polygonLot)

      setShowTooltipCoords({
        latitude: polygonCenter.geometry.coordinates[1] + 0.003,
        longitude: polygonCenter.geometry.coordinates[0],
      })
    },
    [lotsWithPlanet, showTooltipCoords],
  )

  const interactiveLayerIds = useMemo(() => lotsWithPlanet?.map(lot => lot.id), [lotsWithPlanet])

  const geocoderContainerRef = useRef<HTMLDivElement>(null)

  const onResult = useCallback(({ result }: { result: Result }) => {
    const location =
      result &&
      (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates))

    if (location) {
      setViewport({ longitude: location[0], latitude: location[1], zoom: MAP.ZOOM.DEFAULT })
    }
  }, [])

  const handleReuseLots = (reusableLots: ReuseLot[]) => {
    const reuseLots: Lot[] = reusableLots.map(lot => {
      const baseLot: Lot = {
        lot: {
          id: MiscUtils.generateId(),
          name: lot.name,
          cropId: lot.crop.id,
          commercialName: lot.commercialName,
          templateLotId: lot.id,
          size: lot.size,
        },
        action: 'create',
      }

      if (!lot.area?.coordinates) return baseLot

      const feature = polygon(lot.area.coordinates)
      const { polygonCenter, area } = GeoUtils.getPolygonCenterAndArea(feature.geometry.coordinates)

      return {
        ...baseLot,
        lot: {
          ...baseLot.lot,
          polygonCenter,
          area,
        },
        feature,
      }
    })

    setMode({ mode: 'simple_select' })

    setLots(prevLots => {
      // this workaround is to remove all empty lots
      const filterLots = prevLots.filter(lot => isNumber(lot.lot.size))
      const newLots = [...filterLots, ...reuseLots]
      const lastLotIndex = newLots.length - 1
      setSelectedLotIndex(lastLotIndex)
      if (newLots[lastLotIndex].feature?.geometry) {
        goToLot(newLots[lastLotIndex].feature?.geometry)
      } else {
        const latitude = field?.location.coordinates[1]
        const longitude = field?.location.coordinates[0]
        onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
      }
      return newLots
    })
  }

  return (
    <>
      <LeftSidebar>
        <Col flex={1}>
          <SearchBoxContainer ref={geocoderContainerRef} id="geocoder-container" />
        </Col>
      </LeftSidebar>
      <MapSidebar $width={organizationFeatures.crm?.enabled ? '500px' : undefined}>
        <Col flex={1}>
          <FieldsSelectContainer justify="end" hidden={!permissions.fieldEntity}>
            <FieldsSelect companyId={activeCompanyId} onChange={handleChange} defaultId={fieldId} />
          </FieldsSelectContainer>
          <LotsCard
            lots={lots}
            selectedLotIndex={lots.findIndex(({ lot }) => lot.isSelected)}
            field={field}
            onSelectLot={onSelectLot}
            onAddLot={onAddLot}
            onChangeLot={onChangeLot}
            onDeleteLot={onDeleteLot}
            onConfirmLots={onConfirmLots}
            loading={loadingLots || deleteLotLoading || loadingCreateLots || loadingUpdateLots}
            handleKml={handleKml}
            onDrawMode={mode.mode === 'draw_polygon'}
            seasonId={seasonId}
            handleReuseLots={handleReuseLots}
            onAddLotWithoutPerimeter={onAddLotWithoutPerimeter}
          />
        </Col>
      </MapSidebar>
      <Container>
        <ReactMapGL
          {...viewport}
          mapStyle={MAP.STYLES.SATELLITE_STREET}
          onMove={onMove}
          cursor={mode.mode === 'draw_polygon' ? 'crosshair' : 'grab'}
          id="lotMap"
          onClick={onClickLot}
          interactiveLayerIds={showTooltipCoords ? undefined : interactiveLayerIds}
        >
          <GeocoderControl
            geocoderOptions={{
              accessToken: config.mapboxToken,
              language: MAP.LANGUAGES.ES,
              placeholder: t('geocoderPlaceholder'),
              marker: false,
            }}
            containerRef={geocoderContainerRef}
            onResult={onResult}
          />
          <Control
            mapOptions={{
              displayControlsDefault: false,
              controls: {
                polygon: true,
                trash: true,
              },
            }}
            onCreate={onCreateFeature}
            onUpdate={onUpdateFeature}
            onDelete={onDelete}
            onSelectionChange={onSelectionChange}
            features={lotFeatures}
            mode={mode}
          />
          {lots.map(
            ({ feature, lot: { area, polygonCenter } }) =>
              feature &&
              area &&
              polygonCenter && (
                <AreaTooltip
                  drawAreaInformation={{
                    area,
                    feature,
                    polygonCenter,
                  }}
                />
              ),
          )}
          {lotsWithPlanet?.map(({ id, feature }) => (
            <Source key={id} type="geojson" id={id} data={polygon(feature.geometry.coordinates)}>
              <Layer
                id={id}
                type="fill"
                paint={{
                  'fill-color': colors.yellow,
                  'fill-opacity': 0.5,
                }}
              />
              <Layer
                id={`lot-${id}`}
                type="line"
                paint={{
                  'line-color': colors.yellow,
                  'line-width': 2,
                }}
              />
            </Source>
          ))}
          {showTooltipCoords && (
            <Marker longitude={showTooltipCoords.longitude} latitude={showTooltipCoords.latitude}>
              <TooltipTextContainer>
                <TooltipText>{commonT('messages.highFrequencyLot')}</TooltipText>
              </TooltipTextContainer>
            </Marker>
          )}
        </ReactMapGL>
      </Container>
      {showDeleteLotModal && selectedLotToDelete && field && (
        <DeleteLotConfirmationModal
          lotName={selectedLotToDelete.lot.name!}
          fieldName={field.name}
          onConfirm={onConfirmDeleteLot}
          onCancel={onCancelDeleteLot}
          loading={deleteLotLoading}
        />
      )}
    </>
  )
}
