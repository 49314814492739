const APP_ENV = process.env.REACT_APP_ENV

export const config = {
  env: APP_ENV,
  version: process.env.REACT_APP_VERSION,
  apiUrl: process.env.REACT_APP_API_URL,
  mapboxToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  isSentinelImageryOn: process.env.REACT_APP_SENTINEL_IMAGERY_ON === 'true',
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY,
}
