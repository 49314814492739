import { Button, Space as AntSpace } from 'antd'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Content, FormEmailInput, LoginTextCta } from '../../components'
import { useForgotPasswordForm } from './hooks'
import {
  RequestPasswordChange,
  RequestPasswordChangeVariables,
  REQUEST_PASSWORD_CHANGE,
} from './api'
import { useFormIconsStyle } from '../../hooks'

const RecoverPasswordButton = styled(Button)`
  width: 100%;
`

const Space = styled(AntSpace)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const ForgotPassword: React.FC = () => {
  const iconStyle = useFormIconsStyle()
  const { t, i18n } = useTranslation('login')

  const navigate = useNavigate()

  const { control, handleSubmit } = useForgotPasswordForm()

  const [requestPasswordChange, { loading }] = useMutation<
    RequestPasswordChange,
    RequestPasswordChangeVariables
  >(REQUEST_PASSWORD_CHANGE, {
    onCompleted: () => navigate('/login/password-change-request'),
    errorPolicy: 'all',
  })

  return (
    <Content>
      <Space direction="vertical" size="small">
        <FormEmailInput control={control} iconStyle={iconStyle} />
        <RecoverPasswordButton
          loading={loading}
          type="primary"
          onClick={handleSubmit(({ email }) =>
            requestPasswordChange({ variables: { email, locale: i18n.language } }),
          )}
        >
          {t('forgotPasswordPage.buttonText')}
        </RecoverPasswordButton>
      </Space>
      <LoginTextCta />
    </Content>
  )
}
