import { useTranslation } from 'react-i18next'

import { StatisticCard } from '../StatisticCard'

interface Props {
  daysToHarvestFromEmergence?: number
}

export const HarvestDateCard: React.FC<Props> = ({ daysToHarvestFromEmergence }) => {
  const { t } = useTranslation('lot-dashboard')

  return (
    <StatisticCard
      title={t('tabs.secondTab.harvestDateCard.title')}
      value={
        daysToHarvestFromEmergence && daysToHarvestFromEmergence > 0
          ? (t('tabs.secondTab.harvestDateCard.value', {
              days: daysToHarvestFromEmergence,
            }) as string)
          : '-'
      }
    />
  )
}
