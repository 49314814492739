import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

import { useDeliveryCondition } from 'hooks'
import { DeliveryCondition } from 'types'

interface Props {
  disabled?: boolean
  onChange: (productionType: DeliveryCondition) => void
  value: DeliveryCondition
}

export const DeliveryConditionSelect: React.FC<Props> = ({ disabled, onChange, value }) => {
  const { t } = useTranslation('common')

  const { deliveryConditionMap } = useDeliveryCondition()

  const options = Object.values(deliveryConditionMap)

  return (
    <Select
      disabled={disabled}
      onChange={onChange}
      value={value}
      placeholder={t('inputs.deliveryCondition')}
      style={{ width: '100%' }}
    >
      {options.map(option => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  )
}
