import { Col, Select as AntSelect, Space, Typography, SelectProps, Row } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

import { SatelliteIcon } from 'assets/icons'
import { SatelliteProviders } from 'types'
import { IconText } from 'components'

interface StyledProps {
  $width?: string
  $isCompareMapMode: boolean
}

export const Select = styled(AntSelect)<StyledProps>`
  visibility: ${({ $isCompareMapMode }) => $isCompareMapMode && 'hidden'};
  width: ${({ $width }) => $width || '265px'};
  & .ant-select-selector {
    margin-left: ${({ $isCompareMapMode }) => $isCompareMapMode && '-50px'};
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    height: 48px !important;
    border-radius: ${({ theme }) => theme.foundation.smallRadius} !important;
    font-size: 13px;
    border: none !important;
    box-shadow: none !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
` as unknown as React.FC<SelectProps & StyledProps> & { Option: typeof AntSelect.Option }

export const StyledSpace = styled(Space)`
  width: 100%;
`

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px !important;
`
export const IconContainer = styled(Col)`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CompareSelect = styled(Row)`
  width: 50px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  border-radius: ${({ theme }) => theme.foundation.smallRadius} !important;
  margin-left: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer !important;
  }
`

interface Props {
  onChange: (value: SatelliteProviders) => void
  selectedSatellite: SatelliteProviders
  width?: string
  popupMatchSelectWidth?: boolean
  isCompareMapMode?: boolean
  isPlanetAvailable: boolean
  isLandsatAvailable: boolean
  isSentinelAvailable: boolean
  isOpen?: boolean
  handleOpen?: Dispatch<SetStateAction<boolean>>
}

export const SatelliteSelect: React.FC<Props> = ({
  width,
  onChange,
  selectedSatellite,
  popupMatchSelectWidth,
  isCompareMapMode,
  isLandsatAvailable,
  isPlanetAvailable,
  isSentinelAvailable,
  isOpen,
  handleOpen,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation('map')

  const satelliteSelectText = {
    SENTINEL2: t('satelliteSelect.sentinel'),
    PLANET: t('satelliteSelect.planet'),
    LANDSAT89: t('satelliteSelect.landsat'),
  }

  const satelliteSelectTextAbbreviation = {
    SENTINEL2: t('satelliteSelect.sentinelAbbreviation'),
    PLANET: t('satelliteSelect.planetAbbreviation'),
    LANDSAT89: t('satelliteSelect.landsatAbbreviation'),
  }

  const dataSource = [
    {
      Icon: <SatelliteIcon color={colors.white} />,
      title: t('satelliteSelect.all'),
      value: 'ALL',
      isVisible: true,
    },
    {
      Icon: <IconText letter={t('satelliteSelect.sentinelAbbreviation')} />,
      title: satelliteSelectText.SENTINEL2,
      value: 'SENTINEL2',
      isVisible: isSentinelAvailable,
    },
    {
      Icon: <IconText letter={t('satelliteSelect.planetAbbreviation')} />,
      title: satelliteSelectText.PLANET,
      value: 'PLANET',
      isVisible: isPlanetAvailable,
    },
    {
      Icon: <IconText letter={t('satelliteSelect.landsatAbbreviation')} />,
      title: satelliteSelectText.LANDSAT89,
      value: 'LANDSAT89',
      isVisible: isLandsatAvailable,
    },
  ]

  const handleChange = (value: SatelliteProviders) => {
    onChange(value)
    handleOpen?.(false)
  }

  return (
    <>
      {isCompareMapMode && (
        <CompareSelect onClick={() => handleOpen?.(prevState => !prevState)}>
          {selectedSatellite === 'ALL' ? (
            <SatelliteIcon />
          ) : (
            <IconText letter={satelliteSelectTextAbbreviation[selectedSatellite]} />
          )}
        </CompareSelect>
      )}
      <Select
        onChange={handleChange}
        value={selectedSatellite}
        open={isCompareMapMode ? isOpen : undefined}
        popupClassName="custom-select-dropdown"
        $width={width}
        $isCompareMapMode={isCompareMapMode ?? false}
        listHeight={400}
        popupMatchSelectWidth={popupMatchSelectWidth}
      >
        {dataSource.map(data => {
          const { Icon, title, isVisible, value } = data

          return (
            isVisible && (
              <Select.Option key={value} value={value}>
                <StyledSpace size={10}>
                  <IconContainer>{Icon}</IconContainer>
                  <Text>{title}</Text>
                </StyledSpace>
              </Select.Option>
            )
          )
        })}
      </Select>
    </>
  )
}
