import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { MapContext } from 'features/MainRouter/contexts'
import { MapIndex, Lot, CalendarDate } from 'types'
import { LotsUtils } from 'features/MainRouter/features/Map/utils'

import { NoItem } from '../components'
import { BOX_COLORS } from '../const'

interface LevelCardTypes {
  boxBackground: string
  item: ReactElement
  area?: number | undefined
}

interface CardContentTypes {
  irrigation: LevelCardTypes[]
  chlorophyll: LevelCardTypes[]
  grainHumidity: LevelCardTypes[]
  water: LevelCardTypes[]
  weeds: LevelCardTypes[]
}

interface ColorAreaMap {
  [color: string]: number
}

interface Props {
  selectedMapIndex: MapIndex
  lots: Lot[]
}
export const useDescriptionCard = ({ lots, selectedMapIndex }: Props) => {
  const { t } = useTranslation('map')
  const { colors } = useTheme()

  const { selectedCalendarDate, selectedSatellite } = MapContext.useMapContext()

  const isAbsoluteHumidity = useMemo(() => {
    const allLotsHasAbsoluteVariety = lots.every(lot => {
      if (!lot.variety) return true

      return lot.variety.isGrainHumidityAbsolute
    })

    return allLotsHasAbsoluteVariety
  }, [lots])

  const checkIfHistogramIsNotAvailable = useCallback(
    (lotCalendar: CalendarDate[]) => {
      if (selectedMapIndex === 'realImage') return false

      return lotCalendar.some(calendarDate => {
        return (
          calendarDate.date === selectedCalendarDate &&
          calendarDate.mapUrls?.[selectedMapIndex]?.[0].isAvailable &&
          !calendarDate.mapUrls?.[selectedMapIndex]?.[0].colorHistogram
        )
      })
    },
    [selectedCalendarDate, selectedMapIndex],
  )

  const isHistogramNotAvailable = useMemo(
    () => lots.some(lot => checkIfHistogramIsNotAvailable(lot.riceLot.calendar)),
    [checkIfHistogramIsNotAvailable, lots],
  )

  const colorHistogram = useMemo(() => {
    const lotsSelectedDate = lots.map(
      lot =>
        lot.riceLot.calendar.find(calendarDate => calendarDate.date === selectedCalendarDate)
          ?.mapUrls,
    )
    const areaColorClassification = (
      indicator: 'irrigation' | 'chlorophyll' | 'grainHumidity' | 'water',
    ) => {
      return lotsSelectedDate.reduce((colorAreaMap: ColorAreaMap, curr) => {
        const calendarDate = LotsUtils.getCalendarDateByProvider(selectedSatellite, indicator, curr)

        if (curr) {
          if (!calendarDate?.isAvailable) return colorAreaMap

          calendarDate.colorHistogram?.forEach((level: { area: number; color: string }) => {
            const color = level.color.toUpperCase()
            // eslint-disable-next-line no-param-reassign
            colorAreaMap[color] = colorAreaMap[color]
              ? // eslint-disable-next-line no-param-reassign
                (colorAreaMap[color] += level.area)
              : // eslint-disable-next-line no-param-reassign
                (colorAreaMap[color] = level.area)
          })
        }
        return colorAreaMap
      }, {})
    }
    return {
      irrigation: areaColorClassification('irrigation'),
      chlorophyll: areaColorClassification('chlorophyll'),
      grainHumidity: areaColorClassification('grainHumidity'),
      water: areaColorClassification('water'),
    }
  }, [lots, selectedCalendarDate, selectedSatellite])

  const cardContent: CardContentTypes = {
    irrigation: [
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelOne,
        item: <>{t('descriptionCard.irrigation.enoughWater')}</>,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelOne] ?? 0,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelTwo,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelTwo] ?? 0,

        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelThree,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelThree] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelFour,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelFour] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelFive,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelFive] ?? 0,
        item: <>{t('descriptionCard.irrigation.limitedWater')}</>,
      },
    ],

    chlorophyll: [
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelOne,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelOne] ?? 0,
        item: <>{t('descriptionCard.chlorophyll.higherLevel')}</>,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelTwo,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelTwo] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelThree,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelThree] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelFour,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelFour] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelFive,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelFive] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelSix,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelSix] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelSeven,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelSeven] ?? 0,
        item: <>{t('descriptionCard.chlorophyll.lowerLevel')}</>,
      },
    ],
    grainHumidity: [
      {
        boxBackground: colors.grainHumedityGreen,
        area: colorHistogram.grainHumidity[colors.grainHumedityGreen] ?? 0,
        item: isAbsoluteHumidity ? (
          <>{t('descriptionCard.grainHumidity.levelSix')}</>
        ) : (
          <>{t('descriptionCard.grainHumidity.humid')}</>
        ),
      },
      {
        boxBackground: colors.grainHumedityLightGreen,
        area: colorHistogram.grainHumidity[colors.grainHumedityLightGreen] ?? 0,
        item: isAbsoluteHumidity ? <>{t('descriptionCard.grainHumidity.levelFive')}</> : <NoItem />,
      },
      {
        boxBackground: colors.grainHumedityYellow,
        area: colorHistogram.grainHumidity[colors.grainHumedityYellow] ?? 0,
        item: isAbsoluteHumidity ? <>{t('descriptionCard.grainHumidity.levelFour')}</> : <NoItem />,
      },
      {
        boxBackground: colors.grainHumedityOrange,
        area: colorHistogram.grainHumidity[colors.grainHumedityOrange] ?? 0,
        item: isAbsoluteHumidity ? (
          <>{t('descriptionCard.grainHumidity.levelThree')}</>
        ) : (
          <NoItem />
        ),
      },
      {
        boxBackground: colors.grainHumedityLightBrown,
        area: colorHistogram.grainHumidity[colors.grainHumedityLightBrown] ?? 0,
        item: isAbsoluteHumidity ? <>{t('descriptionCard.grainHumidity.levelTwo')}</> : <NoItem />,
      },
      {
        boxBackground: colors.grainHumedityBrown,
        area: colorHistogram.grainHumidity[colors.grainHumedityBrown] ?? 0,
        item: isAbsoluteHumidity ? (
          <>{t('descriptionCard.grainHumidity.levelOne')}</>
        ) : (
          <>{t('descriptionCard.grainHumidity.dry')}</>
        ),
      },
    ],
    water: [
      {
        boxBackground: BOX_COLORS.WATER.levelOne,

        item: <>{t('descriptionCard.water.abundantWater')}</>,
        area: colorHistogram.water[BOX_COLORS.WATER.levelOne] ?? 0,
      },
      {
        boxBackground: BOX_COLORS.WATER.levelTwo,

        area: colorHistogram.water[BOX_COLORS.WATER.levelTwo] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.WATER.levelThree,

        area: colorHistogram.water[BOX_COLORS.WATER.levelThree] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.WATER.levelFour,

        area: colorHistogram.water[BOX_COLORS.WATER.levelFour] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.WATER.levelFive,

        area: colorHistogram.water[BOX_COLORS.WATER.levelFive] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.WATER.levelSix,

        area: colorHistogram.water[BOX_COLORS.WATER.levelSix] ?? 0,
        item: <>{t('descriptionCard.water.noWater')}</>,
      },
    ],
    weeds: [
      {
        boxBackground: BOX_COLORS.WEEDS.levelThree,
        item: <>{t('descriptionCard.weeds.levelThree')}</>,
      },

      {
        boxBackground: BOX_COLORS.WEEDS.levelTwo,
        item: <>{t('descriptionCard.weeds.levelTwo')}</>,
      },
      {
        boxBackground: BOX_COLORS.WEEDS.levelOne,
        item: <>{t('descriptionCard.weeds.levelOne')}</>,
      },
    ],
  }

  const getSelectedIndexColors = () => {
    if (selectedMapIndex === 'realImage') return ''
    const getStringColors = () => {
      return cardContent[selectedMapIndex]
        .map(cardData => cardData.boxBackground.toString())
        .join(',')
    }
    const selectedMapIndexColors = getStringColors()

    return selectedMapIndexColors
  }
  return { isHistogramNotAvailable, getSelectedIndexColors, cardContent }
}
