import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import useUpdateEffect from 'react-use/lib/useUpdateEffect'

import { FullScreenLoader, ProtectedRoute } from 'components'
import { AmplitudeEventsName, OrganizationGeneralModuleFlag } from 'types'
import { useHasCompanyOrFieldsOrLots, useTrackImpression } from 'hooks'
import { ActiveCompanyContext } from 'contexts'

import { Layout, Sider, Content } from './components'
import {
  SideNavbar,
  SettingsRouter,
  DashboardRouter,
  Map,
  InternalMetrics,
  Onboarding,
  LotsManagement,
} from './features'
import { MapContext } from './contexts'

export const MainRouter: React.FC = () => {
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { noCompany, loading } = useHasCompanyOrFieldsOrLots()
  const mapContextValue = MapContext.useValue()

  const navigate = useNavigate()
  const location = useLocation()

  useTrackImpression({
    eventName: AmplitudeEventsName.APP_OPENING,
  })

  useUpdateEffect(() => {
    const path = location.pathname.split('/')[1]

    navigate(`/${path}`)
  }, [activeCompanyId])

  useEffect(() => {
    if (noCompany) navigate('/onboarding', { replace: true })
  }, [navigate, noCompany])

  return loading ? (
    <FullScreenLoader />
  ) : (
    <Layout>
      {!noCompany && (
        <Sider width="80px">
          <SideNavbar />
        </Sider>
      )}
      <Content>
        <Routes>
          {noCompany && <Route path="onboarding" element={<Onboarding />} />}
          <Route path="dashboard/*" element={<DashboardRouter />} />
          <Route
            index
            path="map"
            element={
              <ProtectedRoute flags={[OrganizationGeneralModuleFlag.SATELLITE_IMAGERY]}>
                <MapContext.Provider value={mapContextValue}>
                  <Map />
                </MapContext.Provider>
              </ProtectedRoute>
            }
          />
          <Route path="settings/*" element={<SettingsRouter />} />
          <Route path="internal/metrics" element={<InternalMetrics />} />
          <Route path="lots-management" element={<LotsManagement />} />
          <Route path="*" element={<Navigate to="/map" replace />} />
        </Routes>
      </Content>
    </Layout>
  )
}
