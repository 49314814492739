import { ActiveCompanyContext } from 'contexts'

import { useUser } from './useUser'

export const useCompanyMilestones = () => {
  const { companiesRoles } = useUser()
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const activeCompanyMilestones =
    companiesRoles?.find(companyRole => companyRole.company.id === activeCompanyId)?.company
      .milestonesTimeline ?? []

  return {
    activeCompanyMilestones,
  }
}
