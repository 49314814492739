import { memo } from 'react'
import { useQuery } from '@apollo/client'

import { FIELDS, Fields, FieldsVariables } from './api'
import { BaseFieldsSelect } from '../BaseFieldsSelect'
import { SelectField } from '../types'

interface Props {
  companyId?: number
  onChange: (field: SelectField) => void
  defaultId?: number
  className?: string
}

export const FieldsSelect: React.FC<Props> = memo(
  ({ companyId, onChange, defaultId, className }) => {
    const { data, loading } = useQuery<Fields, FieldsVariables>(FIELDS, {
      variables: { companyId: companyId! },
      skip: !companyId,
    })

    return (
      <BaseFieldsSelect
        onChange={onChange}
        defaultId={defaultId}
        className={className}
        loading={loading}
        fields={data?.fields.results}
      />
    )
  },
)
