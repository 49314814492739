export interface ChartDataObject {
  name: string
  value: number
  size: number
  cropName: string
}

export interface Props {
  varietiesData: ChartDataObject[]
  cropData: ChartDataObject[]
  showCropChart: boolean
}

export enum BallColorsTypes {
  field = 'FIELD',
  lot = 'LOT',
  neutral = 'NEUTRAL',
  good = 'GOOD',
  alert = 'ALERT',
  danger = 'DANGER',
}
