import React from 'react'
import { useForm } from 'react-hook-form'
import { gql, StoreObject, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { ClientForm } from 'features/MainRouter/components/create-company-modals/CreateClientModal/components'
import { usePermissions, useSubregions, useUser } from 'hooks'
import { UpdateCompany, UpdateCompanyVariables, UPDATE_COMPANY } from 'api'
import { ConversionUtils, GraphQLUtils } from 'utils'
import { useOrganizationRegions } from 'features/MainRouter/hooks'

import { Props } from '../../types'

interface FormType {
  companyName: string
  country: string
  governmentId?: string
  subregion: number
  region?: number
  phoneNumber?: string
}

export const UpdateClientForm: React.FC<Props> = ({ companyId, data }) => {
  const user = useUser()
  const { permissions } = usePermissions()
  const { t: commonT } = useTranslation('common')
  const [updateCompany] = useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY, {
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }, { variables }) => {
      if (!response || !variables) return

      const company: StoreObject = { id: variables.id, __typename: 'Company' }
      cache.modify({
        id: cache.identify(company),
        fields: {
          subregion(cachedSubregion) {
            const newSubregion = cache.identify({
              id: response.updateCompany.company.subregionId,
              __typename: 'Subregion',
            })
            if (newSubregion) {
              const ref = cache.readFragment({
                id: newSubregion,
                fragment: gql`
                  fragment Subregion on Subregion {
                    id
                  }
                `,
              })
              return ref
            }
            return cachedSubregion
          },
        },
      })
    },
  })
  const { regionsOptions } = useOrganizationRegions()

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(commonT('validations.required')),
    governmentId: permissions.isFeatureSetGrupoDiana
      ? yup.string().required(commonT('validations.required'))
      : yup.string(),
    country: yup.string().required(commonT('validations.required')),
    subregion: yup.number().required(commonT('validations.required')),
    region: yup.number(),
    phoneNumber: yup.string(),
  })

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver<FormType>(validationSchema),
    defaultValues: {
      companyName: data.companyName,
      country: data.country,
      governmentId: data.governmentId,
      subregion: data.subregion?.id,
      region: data.subregion?.region?.id,
      phoneNumber: data.phoneNumber,
    },
  })

  const selectedRegion = watch().region

  const { subregions } = useSubregions(selectedRegion)

  const subregionOptions = subregions?.map(subregion => {
    return { label: subregion.name, value: subregion.id }
  })

  const countryIsoCode = !data.phoneNumber
    ? user?.organizationRole?.organization.country.isoCode.toLowerCase()
    : undefined

  return (
    <ClientForm
      isUpdateMode
      control={control}
      hideLocationInputs={data.isOrganizationCompany && !permissions.isAnOrganizationUser}
      regionOptions={regionsOptions ?? []}
      subregionOptions={subregionOptions ?? []}
      selectedSubregionId={data?.subregion?.id ?? undefined}
      selectedRegionId={selectedRegion}
      organizationCountry={user?.organizationRole?.organization.country.id ?? ''}
      countryIsoCode={countryIsoCode}
      onInputNameBlur={handleSubmit(({ companyName }) => {
        updateCompany({ variables: { id: companyId, updateCompanyDTO: { name: companyName } } })
      })}
      onInputGovernmentIdBlur={handleSubmit(({ governmentId }) => {
        updateCompany({
          variables: {
            id: companyId,
            updateCompanyDTO: {
              governmentId: governmentId
                ? ConversionUtils.keepOnlyNumbers(governmentId)
                : undefined,
            },
          },
        })
      })}
      onSelectSubregionBlur={handleSubmit(({ subregion }) => {
        updateCompany({
          variables: { id: companyId, updateCompanyDTO: { subregionId: subregion } },
        })
      })}
      onInputPhoneNumberBlur={handleSubmit(({ phoneNumber }) => {
        updateCompany({
          variables: {
            id: companyId,
            updateCompanyDTO: { phoneNumber: phoneNumber?.length ? `+${phoneNumber}` : undefined },
          },
        })
      })}
    />
  )
}
