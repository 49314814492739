import { Button, Space as AntSpace } from 'antd'
import styled from 'styled-components'

export const SignupButton = styled(Button)`
  width: 100%;
`

export const Space = styled(AntSpace)`
  margin-top: 20px;
  & .ant-form-item {
    margin-bottom: 0 !important;
  }
`
