import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import {
  DELETE_RICE_PHYTOSANITARY,
  DeleteRicePhytosanitaryVariables,
  DeleteRicePhytosanitary,
} from '../api'

export const useDeleteRicePhytosanitary = (onCompleted?: () => void) => {
  const [deleteRicePhytosanitary, { loading }] = useMutation<
    DeleteRicePhytosanitary,
    DeleteRicePhytosanitaryVariables
  >(DELETE_RICE_PHYTOSANITARY, {
    onCompleted,
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data: response }, { variables }) => {
      if (!variables || !response?.deleteRicePhytosanitary.success) return

      const deletedRicePhytosanitary: StoreObject = {
        id: variables.id,
        __typename: 'RiceAppliedPhytosanitary',
      }

      cache.evict({ id: cache.identify(deletedRicePhytosanitary) })
      cache.gc()
    },
  })

  return { deleteRicePhytosanitary, loading }
}
