import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Loader } from 'components'
import { satelliteImage } from 'assets/images'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white} !important;
  font-size: 13px !important;
  font-weight: 600;
`

const Image = styled.img`
  width: 55px;
  height: 55px;
`

const StyledCol = styled(Col)`
  padding: 0 5px;
`

type PopupPosition = 'top' | 'bottom'
type PopupType = 'datesLoader' | 'text'

interface ContainerProps {
  $position: PopupPosition
}
const Container = styled(Row)<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  min-width: 150px;
  max-width: 500px;
  position: absolute;
  padding: 15px;
  bottom: ${({ $position }) => ($position === 'bottom' ? '40px' : undefined)};
  top: ${({ $position }) => ($position === 'top' ? '40px' : undefined)};
  left: 50%;
  transform: translate(-50%);
  z-index: 15;
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
`

const DatesLoader: React.FC = () => {
  const { t } = useTranslation('map')

  return (
    <>
      <Col>
        <Image src={satelliteImage} alt={t('datesLoader.satelliteImageAlt')} />
      </Col>
      <StyledCol span={12}>
        <Text>{t('datesLoader.text')}</Text>
      </StyledCol>
      <Col>
        <Loader />
      </Col>
    </>
  )
}

interface Props {
  position?: PopupPosition
  type: PopupType
  text?: string
}

export const MapPopup: React.FC<Props> = ({ position, type, text }) => {
  return (
    <Container justify="space-around" align="middle" $position={position ?? 'bottom'}>
      {type === 'datesLoader' ? (
        <DatesLoader />
      ) : (
        <StyledCol span={20}>
          <Text>{text}</Text>
        </StyledCol>
      )}
    </Container>
  )
}
