import { Avatar, Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EXTERNAL_LINKS } from 'consts'

const OrganizationLogoContainer = styled(Avatar).attrs({
  size: 48,
  shape: 'square',
})`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border: 4px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  svg {
    margin-top: 5px;
  }
`

const { Text, Link: AntLink } = Typography

const DescriptionText = styled(Text)`
  font-size: 13px !important;
  color: ${({ theme }) => theme.colors.white} !important;
  font-weight: 400 !important;
  line-height: 24px !important;
`

export const Link = styled(AntLink)`
  color: ${({ theme }) => theme.colors.new.secondary[600]} !important;
  &:hover {
    color: ${({ theme }) => theme.colors.new.secondary[400]} !important;
  }
`

interface Props {
  name?: string
  logo?: string | null
}
export const OrganizationItem: React.FC<Props> = ({ name, logo }) => {
  const { t } = useTranslation('navbar')
  return (
    <Space size={20}>
      <OrganizationLogoContainer src={logo} />
      <DescriptionText>
        {t('user.menu.organization.part1')}
        <Link target="_blank" href={EXTERNAL_LINKS.AGROSALES}>
          {t('user.menu.organization.part2')}
        </Link>
        {t('user.menu.organization.part3', { organizationName: name })}
      </DescriptionText>
    </Space>
  )
}
