import { Typography, Col, Row, Form, Input, DatePicker } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

import { DATE_FORMATS } from 'consts'
import { InputTitle } from 'components'
import { usePermissions, useUpdateSeason } from 'hooks'
import { Season } from 'api'

import {
  ConfirmButton,
  DatePickerCol,
  LastModificationCallout,
  Space,
  StyledSelect,
} from './components'

const { Title } = Typography

interface FormType {
  name: string
  startDate: Dayjs
  endDate?: Dayjs | null
  description?: string
}

interface Props {
  season: Season
}

const seasonNames = () => {
  const currentYear = dayjs().year()

  return [currentYear - 1, currentYear, currentYear, currentYear + 1].map((year, index) => {
    const name = index % 2 === 0 ? `${year} B` : `${year} A`
    return { label: name, value: name }
  })
}

export const SeasonForm: React.FC<Props> = ({ season }) => {
  const { t } = useTranslation('season-settings')
  const { t: commonT } = useTranslation('common')
  const { updateSeason, loading } = useUpdateSeason()
  const { permissions } = usePermissions()

  const validationSchema = yup.object().shape({
    name: yup.string().required(commonT('validations.required')),
    startDate: yup.mixed<Dayjs>().required(commonT('validations.required')),
    endDate: yup
      .mixed<Dayjs>()
      .nullable()
      .when('startDate', (date, endDateSchema) => {
        const startDate = date as unknown as Dayjs
        return endDateSchema.test({
          name: 'end-date-test',
          test(endDate, context) {
            if (endDate && endDate.isBefore(startDate)) {
              return context.createError({
                path: context.path,
                message: commonT('validations.earlierDate'),
              })
            }
            return true
          },
        })
      }),
    description: yup.string(),
  })
  const { control, handleSubmit } = useForm({
    resolver: yupResolver<FormType>(validationSchema),
    defaultValues: {
      name: season.name,
      startDate: dayjs(season.startDate),
      endDate: season.endDate ? dayjs(season.endDate) : null,
      description: season.description ?? '',
    },
  })

  return (
    <Space direction="vertical" size={15}>
      <Title level={5}>{t('seasonCard.title')}</Title>
      <Col>
        <InputTitle title={t('seasonCard.inputs.seasonName')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="name"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              {permissions.isFeatureSetGrupoDiana ? (
                <StyledSelect
                  size="large"
                  {...restFieldProps}
                  onChange={name => onChange(name)}
                  options={seasonNames()}
                />
              ) : (
                <Input
                  size="large"
                  {...restFieldProps}
                  onChange={event => onChange(event.target.value)}
                />
              )}
            </Form.Item>
          )}
        />
      </Col>
      <Row gutter={24}>
        <DatePickerCol>
          <InputTitle title={t('seasonCard.inputs.startDate')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="startDate"
            control={control}
            render={({
              field: { onChange, value, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <DatePicker
                  size="large"
                  placeholder={commonT('inputs.selectDate')}
                  format={DATE_FORMATS.FULL_DATE}
                  allowClear={false}
                  value={value ? dayjs(value) : undefined}
                  {...restFieldProps}
                  onChange={date => {
                    if (date) onChange(date)
                  }}
                />
              </Form.Item>
            )}
          />
        </DatePickerCol>
        <DatePickerCol>
          <InputTitle title={t('seasonCard.inputs.endDate')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="endDate"
            control={control}
            render={({
              field: { onChange, value, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <DatePicker
                  size="large"
                  format={DATE_FORMATS.FULL_DATE}
                  placeholder={commonT('inputs.selectDate')}
                  value={value ? dayjs(value) : undefined}
                  {...restFieldProps}
                  onChange={date => {
                    if (date) {
                      onChange(date)
                      return
                    }
                    onChange(null)
                  }}
                />
              </Form.Item>
            )}
          />
        </DatePickerCol>
      </Row>
      <Col>
        <InputTitle title={t('seasonCard.inputs.description')} />
        <Controller
          // eslint-disable-next-line i18next/no-literal-string
          name="description"
          control={control}
          render={({
            field: { onChange, ...restFieldProps },
            fieldState: { error: fieldError },
          }) => (
            <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                {...restFieldProps}
                onChange={event => onChange(event.target.value)}
              />
            </Form.Item>
          )}
        />
      </Col>
      <LastModificationCallout date={new Date(season.updatedAt)} />
      <ConfirmButton
        type="primary"
        loading={loading}
        onClick={handleSubmit(updateSeasonDTO =>
          updateSeason({
            variables: {
              id: season.id,
              updateSeasonDTO: {
                ...updateSeasonDTO,
                description: updateSeasonDTO.description || null,
                startDate: dayjs(updateSeasonDTO.startDate).format(DATE_FORMATS.DATE),
                endDate:
                  updateSeasonDTO.endDate &&
                  dayjs(updateSeasonDTO.endDate).format(DATE_FORMATS.DATE),
              },
            },
          }),
        )}
      >
        {t('seasonCard.confirmButtonText')}
      </ConfirmButton>
    </Space>
  )
}
