import { gql } from '@apollo/client'

export type {
  UpdateRicePhytosanitaryVariables,
  UpdateRicePhytosanitary,
} from './types/UpdateRicePhytosanitary'

export const UPDATE_RICE_PHITOSANITARY = gql`
  mutation UpdateRicePhytosanitary(
    $id: Int!
    $updatePhytosanitaryDTO: UpdateRiceAppliedPhytosanitaryInput!
  ) {
    updateRicePhytosanitary(id: $id, updatePhytosanitaryDTO: $updatePhytosanitaryDTO) {
      code
      phytosanitary {
        id
        brand
        date
        name
        quantity
        unit {
          id
          name
          locales {
            es
            en
          }
        }
        activeIngredients {
          id
          name
          locales {
            es
            en
          }
        }
      }
    }
  }
`
