import { Typography } from 'antd'
import styled from 'styled-components'

const { Text } = Typography

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.olive5} !important;
  font-style: normal !important;
  font-size: 12px !important;
  font-weight: 400 !important;
`

interface Props {
  description: string
}

export const EmptyResults: React.FC<Props> = ({ description }) => {
  return (
    <TextContainer>
      <Description>{description}</Description>
    </TextContainer>
  )
}
