import { useQuery } from '@apollo/client'

import { SUBREGIONS, Subregions, SubregionsVariables } from './api'

export const useSubregions = (regionId?: number) => {
  const { data, loading } = useQuery<Subregions, SubregionsVariables>(SUBREGIONS, {
    variables: { regionId: regionId! },
    skip: !regionId,
  })

  return { subregions: data?.subregions.results, loading }
}
