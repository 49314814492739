import { Row } from 'antd'
import styled from 'styled-components'

export { CropsAndVarietiesCard } from './CropsAndVarietiesCard'
export { LotCard } from './LotCard'
export { Card } from './Card'
export { Weather } from './Weather'
export { RiceProductionCard } from './RiceProductionCard'
export { DamCard } from './DamCard'

export const BodyContainerRow = styled(Row)`
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
`

export const WeatherCard = styled(Row)`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 300px;
  width: 600px;
  padding: 24px;
`

export const ContainerLotCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
`

export const ContainerLotCard = styled.div`
  width: 290px;
  height: 200px;
`
