import styled from 'styled-components'

export const CalendarPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  box-shadow: none;
  .ant-picker-panel {
    border: none !important;
  }
  .ant-picker-header {
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.new.baseColor[400]}`};
  }
  .ant-picker-header > * {
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .ant-picker-header:hover,
  .ant-picker-header button:hover {
    color: ${({ theme }) => theme.colors.new.secondary[600]} !important;
  }
  .ant-picker-content thead tr > th {
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-picker-cell {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white} !important;
    &:before {
      background-color: transparent;
    }
  }
  .ant-picker-cell div:hover,
  .ant-picker-cell-selected div {
    background-color: ${({ theme }) => theme.colors.new.secondary[600]} !important;
  }
  .ant-picker-cell-disabled div {
    color: ${({ theme }) => theme.colors.new.baseColor[600]} !important;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 0px;
  }
`
