import { useEffect, useState } from 'react'
import { Input, Row, Col, Space, Dropdown } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'
import { point } from '@turf/helpers'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'

import { ConversionUtils, GraphQLUtils } from 'utils'
import { ActiveCompanyContext } from 'contexts'
import { InputTitle, Button } from 'components'
import type { SelectField } from 'components/fields-selects/types'
import {
  COMPANY_FIELDS,
  CompanyFields,
  CompanyFieldsVariables,
} from 'hooks/useHasCompanyOrFieldsOrLots/api'
import { useUpdateField } from 'features/MainRouter/features/SettingsRouter/hooks'
import { usePermissions } from 'hooks'

import { StyledCard, Title } from '../../../../components'
import { CreateField, CreateFieldVariables, CREATE_FIELD } from './api'
import { Fields, FieldsSettingsVariables, FIELDS_SETTINGS } from '../../../../api'
import { DamsList, DropdownMenu, StyledItem, StyledRow, Text } from './components'
import { Dam } from '../../hooks/useDams/types'
import { useCreateDams, useUpdateDams } from '../../hooks'

interface FormType {
  fieldName: string
}

interface Props {
  field?: SelectField
  markerCoordinates: number[]
  onSuccess: (fieldName: string, fieldId?: number) => void
  dams: Dam[]
  selectedDamIndex?: number
  onDrawButtonClick: () => void
  onSelectedDamChange: (index: number) => void
  onDamChange: (dam: Dam['dam'], damIndex: number) => void
  onDeleteDam: (damIndex: number) => void
  damsLoading: boolean
  handleDamKml: (file: UploadChangeParam<UploadFile>) => void
}

export const FieldCard: React.FC<Props> = ({
  field,
  markerCoordinates,
  onSuccess,
  dams,
  selectedDamIndex,
  onDrawButtonClick,
  onSelectedDamChange,
  onDamChange,
  onDeleteDam,
  damsLoading,
  handleDamKml,
}) => {
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation('field-settings')
  const { t: commonT } = useTranslation('common')
  const validationSchema = yup.object().shape({
    fieldName: yup.string().required(commonT('validations.required')),
  })
  const [showAddDamDropdown, setShowAddDamDropdown] = useState(false)

  const { createDams } = useCreateDams()
  const { updateDams } = useUpdateDams()

  const { permissions } = usePermissions()

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver<FormType>(validationSchema),
    defaultValues: { fieldName: field?.name ?? '' },
  })

  useEffect(() => {
    if (field) reset({ fieldName: field.name })
  }, [field, reset])

  const [createField, { loading: createLoading }] = useMutation<CreateField, CreateFieldVariables>(
    CREATE_FIELD,
    {
      onCompleted: (data: CreateField) => {
        onSuccess(data.createField.field.name, data.createField.field.id)
      },
      update: (cache, { data: response }) => {
        if (!response || !activeCompanyId) return

        const cachedFields = cache.readQuery<Fields, FieldsSettingsVariables>({
          query: FIELDS_SETTINGS,
          variables: { companyId: activeCompanyId },
        })

        const cachedCompanyFields = cache.readQuery<CompanyFields, CompanyFieldsVariables>({
          query: COMPANY_FIELDS,
          variables: { id: activeCompanyId },
        })

        const newField = response.createField.field
        const fields: Fields['fields'] = cachedFields
          ? { ...cachedFields.fields, results: [...cachedFields.fields.results, newField] }
          : { results: [newField], __typename: 'Fields' }

        cache.writeQuery<Fields, FieldsSettingsVariables>({
          query: FIELDS_SETTINGS,
          data: { fields },
          variables: { companyId: activeCompanyId },
        })

        if (!cachedCompanyFields) return

        const companyFields = {
          company: {
            ...cachedCompanyFields.company,
            fields: [...cachedCompanyFields.company.fields!, newField],
          },
        }

        cache.writeQuery<CompanyFields, CompanyFieldsVariables>({
          query: COMPANY_FIELDS,
          data: { ...companyFields },
          variables: { id: activeCompanyId },
        })
      },
      onError: GraphQLUtils.errorHandler,
    },
  )

  const { updateField, updateLoading } = useUpdateField({ onSuccess })

  const editDescriptionText = permissions.satelliteImagery
    ? t('editField.descriptionWithDams')
    : t('editField.description')

  const createDescriptionText = permissions.satelliteImagery
    ? t('createField.descriptionWithDams')
    : t('createField.description')

  return (
    <StyledCard $isEditMode={!!field}>
      <Title level={3}>{field ? field.name : t('createField.title')}</Title>
      <Space direction="vertical" size={22}>
        <Text>{field ? editDescriptionText : createDescriptionText}</Text>
        <Row align="middle">
          <Col flex={1}>
            <InputTitle title={t('inputs.newField')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="fieldName"
              control={control}
              render={({
                field: { onChange, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <StyledItem validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Input
                    size="large"
                    onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
                    {...restFieldProps}
                  />
                </StyledItem>
              )}
            />
          </Col>
        </Row>
        <Row>
          <InputTitle title={t('inputs.coordinates')} />
          <StyledRow>
            <Col span={12}>
              {commonT('vocabulary.latitude')}: {markerCoordinates[1].toFixed(6)}
            </Col>
            <Col span={12}>
              {commonT('vocabulary.longitude')}: {markerCoordinates[0].toFixed(6)}
            </Col>
          </StyledRow>
        </Row>
        {permissions.satelliteImagery && (
          <>
            <Row>
              <Col flex={1}>
                <Dropdown
                  onOpenChange={() => setShowAddDamDropdown(prevState => !prevState)}
                  dropdownRender={() => (
                    <DropdownMenu
                      onAddDam={() => {
                        setShowAddDamDropdown(false)
                        onDrawButtonClick()
                      }}
                      handleKml={kml => {
                        setShowAddDamDropdown(false)
                        handleDamKml(kml)
                      }}
                    />
                  )}
                  trigger={['click']}
                  open={showAddDamDropdown}
                >
                  <Button type="primary">
                    {t('addDamButtonText')} <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            <DamsList
              dams={dams}
              onSelect={onSelectedDamChange}
              onChange={onDamChange}
              onDelete={onDeleteDam}
              disableDelete={false}
              selectedDamIndex={selectedDamIndex}
              loading={damsLoading}
            />
          </>
        )}

        <Row justify="end">
          <Button
            type="primary"
            loading={createLoading || updateLoading}
            disabled={!!dams.length && dams.some(({ dam, feature }) => !dam.name || !feature)}
            onClick={handleSubmit(({ fieldName }) => {
              if (!activeCompanyId) return
              const markerPoint = point(markerCoordinates)
              if (field) {
                const createDamDTOs = dams
                  .filter(({ dam }) => dam.action === 'create')
                  .map(({ dam, feature }) => ({
                    name: dam.name!,
                    area: feature!.geometry,
                    fieldId: field.id,
                  }))
                const updateDamDTOs = dams
                  .filter(({ dam }) => dam.action === 'update')
                  .map(({ dam, feature }) => ({
                    id: Number(dam.id),
                    name: dam.name!,
                    area: feature!.geometry,
                  }))

                if (createDamDTOs.length) {
                  createDams({ variables: { createDamDTOs } })
                }
                if (updateDamDTOs.length) {
                  updateDams({ variables: { updateDamsArgs: updateDamDTOs } })
                }

                updateField({
                  variables: {
                    id: field.id,
                    updateFieldDTO: {
                      name: fieldName,
                      location: markerPoint.geometry,
                    },
                  },
                })
                return
              }

              createField({
                variables: {
                  createFieldDTO: {
                    name: fieldName,
                    location: markerPoint.geometry,
                    companyId: activeCompanyId,
                    createDamsDTOs: dams.map(({ dam, feature }) => ({
                      name: dam.name!,
                      area: feature!.geometry,
                    })),
                  },
                },
              })
            })}
          >
            {field ? t('editField.buttonText') : t('createField.buttonText')}
          </Button>
        </Row>
      </Space>
    </StyledCard>
  )
}
