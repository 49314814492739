import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { Col, Row } from 'antd'
import ReactMapGL, { MapLayerMouseEvent, Marker, ViewStateChangeEvent } from 'react-map-gl'
import { useNavigate, useParams } from 'react-router-dom'
import { Result } from '@mapbox/mapbox-gl-geocoder'
import { useTranslation } from 'react-i18next'
import pointInPolygon from '@turf/boolean-point-in-polygon'
import { point } from '@turf/helpers'

import { config } from 'config'
import { MAP, NAVIGATION } from 'consts'
import { MapPin, ConfirmationModal as DeleteDamConfirmationModal } from 'components'
import { ActiveCompanyContext } from 'contexts'
import type { SelectField } from 'components/fields-selects/types'
import { LotsMapLocationState, MandatoryFeature } from 'types'
import { useUser } from 'hooks'

import { useDams } from './hooks'
import {
  Control,
  CreationFeedbackCard,
  FieldCard,
  FieldsSelect,
  GeocoderControl,
  Container,
  LeftSidebar,
  MapSidebar,
} from './components'
import { SearchBoxContainer } from '../../components'
import { Dam } from './hooks/useDams/types'

export const FieldsMap: React.FC = () => {
  const { activeCompanyId, activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation('field-settings')
  const user = useUser()

  const [viewport, setViewport] = useState({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    zoom: MAP.ZOOM.DEFAULT,
    ...MAP.DEFAULT_TRANSITION,
  })

  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const [selectedField, setSelectedField] = useState<SelectField>()

  const [markerCoordinates, setMarkerCoordinates] = useState<number[]>([
    MAP.DEFAULT_CENTER.LONGITUDE,
    MAP.DEFAULT_CENTER.LATITUDE,
  ])

  const [isCreateFieldCompleted, setIsCreateFieldCompleted] = useState(false)
  const [fieldName, setFieldName] = useState<string>()
  const [fieldId, setFieldId] = useState<number>()
  const {
    dams,
    onCreateFeature,
    onUpdateFeature,
    onDelete,
    onSelectionChange,
    mode,
    selectedDamIndex,
    handleDrawButtonClick,
    handleDamChange,
    handleSelectedDamChange,
    showDeleteDamModal,
    onCancelDeleteDam,
    onConfirmDeleteDam,
    loadingDeleteDam,
    handleDeleteDam,
    selectedDamToDelete,
    loading,
    handleDamKml,
  } = useDams(selectedField?.id ?? fieldId)

  useEffect(() => {
    if (selectedField) {
      const [longitude, latitude] = selectedField.location.coordinates
      setMarkerCoordinates(selectedField.location.coordinates)
      setViewport(prevViewport => ({ ...prevViewport, longitude, latitude }))
      return
    }

    const activeCompany = user?.companiesRoles?.find(
      company => company.company.id === activeCompanyId,
    )?.company
    if (activeCompany?.subregion?.id) {
      const latitude = activeCompany.subregion.location.coordinates[1]
      const longitude = activeCompany.subregion.location.coordinates[0]
      setMarkerCoordinates(activeCompany.subregion.location.coordinates)
      setViewport(prevViewport => ({ ...prevViewport, longitude, latitude }))
    }
  }, [activeCompanyId, selectedField, user?.companiesRoles])

  const geocoderContainerRef = useRef<HTMLDivElement>(null)

  const onSuccess = (newFieldName: string, newFieldId?: number) => {
    if (id) {
      navigate(NAVIGATION.GO_BACK)
      return
    }

    setFieldName(newFieldName)
    setFieldId(newFieldId)
    setIsCreateFieldCompleted(true)
  }

  const onHandleMarker = (event: MapLayerMouseEvent) => {
    const { lat, lng } = event.lngLat
    const isPointInPolygon = dams.some(dam => {
      if (!dam.feature) return false
      const coordinates = point([lng, lat])
      return pointInPolygon(coordinates, dam.feature)
    })

    if (isPointInPolygon || mode?.mode === 'draw_polygon' || mode?.mode === 'direct_select') return

    const { lngLat } = event
    setMarkerCoordinates([lngLat.lng, lngLat.lat])
  }

  const handleChange = useCallback((newField: SelectField) => {
    setSelectedField(newField)
  }, [])

  const onMove = useCallback(
    (evt: ViewStateChangeEvent) =>
      setViewport({
        transitionDuration: MAP.DEFAULT_TRANSITION.transitionDuration,
        ...evt.viewState,
      }),
    [],
  )

  const onResult = useCallback(({ result }: { result: Result }) => {
    const location =
      result &&
      (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates))

    if (location) {
      setMarkerCoordinates([location[0], location[1]])
    }
  }, [])

  const onCreationFeedbackButtonClick = () => {
    setIsCreateFieldCompleted(false)
    navigate('/settings/lots', {
      replace: true,
      state: {
        fieldId: selectedField?.id ?? fieldId,
        seasonId: activeSeasonId,
        goTo: '/dashboard',
      } as LotsMapLocationState,
    })
  }

  const damFeatures = useMemo(() => {
    const damsWithFeature = dams.filter(({ feature }) => !!feature) as (Omit<Dam, 'feature'> & {
      feature: MandatoryFeature
    })[]

    return damsWithFeature.map(({ feature, dam }) => {
      return { ...feature, id: dam.id }
    })
  }, [dams])

  return (
    <>
      <LeftSidebar>
        <SearchBoxContainer ref={geocoderContainerRef} id="geocoder-container" />
      </LeftSidebar>
      <MapSidebar>
        <Col flex={1}>
          {isCreateFieldCompleted ? (
            <CreationFeedbackCard onClick={onCreationFeedbackButtonClick} fieldName={fieldName} />
          ) : (
            <>
              {id && (
                <Row justify="end">
                  <FieldsSelect
                    companyId={activeCompanyId}
                    onChange={handleChange}
                    defaultId={Number(id)}
                  />
                </Row>
              )}
              <FieldCard
                field={selectedField}
                markerCoordinates={markerCoordinates}
                onSuccess={onSuccess}
                dams={dams}
                selectedDamIndex={selectedDamIndex}
                onDrawButtonClick={handleDrawButtonClick}
                onSelectedDamChange={handleSelectedDamChange}
                onDamChange={handleDamChange}
                onDeleteDam={handleDeleteDam}
                damsLoading={loading}
                handleDamKml={handleDamKml}
              />
            </>
          )}
        </Col>
      </MapSidebar>
      <Container>
        <ReactMapGL
          {...viewport}
          onMove={onMove}
          id="fieldsMap"
          cursor={mode?.mode === 'draw_polygon' ? 'crosshair' : 'grab'}
          mapStyle={MAP.STYLES.SATELLITE_STREET}
          onClick={onHandleMarker}
        >
          {!isCreateFieldCompleted && (
            <GeocoderControl
              geocoderOptions={{
                accessToken: config.mapboxToken,
                language: MAP.LANGUAGES.ES,
                placeholder: t('geocoderPlaceholder'),
              }}
              containerRef={geocoderContainerRef}
              onResult={onResult}
            />
          )}
          <Control
            mapOptions={{
              displayControlsDefault: false,
              controls: {
                polygon: true,
                trash: true,
              },
            }}
            onCreate={onCreateFeature}
            onUpdate={onUpdateFeature}
            onDelete={onDelete}
            onSelectionChange={onSelectionChange}
            features={damFeatures}
            mode={mode}
          />
          <Marker
            latitude={markerCoordinates[1]}
            longitude={markerCoordinates[0]}
            draggable={!isCreateFieldCompleted}
            onDrag={event => {
              const { lngLat } = event
              setMarkerCoordinates([lngLat.lng, lngLat.lat])
            }}
          >
            <MapPin />
          </Marker>
        </ReactMapGL>
      </Container>
      {showDeleteDamModal && (
        <DeleteDamConfirmationModal
          description={t('deleteConfirmationText', {
            damName: selectedDamToDelete?.dam.name,
            fieldName: selectedField?.name,
          })}
          onConfirm={onConfirmDeleteDam}
          onCancel={onCancelDeleteDam}
          loading={loadingDeleteDam}
        />
      )}
    </>
  )
}
