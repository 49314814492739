import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { VALIDATIONS } from 'consts'

export interface Form {
  actualPassword: string
  password: string
  passwordConfirm: string
}

export const useUpdatePassword = () => {
  const { t } = useTranslation('common')

  const validationSchema = yup.object().shape({
    actualPassword: yup
      .string()
      .min(
        VALIDATIONS.PASSWORD_MIN_LENGTH,
        t('validations.minLength', { minLength: VALIDATIONS.PASSWORD_MIN_LENGTH }),
      )
      .required(t('validations.required')),
    password: yup
      .string()
      .min(
        VALIDATIONS.PASSWORD_MIN_LENGTH,
        t('validations.minLength', { minLength: VALIDATIONS.PASSWORD_MIN_LENGTH }),
      )
      .required(t('validations.required')),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password')], t('validations.equalPassword'))
      .required(t('validations.required')),
  })
  return useForm<Form>({ resolver: yupResolver(validationSchema) })
}
