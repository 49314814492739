import { Typography, Input as AntInput } from 'antd'
import styled from 'styled-components'

const { Text: AntText } = Typography

export const Border = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  padding: 6px;
  min-width: 120px;
`
export const Input = styled(AntInput)`
  height: 40px;
  width: 70px;
  margin-right: 8px;
`

export const ContainerNumber = styled.div`
  background: ${({ theme }) => theme.colors.trafficLightNeutral};
  padding: 8px 13px;
  border-radius: 2px;
  margin-right: 8px;
  height: 40px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UnitNumber = styled(AntText)`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.olive5};
`
export const UnitText = styled(AntText)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;

  color: ${({ theme }) => theme.colors.olive};
`

export const Title = styled(AntText)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: ${({ theme }) => theme.colors.olive5};
`
