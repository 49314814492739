import { gql } from '@apollo/client'

export type { DeleteCompany, DeleteCompanyVariables } from './types/DeleteCompany'

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: Int!) {
    deleteCompany(id: $id) {
      user {
        id
        email
        companiesRoles {
          role
          company {
            id
            name
            country {
              id
              lng
              code
            }
          }
        }
      }
    }
  }
`
