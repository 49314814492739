import { useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'

import { SEASON_LOTS, SeasonLots, SeasonLotsVariables } from './api'
import { DataType, FieldFilter, Filters, LotFilter } from '../../types'

interface Props {
  startRange?: string
  endRange?: string
}

export const useSeasonLots = ({ startRange, endRange }: Props) => {
  const { data, loading } = useQuery<SeasonLots, SeasonLotsVariables>(SEASON_LOTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      endRange: endRange!,
      startRange: startRange!,
    },
    skip: !startRange || !endRange,
  })

  const allCompaniesFilters: Filters[] = []
  const allFieldsFilters: FieldFilter[] = []
  const allLotsFilters: LotFilter[] = []

  const dataSource: DataType[] =
    data?.seasonLots.results.map(seasonLot => {
      allCompaniesFilters.push({ value: seasonLot.company.id, text: seasonLot.company.name })
      allFieldsFilters.push({
        value: seasonLot.field.id,
        text: seasonLot.field.name,
        companyId: seasonLot.company.id,
      })
      allLotsFilters.push({
        value: seasonLot.id,
        text: seasonLot.name,
        companyId: seasonLot.company.id,
        fieldId: seasonLot.field.id,
      })
      return {
        companyName: seasonLot.company.name,
        companyId: seasonLot.company.id,
        fieldName: seasonLot.field.name,
        fieldId: seasonLot.field.id,
        seasonName: seasonLot.season.name,
        seasonId: seasonLot.season.id,
        lotName: seasonLot.name,
        varietyId: seasonLot.variety?.id,
        plantingDate: seasonLot.riceLot.plantingDate ?? undefined,
        emergenceDate: seasonLot.riceLot.emergenceDate ?? undefined,
        harvestDate: seasonLot.riceLot.harvestDate ?? undefined,
        preparationDate: seasonLot.riceLot.preparationDate ?? undefined,
        burningDate: seasonLot.riceLot.burningDate ?? undefined,
        earAppearanceDate: seasonLot.riceLot.earAppearanceDate ?? undefined,
        differentiationDate: seasonLot.riceLot.differentiationDate ?? undefined,
        firstIrrigationEndDate: seasonLot.riceLot.firstIrrigationEndDate ?? undefined,
        firstIrrigationStartDate: seasonLot.riceLot.firstIrrigationStartDate ?? undefined,
        floweringDate: seasonLot.riceLot.floweringDate ?? undefined,
        physiologicalMaturityDate: seasonLot.riceLot.physiologicalMaturityDate ?? undefined,
        estimatedYield: seasonLot.riceLot.estimatedYield.lotValue ?? undefined,
        yield: seasonLot.riceLot.yield ?? undefined,
        riceLotId: seasonLot.riceLot.id,
        lotId: seasonLot.id,
        cropId: seasonLot.crop.id,
        previousCropId: seasonLot.previousCrop?.id,
        productionType: seasonLot.productionType ?? undefined,
        abandonedSize: seasonLot.abandonedSize ?? undefined,
        deliveryCondition: seasonLot.deliveryCondition ?? undefined,
        deliveryMillId: seasonLot.deliveryMill?.id,
        emergenceUpdateType: seasonLot.riceLot.emergenceUpdateType ?? undefined,
        plantingUpdateType: seasonLot.riceLot.plantingUpdateType ?? undefined,
        floweringUpdateType: seasonLot.riceLot.floweringUpdateType ?? undefined,
        harvestUpdateType: seasonLot.riceLot.harvestUpdateType ?? undefined,
        preparationUpdateType: seasonLot.riceLot.preparationUpdateType ?? undefined,
        burningUpdateType: seasonLot.riceLot.burningUpdateType ?? undefined,
        earAppearanceUpdateType: seasonLot.riceLot.earAppearanceUpdateType ?? undefined,
        differentiationUpdateType: seasonLot.riceLot.differentiationUpdateType ?? undefined,
        size: seasonLot.size,
      }
    }) ?? []

  const allcompanyValues = uniqBy(allCompaniesFilters, 'value')
  const allFieldValues = uniqBy(allFieldsFilters, 'value')
  const allLotValues = uniqBy(allLotsFilters, 'value')

  return {
    seasonLots: data?.seasonLots.results,
    loading,
    dataSource,
    allcompanyValues,
    allFieldValues,
    allLotValues,
  }
}
