import React from 'react'
import { Space, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'

import { StyledCard, Title, Text } from '../../../../components'

interface Props {
  fieldName?: string
  onClick: () => void
}

export const CreationFeedbackCard: React.FC<Props> = ({ onClick, fieldName }) => {
  const { t } = useTranslation('field-settings')

  return (
    <StyledCard>
      <Title>{fieldName}</Title>
      <Space direction="vertical" size={22}>
        <Text>{t('feedbackCard.text')} </Text>
        <Row justify="end">
          <Button onClick={onClick} type="primary">
            {t('feedbackCard.buttonText')}
          </Button>
        </Row>
      </Space>
    </StyledCard>
  )
}
