import { useNavigate } from 'react-router-dom'
import { useMutation, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import { BROADCAST_CHANNEL, STORAGE_KEYS } from 'consts'
import { ActiveCompanyContext, AuthContext } from 'contexts'

import { useLocalStorage } from '../useLocalStorage'
import { LoginWithToken, LOGIN_WITH_TOKEN } from './api'

export const useAuth = (resetActiveCompany?: () => void) => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const {
    login: contextLogin,
    logout: contextLogout,
    userId,
    authChannel,
  } = AuthContext.useAuthContext()
  const { resetStore } = ActiveCompanyContext.useActiveCompanyContext()

  const [userToken, setUserToken, clearUserToken] = useLocalStorage<string>(STORAGE_KEYS.USER_TOKEN)
  const [, , clearUserLanguage] = useLocalStorage<string>(STORAGE_KEYS.USER_LANGUAGE)

  const clearUserData = useCallback(async () => {
    clearUserToken()
    contextLogout()
    clearUserLanguage()
    await client.clearStore()
  }, [clearUserLanguage, clearUserToken, client, contextLogout])

  const handleLogout = async () => {
    authChannel?.postMessage(BROADCAST_CHANNEL.AUTH.LOGOUT)
    await clearUserData()
    navigate('/login', { replace: true })
  }

  const logout = async () => {
    resetStore()
    await handleLogout()
  }

  const onError = async () => {
    // We need to call resetActiveCompany because tokenLoginMutation is being called outside of CompanyContextProvider.
    resetActiveCompany?.()
    await handleLogout()
  }

  const [tokenLoginMutation, { loading: tokenLoginLoading }] = useMutation<LoginWithToken>(
    LOGIN_WITH_TOKEN,
    {
      onCompleted: data => {
        contextLogin({
          id: data.loginWithToken.user.id,
          email: data.loginWithToken.user.email,
          name: data.loginWithToken.user.name,
          lastName: data.loginWithToken.user.lastName,
          organizationId: data.loginWithToken.user.organizationRole?.organization.id,
          organizationName: data.loginWithToken.user.organizationRole?.organization.name,
        })
        setUserToken(data.loginWithToken.token)
      },
      onError,
    },
  )

  const tokenLogin = () => tokenLoginMutation()

  return {
    userId,
    userToken,
    tokenLogin,
    tokenLoginLoading,
    logout,
    authChannel,
    clearUserData,
  }
}
