import styled from 'styled-components'
import { Select as AntSelect } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { isNumber } from 'lodash'

import { UserCompanyRole } from 'types'

import { useIrrigationWorkers } from './hooks'

const Select = styled(AntSelect)`
  width: 170px;
` as typeof AntSelect

const UNASSIGNED_VALUE = 'unassign-irrigation-worker'

interface Props {
  selectedValue?: number
  lotId: number
  companyId?: number
}

export const IrrigationWorkersSelect: React.FC<Props> = ({ selectedValue, companyId, lotId }) => {
  const { t } = useTranslation('common')

  const {
    assignIrrigationWorker,
    companyIrrigationWorkers,
    isAssignIrrigationWorkerLoading,
    isCompanyIrrigationWorkersLoading,
  } = useIrrigationWorkers(companyId)

  const options = useMemo(() => {
    if (!companyIrrigationWorkers) return []

    const filteredIrrigationWorkers = companyIrrigationWorkers.filter(
      irrigationWorker => irrigationWorker.role === UserCompanyRole.IRRIGATION_WORKER,
    )

    return filteredIrrigationWorkers?.map(irrigationWorker => ({
      value: irrigationWorker.user.id,
      label: `${irrigationWorker.user.name} ${irrigationWorker.user.lastName}`,
    }))
  }, [companyIrrigationWorkers])

  return (
    <Select
      value={selectedValue ?? UNASSIGNED_VALUE}
      loading={isAssignIrrigationWorkerLoading || isCompanyIrrigationWorkersLoading}
      onChange={id => {
        const irrigationWorkerId = isNumber(id) ? id : null
        assignIrrigationWorker({ variables: { updateLotDTO: { id: lotId, irrigationWorkerId } } })
      }}
    >
      <Select.Option value={UNASSIGNED_VALUE}>
        {t('inputs.unassignedIrrigationWorker')}
      </Select.Option>
      {options?.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}
