import styled from 'styled-components'
import {
  Layout as AntLayout,
  Input as AntInput,
  Select as AntSelect,
  Space,
  Typography,
} from 'antd'

export { CreateCompanyCard } from './CreateCompanyCard'
export { CreateClientCard } from './CreateClientCard'

export const Layout = styled(AntLayout)`
  height: 100%;
  align-items: center;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  padding: 25px 50px;
  @media (min-width: 576px) {
    padding: 40px 100px;
  }
  @media (min-width: 992px) {
    padding: 60px 200px;
  }
`
export const Container = styled(Space)`
  max-width: 700px;
`
const { Title: AntTitle } = Typography

export const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.white} !important;
  font-weight: 600 !important;
`
export const Description = styled(Title)`
  font-weight: 400 !important;
  line-height: 26px !important;
`
export const FormContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  justify-content: space-between;
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
`
export const FormItemContainer = styled.div`
  height: 50px;
  width: 100%;
`
export const Input = styled(AntInput)`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 0;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.white}`};
  background-color: transparent;
  box-shadow: none !important;
`
export const Select = styled(AntSelect)`
  width: 100%;
  .ant-select-selector {
    font-size: 13px;
    font-weight: 600;
    color: white !important;
    align-items: center;
    height: 50px !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: 2px solid white !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
` as typeof AntSelect
export const Image = styled.img`
  background-position: center;
  width: 26px;
  height: 26px;
  margin-right: 13px;
`
