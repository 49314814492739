import { useEffect, memo } from 'react'
import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

import { SelectField } from '../types'

const Select = styled(AntSelect)`
  min-width: 206px;
` as typeof AntSelect

interface Props {
  onChange: (field: SelectField) => void
  defaultId?: number
  className?: string
  loading: boolean
  fields?: SelectField[]
  dropdownClassName?: string
  popupMatchSelectWidth?: boolean
}

export const BaseFieldsSelect: React.FC<Props> = memo(
  ({
    onChange,
    defaultId,
    className,
    loading,
    fields,
    dropdownClassName,
    popupMatchSelectWidth,
  }) => {
    const defaultField = fields?.find(field => field.id === defaultId) ?? fields?.[0]

    useEffect(() => {
      if (defaultField) {
        onChange(defaultField)
      }
    }, [defaultField, onChange])

    return (
      <Select
        popupMatchSelectWidth={popupMatchSelectWidth}
        popupClassName={dropdownClassName}
        loading={loading}
        className={className}
        key={defaultField?.id}
        defaultValue={defaultField?.id}
        onChange={id => {
          const selectedField = fields?.find(field => field.id === id)
          if (!selectedField) return
          onChange(selectedField)
        }}
      >
        {fields?.map(field => (
          <Select.Option key={field.id} value={field.id}>
            {field.name}
          </Select.Option>
        ))}
      </Select>
    )
  },
)
