/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Crops, Crops_crops_results as Crop, CropsVariables } from './types/Crops'

export const CROPS = gql`
  query Crops($all: Boolean) {
    crops(all: $all) {
      results {
        id
        name
        locales {
          es
          en
        }
      }
    }
  }
`
