import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import styled, { useTheme } from 'styled-components'

import { GreyCardWithTitle } from 'components'

import { HistoricalIrrigation } from '../../../../../../api'
import { CustomTooltip } from './components'

interface Props {
  title: string
  subTitle: string
  historicalIrrigationValues: HistoricalIrrigation['values']
}

const ChartContainer = styled(Col)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  flex: 1;
  height: 250px;
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  padding: 10px 0;
`

const formatTick = (value: number) => `${value}%`
const BREAKAGE_RATES = [0, 60, 85, 100]

export const HistoricIrrigationCard: React.FC<Props> = ({
  title,
  historicalIrrigationValues,
  subTitle,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation('lot-dashboard')

  return (
    <GreyCardWithTitle title={title} subTitle={subTitle}>
      <ChartContainer span={24}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={historicalIrrigationValues}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="daysSinceEmergence"
              interval={0}
              tickCount={historicalIrrigationValues.length}
              label={{
                value: t('tabs.secondTab.irrigationCard.chart.xAxis'),
                position: 'insideBottomRight',
                offset: -5,
              }}
            />
            <Tooltip
              content={({ payload, label }) => <CustomTooltip payload={payload} label={label} />}
            />

            <YAxis
              ticks={BREAKAGE_RATES}
              tickFormatter={formatTick}
              interval={0}
              tick={{ fontSize: 12 }}
            />
            <CartesianGrid strokeDasharray="3 3" vertical={false} />

            <Bar dataKey="percentage" fill={colors.blue} />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </GreyCardWithTitle>
  )
}
